import React from 'react';
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';

const Overlay = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const DialogContent = styled(motion.div)`
  background: rgb(0, 4, 29);
  padding: 20px;
  border-radius: 10px;
  width: 70%;
  max-height: 80%;
  overflow-y: auto;
  color: rgb(255, 255, 255);
  position: relative;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  color: rgb(255, 255, 255);
  font-size: 24px;
  cursor: pointer;
  padding: 5px;
  line-height: 1;
`;

const DialogHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const DialogTitle = styled.h2`
  margin: 0;
  padding-right: 40px; // Ensure title doesn't overlap with close button
`;

const Dialog = ({ isOpen, onClose, title, children }) => {
  return (
    <AnimatePresence>
      {isOpen && (
        <Overlay
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          onClick={onClose}
        >
          <DialogContent
            initial={{ y: -50, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: -50, opacity: 0 }}
            onClick={(e) => e.stopPropagation()} // Prevent closing when clicking inside the dialog
          >
            <DialogHeader>
              <DialogTitle>{title}</DialogTitle>
              <CloseButton onClick={onClose}>&times;</CloseButton>
            </DialogHeader>
            {children}
          </DialogContent>
        </Overlay>
      )}
    </AnimatePresence>
  );
};

export default Dialog;