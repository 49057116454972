// // // Nav.js
// // import React from 'react';
// // import { Link } from 'react-router-dom';
// // import styled from 'styled-components';

// // const Nav = styled.nav`
// //   width: 100%;
// //   background-color: #333;
// //   padding: 10px 0;
// //   display: flex;
// //   justify-content: flex-end;
// //   flex-wrap: wrap;

// //   @media (max-width: 768px) {
// //     justify-content: center;
// //   }
// // `;

// // const NavLink = styled(Link)`
// //   color: #fff;
// //   text-decoration: none;
// //   padding: 0 20px;
// //   font-size: 18px;
// //   position: relative;
// //   transition: color 0.3s;

// //   &::after {
// //     content: '';
// //     position: absolute;
// //     width: 0;
// //     height: 2px;
// //     display: block;
// //     margin-top: 5px;
// //     right: 0;
// //     background: #007bff;
// //     transition: width 0.4s ease, right 0.4s ease;
// //   }

// //   &:hover {
// //     color: #007bff;
// //   }

// //   &:hover::after {
// //     width: 100%;
// //     right: 0;
// //   }

// //   @media (max-width: 768px) {
// //     padding: 0 10px;
// //     font-size: 16px;
// //   }
// // `;

// // const Navigation = () => {
// //   const handleClick = (e, targetId) => {
// //     e.preventDefault();
// //     const targetSection = document.querySelector(targetId);
// //     targetSection.scrollIntoView({ behavior: 'smooth' });
// //   };

// //   return (
// //     <Nav>
// //       <NavLink to="/">Home</NavLink>
// //       <NavLink to="#about" onClick={(e) => handleClick(e, '#about')}>About</NavLink>
// //       <NavLink to="#contact" onClick={(e) => handleClick(e, '#contact')}>Contact</NavLink>
// //       <NavLink to="#privacypolicy" onClick={(e) => handleClick(e, '#privacypolicy')}>Privacy Policy</NavLink>
// //       <NavLink to="#TermsAndConditions" onClick={(e) => handleClick(e, '#TermsAndConditions')}>Terms and Condition</NavLink>
// //       <NavLink to="/sign-in">Login</NavLink>
// //       <NavLink to="#buyNow" onClick={(e) => handleClick(e, '#buyNow')}>Pricing</NavLink>
// //     </Nav>
// //   );
// // };

// // export default Navigation;
// // import React from 'react';
// // import { Link } from 'react-router-dom';
// // import styled from 'styled-components';
// // import { SignedIn, SignedOut } from '@clerk/clerk-react';

// // const Nav = styled.nav`
// //   width: 100%;
// //   background-color: rgb(0, 6, 24);
// //   padding: 10px 0;
// //   display: flex;
// //   justify-content: flex-end;
// //   flex-wrap: wrap;

// //   @media (max-width: 768px) {
// //     justify-content: center;
// //   }
// // `;

// // const NavLink = styled(Link)`
// //   color: rgb(255, 255, 255);
// //   text-decoration: none;
// //   padding: 0 20px;
// //   margin: 5px 0;
// //   font-size: 18px;
// //   position: relative;
// //   transition: color 0.3s;

// //   &::after {
// //     content: '';
// //     position: absolute;
// //     width: 0;
// //     height: 2px;
// //     display: block;
// //     margin-top: 5px;
// //     right: 0;
// //     background: rgb(128, 255, 255);
// //     transition: width 0.4s ease, right 0.4s ease;
// //   }

// //   &:hover {
// //     color: rgb(128, 255, 255);
// //   }

// //   &:hover::after {
// //     width: 100%;
// //     right: 0;
// //   }

// //   @media (max-width: 768px) {
// //     padding: 0 10px;
// //     font-size: 16px;
// //     margin: 5px 10px;
// //   }
// // `;

// // // const Navigation = () => {
// // //   const handleClick = (e, targetId) => {
// // //     e.preventDefault();
// // //     const targetSection = document.querySelector(targetId);
// // //     targetSection.scrollIntoView({ behavior: 'smooth' });
// // //   };

// // //   return (
// // //     <Nav>
// // //       <NavLink to="/">Home</NavLink>
// // //       <NavLink to="#about" onClick={(e) => handleClick(e, '#about')}>About</NavLink>
// // //       <NavLink to="#contact" onClick={(e) => handleClick(e, '#contact')}>Contact</NavLink>
// // //       <NavLink to="#privacypolicy" onClick={(e) => handleClick(e, '#privacypolicy')}>Privacy Policy</NavLink>
// // //       <NavLink to="#TermsAndConditions" onClick={(e) => handleClick(e, '#TermsAndConditions')}>Terms and Condition</NavLink>
// // //       <NavLink to="/sign-in">Login</NavLink>
// // //       <NavLink to="#buyNow" onClick={(e) => handleClick(e, '#buyNow')}>Pricing</NavLink>
// // //     </Nav>
// // //   );
// // // };

// // // export default Navigation;
// // const Navigation = () => {
// //   const handleClick = (e, targetId) => {
// //     e.preventDefault();
// //     const targetSection = document.querySelector(targetId);
// //     targetSection.scrollIntoView({ behavior: 'smooth' });
// //   };

//   // return (
//   //   <Nav>
//   //     <NavLink to="/">Home</NavLink>
//   //     <NavLink to="#about" onClick={(e) => handleClick(e, '#about')}>About</NavLink>
//   //     <NavLink to="#contact" onClick={(e) => handleClick(e, '#contact')}>Contact</NavLink>
//   //     {/* <NavLink to="#privacypolicy" onClick={(e) => handleClick(e, '#privacypolicy')}>Privacy Policy</NavLink> */}
//   //     {/* <NavLink to="#TermsAndConditions" onClick={(e) => handleClick(e, '#TermsAndConditions')}>Terms and Condition</NavLink> */}
//   //     <SignedOut>
//   //       <NavLink to="/sign-in">Login</NavLink>
//   //     </SignedOut>
//   //     <SignedIn>
//   //       <NavLink to="/gst">Converter</NavLink>
//   //     </SignedIn>
//   //     <NavLink to="#buyNow" onClick={(e) => handleClick(e, '#buyNow')}>Pricing</NavLink>
//   //   </Nav>
//   // );
// // };

// // export default Navigation;

// // import React from 'react';
// // import { Link } from 'react-router-dom';
// // import styled from 'styled-components';
// // import { SignedIn, SignedOut } from '@clerk/clerk-react';
// // import Tlogo from './Tlogo.png'

// // // Styled components for Navigation
// // const Nav = styled.nav`
// //   background-color: white;
// //   padding: 10px 20px;
// //   display: flex;
// //   justify-content: space-between;
// //   align-items: center;
// //   flex-wrap: wrap;

// //   @media (max-width: 768px) {
// //     justify-content: center;
// //   }
// // `;

// // const Logo = styled(Link)`
// //   color: rgb(0, 116, 255); // Blue color for logo
// //   font-size: 24px;
// //   font-weight: bold;
// //   text-decoration: none;
// // `;

// // const NavLinks = styled.div`
// //   display: flex;
// //   justify-content: flex-end;
// //   align-items: center;

// //   @media (max-width: 768px) {
// //     flex-direction: column;
// //     align-items: center;
// //   }
// // `;

// // const NavLink = styled(Link)`
// //   color: black; // White color for links
// //   text-decoration: none;
// //   padding: 0 20px;
// //   margin: 5px 0;
// //   font-size: 18px;
// //   position: relative;
// //   transition: color 0.3s;

// //   &::after {
// //     content: '';
// //     position: absolute;
// //     width: 0;
// //     height: 2px;
// //     display: block;
// //     margin-top: 5px;
// //     right: 0;
// //     background: black; // Light blue underline on hover
// //     transition: width 0.4s ease, right 0.4s ease;
// //   }

// //   &:hover {
// //     color: black;
// //   }

// //   &:hover::after {
// //     width: 100%;
// //     right: 0;
// //   }

// //   @media (max-width: 768px) {
// //     padding: 0 10px;
// //     font-size: 16px;
// //     margin: 5px 10px;
// //   }
// // `;


// // const ButtonLink = styled(Link)`
// //   background-color: rgb(0, 116, 255); // Blue background for button
// //   color: rgb(255, 255, 255); // White color for button text
// //   border: none;
// //   border-radius: 20px;
// //   padding: 10px 20px;
// //   text-decoration: none;
// //   font-size: 18px;
// //   transition: background-color 0.3s;

// //   &:hover {
// //     background-color: rgb(0, 90, 200);
// //   }

// //   @media (max-width: 768px) {
// //     font-size: 16px;
// //     padding: 8px 16px;
// //   }
// // `;

// // // Navigation Component
// // const Navigation = () => {
// //   const handleClick = (e, targetId) => {
// //     e.preventDefault();
// //     const targetSection = document.querySelector(targetId);
// //     targetSection.scrollIntoView({ behavior: 'smooth' });
// //   };

// //   return (
// //     <Nav>
// //       <Logo to="/">TallyMatic</Logo> {/* Logo for navigation */}
// //       <NavLinks>
// //         <NavLink to="#about" onClick={(e) => handleClick(e, '#about')}>About</NavLink>
// //         <NavLink to="#contact" onClick={(e) => handleClick(e, '#contact')}>Contact</NavLink>
// //         <NavLink to="#buyNow" onClick={(e) => handleClick(e, '#buyNow')}>Pricing</NavLink>

// //         <SignedOut>
// //           <ButtonLink to="/sign-in">Login</ButtonLink>
// //         </SignedOut>
// //         <SignedIn>
// //           <ButtonLink to="/gst">Converter</ButtonLink>
// //         </SignedIn>
// //         {/* <NavLink to="#buyNow" onClick={(e) => handleClick(e, '#buyNow')}>Pricing</NavLink> */}
// //         {/* <ButtonLink to="/sign-in">Get started for free</ButtonLink> */}
// //       </NavLinks>
// //     </Nav>
// //   );
// // };

// // export default Navigation;

// import React from 'react';
// import { Link } from 'react-router-dom';
// import styled from 'styled-components';
// import { SignedIn, SignedOut } from '@clerk/clerk-react';
// import Tlogo from './Tlogo.png';

// // Styled components for Navigation
// const Nav = styled.nav`
//   background-color: white;
//   padding: 10px 20px;
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   flex-wrap: wrap;

//   @media (max-width: 768px) {
//     justify-content: space-between;
//   }
// `;

// const Logo = styled(Link)`
//   color: rgb(0, 116, 255); // Blue color for logo
//   font-size: 24px;
//   font-weight: bold;
//   text-decoration: none;
// `;

// const NavLinks = styled.div`
//   display: flex;
//   justify-content: flex-end;
//   align-items: center;

//   @media (max-width: 768px) {
//     display: none; // Hide links in mobile view
//   }
// `;

// const NavLink = styled(Link)`
//   color: black; // Black color for links
//   text-decoration: none;
//   padding: 0 20px;
//   margin: 5px 0;
//   font-size: 18px;
//   position: relative;
//   transition: color 0.3s;

//   &::after {
//     content: '';
//     position: absolute;
//     width: 0;
//     height: 2px;
//     display: block;
//     margin-top: 5px;
//     right: 0;
//     background: black; // Black underline on hover
//     transition: width 0.4s ease, right 0.4s ease;
//   }

//   &:hover {
//     color: black;
//   }

//   &:hover::after {
//     width: 100%;
//     right: 0;
//   }
// `;

// const ButtonLink = styled(Link)`
//   background-color: rgb(0, 116, 255); // Blue background for button
//   color: rgb(255, 255, 255); // White color for button text
//   border: none;
//   border-radius: 20px;
//   padding: 10px 20px;
//   text-decoration: none;
//   font-size: 18px;
//   transition: background-color 0.3s;

//   &:hover {
//     background-color: rgb(0, 90, 200);
//   }

//   @media (max-width: 768px) {
//     font-size: 16px;
//     padding: 8px 16px;
//   }
// `;

// const MobileNav = styled.div`
//   display: none;

//   @media (max-width: 768px) {
//     display: flex;
//     justify-content: space-between;
//     align-items: center;
//     width: 100%;
//   }
// `;

// // Navigation Component
// const Navigation = () => {
//   const handleClick = (e, targetId) => {
//     e.preventDefault();
//     const targetSection = document.querySelector(targetId);
//     targetSection.scrollIntoView({ behavior: 'smooth' });
//   };

//   return (
//     <Nav>
//       <Logo to="/">TallyMatic</Logo> {/* Logo for navigation */}
//       <NavLinks>
//         <NavLink to="#about" onClick={(e) => handleClick(e, '#about')}>About</NavLink>
//         <NavLink to="#contact" onClick={(e) => handleClick(e, '#contact')}>Contact</NavLink>
//         <NavLink to="#buyNow" onClick={(e) => handleClick(e, '#buyNow')}>Pricing</NavLink>
//         <SignedOut>
//           <ButtonLink to="/sign-in">Login</ButtonLink>
//         </SignedOut>
//         <SignedIn>
//           <ButtonLink to="/gst">Converter</ButtonLink>
//         </SignedIn>
//       </NavLinks>
//       <MobileNav>
//         <Logo to="/">TallyMatic</Logo>
//         <SignedOut>
//           <ButtonLink to="/sign-in">Login</ButtonLink>
//         </SignedOut>
//         <SignedIn>
//           <ButtonLink to="/gst">Converter</ButtonLink>
//         </SignedIn>
//       </MobileNav>
//     </Nav>
//   );
// };

// export default Navigation;
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { SignedIn, SignedOut } from '@clerk/clerk-react';
import Tlogo from './Tlogo.png';

// Styled components for Navigation
const Nav = styled.nav`
  background-color: white;
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    justify-content: space-between;
  }
`;

const Logo = styled(Link)`
  color: rgb(0, 116, 255); // Blue color for logo
  font-size: 25px;
  font-weight: bold;
  text-decoration: none;

  @media (max-width: 768px) {
    display: none; // Hide logo in mobile view
  }
`;

const MobileLogo = styled(Link)`
  color: rgb(0, 116, 255); // Blue color for logo
  font-size: 24px;
  font-weight: bold;
  text-decoration: none;
  display: none;

  @media (max-width: 768px) {
    display: block; // Show logo in mobile view
  }
`;

const NavLinks = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  @media (max-width: 768px) {
    display: none; // Hide links in mobile view
  }
`;

const NavLink = styled(Link)`
  color: black; // Black color for links
  text-decoration: none;
  padding: 0 20px;
  margin: 5px 0;
  font-size: 18px;
  position: relative;
  transition: color 0.3s;

  &::after {
    content: '';
    position: absolute;
    width: 0;
    height: 2px;
    display: block;
    margin-top: 5px;
    right: 0;
    background: black; // Black underline on hover
    transition: width 0.4s ease, right 0.4s ease;
  }

  &:hover {
    color: black;
  }

  &:hover::after {
    width: 100%;
    right: 0;
  }

  @media (max-width: 768px) {
    padding: 0 10px;
    font-size: 16px;
    margin: 5px 10px;
  }
`;

const ButtonLink = styled(Link)`
  background-color: rgb(0, 116, 255); // Blue background for button
  color: rgb(255, 255, 255); // White color for button text
  border: none;
  border-radius: 20px;
  padding: 10px 20px;
  text-decoration: none;
  font-size: 18px;
  transition: background-color 0.3s;

  &:hover {
    background-color: rgb(0, 90, 200);
  }

  @media (max-width: 768px) {
    font-size: 16px;
    padding: 8px 16px;
  }
`;

const MobileNav = styled.div`
  display: none;

  @media (max-width: 768px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
`;

// Navigation Component
const Navigation = () => {
  const handleClick = (e, targetId) => {
    e.preventDefault();
    const targetSection = document.querySelector(targetId);
    targetSection.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <Nav>
      <Logo to="/">TallyMatic - Bank Statement Convertor</Logo> {/* Logo for navigation */}
      <NavLinks>
        <NavLink to="#about" onClick={(e) => handleClick(e, '#about')}>About</NavLink>
        <NavLink to="#contact" onClick={(e) => handleClick(e, '#contact')}>Contact</NavLink>
        <NavLink to="#buyNow" onClick={(e) => handleClick(e, '#buyNow')}>Pricing</NavLink>
        <SignedOut>
          <ButtonLink to="/sign-in">Login</ButtonLink>
        </SignedOut>
        <SignedIn>
          <ButtonLink to="/gst">Converter</ButtonLink>
        </SignedIn>
      </NavLinks>
      <MobileNav>
        <MobileLogo to="/">TallyMatic</MobileLogo> {/* Logo for mobile view */}
        <SignedOut>
          <ButtonLink to="/sign-in">Login</ButtonLink>
        </SignedOut>
        <SignedIn>
          <ButtonLink to="/gst">Converter</ButtonLink>
        </SignedIn>
      </MobileNav>
    </Nav>
  );
};

export default Navigation;
