import React, { useState, useEffect } from 'react';
import { Table, Button, Input, Form, Modal, Card } from 'antd';
import { supabase } from './supabaseClient';

const ColumnPage = () => {
  const [columnMappings, setColumnMappings] = useState({});
  const [visible, setVisible] = useState(false);
  const [currentCategory, setCurrentCategory] = useState('');
  const [newItem, setNewItem] = useState('');

  const fetchColumnMappings = async () => {
    const { data, error } = await supabase
      .from('column_mappings')
      .select('*');
      
    if (error) {
      console.error('Error fetching column mappings:', error);
      return;
    }

    const mappings = {};
    data.forEach((row) => {
      mappings[row.column_name] = row.mapping_names.split(',');
    });

    setColumnMappings(mappings);
  };

  useEffect(() => {
    fetchColumnMappings();
  }, []);

  const showModal = (category) => {
    setCurrentCategory(category);
    setVisible(true);
  };

  const handleCancel = () => {
    setVisible(false);
    setNewItem('');
  };

  const handleAddItem = async () => {
    if (!newItem) return;

    const updatedMappings = { ...columnMappings };
    updatedMappings[currentCategory] = [...updatedMappings[currentCategory], newItem];

    const { error } = await supabase
      .from('column_mappings')
      .update({ mapping_names: updatedMappings[currentCategory].join(',') })
      .eq('column_name', currentCategory);

    if (error) {
      console.error('Error updating column mappings:', error);
      return;
    }

    fetchColumnMappings();
    handleCancel();
  };

  const columns = [
    {
      title: 'Category',
      dataIndex: 'category',
      key: 'category',
      render: (text) => <b>{text}</b>,
    },
    {
      title: 'Mappings',
      dataIndex: 'mappings',
      key: 'mappings',
      render: (mappings) => (
        <ul>
          {mappings.map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </ul>
      ),
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Button onClick={() => showModal(record.category)}>Add Item</Button>
      ),
    },
  ];

  const data = Object.keys(columnMappings).map((category) => ({
    key: category,
    category,
    mappings: columnMappings[category],
  }));

  return (
    <Card title="Column Mappings" style={{ margin: '20px' }}>
      <Table columns={columns} dataSource={data} pagination={false} />

      <Modal
        title={`Add Item to ${currentCategory}`}
        visible={visible}
        onCancel={handleCancel}
        onOk={handleAddItem}
      >
        <Form layout="vertical">
          <Form.Item label="New Item">
            <Input value={newItem} onChange={(e) => setNewItem(e.target.value)} />
          </Form.Item>
        </Form>
      </Modal>
    </Card>
  );
};

export default ColumnPage;
