// // import React, { useState, useEffect, useRef } from 'react';
// // import { Table, Select, Input, Upload, message, Modal, Card, InputNumber, Statistic, Divider, Typography } from 'antd';
// // import { UploadOutlined } from '@ant-design/icons';
// // import moment from 'moment';
// // import Papa from 'papaparse';
// // import './App.css';
// // import * as XLSX from 'xlsx';
// // import { SwapOutlined } from '@ant-design/icons';
// // import Button from '@mui/material/Button';
// // import CloudUploadIcon from '@mui/icons-material/CloudUpload';
// // import { Tabs } from 'antd';
// // import { SignedIn, SignedOut, SignIn, SignUp } from '@clerk/clerk-react';
// // import { SignOutButton } from '@clerk/clerk-react';
// // import BankStatement from './BankStatement'
// // import Gst from './Gst'

// // const App = () => {
// //   return (
// //     <div>
// //       <Gst/>
// //   </div>
// //   );
// // };

// // export default App;

// // In your App.js or wherever your Router is defined
// import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
// import LandingPage from './LandingPage.js';
// import SignInPage from './SignInPage';
// // import PricingPage from './PricingPage';
// import Gst from './Gst';
// import BankStatement from './BankStatement.js';

// const App = () => {
//   return (
//     // <Router>
//     //   <Routes>
//     //     {/* <Route path="/" element={<LandingPage />} /> */}
//     //     {/* <Route path="/sign-in" element={<SignInPage />} /> */}
//     //     {/* <Route path="/pricing" element={<PricingPage />} /> */}
//     //     <Route path="/gst" element={<Gst />} />
//     //   </Routes>
//     // </Router>
//     <div>
//       <Gst/>
//       {/* <BankStatement/> */}
//     </div>
//   );
// };

// export default App

import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import LandingPage from './LandingPage.js';
//import SignInPage from './SignInPage';
import Gst from './Gst';
import BankStatement from './BankStatement.js';
import WelcomePage from './WelcomePage.js';
import ContactPage from './ContactPage.js';
import SignInPage from './SignInPage'; // Import the SignInPage component
import AdminPanelPage from './AdminPanelPage';

import AboutPage from './AboutPage';
import BuyNow from './BuyNow'
import PrivacyPolicyPage from './PrivacyPolicyPage.js';
import TermsAndConditions from './TermsAndConditions.js';
import RefundPolicy from './RefundPolicy.js'
import PaymentAfterLogin from './PaymentAfterLogin.js';
import ColumnPage from './ColumnPage.js';
// const App = () => {
//   return (
//     <Router>
//       <Routes>
//         <Route path="/" element={<WelcomePage />} />
//         <Route path="/gst" element={<Gst />} />
//         <Route path="/contact" element={<ContactPage />} />
//         <Route path="/about" element={<AboutPage />} />
//         <Route path="/privacypolicy" element={<PrivacyPolicyPage />} />

//         <Route path="/TermsAndConditions" element={<TermsAndConditions />} />
//         <Route path="/buyNow" element={<BuyNow />} />
//         {/* Add other routes as needed */}
//       </Routes>
//     </Router>
//   );
// };
//users_talllymatic
// export default App;
const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<WelcomePage />} />
        <Route path="/sign-in" element={<SignInPage />} /> 
        <Route path="/gst" element={<Gst />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/privacypolicy" element={<PrivacyPolicyPage />} />
        <Route path="/refundpolicy" element={<RefundPolicy />} />
        <Route path="/TermsAndConditions" element={<TermsAndConditions />} />
        <Route path="/buyNow" element={<BuyNow />} />
        <Route path="/purchase" element={<PaymentAfterLogin />} />
        <Route path="/Adm1n_P@n3l_53cUr3_9x7Q!z" element={<AdminPanelPage />} />
        <Route path="/Adm1n_P@n3l_53cUr3_9x7Q!x" element={<ColumnPage />} />
        {/* Add other routes as needed */}
      </Routes>
    </Router>
  );
};

export default App;