// import React, { useState, useEffect, useRef } from 'react';

// const CustomSearchSelect = ({ options, onChange, onSearchChange }) => {
//     const [searchTerm, setSearchTerm] = useState('');
//     const [selectedItems, setSelectedItems] = useState(new Set());
//     const [filteredOptions, setFilteredOptions] = useState([]);
//     const [dropdownVisible, setDropdownVisible] = useState(false);
//     const wrapperRef = useRef(null);  // Reference to the wrapper div

//     useEffect(() => {
//         if (searchTerm) {
//             const filtered = options.filter(option =>
//                 option.label.toLowerCase().includes(searchTerm.toLowerCase())
//             );
//             setFilteredOptions(filtered);
//             setDropdownVisible(true);
//         } else {
//             setFilteredOptions([]);
//             setDropdownVisible(false);
//         }
//     }, [searchTerm, options]);

//     // Click outside handler
//     useEffect(() => {
//         function handleClickOutside(event) {
//             if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
//                 setDropdownVisible(false);  // Close the dropdown
//             }
//         }

//         document.addEventListener('mousedown', handleClickOutside);
//         return () => {
//             document.removeEventListener('mousedown', handleClickOutside);
//         };
//     }, [wrapperRef]);

//     const handleSearchChange = (e) => {
//         const searchTerm = e.target.value;
//         setSearchTerm(searchTerm);
//         if (onSearchChange) {
//             onSearchChange(searchTerm);
//         }
//     };

//       const clearSearch = () => {
//     setSearchTerm('');
//     setFilteredOptions([]);
//     // Optionally call onSearchChange here if you want to reset external state as well
//     if (onSearchChange) {
//       onSearchChange('');
//     }
//   };
//     const handleCheckboxChange = (value, isChecked) => {
//         const newSelectedItems = new Set(selectedItems);
//         if (isChecked) {
//             newSelectedItems.add(value);
//         } else {
//             newSelectedItems.delete(value);
//         }
//         setSelectedItems(newSelectedItems);
//         onChange(Array.from(newSelectedItems));
//         setSearchTerm(value);  // Optionally clear or keep the last selected value
//         setDropdownVisible(false);
//     };

//     return (
//         <div ref={wrapperRef} style={{ position: 'relative', marginBottom: '10px' }}>
//             <div style={{ position: 'relative' }}>
//                 <input
//                     type="text"
//                     placeholder="Search..."
//                     value={searchTerm}
//                     onChange={handleSearchChange}
//                     onClick={() => setDropdownVisible(true)}
//                     style={{ width: '100%', padding: '10px', paddingLeft: '10px', paddingRight: '30px', boxSizing: 'border-box' }}
//                 />
//                 {searchTerm && (
//                     <button
//                         onClick={clearSearch}
//                         style={{
//                             position: 'absolute',
//                             right: '10px',
//                             top: '50%',
//                             transform: 'translateY(-50%)',
//                             cursor: 'pointer',
//                             border: 'none',
//                             background: 'transparent',
//                             fontSize: '16px',
//                             color: 'black'
//                         }}
//                         aria-label="Clear search"
//                     >
//                         &#x2715;
//                     </button>
//                 )}
//             </div>
//             {dropdownVisible && (
//                 <div style={{ maxHeight: '200px', overflowY: 'auto' }}>
//                     {filteredOptions.length > 0 ? (
//                         filteredOptions.map(option => (
//                             <div key={option.value} onClick={() => handleCheckboxChange(option.value, !selectedItems.has(option.value))}>
//                                 {option.label}
//                             </div>
//                         ))
//                     ) : (
//                         <div>No results found</div>
//                     )}
//                 </div>
//             )}
//         </div>
//     );
    
// };

// export default CustomSearchSelect;

import React, { useState, useEffect, useRef } from 'react';

const CustomSearchSelect = ({ options, onChange, onSearchChange }) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedItems, setSelectedItems] = useState(new Set());
    const [filteredOptions, setFilteredOptions] = useState([]);
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const wrapperRef = useRef(null);  // Reference to the wrapper div

    useEffect(() => {
        if (searchTerm) {
            const filtered = options.filter(option =>
                option.label.toLowerCase().includes(searchTerm.toLowerCase()) &&
                !selectedItems.has(option.value)  // Exclude already selected items
            );
            setFilteredOptions(filtered);
            setDropdownVisible(true);
        } else {
            setFilteredOptions([]);
            setDropdownVisible(false);
        }
    }, [searchTerm, options, selectedItems]);  // Include selectedItems in the dependency array

    useEffect(() => {
        function handleClickOutside(event) {
            if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
                setDropdownVisible(false);  // Close the dropdown
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [wrapperRef]);
    
    // useEffect(() => {
    //     if (searchTerm) {
    //         const filtered = options.filter(option =>
    //             option.label.toLowerCase().includes(searchTerm.toLowerCase())
    //         );
    //         setFilteredOptions(filtered);
    //         if (filtered.length > 0) {
    //             setDropdownVisible(true);
    //         } else {
    //             setDropdownVisible(false);
    //         }
    //     } else {
    //         setFilteredOptions([]);
    //         setDropdownVisible(false);
    //     }
    // }, [searchTerm, options]);

    // useEffect(() => {
    //     function handleClickOutside(event) {
    //         if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
    //             setDropdownVisible(false);
    //         }
    //     }

    //     document.addEventListener('mousedown', handleClickOutside);
    //     return () => {
    //         document.removeEventListener('mousedown', handleClickOutside);
    //     };
    // }, [wrapperRef]);


    // const handleSearchChange = (e) => {
    //     const searchTerm = e.target.value;
    //     setSearchTerm(searchTerm);
    //     console.log("Current search term:", searchTerm);  // Log the current search term to the console

    //     if (onSearchChange) {
    //         onSearchChange(searchTerm);
    //     }
    // };
    // const handleSearchChange = (e) => {
    //     const searchTerm = e.target.value;
    //     setSearchTerm(searchTerm);
    //     if (onSearchChange) {
    //         onSearchChange(searchTerm);
    //     }
    //     console.log("Current search term:", searchTerm);  // Log the current search term to the console
    // };
    

    useEffect(() => {
        console.log("Current search term:", searchTerm);
    }, [searchTerm]);  // Log whenever searchTerm changes, regardless of the source of the change
    
    // const handleCheckboxChange = (value, isChecked) => {
    //     const newSelectedItems = new Set(selectedItems);
    //     if (isChecked) {
    //         newSelectedItems.add(value);
    //     } else {
    //         newSelectedItems.delete(value);
    //     }
    //     setSelectedItems(newSelectedItems);
    //     onChange(Array.from(newSelectedItems));
    //     console.log("Selected value:", value)
    //     setSearchTerm(value);  // Clear the search term after selection
    //     setDropdownVisible(false);  // Close dropdown after selection
    // };
    //
    const updateSearchTerm = (newSearchTerm) => {
        setSearchTerm(newSearchTerm);
        console.log("Current search term:", newSearchTerm);  // Log the current search term to the console
        if (onSearchChange) {
            onSearchChange(newSearchTerm);
        }
    };

    const handleSearchChange = (e) => {
        e.stopPropagation(); // Prevent the event from bubbling up to document

        updateSearchTerm(e.target.value);
    };

    const handleCheckboxChange = (value, isChecked, event) => {
        event.stopPropagation(); // Prevent the event from bubbling up to document

        const newSelectedItems = new Set(selectedItems);
        if (isChecked) {
            newSelectedItems.add(value);
        } else {
            newSelectedItems.delete(value);
        }
        setSelectedItems(newSelectedItems);
        onChange(Array.from(newSelectedItems));
    
        updateSearchTerm(value);  // Update the search term through the unified function
        setDropdownVisible(false);  // Close dropdown after selection
    };
    
    //

    // const clearSearch = () => {
    //     setSearchTerm('');
    //     setFilteredOptions([]);
    //     if (onSearchChange) {
    //         onSearchChange('');
    //     }
    // };
    const clearSearch = () => {
        setSearchTerm('');
        setSelectedItems(new Set()); // Clear all selections
        setFilteredOptions(options); // Display all options again
        setDropdownVisible(false); // Close the dropdown
        if (onChange) {
            onChange([]); // Notify that selections have been cleared
        }
        if (onSearchChange) {
            onSearchChange('');
        }
    };
    
    

    return (
        <div ref={wrapperRef} style={{ position: 'relative', marginBottom: '10px' }}>
            <div style={{ position: 'relative' }}>
                <input
                    type="text"
                    placeholder="Search..."
                    value={searchTerm}
                    onChange={handleSearchChange}
                    onClick={() => setDropdownVisible(true)}
                    style={{ width: '100%', padding: '10px', paddingLeft: '10px', paddingRight: '30px', boxSizing: 'border-box' }}
                />
                {searchTerm && (
                    <button
                        onClick={clearSearch}
                        style={{
                            position: 'absolute',
                            right: '10px',
                            top: '50%',
                            transform: 'translateY(-50%)',
                            cursor: 'pointer',
                            border: 'none',
                            background: 'transparent',
                            fontSize: '16px',
                            color: 'black'
                        }}
                        aria-label="Clear search"
                    >
                        &#x2715;
                    </button>
                )}
            </div>
            {dropdownVisible && (
                <div style={{ maxHeight: '200px', overflowY: 'auto' }}>
                    {/* {filteredOptions.length > 0 ? (
                        filteredOptions.map(option => (
                            <div key={option.value} onClick={() => handleCheckboxChange(option.value, !selectedItems.has(option.value))}>
                                {option.label}
                            </div>
                        ))
                    ) 
                    :  searchTerm && (  // Check if searchTerm is not empty before displaying "No results found"
                    <div>No results found</div>
                )
                } */}
                {/* {filteredOptions.map(option => (
            <div key={option.value} onClick={() => handleCheckboxChange(option.value, !selectedItems.has(option.value))}>
                {option.label}
            </div>
        ))} */}
                </div>
            )}
        </div>
    );
};

export default CustomSearchSelect;
