import React, { useCallback, useState, useEffect, useRef } from 'react';
import * as XLSX from 'xlsx';
import { Popconfirm } from 'antd';
import CustomSearchSelect from './CustomSearchSelect'
import { Document, pdfjs } from 'react-pdf';
import { getDocument } from 'pdfjs-dist'; // Import getDocument if needed
import * as pdfjsLib from 'pdfjs-dist'; // Import pdfjsLib if neede
import Papa from 'papaparse';
import { DatePicker } from 'antd';
import { supabase } from '../src/supabaseClient';
import { useUser } from '@clerk/clerk-react';
import { UserButton, useUser as useSupaUser } from '@supabase/auth-helpers-react';
import { Table, Dropdown, Menu, AutoComplete, Select, Form, Input, Button, Popover, Card } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { useSpring, animated } from 'react-spring';
import { Modal } from 'antd';
import { Link } from 'react-router-dom';

import image from './image.png';
import ReactImageZoom from 'react-image-zoom';
// import { v4 as uuidv4 } from 'uuid'; // If using the 'uuid' library
import { v4 as uuidv4, isUUID } from 'uuid';
import { position } from '@chakra-ui/react';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const { Option } = Select;
const { RangePicker } = DatePicker;

const BankStatement = ({ userData }) => {
  //const { user: supaUser } = useSupaUser() || {};
  console.log('User data in BankStatement:', userData);
  const [transactions, setTransactions] = useState([]); // Step 2: Initialize the state variable
  const [selectedTransactions, setSelectedTransactions] = useState(new Set());
  const [selectedTransactionIds, setSelectedTransactionIds] = useState(new Set());
  const [selectedTransactionNIds, setSelectedTransactionNIds] = useState(new Set());
  const [salesRelatedState, setSalesRelatedState] = useState([]); // Initialize the state to store sales related values
  const [history, setHistory] = useState([]);
  const [undoCount, setUndoCount] = useState(0);
  // const { user, isLoaded } = useUser() || {};
  // const { user: supaUser } = useSupaUser() || {};
  const [userCredits, setUserCredits] = useState(null);
  const { user, isLoaded } = useUser() || {};
  const { user: supaUser } = useSupaUser() || {};
  const [selectedOption, setSelectedOption] = useState(null); // State to track the selected dropdown option
  // const [filterOption, setFilterOption] = useState(null);
  const [selectedBank, setSelectedBank] = useState('hdfc'); // Renamed from selectedOption to selectedBank
  const [ledgerData, setLedgerData] = useState([]); // New state for ledger data
  const [particularsAndValues, setParticularsAndValues] = useState([]);
  const [suggestions, setSuggestions] = useState([]);
  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState('');
  const [inputKey, setInputKey] = useState(Date.now);
  const [inputKeyLedger, setInputKeyLedger] = useState(Date.now);
  const [tableVisible, setTableVisible] = useState(true);
  const [searchText, setSearchText] = useState('');
  // const [selectedNarrations, setSelectedNarrations] = useState([]);
  const [showNotAssigned, setShowNotAssigned] = useState(false);
  const [suggestionsVisible, setSuggestionsVisible] = useState(false);
  const [selectedNarrations, setSelectedNarrations] = useState(new Set());
  const [filterOption, setFilterOption] = useState('');
  const [filteredTransaction, setFilteredTransaction] = useState(transactions);
  const [dateRange, setDateRange] = useState({ startDate: null, endDate: null });
  const [sectionData, setSectionData] = useState([]);
  const [selectedLedger, setSelectedLedger] = useState("");
  const [filteredTransactionss, setFilteredTransactionss] = useState([]); // Correctly define this state
  const fileInputRef = useRef(null);
  const [filteredTransactionsss, setFilteredTransactionsss] = useState([]);
  const [narrationInput, setNarrationInput] = useState(''); // State to hold the value of the input field for new narration
  const [dialogVisible, setDialogVisible] = useState(false);
  const [updatedToken, setUpatedToken] = useState(null)
  const [ledgerOptions, setLedgerOptions] = useState([]);
  //const [form] = Form.useForm();
  const [columnMappingss, setColumnMappingss] = useState({});

  const mainData = userData

  useEffect(() => {
    if (!isLoaded) {
      // Handle the case where user data is not loaded yet
      return <div>Loading...</div>;
    }
  }, [isLoaded]);

  useEffect(() => {
    const fetchColumnMappings = async () => {
      const { data, error } = await supabase
        .from('column_mappings')
        .select('*');
      
      if (error) {
        console.error('Error fetching column mappings:', error);
        return;
      }

      const mappings = {};
      data.forEach(row => {
        mappings[row.column_name] = row.mapping_names.split(',');
      });

      setColumnMappingss(mappings);
    };

    fetchColumnMappings();
  }, []);

  const saveToHistory = (currentTransactions) => {
    setHistory((prevHistory) => {
      const newHistory = [...prevHistory];
      if (newHistory.length >= 3) {
        newHistory.shift(); // Remove the oldest entry
      }
      newHistory.push(currentTransactions);
      return newHistory;
    });
  };

  const handleUndo = () => {
    if (history.length === 0 || undoCount >= 3) {
      alert("No more undos available!");
      return;
    }
    setTransactions(history.pop()); // Set the last history state as current
    setHistory([...history]); // Update history state
    setUndoCount(undoCount + 1); // Increment undo count
  };

  const handleAssignNarrationClick = () => {
    if (selectedTransactionNIds.size === 0) {
      alert("No transactions selected.");
      return;
    }
    if (!narrationInput) {
      alert("No narration entered.");
      return;
    }

    const validCharactersRegex = /^[a-zA-Z0-9,.\-\r\n\s]+$/;
    if (!validCharactersRegex.test(narrationInput)) {
      const invalidCharacters = narrationInput.replace(/[a-zA-Z0-9,.\-\r\n\s]/g, '');
      alert(`Invalid characters in narration: ${invalidCharacters}\nOnly alphanumeric characters, commas, periods, hyphens, newlines, and spaces are allowed.`);
      return;
    }

    saveToHistory(transactions);

    const updatedTransactions = transactions.map(transaction => {
      if (selectedTransactionNIds.has(transaction.id)) {
        return { ...transaction, narration: narrationInput };
      }
      return transaction;
    });

    setTransactions(updatedTransactions);
    setNarrationInput('');
    setSelectedTransactionNIds(new Set())
  };

  const handleDialogOpen = () => {
    setDialogVisible(true);
  };

  const handleDialogClose = () => {
    setDialogVisible(false);
  };

  useEffect(() => {
    const filtered = transactions
      .filter(transaction => {
        return searchText ? transaction.narration.toLowerCase().includes(searchText.toLowerCase()) : true;
      })
      .filter(transaction => {
        return filterOption ? (transaction.ledger === filterOption || filterOption === "") : true;
      })
      .filter(transaction => {
        if (!transaction.date) return true;
        const [day, month, year] = transaction.date.split("/");
        const transactionDate = new Date(`20${year}`, month - 1, day);

        const startDate = dateRange.startDate ? dateRange.startDate.toDate() : null;
        const endDate = dateRange.endDate ? dateRange.endDate.toDate() : null;

        if (endDate) endDate.setHours(23, 59, 59, 999);

        if (startDate && endDate) {
          return transactionDate >= startDate && transactionDate <= endDate;
        } else if (startDate) {
          return transactionDate >= startDate;
        } else if (endDate) {
          return transactionDate <= endDate;
        }
        return true;
      });

    setFilteredTransactionss(filtered);
  }, [transactions, searchText, filterOption, dateRange]);

  const handleLedgerSelect = (value) => {
    setSelectedLedger(value);
  };

  useEffect(() => {
    const transactionOptions = [...new Set(transactions.map(transaction => transaction.ledger))];

    const filteredOptions = transactionOptions.filter(ledger => Boolean(ledger) || ledger === "Not Assigned");

    const sortedOptions = filteredOptions.filter(ledger => ledger !== "Not Assigned").sort();

    const combinedOptions = ["Not Assigned", ...sortedOptions];

    setLedgerOptions(combinedOptions);
    console.log(combinedOptions);
  }, [transactions]);


  useEffect(() => {
    const filtered = filterOption
      ? transactions.filter(transaction => transaction.ledger === filterOption || filterOption === "")
      : transactions;

    setFilteredTransaction(filtered);
  }, [filterOption, transactions]);

  const narrationOptions = Array.from(
    new Set(transactions.map((transaction) => transaction.narration))
  )
    .sort()
    .map((narration) => ({
      label: narration,
      value: narration,
    }));

  const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType = 'text',
    record,
    index,
    children,
    ...restProps
  }) => (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{ margin: 0 }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          <Input />
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );

  const isEditing = record => record.id === editingKey;

  const edit = record => {
    form.setFieldsValue({ narration: '', ledger: '', ...record });
    setEditingKey(record.id);
  };

  const cancel = () => {
    setEditingKey('');
  };

  useEffect(() => {
    let filtered = transactions;

    if (filterOption) {
      filtered = filtered.filter(transaction => transaction.ledger === filterOption);
    }

    if (showNotAssigned) {
      filtered = filtered.filter(transaction => !transaction.ledger || transaction.ledger === 'Not Assigned');
    }

    setFilteredTransaction(filtered);
  }, [transactions, filterOption, showNotAssigned]);

  const clearFileInput = () => {
    setInputKey(Date.now);
    setTransactions([]);
  };

  const save = async (id) => {
    try {
      const row = await form.validateFields();
      const newData = [...transactions];
      const index = newData.findIndex(item => id === item.id);

      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, { ...item, ...row });
        setTransactions(newData);
        setEditingKey('');
      }
    } catch (errInfo) {
      console.log('Save failed:', errInfo);
    }
  };

  const filteredTransactions = searchText
    ? transactions.filter((transaction) =>
      transaction.narration.toLowerCase().includes(searchText.toLowerCase())
    )
    : transactions;

  const columnMappings = {
    date: ["Value Date", "Date", "Transaction Date", "Transaction Date", "Value Date", "Value Date"],
    narration: ["Narration", "Description", "Transaction Details", "Transaction Remarks", "Remarks", "Description/Narration", "Account Description", "Remarks"],
    referenceNo: ["Chq./Ref.No", "Reference No", "Chq./Ref.No."],
    valueDate: ["Value Dt", "Value Date"],
    withdrawalAmount: ["Withdrawal Amt.", "Debit", "Withdrawal Amount (INR )", "Debit(Dr.)", "Debit", "Withdrawals"],
    depositAmount: ["Deposit Amt.", "Credit", "depositAmount", "Deposit Amount (INR )", 'Credit(Cr.)', "Credit", "Deposits"],
    closingBalance: ["Closing Balance", "Balance", 'Balance (INR )', 'Balance (INR)', 'Balance(INR)'],
  };

  console.log('from api call', columnMappingss)
  const detectColumns = (headerRow) => {
    const headerMapping = {};
    Object.keys(columnMappingss).forEach((key) => {
      const possibleHeaders = columnMappingss[key];
      const foundHeader = possibleHeaders.find((header) =>
        headerRow.includes(header)
      );
      if (foundHeader) {
        headerMapping[key] = headerRow.indexOf(foundHeader);
      }
    });
    return headerMapping;
  };

  const processExcelData = (data) => {
    if (data.length === 0) return;

    const headerRow = Object.keys(data[0]);

    console.log('entering excel process')
    const detectedColumns = detectColumns(headerRow);
    console.log('Detected Columns:', detectedColumns);

    const dateRegex = /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[012])\/\d{2,4}$/;

    const formattedData = data.map((row, index) => {
      const rowData = {};
      Object.keys(detectedColumns).forEach((key) => {
        const columnIndex = detectedColumns[key];
        const value = row[headerRow[columnIndex]];
        if (key === 'date' || key === 'valueDate') {
          // rowData[key] = typeof value === 'number' ? excelDateToJSDate(value) : value;
          rowData[key] = formatExcelDate(value);

        } else if (['withdrawalAmount', 'depositAmount', 'closingBalance'].includes(key)) {
          //rowData[key] = value ? parseFloat(value.toString().replace(/,/g, '')) : 0;
          const parsedValue = value ? parseFloat(value.toString().replace(/,/g, '')) : NaN;
          rowData[key] = isNaN(parsedValue) ? '' : parsedValue;
        } else {
          rowData[key] = value || '';
        }
      });

      return {
        id: index.toString(),
        ledger: rowData.ledger || "Not Assigned", // Default ledger to "Not Assigned" if not mapped
        ...rowData
      };
    });

    const invalidData = formattedData.filter(entry => entry.date === "Invalid Date" || entry.narration === "");
    const validData = formattedData.filter(entry => entry.date !== "Invalid Date" && entry.narration !== "");

    console.log("Invalid Data Entries:", invalidData);
    console.log("Valid Extracted Excel Data:", validData);
    setTransactions(validData)
  };

  function formatExcelDate(dateValue) {
    if (typeof dateValue === 'string') {
      if (/^\d{1,2}\/\d{1,2}\/\d{2}$/.test(dateValue)) {
        // Already in dd/mm/yy format
        return dateValue;
      } else if (/^\d{1,2}\/\d{1,2}\/\d{4}$/.test(dateValue)) {
        // Convert dd/mm/yyyy to dd/mm/yy
        const parts = dateValue.split('/');
        return `${parts[0]}/${parts[1]}/${parts[2].slice(2)}`;
      } else if (/^\d{1,2}-\d{1,2}-\d{4}.*$/.test(dateValue)) {
        // Extract date part from "dd-mm-yyyy hh:mm:ss" format
        const datePart = dateValue.split(" ")[0];
        const [day, month, year] = datePart.split("-");
        return `${day}/${month}/${year.slice(2)}`;
      } else if (/^\d{1,2} [A-Za-z]{3} \d{4}$/.test(dateValue)) {
        // Convert dd MMM yyyy to dd/mm/yy
        const parts = dateValue.split(' ');
        const day = parts[0];
        const month = new Date(Date.parse(parts[1] + " 1, 2000")).getMonth() + 1;
        const year = parts[2].slice(2);
        return `${day.padStart(2, '0')}/${month.toString().padStart(2, '0')}/${year}`;
      }
    }

    // Attempt to handle Excel date serial (originally based on days since Jan 1, 1900)
    if (typeof dateValue === 'number') {
      const excelEpoch = new Date(1899, 11, 31); // Excel epoch starts on Dec 31, 1899
      const dateObj = new Date(excelEpoch.getTime() + dateValue * 86400000);
      return `${('0' + dateObj.getDate()).slice(-2)}/${('0' + (dateObj.getMonth() + 1)).slice(-2)}/${dateObj.getFullYear().toString().slice(2)}`;
    }

    // Fallback for unrecognized formats
    console.error("Unhandled date format:", dateValue);
    return "Invalid Date";
  }
  function excelDateToJSDate(serial) {
    const utc_days = Math.floor(serial - 25569);
    const utc_value = utc_days * 86400;
    const date_info = new Date(utc_value * 1000);

    const formatted_date = ('0' + date_info.getDate()).slice(-2) + '/' +
      ('0' + (date_info.getMonth() + 1)).slice(-2) + '/' +
      date_info.getFullYear().toString().slice(-2);

    return formatted_date;
  }

  function isAlreadyFormattedDate(dateStr) {
    return /^\d{2}\/\d{2}\/\d{4}$/.test(dateStr);
  }

  function excelDateToJSDate(serial) {
    if (isAlreadyFormattedDate(serial)) {
      return serial;  // Return the date as is if it's already formatted
    }

    if (typeof serial !== 'number' || isNaN(serial)) {
      console.error("Invalid date serial:", serial);
      return "Invalid Date";  // Return a fallback for non-date values
    }

    const utc_days = Math.floor(serial - 25569);
    const utc_value = utc_days * 86400;
    const date_info = new Date(utc_value * 1000);

    const formattedDate = date_info.getDate().toString().padStart(2, '0') + '/' +
      (date_info.getMonth() + 1).toString().padStart(2, '0') + '/' +
      date_info.getFullYear();

    return formattedDate;
  }

  const handleFileChange = useCallback(async (event) => {
    const file = event.target.files[0];
    if (!file) return; // Exit if no file selected

    const supportedExcelTypes = [
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    ];

    if (file.type === "text/csv") {
      Papa.parse(file, {
        complete: function (results) {
          const data = results.data.map(row => {
            return row.map(cell => {
              // Check if the cell matches the ISO date format
              if (/^\d{4}-\d{2}-\d{2}$/.test(cell)) {
                // Convert from YYYY-MM-DD to DD/MM/YYYY
                const [year, month, day] = cell.split('-');
                return `${day}/${month}/${year}`;
              }
              return cell;
            });
          });

          // Convert CSV data to worksheet object
          const worksheet = XLSX.utils.aoa_to_sheet(data);
          const workbook = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

          // Convert workbook to binary string
          const binaryString = XLSX.write(workbook, { type: 'binary', bookType: 'xlsx' });

          // Create a Blob from the binary string
          const blob = new Blob([s2ab(binaryString)], { type: 'application/octet-stream' });

          // Process the converted Excel file using the existing Excel file handling logic
          const fileReader = new FileReader();
          fileReader.onload = (e) => {
            try {
              const workbook = XLSX.read(e.target.result, { type: 'binary' });
              processExcelWorkbook(workbook);
            } catch (error) {
              console.error("Error reading Excel file:", error);
              alert("There was an error processing your Excel file.");
            }
          };
          fileReader.readAsBinaryString(blob);
        },
      });
    }

    else if (file.type === "application/pdf") {
      const fileReader = new FileReader();
      fileReader.onload = async (e) => {
        const pdfUrl = e.target.result;
        await extractTextFromPDF(pdfUrl);
      };
      fileReader.readAsDataURL(file);
    } else if (supportedExcelTypes.includes(file.type)) {
      const fileReader = new FileReader();
      fileReader.onload = (e) => {
        try {
          const workbook = XLSX.read(e.target.result, { type: 'binary' });
          processExcelWorkbook(workbook);
        } catch (error) {
          console.error("Error reading Excel file:", error);
          alert("There was an error processing your Excel file.");
        }
      };
      fileReader.readAsBinaryString(file);
      console.log('file', file);
    } else {
      alert("Unsupported file type. Please upload a PDF, CSV, or Excel file.");
    }
  }, [processExcelData]);

  // Helper function to convert string to ArrayBuffer
  function s2ab(s) {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i < s.length; i++) {
      view[i] = s.charCodeAt(i) & 0xff;
    }
    return buf;
  }

  function processExcelWorkbook(workbook) {
    const sheetName = workbook.SheetNames[0]; // Assuming data is in the first sheet
    const worksheet = workbook.Sheets[sheetName];
    const data = XLSX.utils.sheet_to_json(worksheet, { header: 1 }); // Read rows as arrays
    let headerRowIndex = -1;

    const headerTerms = ['narration', 'sr no', 's no.', 'value date', 'Tran Id']; // Initial header terms including 'remarks'

    data.forEach((row, index) => {
      console.log(`Row ${index}:`, row);
    });

    const findHeaderRow = (terms) => {
      for (let i = 0; i < data.length; i++) {
        if (data[i].some(cell => cell && terms.some(term => cell.toString().trim().toLowerCase().includes(term)))) {
          console.log(`Header found in Row ${i}:`, data[i]);
          return i;
        }
      }
      return -1;
    };

    headerRowIndex = findHeaderRow(headerTerms);

    if (headerRowIndex === -1) {
      console.error("No 'Narration' column found");
      return;
    }

    const headers = data[headerRowIndex].map(header => header.toString().trim());
    const rowData = data.slice(headerRowIndex + 1).map((row, index) => {
      const rowObject = { id: index.toString(), ledger: "Not Assigned" }; // Initialize with id and default ledger
      headers.forEach((header, colIndex) => {
        rowObject[header] = row[colIndex] || null; // Map each cell to the corresponding header, use null for empty cells
      });
      return rowObject;
    });

    processExcelData(rowData);
    console.log(rowData); // Log or process the structured data
  }

  function combineTextItems(textItems) {
    const combinedItems = [];
    let currentItem = "";

    textItems.forEach((item, index) => {
      if (item.trim().match(/^\d{2}\/\d{2}\/\d{2}$/)) { // Starts with a date
        if (currentItem) {
          combinedItems.push(currentItem.trim());
        }
        currentItem = item;
      } else {
        currentItem += " " + item;
      }
    });

    if (currentItem) {
      combinedItems.push(currentItem.trim()); // Add the last item
    }

    return combinedItems;
  }

  function processPdfData(allTextItems) {
    return allTextItems.map((item, index) => {
      // Assuming fields are separated by special characters or fixed widths
      // This is a simple split by spaces; adjust according to your actual data format
      const fields = item.split(/\s{2,}/); // Split by two or more spaces
      return {
        id: index.toString(),
        ledger: "Not Assigned", // Default to not assigned
        Date: fields[0],
        Narration: fields.slice(1, -5).join(" "), // Assuming Narration spans multiple fields
        'Chq./Ref.No.': fields[fields.length - 5],
        'Value Dt': fields[fields.length - 4],
        'Withdrawal Amt.': fields[fields.length - 3],
        'Deposit Amt.': fields[fields.length - 2],
        'Closing Balance': fields[fields.length - 1]
      };
    });
  }

  const extractTableData = (items) => {
    const tableData = [];
    let currentRow = [];

    items.forEach((item) => {
      if (item.hasEOL) {
        currentRow.push(item.str);
        tableData.push(currentRow);
        currentRow = [];
      } else {
        currentRow.push(item.str);
      }
    });

    return tableData;
  };

  const processLedgerData = (data) => {
    let particularsAndValuesSet = new Set(); // Use a Set to store unique values

    data.forEach((item) => {
      const particulars = item['__EMPTY']; // Key for "Particulars"
      const value = item['__EMPTY_1']; // Key for associated values; adjust based on actual structure

      // Skip entries where particulars or value are undefined
      if (particulars && value) {
        const entry = `${particulars}: ${value}`;
        particularsAndValuesSet.add(entry);
      }
    });

    // Convert the Set back to an array for the state update
    let uniqueParticularsAndValues = Array.from(particularsAndValuesSet);

    let valuesAfterColonSet = new Set(uniqueParticularsAndValues.map(entry => {
      const splitEntry = entry.split(':');
      return splitEntry.length > 1 ? splitEntry[1].trim() : splitEntry[0].trim();
    }).filter(value => value !== "Opening Balance" && value !== "Closing Balance" && value !== "(as per details)"));

    // Manually add "Suspense" to the Set
    valuesAfterColonSet.add("Suspense");

    // Convert the Set back to an array and sort it
    const uniqueValuesAfterColon = Array.from(valuesAfterColonSet).sort();

    console.log("Sorted Unique Extracted Values After Colon (Filtered):", uniqueValuesAfterColon);
    setParticularsAndValues(uniqueValuesAfterColon);
  };


  const handleLedgerFileChange = useCallback(async (event) => {
    const file = event.target.files[0];
    if (!file) return; // Exit if no file selected

    const supportedExcelTypes = [
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    ];

    if (supportedExcelTypes.includes(file.type)) {
      const fileReader = new FileReader();
      fileReader.onload = (e) => {
        try {
          // Use XLSX to read the binary string
          const workbook = XLSX.read(e.target.result, { type: 'binary' });
          const sheetName = workbook.SheetNames[0]; // Use the first sheet
          const worksheet = workbook.Sheets[sheetName];
          // Convert sheet to JSON - add {range: 1} if you need to skip the first row (header)
          // const data = XLSX.utils.sheet_to_json(worksheet, { raw: true });
          const data = XLSX.utils.sheet_to_json(worksheet, { range: 4 });
          processLedgerData(data); // Process the data
        } catch (error) {
          console.error("Error reading Excel file:", error);
          alert("There was an error processing your ledger Excel file.");
        }
      };
      fileReader.readAsBinaryString(file);
    } else {
      alert("Unsupported file type. Please upload an Excel file.");
    }
  }, [processLedgerData]);

  const handleAssignClick = () => {
    if (selectedTransactionIds.size === 0) {
      alert("Please select at least one item to assign.");
      return;
    }

    if (!selectedOption) {
      alert("Please select an option from the dropdown before assigning.");
      return;
    }

    saveToHistory(transactions);
    let tempSalesRelatedValues = [];
    let startIndex = -1;
    let endIndex = -1;

    for (let i = 0; i < particularsAndValues.length; i++) {
      if (particularsAndValues[i] === "Sales Accounts") {
        startIndex = i + 1;
      } else if (particularsAndValues[i] === "Suspense A/c") {
        endIndex = i;
        break;
      }
    }

    if (startIndex !== -1 && endIndex !== -1) {
      for (let i = startIndex; i < endIndex; i++) {
        tempSalesRelatedValues.push(particularsAndValues[i].toUpperCase().trim()); // Prepare values directly
      }
    }

    const updatedTransactions = transactions.map(transaction => {
      if (selectedTransactionIds.has(transaction.id)) {
        let entryType = '';

        if (transaction.depositAmount && !transaction.withdrawalAmount) {
          entryType = 'Receipt';
        } else if (!transaction.depositAmount && transaction.withdrawalAmount) {
          entryType = 'Payment';
        }

        //if (['CASH', 'HDFC CC ACCOUNT', 'HDFC CURRENT ACCOUNT'].includes(selectedOption.toUpperCase())) {
        console.log('sectionData', sectionData, selectedOption)
        if (['CASH', 'HDFC CC ACCOUNT', 'HDFC CURRENT ACCOUNT'].includes(selectedOption.toUpperCase()) || sectionData.includes(selectedOption)) {
          entryType = 'Contra';
        }

        if (entryType === 'Receipt' && tempSalesRelatedValues.includes(selectedOption.toUpperCase())) {
          entryType = 'Sales';
        }

        return {
          ...transaction,
          ledger: selectedOption.toUpperCase(),
          entryType
        };
      }
      return transaction;
    });

    // Update the transactions state
    setTransactions(updatedTransactions);
    setSelectedTransactionIds(new Set()); // Clear the selection if needed
    setSelectedOption(null); // Reset the dropdown selection

    // Optionally, update the sales related state if needed elsewhere
    setSalesRelatedState(tempSalesRelatedValues);
  };


  const handleNarrationChange = (selectedNarrations) => {
    setSelectedNarrations(selectedNarrations);

    const matchingTransactionIds = transactions
      .filter(transaction => selectedNarrations.includes(transaction.narration))
      .map(matchingTransaction => matchingTransaction.id);

    console.log(matchingTransactionIds);
  };



  const handleCheckboxChange = (event, id) => {
    event.stopPropagation();  // Stop the event from propagating further

    setSelectedTransactionIds(prevSelected => {
      const newSelected = new Set(prevSelected);
      if (event.target.checked) {
        newSelected.add(id);
      } else {
        newSelected.delete(id);
      }
      console.log(`Checkbox Change for ID: ${id}, Checked: ${event.target.checked}`);
      console.log(Array.from(newSelected)); // Log the current state of selected IDs
      return newSelected;
    });
  };

  const handleCheckboxNarrationChange = (event, id) => {
    event.stopPropagation();  // Stop the event from propagating further

    setSelectedTransactionNIds(prevSelected => {
      const newSelected = new Set(prevSelected);
      if (event.target.checked) {
        newSelected.add(id);
      } else {
        newSelected.delete(id);
      }
      return newSelected;
    });
  };

  const extractTextFromPDF = async (pdfUrl) => {
    const extractedText = [];
    const rowData = [];

    try {
      const pdfDoc = await pdfjsLib.getDocument(pdfUrl).promise;
      const numPages = pdfDoc.numPages;

      // Extract text from each page
      for (let pageNum = 1; pageNum <= numPages; pageNum++) {
        const page = await pdfDoc.getPage(pageNum);
        const viewport = page.getViewport({ scale: 1.0 });
        const textContent = await page.streamTextContent(/* optional parameters */);
        textContent.items.map(token => {
          extractedText.push(token.str);
        });
      }

      // Process the extracted text as if it were an Excel file
      const headerTerms = ['narration', 'value date'];
      const textLines = extractedText.join(' ').split('\n');
      let headerRowIndex = -1;

      for (let i = 0; i < textLines.length; i++) {
        const line = textLines[i].toLowerCase().trim();
        if (headerTerms.some(term => line.includes(term))) {
          headerRowIndex = i;
          break;
        }
      }

      if (headerRowIndex === -1) {
        console.error("No 'Narration' column found");
        return;
      }

      const headers = textLines[headerRowIndex].trim().split(/\s+/);
      for (let i = headerRowIndex + 1; i < textLines.length; i++) {
        const row = textLines[i].trim().split(/\s+/);
        const rowObject = { id: (i - headerRowIndex - 1).toString(), ledger: "Not Assigned" };
        headers.forEach((header, colIndex) => {
          rowObject[header] = row[colIndex] || null;
        });
        rowData.push(rowObject);
      }

      processExcelData(rowData);
      console.log(rowData);
    } catch (err) {
      console.error('Error extracting text from PDF:', err);
    }
  };

  const parseTransactions = (text) => {
    const transactionPattern = /(\d{2} \w{3} \d{4})\s*(\d{2}:\d{2} (AM|PM)).*?((?:UPI|SentIMPS|Recd:IMPS|Chrg:|ADDMONEY|UPI\/|UPI-|Recd:IMPS\/[\w\/]+|Chrg:|ADDMONEY|[\w\/]+).*?)(-?\d{1,3}(?:,\d{3})*\.\d{2})/g;
    const parsedTransactions = [];
    let match;
    //const parsedTransactions = [];
    while ((match = transactionPattern.exec(text)) !== null) {
      parsedTransactions.push({
        id: parsedTransactions.length.toString(), // Assign a unique ID
        date: match[1],
        time: match[2],
        type: match[4].trim(),
        amount: match[5],
        // Ensure 'balance' or any other fields are correctly captured and assigned here if needed
      });
    }

    console.log('parsedTransactions', parsedTransactions)
    setTransactions(parsedTransactions);
  };

  const handleSelectAllNarrationsChange = (event) => {
    event.stopPropagation(); // Stop the event from propagating further
    const checked = event.target.checked;
    const currentlyVisibleTransactionIds = getVisibleTransactionIds(transactions, searchText, filterOption, dateRange);

    if (checked) {
      // Add all visible transaction IDs to the set
      setSelectedTransactionNIds(new Set([...selectedTransactionNIds, ...currentlyVisibleTransactionIds]));
    } else {
      // Remove only the visible transaction IDs from the set
      setSelectedTransactionNIds(new Set(
        [...selectedTransactionNIds].filter(id => !currentlyVisibleTransactionIds.includes(id))
      ));
    }
  };

  const getVisibleTransactionIds = (transactions, searchText, filterOption, dateRange) => {
    return transactions
      .filter(transaction => {
        const transactionDate = parseTransactionDate(transaction.date);
        return isNarrationMatch(transaction.narration, searchText) &&
          isLedgerMatch(transaction.ledger, filterOption) &&
          isDateInRange(transactionDate, dateRange);
      })
      .map((transaction) => transaction.id);
  };

  useEffect(() => {
    const currentlyVisibleTransactionIds = getVisibleTransactionIds(transactions, searchText, filterOption, dateRange);

    // Remove transaction IDs that are no longer visible
    setSelectedTransactionIds(prevSelectedIds => {
      return new Set([...prevSelectedIds].filter(id => currentlyVisibleTransactionIds.includes(id)));
    });
  }, [transactions, searchText, filterOption, dateRange]);

  const handleSelectAllChange = (event) => {
    event.stopPropagation(); // Stop the event from propagating further
    const checked = event.target.checked;
    const currentlyVisibleTransactionIds = getVisibleTransactionIds(transactions, searchText, filterOption, dateRange);

    if (checked) {
      // Add all visible transaction IDs to the set
      setSelectedTransactionIds(new Set([...selectedTransactionIds, ...currentlyVisibleTransactionIds]));
    } else {
      // Remove only the visible transaction IDs from the set
      setSelectedTransactionIds(new Set(
        [...selectedTransactionIds].filter(id => !currentlyVisibleTransactionIds.includes(id))
      ));
    }
  };

  const parseTransactionDate = (dateString) => {
    if (!dateString) return null;
    const [day, month, year] = dateString.split("/");
    return new Date(`20${year}`, month - 1, day);
  };

  const isNarrationMatch = (narration, searchText) => {
    return searchText ? narration.toLowerCase().includes(searchText.toLowerCase()) : true;
  };

  const isLedgerMatch = (ledger, filterOption) => {
    return filterOption ? (ledger === filterOption || filterOption === "") : true;
  };

  const isDateInRange = (transactionDate, dateRange) => {
    const startDate = dateRange.startDate ? dateRange.startDate.toDate() : null;
    const endDate = dateRange.endDate ? dateRange.endDate.toDate() : null;
    if (endDate) endDate.setHours(23, 59, 59, 999);
    return (!startDate || transactionDate >= startDate) && (!endDate || transactionDate <= endDate);
  };

  const handleMenuClick = (e) => {
    setFilterOption(e.key);

    const selectedValue = particularsAndValues[e.key];
    console.log(selectedValue)
    setSelectedOption(selectedValue); // Store the actual selected value
  };


  const kotakColumns = [
    {
      title: 'Transaction Date',
      dataIndex: 'date',
      key: 'date',
    },
    {
      title: 'Time',
      dataIndex: 'time',
      key: 'time',
    },
    {
      title: 'Transaction Details',
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: 'Debit/Credit (â‚¹)',
      dataIndex: 'amount',
      key: 'amount',
      render: (text) => {
        const isDebit = text.startsWith('-');
        return {
          children: text,
          props: {
            style: { color: isDebit ? 'red' : 'green' }
          }
        };
      }
    },
    {
      title: 'Balance (â‚¹)',
      dataIndex: 'balance',
      key: 'balance',
    },
    {
      title: 'Ledger',
      dataIndex: 'ledger', // This assumes you have a ledger field in your transactions
      key: 'ledger',
      render: ledger => ledger || 'N/A' // Example, showing 'N/A' if ledger data is not available
    },
    {
      title: 'Select',
      key: 'select',
      dataIndex: 'id', // This should match the property name in your transaction objects
      render: (_, record) => (
        <input
          type="checkbox"
          checked={selectedTransactionIds.has(record.id)}
          onChange={(e) => handleCheckboxChange(e, record.id)}
        />
      ),
    },

  ];

  const hdfcColumns = [
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
    },
    {
      title: (
        <div style={{ display: 'flex' }}>
          Select
          <input
            type="checkbox"
            style={{ marginLeft: '10px' }}
            checked={selectedTransactionNIds.size === filteredTransactionsss.length && filteredTransactionsss.length > 0}
            ref={el => el && (el.indeterminate = selectedTransactionNIds.size > 0 && selectedTransactionNIds.size < filteredTransactionsss.length)}
            onChange={handleSelectAllNarrationsChange}
          />
        </div>
      ),
      key: 'select',
      dataIndex: 'select',
      render: (_, record) => (
        <input
          type="checkbox"
          checked={selectedTransactionNIds.has(record.id)}
          onChange={(e) => handleCheckboxNarrationChange(e, record.id)}
        />
      ),
    },

    {
      title: 'Narration',
      dataIndex: 'narration',
      editable: true, // Add this to mark the column as editable
      onCell: record => ({
        record,
        inputType: 'text',
        dataIndex: 'narration',
        title: 'Narration',
        editing: isEditing(record),
      }),
    }
    ,
    {
      title: 'Withdrawal Amt.',
      dataIndex: 'withdrawalAmount',
      key: 'withdrawalAmount',
    },
    {
      title: 'Deposit Amt.',
      dataIndex: 'depositAmount',
      key: 'depositAmount',
    },
    {
      title: 'Ledger',
      dataIndex: 'ledger',
      key: 'ledger',
      render: ledger => ledger || 'Not Assigned',
    },
    {
      title: 'Entry Type',
      dataIndex: 'entryType',
      key: 'entryType',
      render: entryType => entryType || 'N/A',
    },

    {
      title: (
        <div style={{ display: 'flex' }}>
          Select
          <input
            type="checkbox"
            style={{ marginLeft: '10px' }}
            checked={selectedTransactionIds.size === filteredTransactionsss.length && filteredTransactionsss.length > 0}
            ref={el => el && (el.indeterminate = selectedTransactionIds.size > 0 && selectedTransactionIds.size < filteredTransactionsss.length)}
            onChange={handleSelectAllChange}
          />
        </div>
      ),
      key: 'select',
      dataIndex: 'id',
      render: (_, record) => (
        <input
          type="checkbox"
          checked={selectedTransactionIds.has(record.id)}
          onChange={(e) => handleCheckboxChange(e, record.id)}
        />
      ),
    },
  ];

  const iciciColumns = [
    {
      title: 'Date',
      dataIndex: 'Date',
      key: 'Date',
    },
    {
      title: 'Narration',
      dataIndex: 'Narration',
      key: 'Narration',
    },
    {
      title: 'Reference No',
      dataIndex: 'Chq./Ref.No.',
      key: 'Chq./Ref.No.',
    },
    {
      title: 'Value Date',
      dataIndex: 'Value Dt',
      key: 'Value Dt',
    },
    {
      title: 'Withdrawal Amount',
      dataIndex: 'Withdrawal Amt.',
      key: 'Withdrawal Amt.',
    },
    {
      title: 'Deposit Amount',
      dataIndex: 'Deposit Amt.',
      key: 'Deposit Amt.',
    },
    {
      title: 'Closing Balance',
      dataIndex: 'Closing Balance',
      key: 'Closing Balance',
    },
    {
      title: 'Ledger',
      dataIndex: 'ledger',
      key: 'ledger',
      render: ledger => ledger || 'N/A'
    },
    {
      title: 'Select',
      key: 'select',
      dataIndex: 'id',
      render: (_, record) => (
        <input
          type="checkbox"
          checked={selectedTransactionIds.has(record.id)}
          onChange={(e) => handleCheckboxChange(e, record.id)}
        />
      ),
    }
  ];

  // Use selectedBank to determine which columns to display
  const getCurrentColumns = () => {
    switch (selectedBank) {
      case 'hdfc':
        return hdfcColumns;
      case 'kotak':
        return kotakColumns;
      case 'icici':
        return iciciColumns;
      default:
        return []; // Return an empty array or a default set of columns if needed
    }
  };

  const handleBankSelection = (e) => {
    setSelectedBank(e.key);
    setTableVisible(e.key === 'hdfc' || e.key === 'kotak' || e.key === 'icici'); // Show the table for HDFC or Kotak, adjust logic as needed
  };

  const bankSelectionMenu = (
    <Menu onClick={handleBankSelection}>
      <Menu.Item key="kotak">Kotak</Menu.Item>
      <Menu.Item key="hdfc">HDFC</Menu.Item>
      <Menu.Item key="icici">ICICI</Menu.Item>
      {/* Add more Menu.Items as needed */}
    </Menu>
  );

  const menu = (
    <Menu onClick={handleMenuClick}>
      {particularsAndValues.map((value, index) => (
        <Menu.Item key={index.toString()}>{value}</Menu.Item>
      ))}
    </Menu>
  );

  useEffect(() => {
    const filtered = transactions.filter(transaction => {
      if (filterOption === "Not Assigned") {
        // Check for transactions where ledger is undefined, null, or explicitly "Not Assigned"
        return !transaction.ledger || transaction.ledger === "Not Assigned";
      } else if (filterOption) {
        // Filter by specific ledger
        return transaction.ledger === filterOption;
      }
      // If no filterOption is selected, include all transactions
      return true;
    });

    setFilteredTransaction(filtered);
  }, [filterOption, transactions]);

  const filterMenu = (
    <Menu onClick={(e) => setFilterOption(e.key)}>
      <Menu.Item key="">All</Menu.Item>
      {ledgerOptions.map(option => (
        <Menu.Item key={option}>{option}</Menu.Item>
      ))}
    </Menu>
  );


  const handleChange = (value) => {
    console.log("Selected Ledger:", value); // Check what value is being set
    setFilterOption(value);
  };

  useEffect(() => {
    const filtered = transactions.filter(transaction => {
      if (filterOption === "Not Assigned") {
        // Adjust based on how unassigned transactions are represented in your data
        return !transaction.ledger || transaction.ledger === "Not Assigned";
      } else if (filterOption) {
        return transaction.ledger === filterOption;
      }
      return true; // When no filter is applied, show all transactions
    });

    setFilteredTransaction(filtered);
  }, [filterOption, transactions]);

  useEffect(() => {
    const filtered = filterOption
      ? transactions.filter(transaction => transaction.ledger === filterOption || filterOption === "")
      : transactions;

    setFilteredTransaction(filtered);
  }, [filterOption, transactions]);

  useEffect(() => {
    const fetchUserCredits = async () => {
      if (!isLoaded || !user) return;

      const validUserId = convertToValidUUID(user.id);
      const { data, error } = await supabase
        .from('user_credits')
        .select('credits')
        .eq('user_id', validUserId)

      if (error) {
        console.error('Error fetching user credits:', error);
      } else if (data && data.length > 0) {
        // Access the first element of the array
        setUserCredits(data[0].credits);
      } else {
        // Handle the case when data is an empty array or undefined
        console.log('No user credits found for the current user.');
        setUserCredits(10);
      }
    };

    fetchUserCredits();
  }, [isLoaded, user]);

  const handleDownloadXmlClick = async () => {
    if (!selectedLedger) {
      alert("Please select an account from the 'Select Bank Accounts' dropdown.");
      return;  // Stop execution if no ledger selected
    }

    const unassignedLedgerExists = transactions.some(transaction => !transaction.ledger || transaction.ledger === "Not Assigned");

    if (unassignedLedgerExists) {
      alert("Please assign ledgers to all transactions before downloading the XML.");
      return;  // Exit the function if there are unassigned ledgers
    }

    const { data: userData, error } = await supabase
      .from('users_talllymatic')
      .select('token')
      .eq('email', user.primaryEmailAddress.emailAddress)
      .single();

    if (error) {
      console.error('Error fetching user credits:', error);
      alert('An error occurred while fetching your credits.');
      return;
    }

    if (userCredits.credits <= 0) {
      alert('You have no credits left. Please buy more credits.');
      return;
    }

    if (error) {
      console.error('Error fetching user tokens:', error);
      alert('An error occurred while fetching your tokens.');
      return;
    }


    const userTokens = userData.token;

    const wholeData = userData
    if (userTokens <= 0) {
      alert('You have no tokens left. Please buy more tokens.');
      return;
    }

    console.log('mainData', mainData)
    // if (mainData.paid == null && mainData.created_at === new Date() + 3) {
    //   console.log('entered')
    //   if (daysElapsed > 3) {
    //     alert('Your trial session has expired. Please buy tokens or top up your account.');
    //     return; // Exit the function if the trial has expired
    //   }
    //   console.log('entered')
    // }
    console.log('mainData', mainData);

    if (mainData.paid === null) {
      const createdDate = new Date(mainData.created_at);
      const currentDate = new Date();
      const daysElapsed = (currentDate - createdDate) / (1000 * 60 * 60 * 24);

      console.log('Days Elapsed:', daysElapsed);

      if (daysElapsed > 3) {
        alert('Your trial session has expired due to trial days. Please buy tokens or top up your account.');
        return; // Exit the function if the trial has expired
      }
    }

    console.log('User tokens:', userTokens);
    // setUpatedToken(userTokens)
    setUpatedToken(userTokens - 1);


    const filteredTransactions = transactions
      .filter(transaction => {
        // Apply search text filter
        return searchText ? transaction.narration.toLowerCase().includes(searchText.toLowerCase()) : true;
      })
      // .filter(transaction => {
      //   // Apply ledger filter
      //   return filterOption ? (transaction.ledger === filterOption || filterOption === "") : true;
      // })
      .filter(transaction => {
        // Convert transaction date string "DD/MM/YY" to a JavaScript Date object
        const [day, month, year] = transaction.date.split("/");
        const transactionDate = new Date(`20${year}`, month - 1, day);

        // Assuming dateRange.startDate and dateRange.endDate are moment objects
        const startDate = dateRange.startDate ? dateRange.startDate.toDate() : null; // Convert to JavaScript Date
        const endDate = dateRange.endDate ? dateRange.endDate.toDate() : null;

        // Adjust endDate to include the entire day
        if (endDate) {
          endDate.setHours(23, 59, 59, 999);
        }

        if (startDate && endDate) {
          return transactionDate >= startDate && transactionDate <= endDate;
        } else if (startDate) {
          return transactionDate >= startDate;
        } else if (endDate) {
          return transactionDate <= endDate;
        }
        return true; // No date filter applied
      });

    console.log('Filtered Transactions for XML Conversion:', filteredTransactions);
    const xml = convertToXml(filteredTransactions);
    downloadXml(xml, 'transactions.xml');

    const { error: updateError } = await supabase
      .from('users_talllymatic')
      .update({ token: userTokens - 1 })
      .eq('email', user.primaryEmailAddress.emailAddress);

    if (updateError) {
      console.error('Error updating user tokens:', updateError);
      alert('An error occurred while updating your tokens.');
    } else {
      console.log('Tokens updated successfully');

      // Make a GET call to fetch the updated user tokens
      const { data: updatedUserData, error: fetchError } = await supabase
        .from('users_talllymatic')
        .select('token')
        .eq('email', user.primaryEmailAddress.emailAddress)
        .single();

      if (fetchError) {
        //console.error('Error fetching updated user tokens:', fetchError);
        alert('An error occurred while fetching the updated tokens.');
      } else {
        //console.log('Updated user tokens:', updatedUserData.token);
        setUpatedToken(updatedUserData.token)
        setUserCredits(updatedUserData.token); // Update the userCredits state with the updated token value
      }
    }
  };

  const downloadXml = (xml, filename) => {
    const blob = new Blob([xml], { type: 'application/xml' });
    const href = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = href;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(href);
  };


  const isValidUUID = (userId) => {
    const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
    return uuidRegex.test(userId.toString());
  };

  const convertToValidUUID = (userId) => {
    // Check if the user.id is already a valid UUID
    if (isValidUUID(userId)) {
      return userId;
    }

    // If not, generate a new valid UUID
    return uuidv4();
  };

  const convertToXml = (transactions) => {
    let xml = '<ENVELOPE>\n';
    xml += '<HEADER>\n';
    xml += '<VERSION>1</VERSION>\n';
    xml += '<TALLYREQUEST>Import</TALLYREQUEST>\n';
    xml += '<TYPE>Data</TYPE>\n';
    xml += '<ID>Vouchers</ID>\n';
    xml += '</HEADER>\n';

    xml += '<BODY>\n';
    xml += '<DESC>\n';
    xml += '</DESC>\n';
    xml += '<DATA>\n';
    xml += '<TALLYMESSAGE>\n';

    transactions.forEach((transaction, index) => {
      // const dateFormatted = transaction.date.replace(/\//g, '');
      const [day, month, year] = transaction.date.split("/");
      const dateFormatted = `20${year}${month}${day}`;
      xml += '<VOUCHER>\n';
      xml += `<DATE>${dateFormatted}</DATE>\n`;
      xml += `<NARRATION>${transaction.narration}</NARRATION>\n`;

      let voucherTypeName;
      const ledgerNameUpper = transaction.ledger.toUpperCase();

      //if (transaction.ledger.toUpperCase() === 'CASH') {
      if (transaction.ledger.toUpperCase() === 'CASH' || sectionData.some(item => item.toUpperCase() === transaction.ledger.toUpperCase())) {

        voucherTypeName = 'Contra';
      }
      // console.log('sectionData', sectionData )
      // if (['CASH', 'HDFC CC ACCOUNT', 'HDFC CURRENT ACCOUNT']) {
      //   voucherTypeName = 'Contra';
      // }
      // else if (salesRelatedState.includes(ledgerNameUpper)){
      //     voucherTypeName = 'Sales';
      // }
      else if (transaction.depositAmount && parseFloat(transaction.depositAmount) > 0 && salesRelatedState.includes(ledgerNameUpper)) {
        voucherTypeName = 'Sales';
      } else if (transaction.depositAmount && parseFloat(transaction.depositAmount) > 0) {
        voucherTypeName = 'Receipt';
      }
      else if (transaction.depositAmount && parseFloat(transaction.depositAmount) > 0) {
        voucherTypeName = 'Receipt';
      } else {
        voucherTypeName = 'Payment';
      }

      xml += `<VOUCHERTYPENAME>${voucherTypeName}</VOUCHERTYPENAME>\n`;
      xml += `<VOUCHERNUMBER>${index + 1}</VOUCHERNUMBER>\n`;

      if (voucherTypeName === 'Contra') {
        if (transaction.depositAmount && parseFloat(transaction.depositAmount) > 0) {
          xml += '<ALLLEDGERENTRIES.LIST>\n';
          xml += `<LEDGERNAME>${transaction.ledger}</LEDGERNAME>\n`;
          xml += '<ISDEEMEDPOSITIVE>No</ISDEEMEDPOSITIVE>\n';
          xml += `<AMOUNT>${transaction.depositAmount}</AMOUNT>\n`;
          xml += '</ALLLEDGERENTRIES.LIST>\n';
          //
          xml += '<ALLLEDGERENTRIES.LIST>\n';
          xml += `<LEDGERNAME>${selectedLedger}</LEDGERNAME>\n`;
          xml += '<ISDEEMEDPOSITIVE>Yes</ISDEEMEDPOSITIVE>\n';
          xml += `<AMOUNT>-${transaction.depositAmount}</AMOUNT>\n`;
          xml += '</ALLLEDGERENTRIES.LIST>\n';
        } else {
          xml += '<ALLLEDGERENTRIES.LIST>\n';
          xml += `<LEDGERNAME>${transaction.ledger}</LEDGERNAME>\n`;
          xml += '<ISDEEMEDPOSITIVE>Yes</ISDEEMEDPOSITIVE>\n';
          xml += `<AMOUNT>-${transaction.withdrawalAmount}</AMOUNT>\n`;
          xml += '</ALLLEDGERENTRIES.LIST>\n';

          xml += '<ALLLEDGERENTRIES.LIST>\n';
          xml += `<LEDGERNAME>${selectedLedger}</LEDGERNAME>\n`;
          xml += '<ISDEEMEDPOSITIVE>No</ISDEEMEDPOSITIVE>\n';
          xml += `<AMOUNT>${transaction.withdrawalAmount}</AMOUNT>\n`;
          xml += '</ALLLEDGERENTRIES.LIST>\n';
        }
      } else if (voucherTypeName === 'Sales') {
        if (transaction.depositAmount && parseFloat(transaction.depositAmount) > 0) {
          xml += '<ALLLEDGERENTRIES.LIST>\n';
          // xml += `<LEDGERNAME>${transaction.ledger}</LEDGERNAME>\n`;
          xml += `<LEDGERNAME>${selectedLedger}</LEDGERNAME>\n`;
          xml += '<ISDEEMEDPOSITIVE>Yes</ISDEEMEDPOSITIVE>\n';
          // xml += `<AMOUNT>${transaction.depositAmount}</AMOUNT>\n`;
          xml += `<AMOUNT>-${transaction.depositAmount}</AMOUNT>\n`;
          xml += '</ALLLEDGERENTRIES.LIST>\n';
          //
          xml += '<ALLLEDGERENTRIES.LIST>\n';
          xml += `<LEDGERNAME>${transaction.ledger}</LEDGERNAME>\n`;
          xml += '<ISDEEMEDPOSITIVE>No</ISDEEMEDPOSITIVE>\n';
          xml += `<AMOUNT>${transaction.depositAmount}</AMOUNT>\n`;
          // xml += `<AMOUNT>-${transaction.depositAmount}</AMOUNT>\n`;
          xml += '</ALLLEDGERENTRIES.LIST>\n';
        } else {
          xml += '<ALLLEDGERENTRIES.LIST>\n';
          xml += `<LEDGERNAME>${transaction.ledger}</LEDGERNAME>\n`;
          xml += '<ISDEEMEDPOSITIVE>Yes</ISDEEMEDPOSITIVE>\n';
          xml += `<AMOUNT>-${transaction.withdrawalAmount}</AMOUNT>\n`;
          xml += '</ALLLEDGERENTRIES.LIST>\n';

          xml += '<ALLLEDGERENTRIES.LIST>\n';
          xml += `<LEDGERNAME>${selectedLedger}</LEDGERNAME>\n`;
          xml += '<ISDEEMEDPOSITIVE>No</ISDEEMEDPOSITIVE>\n';
          xml += `<AMOUNT>${transaction.withdrawalAmount}</AMOUNT>\n`;
          xml += '</ALLLEDGERENTRIES.LIST>\n';
        }
      } else {
        if (voucherTypeName === 'Receipt') {
          // Deposit first
          xml += '<ALLLEDGERENTRIES.LIST>\n';
          xml += `<LEDGERNAME>${transaction.ledger}</LEDGERNAME>\n`;
          xml += '<ISDEEMEDPOSITIVE>No</ISDEEMEDPOSITIVE>\n';
          xml += `<AMOUNT>${transaction.depositAmount}</AMOUNT>\n`;
          xml += '</ALLLEDGERENTRIES.LIST>\n';

          // Then the corresponding deduction from the bank account
          xml += '<ALLLEDGERENTRIES.LIST>\n';
          xml += `<LEDGERNAME>${selectedLedger}</LEDGERNAME>\n`;
          xml += '<ISDEEMEDPOSITIVE>Yes</ISDEEMEDPOSITIVE>\n';
          xml += `<AMOUNT>-${transaction.depositAmount}</AMOUNT>\n`;
          xml += '</ALLLEDGERENTRIES.LIST>\n';
        } else if (voucherTypeName === 'Payment') {

          xml += '<ALLLEDGERENTRIES.LIST>\n';
          xml += `<LEDGERNAME>${transaction.ledger}</LEDGERNAME>\n`;
          xml += '<ISDEEMEDPOSITIVE>Yes</ISDEEMEDPOSITIVE>\n';
          xml += `<AMOUNT>-${transaction.withdrawalAmount}</AMOUNT>\n`;
          xml += '</ALLLEDGERENTRIES.LIST>\n';
          //
          xml += '<ALLLEDGERENTRIES.LIST>\n';
          xml += `<LEDGERNAME>${selectedLedger}</LEDGERNAME>\n`;
          xml += '<ISDEEMEDPOSITIVE>No</ISDEEMEDPOSITIVE>\n';
          xml += `<AMOUNT>${transaction.withdrawalAmount}</AMOUNT>\n`;
          xml += '</ALLLEDGERENTRIES.LIST>\n';
        }
      }

      xml += '</VOUCHER>\n';
    });


    xml += '</TALLYMESSAGE>\n';
    xml += '</DATA>\n';
    xml += '</BODY>\n';
    xml += '</ENVELOPE>';

    return xml;
  };


  const handleSelectChange = (value) => {
    setSelectedOption(value); // Assuming you have a setSelectedOption function to update the state
  };

  const currentColumns = getCurrentColumns().map(col => {
    return {
      ...col,
      onHeaderCell: () => ({
        style: {
          position: 'sticky',
          top: '135px',
          zIndex: 1000,
          backgroundColor: 'white',
          boxShadow: '0 2px 2px -1px rgba(0,0,0,0.4)'
        }
      })
    };
  });

  const handleFileChange2 = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: 'array' });
      const firstSheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[firstSheetName];
      const range = XLSX.utils.decode_range(worksheet['!ref']);

      let allSectionData = [];
      let sectionData = [];
      let specificSectionData = [];
      let previousDateRange = null;
      let inSection = false;
      let inSpecificSection = false;

      for (let R = range.s.r; R <= range.e.r; ++R) {
        const cellAddress = { c: 0, r: R };
        const cellRef = XLSX.utils.encode_cell(cellAddress);
        const cell = worksheet[cellRef];

        // Check if the cell contains a date range
        if (cell && isDateRange(cell.v)) {
          if (inSection) {
            console.log(`Data from ${previousDateRange} to ${cell.v}:`, sectionData);
            allSectionData = allSectionData.concat(sectionData);
            sectionData = []; // Reset section data for the new date range
          }
          previousDateRange = cell.v; // Update the last date range cell encountered
          inSection = true; // Start a new section
          continue; // Skip the date range marker itself
        }

        // Special handling for the "Bank Accounts" to "Cash-in-hand" section
        if (cell && cell.v === "Bank Accounts") {
          specificSectionData = []; // Reset section data
          inSpecificSection = true; // Start recording data for the specific section
          continue; // Skip the start marker itself
        } else if (cell && /^cash-in-hand$/i.test(cell.v) && inSpecificSection) {
          setSectionData(specificSectionData)
          console.log("Data between 'Bank Accounts' and 'Cash-in-hand':", specificSectionData);
          inSpecificSection = false; // Stop recording data for the specific section
        }

        // Collect data if in a general section
        if (inSection && cell) {
          sectionData.push(cell.v);
        }

        // Collect data for the specific section
        if (inSpecificSection && cell) {
          specificSectionData.push(cell.v);
        }
      }

      // Handle the last section if it wasn't closed by a new date range
      if (sectionData.length > 0) {
        console.log(`Data from ${previousDateRange} to end:`, sectionData);
        allSectionData = allSectionData.concat(sectionData);
      }

      console.log(allSectionData, allSectionData.length)
      setParticularsAndValues(allSectionData); // Update state with all extracted data
    };

    reader.readAsArrayBuffer(file);
  };

  function isDateRange(value) {
    // Check for a date range in the format "1-Apr-2024 to 13-Apr-2024"
    return /^\d{1,2}-\w{3}-\d{4}\s+to\s+\d{1,2}-\w{3}-\d{4}$/.test(value);
  }

  useEffect(() => {
    if (filterOption === "Not Assigned") {
      // When switching to "Not Assigned", clear selections that are not visible under this filter
      setSelectedTransactionIds(new Set(
        [...selectedTransactionIds].filter(id =>
          transactions.find(t => t.id === id && (t.ledger === "Not Assigned" || t.ledger === ""))
        )
      ));
    } else if (filterOption) {
      // Adjust for any specific ledger
      setSelectedTransactionIds(new Set(
        [...selectedTransactionIds].filter(id =>
          transactions.find(t => t.id === id && t.ledger === filterOption)
        )
      ));
    } else {
      // Optionally clear or adjust when no filter is applied
      setSelectedTransactionIds(new Set());
    }
  }, [filterOption, transactions]);

  //
  useEffect(() => {
    const updatedFilteredTransactions = transactions
      .filter(transaction => {
        return searchText ? transaction.narration.toLowerCase().includes(searchText.toLowerCase()) : true;
      })
      .filter(transaction => {
        return filterOption ? (transaction.ledger === filterOption || filterOption === "") : true;
      })
      .filter(transaction => {
        if (!transaction.date) return false;
        const [day, month, year] = transaction.date.split("/");
        const transactionDate = new Date(`20${year}`, month - 1, day);
        const startDate = dateRange.startDate ? dateRange.startDate.toDate() : null;
        const endDate = dateRange.endDate ? dateRange.endDate.toDate() : null;
        if (endDate) endDate.setHours(23, 59, 59, 999);
        if (startDate && endDate) {
          return transactionDate >= startDate && transactionDate <= endDate;
        } else if (startDate) {
          return transactionDate >= startDate;
        } else if (endDate) {
          return transactionDate <= endDate;
        }
        return true; // No date filter applied
      });

    setFilteredTransactionsss(updatedFilteredTransactions);
  }, [transactions, searchText, filterOption, dateRange]);

  //
  const clearFileInputLedger = () => {
    // Reset the file input by updating its key
    setInputKeyLedger(Date.now());

    setSelectedLedger(null);  // Assuming null is your initial state
    setSelectedOption(null);

    // Optionally clear any additional related state, such as loaded data or parsed sections
    setParticularsAndValues([]);
    setSectionData([]);


  };

  const excludedValues = [
    "Branch / Divisions", "Capital Account", "Reserves & Surplus", "Current Assets",
    "Bank Accounts", "Cash-in-Hand", "Loans & Advances (Asset)", "Deposits (Asset)",
    "Stock-in-Hand", "Sundry Debtors", "Current Liabilities", "Duties & Taxes",
    "Provisions", "Sundry Creditors", "Direct Expenses", "Direct Incomes",
    "Fixed Assets", "Indirect Expenses", "Indirect Incomes", "Investments",
    "Loans (Liability)", "Bank OD A/c", "Secured Loans", "Unsecured Loans",
    "Misc. Expenses (ASSET)", "Purchase Accounts", "Sales Accounts", "Suspense A/c",
    "28 Group(s)"
  ];

  // Filter out excluded values
  const filteredAndUniqueParticularsAndValues = Array.from(new Set(particularsAndValues))
    .filter(value => !excludedValues.includes(value));
  console.log('convert2', filteredTransactions)

  // if (!user) {
  //   // Handle the case when the user is not authenticated or logged in
  //   return <div>Loading...</div>; // Or any other appropriate component or UI
  // }

  return (
    <div>
      {/* <Modal
        title="Example Bank Statement"
        visible={dialogVisible}
        onCancel={handleDialogClose}
        footer={null}
      >
        <p>Here's an example of a bank statement:</p>
        <img src={image} alt="Example Bank Statement" style={{ width: '100%' }} />
        <p>Make sure your bank statement follows a similar format.</p>
      </Modal> */}
      {/* <Modal
  title="Example Bank Statement"
  visible={dialogVisible}
  onCancel={handleDialogClose}
  footer={null}
>
  <p>Here's an example of a bank statement headers:</p>
  <div
    style={{
      overflow: 'hidden',
      position: 'relative',
    }}
  >
    <img
      src={image}
      alt="Example Bank Statement"
      style={{
        width: '100%',
        transition: 'transform 0.3s ease',
        cursor: 'zoom-in',
        transform: 'scale(1)',
      }}
      onMouseEnter={(e) => {
        e.currentTarget.style.transform = 'scale(1.5)';
      }}
      onMouseLeave={(e) => {
        e.currentTarget.style.transform = 'scale(1)';
      }}
      onMouseMove={(e) => {
        const rect = e.currentTarget.getBoundingClientRect();
        const offsetX = e.clientX - rect.left;
        const offsetY = e.clientY - rect.top;
        const percentX = (offsetX / rect.width) * 100;
        const percentY = (offsetY / rect.height) * 100;
        e.currentTarget.style.transformOrigin = `${percentX}% ${percentY}%`;
      }}
    />
  </div>
  <p>Ensure your column headers match the column headers in this example to make it work.</p>
</Modal> */}
{/* <Modal
  title="Example Bank Statement"
  visible={dialogVisible}
  onCancel={handleDialogClose}
  footer={null}
  
>
  <p>Here's an example of a bank statement headers:</p>
  <div
    style={{
      overflow: 'hidden',
      position: 'relative',
      //width: '100%',
      height: '400px', // Set a fixed height for the container
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}
  >
    <img
      src={image}
      alt="Example Bank Statement"
      style={{
        //maxWidth: '100%',
        maxHeight: '100%',
        transition: 'transform 0.3s ease',
        cursor: 'zoom-in',
        transform: 'scale(1)',
      }}
      onMouseEnter={(e) => {
        e.currentTarget.style.transform = 'scale(1.5)';
      }}
      onMouseLeave={(e) => {
        e.currentTarget.style.transform = 'scale(1)';
      }}
      onMouseMove={(e) => {
        const rect = e.currentTarget.getBoundingClientRect();
        const offsetX = e.clientX - rect.left;
        const offsetY = e.clientY - rect.top;
        const percentX = (offsetX / rect.width) * 100;
        const percentY = (offsetY / rect.height) * 100;
        e.currentTarget.style.transformOrigin = `${percentX}% ${percentY}%`;
      }}
    />
  </div>
  <p>Ensure your column headers match the column headers in this example to make it work.</p>
</Modal> */}
<Modal
  title="Example Bank Statement"
  visible={dialogVisible}
  onCancel={handleDialogClose}
  footer={null}
  width="100vw" // Set modal width to full viewport width
  centered // Center the modal vertically
  bodyStyle={{ padding: 0 }} // Optional: Remove padding for full-width content
  style={{
    top: '25%', // Center the modal vertically
    transform: 'translateY(-50%)', // Adjust to keep modal centered
    maxWidth: '100%', // Ensure modal doesn't exceed the screen width
    margin: 0, // Reset margin to prevent shifting
    padding: 0, // Remove padding for exact width
    
  }}
>
  <p style={{ margin: '0 16px' }}>Here's an example of a bank statement headers:</p> {/* Optional: Add some margin for text */}
  <div
    style={{
      overflow: 'hidden',
      position: 'relative',
      width: '100%', // Ensure the container spans full width
      height: '400px', // Set a fixed height for the container
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}
  >
    <img
      src={image}
      alt="Example Bank Statement"
      style={{
        maxWidth: '100%',
        maxHeight: '100%',
        transition: 'transform 0.3s ease',
        cursor: 'zoom-in',
        transform: 'scale(1)',
      }}
      onMouseEnter={(e) => {
        e.currentTarget.style.transform = 'scale(1.5)';
      }}
      onMouseLeave={(e) => {
        e.currentTarget.style.transform = 'scale(1)';
      }}
      onMouseMove={(e) => {
        const rect = e.currentTarget.getBoundingClientRect();
        const offsetX = e.clientX - rect.left;
        const offsetY = e.clientY - rect.top;
        const percentX = (offsetX / rect.width) * 100;
        const percentY = (offsetY / rect.height) * 100;
        e.currentTarget.style.transformOrigin = `${percentX}% ${percentY}%`;
      }}
    />
  </div>
  <p style={{ margin: '0 16px' }}>Ensure your column headers match the column headers in this example to make it work. Additionally, other column headers do not need to change.</p> {/* Optional: Add some margin for text */}
</Modal>

      {/* <UserButton supabaseClient={supabase} appearance={{ theme: 'auto' }} /> */}
      <div style={{display:'flex', flexDirection:'row-reverse'}}>
      {/* {userData.is_paid !== true && (
  <div
    style={{
      backgroundColor: 'rgb(255, 0, 128)',
      color: 'white',
      padding: '4px 8px',
      borderRadius: '4px',
      marginLeft: '92.5%',
      fontSize: '14px',
      fontWeight: 'bold',
      width:'20%'
    }}
  >
    Trial Period
  </div>
)} */}
{userData.is_paid !== true && (
    <div
      style={{
        backgroundColor: 'rgb(255, 0, 128)',
        color: 'white',
        padding: '4px 8px',
        borderRadius: '4px',
        //marginLeft: '92.5%',
        fontSize: '14px',
        fontWeight: 'bold',
        // width: '20%'
      }}
    >
      {new Date(userData.latest_login_date) > new Date(new Date(userData.created_at).getTime() + 3 * 24 * 60 * 60 * 1000 + userData.additional_days * 24 * 60 * 60 * 1000)
        ? 'Trial Period Expired'
        : 'Trial Period Ongoing'}
    </div>
  )}
  <div>
  {userData.email === 'solutionnyx@gmail.com' && (
      <Link to="/Adm1n_P@n3l_53cUr3_9x7Q!x">
        <Button>Add column headers</Button>
      </Link>
    )}
    </div>
</div>
      <div style={{ marginLeft: '20px', display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
        
        <h2 style={{color:'#007bff'}}>Token Count: </h2>
        <h2
          style={{
            // fontSize: '2rem',
            fontWeight: 'bold',
            color: 'rgb(255, 0, 128)',
            // ...tokenSpring
          }}
        >
          &nbsp;&nbsp;
          {/* {updatedToken !== undefined ? updatedToken : userData.token} */}
          {updatedToken !== null ? updatedToken : userData.token}
        </h2>
        
      </div>
      <Card>
        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
        <h4 onClick={handleDialogOpen} style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline' }}>
                if your bank statement isn't compatible with our converter click this</h4>
          <Button onClick={handleDownloadXmlClick}>Download XML</Button>
        </div>

        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ flex: 1, marginRight: '20px' }}>
            <h2 style={{ color: '#1677ff', marginBottom: '15px' }}>Bank Statement Processing</h2>
            <div style={{ marginBottom: '10px' }}>
              <h4>Upload Bank Statement Here</h4>
              <h6 style={{color:'rgb(255, 0, 128)'}}>Please note: This feature only works with Excel and CSV formats.</h6>
              <Input
                key={inputKey}
                type="file"
                accept="application/pdf, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, text/csv"
                onChange={handleFileChange}
              />
              <Button onClick={clearFileInput} style={{ marginTop: '5px' }}>Clear</Button>
            </div>
            <div style={{ marginBottom: '10px' }}>
              <h4>Filter By Date Range</h4>
              <RangePicker
                onChange={(dates) => {
                  const [start, end] = dates || [];
                  setDateRange({ startDate: start, endDate: end });
                }}
              />
            </div>
          </div>
          <div style={{ flex: 1 }}>
            <h2 style={{ color: '#1677ff', marginBottom: '15px' }}>Upload Ledger File</h2>
            <div style={{ marginBottom: '10px' }}>
              <h4>Upload Master File Here</h4>
                            <h6 style={{color:'rgb(255, 0, 128)'}}>Please note: This feature only works with Excel and CSV formats.</h6>

              <Input
                key={inputKeyLedger}
                type="file"
                accept=".xlsx, .xls"
                onChange={handleFileChange2}
              />
              <Button onClick={clearFileInputLedger} style={{ marginTop: '5px' }}>Clear</Button>
            </div>
            <div style={{ marginBottom: '10px' }}>
              <h4>Filter by Ledger</h4>
              <Select
                showSearch
                style={{ width: '100%' }}
                placeholder="Filter by Ledger"
                optionFilterProp="children"
                onChange={handleChange}
                allowClear={true}
                value={filterOption || undefined}
              >
                <Option value="">All</Option>
                {ledgerOptions.map(option => (
                  <Option key={option} value={option}>{option}</Option>
                ))}
              </Select>
            </div>
            <div style={{ marginBottom: '10px' }}>
              <h4>Select Bank Accounts</h4>
              <Select
                showSearch
                style={{ width: '100%' }}
                placeholder="Select Bank Accounts"
                onChange={handleLedgerSelect}
                value={selectedLedger || null}
                allowClear={true}
              >
                {sectionData.sort().map((item, index) => (
                  <Select.Option key={index.toString()} value={item}>{item}</Select.Option>
                ))}
              </Select>
            </div>
          </div>
        </div>
      </Card>

      <div style={{ position: 'sticky', top: '0px', zIndex: 1000, backgroundColor: 'white', padding: '10px 0', borderBottom: '1px solid #ccc', width: '100%' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
          <div style={{ width: '95%' }}>
            <h4 style={{ marginBottom: '5px' }}>Search By Narration</h4>
            <CustomSearchSelect
              options={narrationOptions}
              onSearchChange={setSearchText}
              onChange={handleNarrationChange}
              multiSelect={false}

            />
            <Button onClick={handleUndo} disabled={undoCount >= 3}>Undo</Button>

          </div>
          <div style={{ position: 'sticky', top: 15, zIndex: 1000, backgroundColor: 'white', paddingLeft: '30px', width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>

            <h4 style={{ marginBottom: '5px', marginLeft: '-175px' }}>Narration Input</h4>
            <div style={{ paddingLeft: '20px', display: 'flex' }}>
              <Input
                style={{ width: 200 }}
                placeholder="Enter Narration"
                value={narrationInput}
                onChange={(e) => setNarrationInput(e.target.value)}
              />
              <Button onClick={handleAssignNarrationClick} style={{ marginLeft: '10px' }}>Assign Narration</Button>
            </div>
          </div>
          <div style={{ position: 'sticky', top: 15, zIndex: 1000, backgroundColor: 'white', paddingLeft: '30px', width: '100%' }}>
            <h4 style={{ marginBottom: '5px' }}>Select a Ledger</h4>
            <div style={{ marginBottom: '20px', display: 'flex' }}>

              <div>
                <Select
                  showSearch
                  style={{ width: 200 }}
                  placeholder="Select a Ledger"
                  optionFilterProp="children"
                  onChange={handleSelectChange}
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  value={selectedOption}
                  allowClear={true}
                >
                  {filteredAndUniqueParticularsAndValues.sort().map((value, index) => (
                    <Select.Option key={index.toString()} value={value}>{value}</Select.Option>
                  ))}
                </Select>
              </div>
              <div style={{ paddingLeft: '20px' }}>
                <Button onClick={handleAssignClick}>Assign Ledger</Button>

              </div>
            </div>
          </div>
        </div>
      </div>
      {tableVisible && (
        <Form form={form} component={false}>
          <Table
            rowKey="id"
            dataSource={filteredTransactionsss}
            columns={currentColumns}
            pagination={false}
            components={{
              body: {
                cell: EditableCell,
              },
            }}
          />
        </Form>
      )}
    </div>
  );
};

export default BankStatement;