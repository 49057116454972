// import React from 'react';
// import { motion } from 'framer-motion';
// import BeforeAfterImg from './BeforeAfterImg.jpeg';
// import { color } from '@chakra-ui/react';

// const BeforeAfterImage = () => {
//   // Container style for the component
//   const containerStyle = {
//     display: 'flex',
//     flexDirection: 'row',
//     alignItems: 'center',
//     justifyContent: 'space-between',
//     padding: '40px',
//     backgroundColor: '#f5f5f5',
//     borderRadius: '12px',
//     boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
//     margin: '20px',
//     backgroundColor:'black'
//   };

//   // Style for the image container
//   const imageContainerStyle = {
//     flex: '1',
//     marginRight: '20px',
//     width: '40%', // Reduced width by 20%
//     position: 'relative',
//     right: '1%',
//   };

//   // Style for the image
//   const imageStyle = {
//     width: '100%',
//     height: 'auto',
//     borderRadius: '12px',
//   };

//   // Style for the text content
//   const contentStyle = {
//     flex: '1',
//     width: '60%', // Increased width to occupy the remaining space
//     position: 'relative',
//     left: '1%',
//   };

//   const headingStyle = {
//     marginBottom: '20px',
//     fontSize: '2rem',
//     color: '#333', // Color to match the text
//     color:"rgb(128, 255, 255)"
//   };

//   const listStyle = {
//     listStyleType: 'none',
//     paddingLeft: '0',
//   };

//   const listItemStyle = {
//     marginBottom: '10px',
//     fontSize: '1.1rem',
//   };
// //   return (
// //     <div style={containerStyle}>
// //       <div style={imageContainerStyle}>
// //         <img 
// //           style={imageStyle} 
// //           src={BeforeAfterImg} 
// //           alt="Before and After Tallymatic" 
// //         />
// //       </div>
// //       <div style={contentStyle}>
// //         <motion.h2
// //           initial={{ opacity: 0, y: 50 }}
// //           animate={{ opacity: 1, y: 0 }}
// //           transition={{ duration: 1, delay: 2.5 }}
// //         >
// //           Why Choose TallyMatic?
// //         </motion.h2>
// //         <motion.ul
// //           initial={{ opacity: 0, y: 50 }}
// //           animate={{ opacity: 1, y: 0 }}
// //           transition={{ duration: 1, delay: 2.5 }}
// //         >
// //           <motion.li><strong>User-Friendly Interface:</strong> Our intuitive design makes it easy for anyone to use TallyMatic, regardless of their technical expertise.</motion.li>
// //           <motion.li><strong>Time-Saving:</strong> Spend less time on manual entries and more time on what matters most.</motion.li>
// //           <motion.li><strong>Accuracy:</strong> Reduce errors and improve the reliability of your accounting data.</motion.li>
// //           <motion.li><strong>Customer Support:</strong> Our dedicated support team is always here to help you make the most of TallyMatic.</motion.li>
// //         </motion.ul>
// //       </div>
// //     </div>
// //   );
// return (
//     <div style={containerStyle}>
//       <div style={imageContainerStyle}>
//         <img 
//           style={imageStyle} 
//           src={BeforeAfterImg} 
//           alt="Before and After Tallymatic" 
//         />
//       </div>
//       <div style={contentStyle}>
//         <motion.h2
//           style={headingStyle}
//           initial={{ opacity: 0, y: 50 }}
//           animate={{ opacity: 1, y: 0 }}
//           transition={{ duration: 1, delay: 0.5 }}
//         >
//           Why Choose TallyMatic?
//         </motion.h2>
//         <motion.ul
//           style={listStyle}
//           initial={{ opacity: 0, y: 50 }}
//           animate={{ opacity: 1, y: 0 }}
//           transition={{ duration: 1, delay: 1 }}
//         >
//           <motion.li style={listItemStyle}>
//             <strong>Increase Accuracy:</strong> Say goodbye to manual entry errors and ensure precise accounting records with TallyMatic.
//           </motion.li>
//           <motion.li style={listItemStyle}>
//             <strong>Save Time:</strong> Convert months or even years of transactions into Tally in just seconds, saving you valuable time.
//           </motion.li>
//           <motion.li style={listItemStyle}>
//             <strong>User-Friendly Interface:</strong> Our intuitive design makes it easy for anyone to use TallyMatic, regardless of their technical expertise.
//           </motion.li>
//           <motion.li style={listItemStyle}>
//             <strong>Customer Support:</strong> Our dedicated support team is always here to help you make the most of TallyMatic.
//           </motion.li>
//         </motion.ul>
//       </div>
//     </div>
//   );
// };

// export default BeforeAfterImage;

// import React from 'react';
// import { motion } from 'framer-motion';
// import BeforeAfterImg from './BeforeAfterImg.jpeg';

// const containerStyle = {
//   display: 'flex',
//   flexDirection: 'row',
//   alignItems: 'center',
//   justifyContent: 'space-between',
//   padding: '40px',
//   backgroundColor: 'black',
//   borderRadius: '12px',
//   boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
//   margin: '20px',
//   flexWrap: 'wrap',
// };

// const imageContainerStyle = {
//   flex: '1',
//   marginRight: '20px',
//   width: '40%',
//   '@media (max-width: 768px)': {
//     width: '100%',
//     marginBottom: '20px',
//   },
// };

// const imageStyle = {
//   width: '100%',
//   height: 'auto',
//   borderRadius: '12px',
// };

// const contentStyle = {
//   flex: '1',
//   width: '60%',
//   '@media (max-width: 768px)': {
//     width: '100%',
//   },
// };

// const headingStyle = {
//   marginBottom: '20px',
//   fontSize: '2rem',
//   color: 'rgb(128, 255, 255)',
// };

// const listStyle = {
//   listStyleType: 'none',
//   paddingLeft: '0',
// };

// const listItemStyle = {
//   marginBottom: '10px',
//   fontSize: '1.1rem',
//   color: 'rgb(128, 255, 255)',
// };

// const BeforeAfterImage = () => (
//   <div style={containerStyle}>
//     <div style={imageContainerStyle}>
//       <img
//         style={imageStyle}
//         src={BeforeAfterImg}
//         alt="Before and After Tallymatic"
//       />
//     </div>
//     <div style={contentStyle}>
//       <motion.h2
//         style={headingStyle}
//         initial={{ opacity: 0, y: 50 }}
//         animate={{ opacity: 1, y: 0 }}
//         transition={{ duration: 1, delay: 0.5 }}
//       >
//         Why Choose TallyMatic?
//       </motion.h2>
//       <motion.ul
//         style={listStyle}
//         initial={{ opacity: 0, y: 50 }}
//         animate={{ opacity: 1, y: 0 }}
//         transition={{ duration: 1, delay: 1 }}
//       >
//         <motion.li style={listItemStyle}>
//           <strong>Increase Accuracy:</strong> Say goodbye to manual entry errors and ensure precise accounting records with TallyMatic.
//         </motion.li>
//         <motion.li style={listItemStyle}>
//           <strong>Save Time:</strong> Convert months or even years of transactions into Tally in just seconds, saving you valuable time.
//         </motion.li>
//         <motion.li style={listItemStyle}>
//           <strong>User-Friendly Interface:</strong> Our intuitive design makes it easy for anyone to use TallyMatic, regardless of their technical expertise.
//         </motion.li>
//         <motion.li style={listItemStyle}>
//           <strong>Customer Support:</strong> Our dedicated support team is always here to help you make the most of TallyMatic.
//         </motion.li>
//       </motion.ul>
//     </div>
//   </div>
// );

// export default BeforeAfterImage;

// import React from 'react';
// import { motion } from 'framer-motion';
// import BeforeAfterImg from './BeforeAfterImg.jpeg';

// const containerStyle = {
//   display: 'flex',
//   flexDirection: 'row',
//   alignItems: 'center',
//   justifyContent: 'space-between',
//   padding: '40px',
//   margin: '20px 0',
//   flexWrap: 'wrap',
// };

// const imageContainerStyle = {
//   flex: '1',
//   marginRight: '20px',
//   width: '40%',

//   '@media (max-width: 768px)': {
//     width: '100%',
//     marginBottom: '20px',
//   },
// };

// const imageStyle = {
//   width: '80%',
//   height: 'auto',
//   borderRadius: '12px',
// };

// const contentStyle = {
//   flex: '1',
//   width: '60%',

//   '@media (max-width: 768px)': {
//     width: '100%',
//   },
// };

// const swipeFilesStyle = {
//   fontSize: '18px',
//   color: 'rgb(0, 123, 255)', // Adjusted font color
//   marginBottom: '10px',
//   fontStyle: 'italic', // Italic style

//   '@media (max-width: 768px)': {
//     fontSize: '16px',
//     marginBottom: '5px',
//   },
// };

// const headingStyle = {
//   marginBottom: '20px',
//   fontSize: '36px', // Adjusted font size
//   color: 'black', // Adjusted font color
//   fontWeight: 'bold',

//   '@media (max-width: 768px)': {
//     fontSize: '28px',
//     marginBottom: '10px',
//   },
// };

// const listStyle = {
//   listStyleType: 'none',
//   paddingLeft: '0',
// };

// const listItemStyle = {
//   display: 'flex',
//   alignItems: 'center',
//   marginBottom: '15px',
//   fontSize: '18px', // Adjusted font size
//   color: 'black', // Adjusted font color

//   '@media (max-width: 768px)': {
//     fontSize: '16px',
//     marginBottom: '10px',
//   },
// };

// const iconStyle = {
//   marginRight: '10px',
//   color: 'rgb(0, 123, 255)', // Adjusted icon color
// };

// const BeforeAfterImage = () => (
//   <div style={containerStyle}>
//     <div style={imageContainerStyle}>
//       <img
//         style={imageStyle}
//         src={BeforeAfterImg}
//         alt="Before and After Tallymatic"
//       />
//     </div>
//     <div style={contentStyle}>
//       <motion.div
//         initial={{ opacity: 0, y: 50 }}
//         animate={{ opacity: 1, y: 0 }}
//         transition={{ duration: 1, delay: 0.5 }}
//       >
//         {/* <div style={swipeFilesStyle}>Swipe files</div> */}
//         <h2 style={headingStyle}>Why Choose TallyMatic?</h2>
//         <motion.ul
//           style={listStyle}
//           initial={{ opacity: 0, y: 50 }}
//           animate={{ opacity: 1, y: 0 }}
//           transition={{ duration: 1, delay: 1 }}
//         >
//           <motion.li style={listItemStyle}>
//             <span style={iconStyle}>✔</span> <strong>Increase Accuracy:</strong> Say goodbye to manual entry errors and ensure precise accounting records with TallyMatic.
//           </motion.li>
//           <motion.li style={listItemStyle}>
//             <span style={iconStyle}>✔</span> <strong>Save Time:</strong> Convert months or even years of transactions into Tally in just seconds, saving you valuable time.
//           </motion.li>
//           <motion.li style={listItemStyle}>
//             <span style={iconStyle}>✔</span> <strong>User-Friendly Interface:</strong> Our intuitive design makes it easy for anyone to use TallyMatic, regardless of their technical expertise.
//           </motion.li>
//           <motion.li style={listItemStyle}>
//             <span style={iconStyle}>✔</span> <strong>Customer Support:</strong> Our dedicated support team is always here to help you make the most of TallyMatic.
//           </motion.li>
//         </motion.ul>
//       </motion.div>
//     </div>
//   </div>
// );

// export default BeforeAfterImage;

import React from 'react';
import { motion } from 'framer-motion';
import BeforeAfterImg from './BeforeAfterImg.jpeg';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 40px;
  margin: 20px 0;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const ImageContainer = styled.div`
  flex: 1;
  margin-right: 20px;
  width: 40%;

  @media (max-width: 768px) {
    width: 100%;
    margin-bottom: 20px;
  }
`;

const Image = styled.img`
  width: 80%;
  height: auto;
  border-radius: 12px;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const Content = styled.div`
  flex: 1;
  width: 60%;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const Heading = styled.h2`
  margin-bottom: 20px;
  font-size: 36px;
  color: black;
  font-weight: bold;

  @media (max-width: 768px) {
    font-size: 28px;
    margin-bottom: 10px;
  }
`;

const List = styled(motion.ul)`
  list-style-type: none;
  padding-left: 0;
`;

// const ListItem = styled(motion.li)`
//   display: flex;
//   align-items: center;
//   margin-bottom: 15px;
//   font-size: 18px;
//   color: black;

//   @media (max-width: 768px) {
//     font-size: 16px;
//     margin-bottom: 10px;
//   }
// `;
const ListItem = styled.li`
  display: flex;
  margin-bottom: 10px;
  
  strong {
    min-width: 150px; // Adjust this value as needed
    margin-right: 10px;
  }
`;
const Paragraph = styled.p`
  margin-bottom: 10px;
  font-size: 18px;
  color: black;

  @media (max-width: 768px) {
    font-size: 16px;
  }
`;
const Icon = styled.span`
  margin-right: 10px;
  color: rgb(0, 123, 255);
`;

const BeforeAfterImage = () => (
  <Container>
    <ImageContainer>
      <Image
        src={BeforeAfterImg}
        alt="Before and After Tallymatic"
      />
    </ImageContainer>
    <Content>
  <motion.div
    initial={{ opacity: 0, y: 50 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ duration: 1, delay: 0.5 }}
  >
    <Heading>Why Choose TallyMatic?</Heading>
    <Paragraph>
      TallyMatic offers a range of benefits to revolutionize your accounting process:
    </Paragraph>
    <List>
      <ListItem>
        <Icon>✔</Icon> <strong>Increase Accuracy:</strong> Say goodbye to manual entry errors and ensure precise accounting records with TallyMatic.
      </ListItem>
      <ListItem>
        <Icon>✔</Icon> <strong>Save Time:</strong> Convert months or even years of transactions into Tally in just seconds, saving you valuable time.
      </ListItem>
      <ListItem>
        <Icon>✔</Icon> <strong>User-Friendly Interface:</strong> Our intuitive design makes it easy for anyone to use TallyMatic, regardless of their technical expertise.
      </ListItem>
      <ListItem>
        <Icon>✔</Icon> <strong>Customer Support:</strong> Our dedicated support team is always here to help you make the most of TallyMatic.
      </ListItem>
    </List>
    <Paragraph>
      Experience these benefits and more by choosing TallyMatic for your accounting needs.
    </Paragraph>
  </motion.div>
</Content>
  </Container>
);

export default BeforeAfterImage;
