import React from 'react';
import { motion } from 'framer-motion';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px 20px;
  background-color: #f9f9f9;
  font-family: 'Poppins', sans-serif;
  color: #333;
`;

const Title = styled(motion.h1)`
  font-size: 42px;
  color: #007bff;
  margin-bottom: 20px;
  text-align: center;
`;

const SectionTitle = styled(motion.h2)`
  font-size: 32px;
  color: #333;
  margin-top: 30px;
  margin-bottom: 15px;
  text-align: center;
`;

const Paragraph = styled(motion.p)`
  font-size: 18px;
  line-height: 1.8;
  margin-bottom: 15px;
  text-align: justify;
  max-width: 800px;
`;

const List = styled(motion.ul)`
  font-size: 18px;
  line-height: 1.8;
  margin-bottom: 15px;
  max-width: 800px;
  padding-left: 20px;
`;

const ListItem = styled(motion.li)`
  margin-bottom: 10px;
`;

const Footer = styled.footer`
  width: 100%;
  padding: 20px;
  text-align: center;
  background-color: #333;
  color: #fff;
  bottom: 0;
  position: fixed;
`;

const Nav = styled.nav`
  width: 100%;
  background-color: #333;
  padding: 10px 0;
  display: flex;
  justify-content: flex-end;
`;

const NavLink = styled(Link)`
  color: #fff;
  text-decoration: none;
  padding: 0 20px;
  font-size: 18px;
  position: relative;
  transition: color 0.3s;

  &::after {
    content: '';
    position: absolute;
    width: 0;
    height: 2px;
    display: block;
    margin-top: 5px;
    right: 0;
    background: #007bff;
    transition: width 0.4s ease, right 0.4s ease;
  }

  &:hover {
    color: #007bff;
  }

  &:hover::after {
    width: 100%;
    right: 0;
  }
`;

const RefundPolicy = () => {
  return (
    <div>
      <Nav>
        <NavLink to="/">Home</NavLink>
        <NavLink to="/about">About</NavLink>
        <NavLink to="/contact">Contact</NavLink>
        {/* <NavLink to="/gst">Login</NavLink> */}
        <NavLink to="/privacypolicy">Privacy Policy</NavLink>
        {/* <NavLink to="/refundpolicy">Refunds/Cancellations</NavLink> */}
        <NavLink to="/TermsAndConditions">Terms and Condition</NavLink>
        <NavLink to="/buyNow">Pricing</NavLink>
      </Nav>

      <Container>
        <Title
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1 }}
        >
          Refund/Cancellation Policy
        </Title>
        
        <Paragraph
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1, delay: 0.5 }}
        >
          We offer a full money-back guarantee for all purchases made on our website. If you are not satisfied with the product that you have purchased from us, you can get your money back no questions asked. You are eligible for a full reimbursement within 14 calendar days of your purchase.
        </Paragraph>

        <Paragraph
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1, delay: 1 }}
        >
          After the 14-day period, you will no longer be eligible and won't be able to receive a refund. We encourage our customers to try the product in the first two weeks after their purchase to ensure it fits your needs.
        </Paragraph>

        <Paragraph
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1, delay: 1.5 }}
        >
          If you have any additional questions or would like to request a refund, feel free to contact us.
        </Paragraph>

        <Paragraph
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1, delay: 2 }}
        >
          Refund processing time: 5-7 working days.
        </Paragraph>
      </Container>

      <Footer>---------R. K. Paper Products 2024 ----</Footer>
    </div>
  );
};

export default RefundPolicy;
