import React, { useState } from 'react';
import { motion } from 'framer-motion';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';
import { useLocation } from 'react-router-dom';
import { supabase } from './supabaseClient';
//import TallyMaticVideo from './TallyMaticVideo.mp4';
import Dialog from './Dialog';
import { position } from '@chakra-ui/react';

const styles = {
  container: {
    // display: 'flex',
    // gap: '20px',
    // justifyContent: 'center',
    // alignItems: 'center',
    // height: '55vh',
    // fontFamily: "'Poppins', sans-serif",
    // padding: '20px',
    display: 'flex',
    flexWrap: 'wrap',
    gap: '20px',
    justifyContent: 'center',
    alignItems: 'center',
    height: 'auto',
    fontFamily: "'Poppins', sans-serif",
    padding: '20px',
  },
  plan: {
    // borderRadius: '15px',
    // textAlign: 'center',
    // width: '300px',
    // padding: '20px',
    // color: '#fff',
    // position: 'relative',
    // transition: 'transform 0.3s ease',
    // height: '415px',
    // overflow: 'hidden',
    // display:'flex',
    // flexDirection:'column',
    // justifyContent:'space-between'
    borderRadius: '15px',
    textAlign: 'center',
    width: '100%',
    maxWidth: '300px',
    padding: '20px',
    color: '#fff',
    position: 'relative',
    transition: 'transform 0.3s ease',
    height: '415px',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  planHover: {
    transform: 'translateY(-10px)',
  },
  basic: {
    background: 'linear-gradient(135deg, rgb(0, 128, 255) 0%, rgb(128, 255, 255) 100%)',
  },
  premium: {
    background: 'linear-gradient(135deg, rgb(70, 0, 89) 0%, rgb(255, 0, 128) 100%)',
  },
  enterprise: {
    background: 'linear-gradient(135deg, rgb(0, 6, 24) 0%, rgb(0, 8, 19) 100%)',
  },
  newBasic: {
    background: 'linear-gradient(135deg, rgb(30, 60, 90) 0%, rgb(60, 120, 180) 100%)', // Customized colors for the new card
  },
  title: {
    fontSize: '24px',
    margin: '10px 0',
  },
  subtitle: {
    fontSize: '16px',
    margin: '10px 0',
  },
  price: {
    fontSize: '36px',
    margin: '20px 0',
  },
  button: {
    backgroundColor: 'rgba(255, 255, 255, 0.3)',
    color: '#fff',
    border: 'none',
    padding: '10px 20px',
    borderRadius: '25px',
    cursor: 'pointer',
    marginTop: '20px',
    transition: 'background-color 0.3s',
  },
  buttonHover: {
    backgroundColor: 'rgba(255, 255, 255, 0.5)',
  },
  features: {
    textAlign: 'left',
    marginTop: '120px',
  },
  feature: {
    // display: 'flex',
    // alignItems: 'center',
    // margin: '10px 0',
    // fontSize: '14px',
    display: 'flex',
    alignItems: 'center',
    margin: '10px 0',
    fontSize: '14px',
    position: 'relative',
    bottom: '25px'
    //top:'20px'
  },
  icon: {
    marginRight: '10px',
    color: '#fff',
  },
  ribbon: {
    position: 'absolute',
    top: '15px',
    right: '15px',
    background: '#ff007b',
    color: '#fff',
    padding: '5px 10px',
    borderRadius: '15px',
    fontSize: '12px',
  },
};

const Title = styled(motion.h1)`
  font-size: 42px;
  color: rgb(0, 123, 255);
  margin-bottom: 20px;
  text-align: center;
`;

const PopupOverlay = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PopupContent = styled(motion.div)`
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  max-width: 400px;
  width: 80%;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  text-align: center;
`;

const Nav = styled.nav`
  width: 100%;
  background-color: rgb(0, 6, 24);
  padding: 10px 0;
  display: flex;
  justify-content: flex-end;
`;

const NavLink = styled(Link)`
  color: rgb(255, 255, 255);
  text-decoration: none;
  padding: 0 20px;
  font-size: 18px;
  position: relative;
  transition: color 0.3s;

  &::after {
    content: '';
    position: absolute;
    width: 0;
    height: 2px;
    display: block;
    margin-top: 5px;
    right: 0;
    background: rgb(0, 128, 255);
    transition: width 0.4s ease, right 0.4s ease;
  }

  &:hover {
    color: rgb(0, 128, 255);
  }

  &:hover::after {
    width: 100%;
    right: 0;
  }
`;

const Footer = styled.footer`
  width: 100%;
  padding: 20px;
  text-align: center;
  background-color: white;
  color: black;
  bottom: 0;
  position: fixed;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px 20px;
  background-color: white;
  font-family: 'Poppins', sans-serif;
  color: rgb(255, 255, 255);
`;

const Paragraph = styled(motion.p)`
  font-size: 18px;
  line-height: 1.8;
  margin-bottom: 15px;
  text-align: justify;
  max-width: 800px;
`;

const InlineLink = styled.span`
  color: #007bff;
  cursor: pointer;
  text-decoration: underline;

  &:hover {
    color: black;
  }
`;

const PaymentAfterLogin = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const userEmail = searchParams.get('email');

  console.log('User email from PaymentAfterLoginss:', userEmail);
  const [amount, setAmount] = useState(5000);
  const [email, setEmail] = useState(userEmail);
  const [dialogState, setDialogState] = useState({
    showToast: true,
    showTerms: false,
    showPrivacy: false,
    showRefund: false,
    showHowItWorks: false,
  });

  const toggleDialog = (dialog) => {
    setDialogState((prevState) => ({
      ...prevState,
      [dialog]: !prevState[dialog],
    }));
  };
  const handlePayment = async (amount) => {
    try {
      const res = await fetch(`https://ec2-54-227-142-120.compute-1.amazonaws.com:4000/api/payment/order`, {
        method: 'POST',
        headers: {
          'content-type': 'application/json',
        },
        body: JSON.stringify({ amount }),
      });

      const data = await res.json();
      console.log('data', data);
      handlePaymentVerify(data.data, email, amount);

      const getRes = await fetch(`https://ec2-54-227-142-120.compute-1.amazonaws.com:400/api/payment/data`, {
        method: 'GET',
        headers: {
          'content-type': 'application/json',
        },
      });

      const getData = await getRes.json();
      console.log('getData', getData);
    } catch (error) {
      console.log(error);
    }
  };

  const handlePaymentVerify = async (data, email, amount) => {
    const options = {
      key: 'rzp_live_eitGuFQofbaGnG',
      amount: data.amount,
      currency: data.currency,
      name: 'tallyMatic',
      //description: 'Test Mode',
      description: 'Live Transaction',
      order_id: data.id,
      handler: async (response) => {
        console.log('response', response);
        try {
          const res = await fetch(`https://ec2-54-227-142-120.compute-1.amazonaws.com:400/api/payment/verify`, {
            method: 'POST',
            headers: {
              'content-type': 'application/json',
            },
            body: JSON.stringify({
              razorpay_order_id: response.razorpay_order_id,
              razorpay_payment_id: response.razorpay_payment_id,
              razorpay_signature: response.razorpay_signature,
              email,
              amount,
            }),
          });

          const verifyData = await res.json();

          console.log(verifyData);
          if (verifyData.message === 'Payment Successfully') {
            console.log('Payload sent for verification:', {
              razorpay_order_id: response.razorpay_order_id,
              razorpay_payment_id: response.razorpay_payment_id,
              razorpay_signature: response.razorpay_signature,
              email,
              amount,
            });
            toast.success(verifyData.message);

            let newToken;
            if (amount === 5000) {
              newToken = 50;
            } else if (amount === 8000) {
              newToken = 100;
            } else {
              newToken = 0;
            }

            const { data: userData, error: userError } = await supabase
              .from('users_talllymatic')
              .select('token')
              .eq('email', email)
              .single();

            if (userError) {
              console.error('Error fetching user data:', userError);
              return;
            }

            const existingToken = userData.token || 0;
            const updatedToken = existingToken + newToken;

            const { data: updatedUser, error } = await supabase
              .from('users_talllymatic')
              .update({ is_paid: true, token: updatedToken, amount: amount })
              .eq('email', email);

            if (error) {
              console.error('Error updating user data:', error);
            } else {
              console.log('User data updated successfully:', updatedUser);
            }
            window.location.href = '/gst';
          }
        } catch (e) {
          console.log(e);
        }
      },
      theme: {
        color: '#5f63b8',
      },
    };
    const rzp1 = new window.Razorpay(options);
    rzp1.open();
  };

  return (
    <div>
      <Container>
        <Container>
          <Title
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1 }}
          >
            Buy Now
          </Title>
        </Container>

        <div style={styles.container}>
          <div style={{ ...styles.plan, ...styles.newBasic }}>
            <h2 style={styles.title}>Basic</h2>
            <p style={styles.subtitle}>Ideal for personal use</p>
            <p style={styles.price}>
              Rs 1,000 <span>/ Per Month</span>
            </p>
            <button
              style={styles.button}
              onMouseOver={(e) => (e.target.style.backgroundColor = 'rgba(255, 255, 255, 0.5)')}
              onMouseOut={(e) => (e.target.style.backgroundColor = 'rgba(255, 255, 255, 0.3)')}
              onClick={handlePayment}
            >
              Get started
            </button>
            <div style={styles.features}>
              <div style={styles.feature}>
                <i className="fas fa-check-circle" style={styles.icon}></i> 50 Bank Statements per month
              </div>
            </div>
          </div>
          <div style={{ ...styles.plan, ...styles.basic }}>
            <h2 style={styles.title}>Pro</h2>
            <p style={styles.subtitle}>Best for personal use</p>
            <p style={styles.price}>
              Rs 5,000 <span>/ Per Year</span>
            </p>

            <button
              style={styles.button}
              onMouseOver={(e) => (e.target.style.backgroundColor = 'rgba(255, 255, 255, 0.5)')}
              onMouseOut={(e) => (e.target.style.backgroundColor = 'rgba(255, 255, 255, 0.3)')}
              onClick={() => handlePayment(5000)}
            >
              Get started
            </button>
            <Toaster />
            <div style={styles.features}>
              <div style={styles.feature}>
                <i className="fas fa-check-circle" style={styles.icon}></i> 50 Bank Statements per month
              </div>
            </div>
          </div>
          <div style={{ ...styles.plan, ...styles.premium }}>
            <div style={styles.ribbon}>Popular</div>
            <h2 style={styles.title}>Premium</h2>
            <p style={styles.subtitle}>Perfect for professionals and small businesses</p>
            <p style={styles.price}>
              Rs 8,000 <span>/ Per Year</span>
            </p>

            <button
              style={styles.button}
              onMouseOver={(e) => (e.target.style.backgroundColor = 'rgba(255, 255, 255, 0.5)')}
              onMouseOut={(e) => (e.target.style.backgroundColor = 'rgba(255, 255, 255, 0.3)')}
              onClick={() => handlePayment(8000)}
            >
              Get started
            </button>
            <div style={styles.features}>
              <div style={styles.feature}>
                <i className="fas fa-check-circle" style={styles.icon}></i> 100 Bank Statements per month
              </div>
            </div>
          </div>
          
        </div>
        {/* <Container>
          <Title
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1 }}
          >
            Refund/Cancellation Policy
          </Title>

          <Paragraph
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1, delay: 0.5 }}
          >
            We offer a full money-back guarantee for all purchases made on our website. If you are not satisfied with the product that you have purchased from us, you can get your money back no questions asked. You are eligible for a full reimbursement within 14 calendar days of your purchase.
          </Paragraph>

          <Paragraph
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1, delay: 1 }}
          >
            After the 14-day period, you will no longer be eligible and won't be able to receive a refund. We encourage our customers to try the product in the first two weeks after their purchase to ensure it fits your needs.
          </Paragraph>

          <Paragraph
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1, delay: 1.5 }}
          >
            If you have any additional questions or would like to request a refund, feel free to contact us.
          </Paragraph>

          <Paragraph
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1, delay: 2 }}
          >
            Refund processing time: 5-7 working days.
          </Paragraph>
        </Container> */}
      </Container>
      {/* <Footer>---------R. K. Paper Products 2024 ----</Footer> */}
      <Footer>
        © R. K. Paper Products 2024 |
        <InlineLink onClick={() => toggleDialog('showTerms')}> Terms and Conditions</InlineLink> |
        <InlineLink onClick={() => toggleDialog('showPrivacy')}> Privacy Policy</InlineLink> |
        <InlineLink onClick={() => toggleDialog('showRefund')}> Refund/Cancellation Policy</InlineLink>
      </Footer>


      <Dialog isOpen={dialogState.showTerms} onClose={() => toggleDialog('showTerms')} title="Terms and Conditions">
        <p>Last updated: 9th June 2024</p>
        <h2>Introduction</h2>
        <p>Welcome to TallyMatic! These Terms and Conditions outline the rules and regulations for the use of TallyMatic's website and services.</p>
        <h2>Acceptance of Terms</h2>
        <p>By accessing and using TallyMatic, you accept and agree to be bound by these terms. If you do not agree to these terms, please do not use our services.</p>
        <h2>Use of Service</h2>
        <p>You agree to use the service only for lawful purposes and in accordance with these Terms and Conditions.</p>
        <h2>Changes to Terms</h2>
        <p>We reserve the right to modify or replace these Terms and Conditions at any time. We will notify you of changes by posting the new terms on our website.</p>
        <p>If you have any questions about these Terms and Conditions, please contact us.</p>
      </Dialog>

      <Dialog isOpen={dialogState.showPrivacy} onClose={() => toggleDialog('showPrivacy')} title="Privacy Policy">
        <p>Your privacy is important to us. It is TallyMatic's policy to respect your privacy regarding any information we may collect from you across our website, http://tallymatic.com, and other sites we own and operate.</p>
        <p>We only ask for personal information when we truly need it to provide a service to you. We collect it by fair and lawful means, with your knowledge and consent. We also let you know why we're collecting it and how it will be used.</p>
        <p>We only retain collected information for as long as necessary to provide you with your requested service. What data we store, we'll protect within commercially acceptable means to prevent loss and theft, as well as unauthorized access, disclosure, copying, use, or modification.</p>
        <p>We don't share any personally identifying information publicly or with third-parties, except when required to by law.</p>
        <p>Our website may link to external sites that are not operated by us. Please be aware that we have no control over the content and practices of these sites, and cannot accept responsibility or liability for their respective privacy policies.</p>
        <p>You are free to refuse our request for your personal information, with the understanding that we may be unable to provide you with some of your desired services.</p>
        <p>Your continued use of our website will be regarded as acceptance of our practices around privacy and personal information. If you have any questions about how we handle user data and personal information, feel free to contact us.</p>
        <p>This policy is effective as of 1 January 2024.</p>
      </Dialog>

      <Dialog isOpen={dialogState.showRefund} onClose={() => toggleDialog('showRefund')} title="Refund/Cancellation Policy">
        <p>We offer a full money-back guarantee for all purchases made on our website. If you are not satisfied with the product that you have purchased from us, you can get your money back no questions asked. You are eligible for a full reimbursement within 14 calendar days of your purchase.</p>
        <p>After the 14-day period, you will no longer be eligible and won't be able to receive a refund. We encourage our customers to try the product in the first two weeks after their purchase to ensure it fits your needs.</p>
        <p>If you have any additional questions or would like to request a refund, feel free to contact us.</p>
        <p>Refund processing time: 5-7 working days.</p>
      </Dialog>

      <Dialog isOpen={dialogState.showHowItWorks} onClose={() => toggleDialog('showHowItWorks')} title="How It Works?">
        <div style={{ textAlign: 'center' }}>
          {/* <video width="100%" controls>
          <source src='https://www.youtube.com/watch?v=CdmbipAzaBU' type="video/mp4" />
            Your browser does not support the video tag.
          </video> */}
          <iframe width="100%" height="315" src="https://www.youtube.com/embed/CdmbipAzaBU" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
      </Dialog>

    </div>
  );
};

export default PaymentAfterLogin;
