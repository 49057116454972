// // WelcomePage.js
// import React from 'react';
// import { Link } from 'react-router-dom';
// import { motion } from 'framer-motion';
// import styled from 'styled-components';
// import Navigation from './Navigation'
// import AboutPage from './AboutPage';
// import ContactPage from './ContactPage';
// import PrivacyPolicyPage from './PrivacyPolicyPage';
// import TermsAndConditions from './TermsAndConditions';
// import BuyNow from './BuyNow';

// // const Container = styled.div`
// //   display: flex;
// //   flex-direction: column;
// //   align-items: center;
// //   justify-content: center;
// //   height: 100vh;
// //   background-color: #f5f5f5;
// //   font-family: 'Arial, sans-serif';
// // `;

// // const Title = styled(motion.h1)`
// //   font-size: 48px;
// //   color: #333;
// //   margin-bottom: 20px;
// // `;

// // const Description = styled(motion.p)`
// //   font-size: 24px;
// //   color: #666;
// //   margin-bottom: 40px;
// //   text-align: center;
// // `;

// const Button = styled(motion.button)`
//   padding: 16px 32px;
//   font-size: 20px;
//   background-color: #007bff;
//   color: #fff;
//   border: none;
//   border-radius: 4px;
//   cursor: pointer;
//   transition: background-color 0.3s ease;

//   &:hover {
//     background-color: #0056b3;
//   }
// `;

// const Nav = styled.nav`
//   width: 100%;
//   background-color: #333;
//   padding: 10px 0;
//   display: flex;
//   justify-content: flex-end;
// `;

// const NavLink = styled(Link)`
//   color: #fff;
//   text-decoration: none;
//   padding: 0 20px;
//   font-size: 18px;
//   position: relative;
//   transition: color 0.3s;

//   &::after {
//     content: '';
//     position: absolute;
//     width: 0;
//     height: 2px;
//     display: block;
//     margin-top: 5px;
//     right: 0;
//     background: #007bff;
//     transition: width 0.4s ease, right 0.4s ease;
//   }

//   &:hover {
//     color: #007bff;
//   }

//   &:hover::after {
//     width: 100%;
//     right: 0;
//   }
// `;

// const PopupOverlay = styled(motion.div)`
//   position: fixed;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 100%;
//   background: rgba(0, 0, 0, 0.5);
//   display: flex;
//   justify-content: center;
//   align-items: center;
// `;

// const PopupContent = styled(motion.div)`
//   background: #fff;
//   padding: 20px;
//   border-radius: 8px;
//   max-width: 400px;
//   width: 80%;
//   box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
//   text-align: center;
// `;

// const Container = styled.div`
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: center;
//   height: 100vh;
//   background-color: #f5f5f5;
//   font-family: 'Arial, sans-serif';
//   padding: 20px;

//   @media (max-width: 768px) {
//     padding: 10px;
//   }
// `;

// const Title = styled(motion.h1)`
//   font-size: 48px;
//   color: #333;
//   margin-bottom: 20px;

//   @media (max-width: 768px) {
//     font-size: 32px;
//   }
// `;

// const Description = styled(motion.p)`
//   font-size: 24px;
//   color: #666;
//   margin-bottom: 40px;
//   text-align: center;

//   @media (max-width: 768px) {
//     font-size: 18px;
//   }
// `;

// const Footer = styled.footer`
//   width: 100%;
//   padding: 20px;
//   text-align: center;
//   background-color: #333;
//   color: #fff;
//   position: fixed;
//   bottom: 0;

//   @media (max-width: 768px) {
//     padding: 10px;
//     font-size: 14px;
//   }
// `;
// const WelcomePage = () => {
//   return (
//     <div>
//         <div>
//         <Navigation /> 
//     </div>
//     <Container>

//       <Title
//         initial={{ opacity: 0, y: -50 }}
//         animate={{ opacity: 1, y: 0 }}
//         transition={{ duration: 1 }}
//       >
//         Welcome to Bank Statement Converter
//       </Title>
//       <Description
//         initial={{ opacity: 0, y: 50 }}
//         animate={{ opacity: 1, y: 0 }}
//         transition={{ duration: 1, delay: 0.5 }}
//       >
//         Simplify your financial analysis with our powerful tool.
//         <br />
//         Convert your bank statements effortlessly!
//       </Description>
//       <Link to="/sign-in">
//         <Button
//           whileHover={{ scale: 1.1 }}
//           whileTap={{ scale: 0.95 }}
//           initial={{ opacity: 0 }}
//           animate={{ opacity: 1 }}
//           transition={{ duration: 1, delay: 1 }}
//         >
//           Get Started
//         </Button>
//       </Link>
//     </Container>
//     <AboutPage />
//     <ContactPage/>
//     <PrivacyPolicyPage/>
//     <TermsAndConditions/>
//     <BuyNow/>
//     <Footer>---------R. K. Paper Products 2024 ----</Footer>
//     </div>
//   );
// };

// export default WelcomePage;

// import React from 'react';
// import { Link } from 'react-router-dom';
// import { motion } from 'framer-motion';
// import styled from 'styled-components';
// import Navigation from './Navigation'
// import AboutPage from './AboutPage';
// import ContactPage from './ContactPage';
// import PrivacyPolicyPage from './PrivacyPolicyPage';
// import TermsAndConditions from './TermsAndConditions';
// import BuyNow from './BuyNow';
// import { SignedIn, SignedOut } from '@clerk/clerk-react';

// const Container = styled.div`
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: center;
//   height: 100vh;
//   background-color: rgb(0, 4, 29);
//   font-family: 'Arial, sans-serif';
//   padding: 20px;

//   @media (max-width: 768px) {
//     padding: 10px;
//   }
// `;

// const Title = styled(motion.h1)`
//   font-size: 48px;
//   color: rgb(128, 255, 255);
//   margin-bottom: 20px;

//   @media (max-width: 768px) {
//     font-size: 32px;
//   }
// `;

// const Description = styled(motion.p)`
//   font-size: 24px;
//   color: rgb(255, 255, 255);
//   margin-bottom: 40px;
//   text-align: center;

//   @media (max-width: 768px) {
//     font-size: 18px;
//   }
// `;

// const Button = styled(motion.button)`
//   padding: 16px 32px;
//   font-size: 20px;
//   background-color: rgb(255, 0, 128);
//   color: rgb(255, 255, 255);
//   border: none;
//   border-radius: 4px;
//   cursor: pointer;
//   transition: background-color 0.3s ease;

//   &:hover {
//     background-color: rgb(70, 0, 89);
//   }
// `;

// const Footer = styled.footer`
//   width: 100%;
//   padding: 20px;
//   text-align: center;
//   background-color: rgb(0, 6, 24);
//   color: rgb(255, 255, 255);
//   position: fixed;
//   bottom: 0;

//   @media (max-width: 768px) {
//     padding: 10px;
//     font-size: 14px;
//   }
// `;

// const WelcomePage = () => {
//   return (
//     <div>
//       <div>
//         <Navigation /> 
//       </div>
//       <Container>
//         <Title
//           initial={{ opacity: 0, y: -50 }}
//           animate={{ opacity: 1, y: 0 }}
//           transition={{ duration: 1 }}
//         >
//           Welcome to Bank Statement Converter
//         </Title>
//         <Description
//           initial={{ opacity: 0, y: 50 }}
//           animate={{ opacity: 1, y: 0 }}
//           transition={{ duration: 1, delay: 0.5 }}
//         >
//           Simplify your financial analysis with our powerful tool.
//           <br />
//           Convert your bank statements effortlessly!
//         </Description>
//         {/* <Link to="/sign-in">
//           <Button
//             whileHover={{ scale: 1.1 }}
//             whileTap={{ scale: 0.95 }}
//             initial={{ opacity: 0 }}
//             animate={{ opacity: 1 }}
//             transition={{ duration: 1, delay: 1 }}
//           >
//             Get Started
//           </Button>
//         </Link> */}
//         <SignedOut>
//           <Link to="/sign-in">
//             <Button
//               whileHover={{ scale: 1.1 }}
//               whileTap={{ scale: 0.95 }}
//               initial={{ opacity: 0 }}
//               animate={{ opacity: 1 }}
//               transition={{ duration: 1, delay: 1 }}
//             >
//               Get Started
//             </Button>
//           </Link>
//         </SignedOut>
//         <SignedIn>
//           <Link to="/gst">
//             <Button
//               whileHover={{ scale: 1.1 }}
//               whileTap={{ scale: 0.95 }}
//               initial={{ opacity: 0 }}
//               animate={{ opacity: 1 }}
//               transition={{ duration: 1, delay: 1 }}
//             >
//               Go to Dashboard
//             </Button>
//           </Link>
//         </SignedIn>
//       </Container>
//       <AboutPage />
//       <ContactPage/>
//       <PrivacyPolicyPage/>
//       <TermsAndConditions/>
//       <BuyNow/>
//       <Footer>---------R. K. Paper Products 2024 ----</Footer>
//     </div>
//   );
// };

// export default WelcomePage;

// import React from 'react';
// import { Link } from 'react-router-dom';
// import { motion } from 'framer-motion';
// import styled from 'styled-components';
// import Navigation from './Navigation';
// import AboutPage from './AboutPage';
// import ContactPage from './ContactPage';
// import PrivacyPolicyPage from './PrivacyPolicyPage';
// import TermsAndConditions from './TermsAndConditions';
// import BuyNow from './BuyNow';
// import { SignedIn, SignedOut } from '@clerk/clerk-react';
// import StatsCards from './StatsCards'; // Import the StatsCards component

// const Container = styled.div`
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: center;
//   height: 100vh;
//   background-color: rgb(0, 4, 29);
//   font-family: 'Arial, sans-serif';
//   padding: 20px;

//   @media (max-width: 768px) {
//     padding: 10px;
//   }
// `;

// const Title = styled(motion.h1)`
//   font-size: 48px;
//   color: rgb(128, 255, 255);
//   margin-bottom: 20px;

//   @media (max-width: 768px) {
//     font-size: 32px;
//   }
// `;

// const Description = styled(motion.p)`
//   font-size: 24px;
//   color: rgb(255, 255, 255);
//   margin-bottom: 40px;
//   text-align: center;

//   @media (max-width: 768px) {
//     font-size: 18px;
//   }
// `;

// const Button = styled(motion.button)`
//   padding: 16px 32px;
//   font-size: 20px;
//   background-color: rgb(255, 0, 128);
//   color: rgb(255, 255, 255);
//   border: none;
//   border-radius: 4px;
//   cursor: pointer;
//   transition: background-color 0.3s ease;

//   &:hover {
//     background-color: rgb(70, 0, 89);
//   }
// `;

// const Footer = styled.footer`
//   width: 100%;
//   padding: 20px;
//   text-align: center;
//   background-color: rgb(0, 6, 24);
//   color: rgb(255, 255, 255);
//   position: fixed;
//   bottom: 0;

//   @media (max-width: 768px) {
//     padding: 10px;
//     font-size: 14px;
//   }
// `;

// const WelcomePage = () => {
//   return (
//     <div>
//       <div>
//         <Navigation /> 
//       </div>
//       <Container>
//         <Title
//           initial={{ opacity: 0, y: -50 }}
//           animate={{ opacity: 1, y: 0 }}
//           transition={{ duration: 1 }}
//         >
//           Welcome to the Future of Accounting with TallyMatic!
//         </Title>
//         <Description
//           initial={{ opacity: 0, y: 50 }}
//           animate={{ opacity: 1, y: 0 }}
//           transition={{ duration: 1, delay: 0.5 }}
//         >
//           Simplify your financial analysis with our powerful tool.
//           <br />
//           Convert your bank statements effortlessly!
//         </Description>
//         <SignedOut>
//           <Link to="/sign-in">
//             <Button
//               whileHover={{ scale: 1.1 }}
//               whileTap={{ scale: 0.95 }}
//               initial={{ opacity: 0 }}
//               animate={{ opacity: 1 }}
//               transition={{ duration: 1, delay: 1 }}
//             >
//               Get Started
//             </Button>
//           </Link>
//         </SignedOut>
//         <SignedIn>
//           <Link to="/gst">
//             <Button
//               whileHover={{ scale: 1.1 }}
//               whileTap={{ scale: 0.95 }}
//               initial={{ opacity: 0 }}
//               animate={{ opacity: 1 }}
//               transition={{ duration: 1, delay: 1 }}
//             >
//               Go to Dashboard
//             </Button>
//           </Link>
//         </SignedIn>
//       </Container>
//       <AboutPage />
//       <StatsCards /> {/* Use the StatsCards component */}
//       {/* <AboutPage /> */}
//       <ContactPage />
//       <PrivacyPolicyPage />
//       <TermsAndConditions />
//       <BuyNow />
//       <Footer>---------R. K. Paper Products 2024 ----</Footer>
//     </div>
//   );
// };

// export default WelcomePage;

// import React, { useState } from 'react';
// import { Link } from 'react-router-dom';
// import { motion, AnimatePresence } from 'framer-motion';
// import styled from 'styled-components';
// import { SignedIn, SignedOut } from '@clerk/clerk-react';
// import Navigation from './Navigation';
// import AboutPage from './AboutPage';
// import ContactPage from './ContactPage';
// import PrivacyPolicyPage from './PrivacyPolicyPage';
// import TermsAndConditions from './TermsAndConditions';
// import BuyNow from './BuyNow';
// import StatsCards from './StatsCards'; // Import the StatsCards component

// const Container = styled.div`
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: center;
//   height: 100vh;
//   background-color: rgb(0, 4, 29);
//   font-family: 'Arial, sans-serif';
//   padding: 20px;

//   @media (max-width: 768px) {
//     padding: 10px;
//   }
// `;

// const Title = styled(motion.h1)`
//   font-size: 48px;
//   color: rgb(128, 255, 255);
//   margin-bottom: 20px;

//   @media (max-width: 768px) {
//     font-size: 32px;
//   }
// `;

// const Description = styled(motion.p)`
//   font-size: 24px;
//   color: rgb(255, 255, 255);
//   margin-bottom: 20px;
//   text-align: center;

//   @media (max-width: 768px) {
//     font-size: 18px;
//   }
// `;

// const Button = styled(motion.button)`
//   padding: 16px 32px;
//   font-size: 20px;
//   background-color: rgb(255, 0, 128);
//   color: rgb(255, 255, 255);
//   border: none;
//   border-radius: 4px;
//   cursor: pointer;
//   transition: background-color 0.3s ease;

//   &:hover {
//     background-color: rgb(70, 0, 89);
//   }
// `;

// const Footer = styled.footer`
//   width: 100%;
//   padding: 20px;
//   text-align: center;
//   background-color: rgb(0, 6, 24);
//   color: rgb(255, 255, 255);
//   position: fixed;
//   bottom: 0;

//   @media (max-width: 768px) {
//     padding: 10px;
//     font-size: 14px;
//   }
// `;

// const Toast = styled(motion.div)`
//   position: fixed;
//   top: 20px;
//   left: 20px;
//   background-color: rgb(255, 0, 128);
//   color: rgb(255, 255, 255);
//   padding: 16px 32px;
//   border-radius: 4px;
//   z-index: 1000;
//   font-size: 18px;
//   cursor: pointer;
// `;

// const WelcomePage = () => {
//   const [showToast, setShowToast] = useState(true);

//   return (
//     <div>
//       <Navigation />
//       <AnimatePresence>
//         {showToast && (
//           <Toast
//             initial={{ opacity: 0, x: -50 }}
//             animate={{ opacity: 1, x: 0 }}
//             exit={{ opacity: 0, x: -50 }}
//             transition={{ duration: 0.5 }}
//             onClick={() => setShowToast(false)}
//           >
//             Login and take a free trial now!
//           </Toast>
//         )}
//       </AnimatePresence>
//       <Container>
//         <Title
//           initial={{ opacity: 0, y: -50 }}
//           animate={{ opacity: 1, y: 0 }}
//           transition={{ duration: 1 }}
//         >
//           Welcome to the Future of Accounting with TallyMatic!
//         </Title>
//         <Description
//           initial={{ opacity: 0, y: 50 }}
//           animate={{ opacity: 1, y: 0 }}
//           transition={{ duration: 1, delay: 0.5 }}
//         >
//           Simplify your financial analysis with our powerful tool.
//           <br />
//           Convert your bank statements effortlessly!
//         </Description>
//         <SignedOut>
//           <Link to="/sign-in">
//             <Button
//               whileHover={{ scale: 1.1 }}
//               whileTap={{ scale: 0.95 }}
//               initial={{ opacity: 0 }}
//               animate={{ opacity: 1 }}
//               transition={{ duration: 1, delay: 1 }}
//             >
//               Get Started
//             </Button>
//           </Link>
//         </SignedOut>
//         <SignedIn>
//           <Link to="/gst">
//             <Button
//               whileHover={{ scale: 1.1 }}
//               whileTap={{ scale: 0.95 }}
//               initial={{ opacity: 0 }}
//               animate={{ opacity: 1 }}
//               transition={{ duration: 1, delay: 1 }}
//             >
//               Go to Dashboard
//             </Button>
//           </Link>
//         </SignedIn>
//       </Container>
//       <AboutPage />
//       <StatsCards /> 
//       <ContactPage />
//       <PrivacyPolicyPage />
//       <TermsAndConditions />
//       <BuyNow />
//       <Footer>---------R. K. Paper Products 2024 ----</Footer>
//     </div>
//   );
// };

// export default WelcomePage;

// import React, { useState } from 'react';
// import { Link } from 'react-router-dom';
// import { motion, AnimatePresence } from 'framer-motion';
// import styled from 'styled-components';
// import { SignedIn, SignedOut } from '@clerk/clerk-react';
// import Navigation from './Navigation';
// import AboutPage from './AboutPage';
// import ContactPage from './ContactPage';
// import BuyNow from './BuyNow';
// import StatsCards from './StatsCards';
// import Dialog from './Dialog';
// import Welcome from './welcome.png'

// // const Container = styled.div`
// //   display: flex;
// //   flex-direction: column;
// //   align-items: center;
// //   justify-content: center;
// //   height: 100vh;
// //   background-color: rgb(0, 4, 29);
// //   font-family: 'Arial, sans-serif';
// //   padding: 20px;
// //   background-image: url(${Welcome});
// //   background-image: url(${Welcome});
// //   background-size: cover;
// //   background-position: center;
// //   background-repeat: no-repeat;
// //   position: relative;
// //   @media (max-width: 768px) {
// //     padding: 10px;
// //   }
// // `;
// // const Container = styled.div`
// //   display: flex;
// //   flex-direction: column;
// //   align-items: center;
// //   justify-content: center;
// //   height: 100vh;
// //   background-color: rgb(0, 4, 29);
// //   font-family: 'Arial, sans-serif';
// //   padding: 20px;
// //   background-image: url(${Welcome});
// //   background-size: cover;
// //   background-position: center;
// //   background-repeat: no-repeat;
// //   position: relative;

// //   &::before {
// //     content: '';
// //     position: absolute;
// //     top: 0;
// //     left: 0;
// //     right: 0;
// //     bottom: 0;
// //     background-color: rgba(0, 4, 29, 0.7); /* Adjust the opacity here */
// //     z-index: 0;
// //   }

// //   @media (max-width: 768px) {
// //     padding: 10px;
// //   }
// // `;

// // const Title = styled(motion.h1)`
// //   font-size: 48px;
// //   color: rgb(128, 255, 255);
// //   margin-bottom: 20px;

// //   @media (max-width: 768px) {
// //     font-size: 32px;
// //   }
// // `;

// // const Description = styled(motion.p)`
// //   font-size: 24px;
// //   color: rgb(255, 255, 255);
// //   margin-bottom: 20px;
// //   text-align: center;

// //   @media (max-width: 768px) {
// //     font-size: 18px;
// //   }
// // `;

// // const Button = styled(motion.button)`
// //   padding: 16px 32px;
// //   font-size: 20px;
// //   background-color: rgb(255, 0, 128);
// //   color: rgb(255, 255, 255);
// //   border: none;
// //   border-radius: 4px;
// //   cursor: pointer;
// //   transition: background-color 0.3s ease;

// //   &:hover {
// //     background-color: rgb(70, 0, 89);
// //   }
// // `;

// // const Footer = styled.footer`
// //   width: 100%;
// //   padding: 20px;
// //   text-align: center;
// //   background-color: rgb(0, 6, 24);
// //   color: rgb(255, 255, 255);
// //   position: fixed;
// //   bottom: 0;

// //   @media (max-width: 768px) {
// //     padding: 10px;
// //     font-size: 14px;
// //   }
// // `;

// // const Toast = styled(motion.div)`
// //   position: fixed;
// //   top: 20px;
// //   left: 20px;
// //   background-color: rgb(255, 0, 128);
// //   color: rgb(255, 255, 255);
// //   padding: 16px 32px;
// //   border-radius: 4px;
// //   z-index: 1000;
// //   font-size: 18px;
// //   cursor: pointer;
// // `;

// // const InlineLink = styled.span`
// //   color: rgb(128, 255, 255);
// //   cursor: pointer;
// //   text-decoration: underline;

// //   &:hover {
// //     color: rgb(255, 255, 255);
// //   }
// // `;
// const Container = styled.div`
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: center;
//   height: 100vh;
//   background-color: rgb(0, 4, 29);
//   font-family: 'Arial, sans-serif';
//   padding: 20px;
//   background-image: url(${Welcome});
//   background-size: cover;
//   background-position: center;
//   background-repeat: no-repeat;
//   position: relative;

//   &::before {
//     content: '';
//     position: absolute;
//     top: 0;
//     left: 0;
//     right: 0;
//     bottom: 0;
//     background-color: rgba(0, 4, 29, 0.8); /* Adjust the opacity here */
//     z-index: 0;
//   }

//   @media (max-width: 768px) {
//     padding: 10px;
//   }
// `;

// const Content = styled.div`
//   position: relative;
//   z-index: 1;
//   text-align: center;
// `;

// const Title = styled(motion.h1)`
//   font-size: 48px;
//   color: rgb(128, 255, 255);
//   margin-bottom: 20px;

//   @media (max-width: 768px) {
//     font-size: 32px;
//   }
// `;

// const Description = styled(motion.p)`
//   font-size: 24px;
//   color: rgb(255, 255, 255);
//   margin-bottom: 20px;
//   text-align: center;

//   @media (max-width: 768px) {
//     font-size: 18px;
//   }
// `;

// const Button = styled(motion.button)`
//   padding: 16px 32px;
//   font-size: 20px;
//   background-color: rgb(255, 0, 128);
//   color: rgb(255, 255, 255);
//   border: none;
//   border-radius: 4px;
//   cursor: pointer;
//   transition: background-color 0.3s ease;

//   &:hover {
//     background-color: rgb(70, 0, 89);
//   }
// `;

// const Footer = styled.footer`
//   width: 100%;
//   padding: 20px;
//   text-align: center;
//   background-color: rgb(0, 6, 24);
//   color: rgb(255, 255, 255);
//   position: fixed;
//   bottom: 0;

//   @media (max-width: 768px) {
//     padding: 10px;
//     font-size: 14px;
//   }
// `;

// const Toast = styled(motion.div)`
//   position: fixed;
//   top: 20px;
//   left: 20px;
//   background-color: rgb(255, 0, 128);
//   color: rgb(255, 255, 255);
//   padding: 16px 32px;
//   border-radius: 4px;
//   z-index: 1000;
//   font-size: 18px;
//   cursor: pointer;
// `;

// const InlineLink = styled.span`
//   color: rgb(128, 255, 255);
//   cursor: pointer;
//   text-decoration: underline;

//   &:hover {
//     color: rgb(255, 255, 255);
//   }
// `;

// const WelcomePage = () => {
//   const [showToast, setShowToast] = useState(true);
//   const [showTerms, setShowTerms] = useState(false);
//   const [showPrivacy, setShowPrivacy] = useState(false);

//   return (
//     <div>
//       <Navigation />
//       <AnimatePresence>
//         {showToast && (
//           <Toast
//             initial={{ opacity: 0, x: -50 }}
//             animate={{ opacity: 1, x: 0 }}
//             exit={{ opacity: 0, x: -50 }}
//             transition={{ duration: 0.5 }}
//             onClick={() => setShowToast(false)}
//           >
//             Login and take a free trial now!
//           </Toast>
//         )}
//       </AnimatePresence>
//       <Container>
//         <Title
//           initial={{ opacity: 0, y: -50 }}
//           animate={{ opacity: 1, y: 0 }}
//           transition={{ duration: 1 }}
//         >
//           Welcome to the Future of Accounting with TallyMatic!
//         </Title>
//         <Description
//           initial={{ opacity: 0, y: 50 }}
//           animate={{ opacity: 1, y: 0 }}
//           transition={{ duration: 1, delay: 0.5 }}
//         >
//           Simplify your financial analysis with our powerful tool.
//           <br />
//           Convert your bank statements effortlessly!
//         </Description>
//         <SignedOut>
//           <Link to="/sign-in">
//             <Button
//               whileHover={{ scale: 1.1 }}
//               whileTap={{ scale: 0.95 }}
//               initial={{ opacity: 0 }}
//               animate={{ opacity: 1 }}
//               transition={{ duration: 1, delay: 1 }}
//             >
//               Get Started
//             </Button>
//           </Link>
//         </SignedOut>
//         <SignedIn>
//           <Link to="/gst">
//             <Button
//               whileHover={{ scale: 1.1 }}
//               whileTap={{ scale: 0.95 }}
//               initial={{ opacity: 0 }}
//               animate={{ opacity: 1 }}
//               transition={{ duration: 1, delay: 1 }}
//             >
//               Go to Converter
//             </Button>
//           </Link>
//         </SignedIn>
//       </Container>
//       <AboutPage />
//       <StatsCards />
//       <BuyNow />
//       <ContactPage />
//       <Footer>
//         © R. K. Paper Products 2024 | 
//         <InlineLink onClick={() => setShowTerms(true)}> Terms and Conditions</InlineLink> | 
//         <InlineLink onClick={() => setShowPrivacy(true)}> Privacy Policy</InlineLink>
//       </Footer>

//       <Dialog isOpen={showTerms} onClose={() => setShowTerms(false)} title="Terms and Conditions">
//         <p>Last updated: 9th June 2024</p>
//         <h2>Introduction</h2>
//         <p>Welcome to TallyMatic! These Terms and Conditions outline the rules and regulations for the use of TallyMatic's website and services.</p>
//         <h2>Acceptance of Terms</h2>
//         <p>By accessing and using TallyMatic, you accept and agree to be bound by these terms. If you do not agree to these terms, please do not use our services.</p>
//         <h2>Use of Service</h2>
//         <p>You agree to use the service only for lawful purposes and in accordance with these Terms and Conditions.</p>
//         <h2>Changes to Terms</h2>
//         <p>We reserve the right to modify or replace these Terms and Conditions at any time. We will notify you of changes by posting the new terms on our website.</p>
//         <p>If you have any questions about these Terms and Conditions, please contact us.</p>
//       </Dialog>

//       <Dialog isOpen={showPrivacy} onClose={() => setShowPrivacy(false)} title="Privacy Policy">
//         <p>Your privacy is important to us. It is TallyMatic's policy to respect your privacy regarding any information we may collect from you across our website, http://tallymatic.com, and other sites we own and operate.</p>
//         <p>We only ask for personal information when we truly need it to provide a service to you. We collect it by fair and lawful means, with your knowledge and consent. We also let you know why we're collecting it and how it will be used.</p>
//         <p>We only retain collected information for as long as necessary to provide you with your requested service. What data we store, we'll protect within commercially acceptable means to prevent loss and theft, as well as unauthorized access, disclosure, copying, use, or modification.</p>
//         <p>We don't share any personally identifying information publicly or with third-parties, except when required to by law.</p>
//         <p>Our website may link to external sites that are not operated by us. Please be aware that we have no control over the content and practices of these sites, and cannot accept responsibility or liability for their respective privacy policies.</p>
//         <p>You are free to refuse our request for your personal information, with the understanding that we may be unable to provide you with some of your desired services.</p>
//         <p>Your continued use of our website will be regarded as acceptance of our practices around privacy and personal information. If you have any questions about how we handle user data and personal information, feel free to contact us.</p>
//         <p>This policy is effective as of 1 January 2024.</p>
//       </Dialog>
//     </div>
//   );
// };

// export default WelcomePage;

// import React, { useState } from 'react';
// import { Link } from 'react-router-dom';
// import { motion, AnimatePresence } from 'framer-motion';
// import styled from 'styled-components';
// import { SignedIn, SignedOut } from '@clerk/clerk-react';
// import Navigation from './Navigation';
// import AboutPage from './AboutPage';
// import ContactPage from './ContactPage';
// import BuyNow from './BuyNow';
// import StatsCards from './StatsCards';
// import Dialog from './Dialog';
// import Welcome from './welcome.png';

// const Container = styled.div`
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: center;
//   height: 100vh;
//   background-color: rgb(0, 4, 29);
//   font-family: 'Arial, sans-serif';
//   padding: 20px;
//   background-image: url(${Welcome});
//   background-size: cover;
//   background-position: center;
//   background-repeat: no-repeat;
//   position: relative;

//   &::before {
//     content: '';
//     position: absolute;
//     top: 0;
//     left: 0;
//     right: 0;
//     bottom: 0;
//     background-color: rgba(0, 4, 29, 0.8); /* Adjust the opacity here */
//     z-index: 0;
//   }

//   @media (max-width: 768px) {
//     padding: 10px;
//   }
// `;

// const Content = styled(motion.div)`
//   position: relative;
//   z-index: 1;
//   text-align: center;
//   color: rgb(255, 255, 255);
//   position:'relative';
//   text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); /* Add text shadow for better readability */
//   top:18%
// `;

// const Title = styled(motion.h1)`
//   font-size: 48px;
//   color: rgb(128, 255, 255);
//   margin-bottom: 20px;

//   @media (max-width: 768px) {
//     font-size: 32px;
//   }
// `;

// const Description = styled(motion.p)`
//   font-size: 24px;
//   margin-bottom: 20px;
//   text-align: center;

//   @media (max-width: 768px) {
//     font-size: 18px;
//   }
// `;

// const Button = styled(motion.button)`
//   padding: 16px 32px;
//   font-size: 20px;
//   background-color: rgb(255, 0, 128);
//   color: rgb(255, 255, 255);
//   border: none;
//   border-radius: 4px;
//   cursor: pointer;
//   transition: background-color 0.3s ease;

//   &:hover {
//     background-color: rgb(70, 0, 89);
//   }
// `;

// const Footer = styled.footer`
//   width: 100%;
//   padding: 20px;
//   text-align: center;
//   background-color: rgb(0, 6, 24);
//   color: rgb(255, 255, 255);
//   position: fixed;
//   bottom: 0;

//   @media (max-width: 768px) {
//     padding: 10px;
//     font-size: 14px;
//   }
// `;

// const Toast = styled(motion.div)`
//   position: fixed;
//   top: 20px;
//   left: 20px;
//   background-color: rgb(255, 0, 128);
//   color: rgb(255, 255, 255);
//   padding: 16px 32px;
//   border-radius: 4px;
//   z-index: 1000;
//   font-size: 18px;
//   cursor: pointer;
// `;

// const InlineLink = styled.span`
//   color: rgb(128, 255, 255);
//   cursor: pointer;
//   text-decoration: underline;

//   &:hover {
//     color: rgb(255, 255, 255);
//   }
// `;

// const WelcomePage = () => {
//   const [showToast, setShowToast] = useState(true);
//   const [showTerms, setShowTerms] = useState(false);
//   const [showPrivacy, setShowPrivacy] = useState(false);

//   return (
//     <div>
//       <Navigation />
//       <AnimatePresence>
//         {showToast && (
//           <Toast
//             initial={{ opacity: 0, x: -50 }}
//             animate={{ opacity: 1, x: 0 }}
//             exit={{ opacity: 0, x: -50 }}
//             transition={{ duration: 0.5 }}
//             onClick={() => setShowToast(false)}
//           >
//             Login and take a free trial now!
//           </Toast>
//         )}
//       </AnimatePresence>
//       <Container>
//         <Content
//           initial={{ opacity: 0, y: -50 }}
//           animate={{ opacity: 1, y: 0 }}
//           transition={{ duration: 1 }}
//         >
//           <Title>
//             Welcome to the Future of Accounting with TallyMatic!
//           </Title>
//           <Description
//             initial={{ opacity: 0, y: 50 }}
//             animate={{ opacity: 1, y: 0 }}
//             transition={{ duration: 1, delay: 0.5 }}
//           >
//             Simplify your financial analysis with our powerful tool.
//             <br />
//             Convert your bank statements effortlessly!
//           </Description>
//           <SignedOut>
//             <Link to="/sign-in">
//               <Button
//                 whileHover={{ scale: 1.1 }}
//                 whileTap={{ scale: 0.95 }}
//                 initial={{ opacity: 0 }}
//                 animate={{ opacity: 1 }}
//                 transition={{ duration: 1, delay: 1 }}
//               >
//                 Get Started
//               </Button>
//             </Link>
//           </SignedOut>
//           <SignedIn>
//             <Link to="/gst">
//               <Button
//                 whileHover={{ scale: 1.1 }}
//                 whileTap={{ scale: 0.95 }}
//                 initial={{ opacity: 0 }}
//                 animate={{ opacity: 1 }}
//                 transition={{ duration: 1, delay: 1 }}
//               >
//                 Go to Converter
//               </Button>
//             </Link>
//           </SignedIn>
//         </Content>
//       </Container>
//       <AboutPage />
//       <StatsCards />
//       <BuyNow />
//       <ContactPage />
//       <Footer>
//         © R. K. Paper Products 2024 | 
//         <InlineLink onClick={() => setShowTerms(true)}> Terms and Conditions</InlineLink> | 
//         <InlineLink onClick={() => setShowPrivacy(true)}> Privacy Policy</InlineLink>
//       </Footer>

//       <Dialog isOpen={showTerms} onClose={() => setShowTerms(false)} title="Terms and Conditions">
//         <p>Last updated: 9th June 2024</p>
//         <h2>Introduction</h2>
//         <p>Welcome to TallyMatic! These Terms and Conditions outline the rules and regulations for the use of TallyMatic's website and services.</p>
//         <h2>Acceptance of Terms</h2>
//         <p>By accessing and using TallyMatic, you accept and agree to be bound by these terms. If you do not agree to these terms, please do not use our services.</p>
//         <h2>Use of Service</h2>
//         <p>You agree to use the service only for lawful purposes and in accordance with these Terms and Conditions.</p>
//         <h2>Changes to Terms</h2>
//         <p>We reserve the right to modify or replace these Terms and Conditions at any time. We will notify you of changes by posting the new terms on our website.</p>
//         <p>If you have any questions about these Terms and Conditions, please contact us.</p>
//       </Dialog>

//       <Dialog isOpen={showPrivacy} onClose={() => setShowPrivacy(false)} title="Privacy Policy">
//         <p>Your privacy is important to us. It is TallyMatic's policy to respect your privacy regarding any information we may collect from you across our website, http://tallymatic.com, and other sites we own and operate.</p>
//         <p>We only ask for personal information when we truly need it to provide a service to you. We collect it by fair and lawful means, with your knowledge and consent. We also let you know why we're collecting it and how it will be used.</p>
//         <p>We only retain collected information for as long as necessary to provide you with your requested service. What data we store, we'll protect within commercially acceptable means to prevent loss and theft, as well as unauthorized access, disclosure, copying, use, or modification.</p>
//         <p>We don't share any personally identifying information publicly or with third-parties, except when required to by law.</p>
//         <p>Our website may link to external sites that are not operated by us. Please be aware that we have no control over the content and practices of these sites, and cannot accept responsibility or liability for their respective privacy policies.</p>
//         <p>You are free to refuse our request for your personal information, with the understanding that we may be unable to provide you with some of your desired services.</p>
//         <p>Your continued use of our website will be regarded as acceptance of our practices around privacy and personal information. If you have any questions about how we handle user data and personal information, feel free to contact us.</p>
//         <p>This policy is effective as of 1 January 2024.</p>
//       </Dialog>
//     </div>
//   );
// };

// export default WelcomePage

// import React, { useState } from 'react';
// import { Link } from 'react-router-dom';
// import { motion, AnimatePresence } from 'framer-motion';
// import styled from 'styled-components';
// import { SignedIn, SignedOut } from '@clerk/clerk-react';
// import Navigation from './Navigation';
// import AboutPage from './AboutPage';
// import ContactPage from './ContactPage';
// import BuyNow from './BuyNow';
// import StatsCards from './StatsCards';
// import Dialog from './Dialog';
// import Welcome from './welcome.png';
// import Illustration from './welcome.png';  // Add your illustration image here
// import Testimonials from './Testimonials';
// import landingPageGif2 from './landingPageGif2.gif'

// const Container = styled.div`
//   display: flex;
//   align-items: center;
//   justify-content: space-between;
//   height: 80vh;
//   background-color: white;
//   font-family: 'Arial, sans-serif';
//   padding: 20px;
//   position: relative;

//   @media (max-width: 768px) {
//     flex-direction: column;
//     padding: 10px;
//   }
// `;

// const Content = styled(motion.div)`
//   flex: 1;
//   text-align: left;
//   color: rgb(255, 255, 255);
//   position: relative;
//   z-index: 1;
//   position: relative !important;


//   left:55px;
//   @media (max-width: 768px) {
//     text-align: center;
//     margin-bottom: 20px;
//   }
// `;

// const Title = styled(motion.h1)`
//   font-size: 48px;
//   color: rgb(0, 123, 255);
//   margin-bottom: 20px;

//   @media (max-width: 768px) {
//     font-size: 32px;
//   }
// `;

// const Description = styled(motion.p)`
//   font-size: 24px;
//   margin-bottom: 20px;

//   color: black;
//   @media (max-width: 768px) {
//     font-size: 18px;
//   }
// `;

// const Button = styled(motion.button)`
//   padding: 16px 32px;
//   font-size: 20px;
//   background-color: rgb(255, 0, 128);
//   color: rgb(255, 255, 255);
//   border: none;
//   border-radius: 4px;
//   cursor: pointer;
//   transition: background-color 0.3s ease;

//   &:hover {
//     background-color: rgb(70, 0, 89);
//   }
// `;

// const ImageContainer = styled.div`
//   flex: 1;
//   display: flex;
//   align-items: center;
//   justify-content: center;
// `;

// const Image = styled.img`
//   max-width: 100%;
//   height: auto;

//   @media (max-width: 768px) {
//     max-width: 80%;
//   }
// `;

// const Footer = styled.footer`
//   width: 100%;
//   padding: 20px;
//   text-align: center;
//   background-color: white;
//   color: black;
//   position: fixed;
//   bottom: 0;

//   @media (max-width: 768px) {
//     padding: 10px;
//     font-size: 14px;
//   }
// `;

// const Toast = styled(motion.div)`
//   position: fixed;
//   top: 20px;
//   left: 20px;
//   background-color: rgb(255, 0, 128);
//   color: rgb(255, 255, 255);
//   padding: 16px 32px;
//   border-radius: 4px;
//   z-index: 1000;
//   font-size: 18px;
//   cursor: pointer;
// `;

// const InlineLink = styled.span`
//   color: rgb(0, 123, 255);
//   cursor: pointer;
//   text-decoration: underline;

//   &:hover {
//     color: rgb(255, 255, 255);
//   }
// `;

// const WelcomePage = () => {
//   const [showToast, setShowToast] = useState(true);
//   const [showTerms, setShowTerms] = useState(false);
//   const [showPrivacy, setShowPrivacy] = useState(false);
//   const [showRefund, setShowRefund] = useState(false);

//   return (
//     <div>
//       <Navigation />
//       <AnimatePresence>
//         {showToast && (
//           <Toast
//             initial={{ opacity: 0, x: -50 }}
//             animate={{ opacity: 1, x: 0 }}
//             exit={{ opacity: 0, x: -50 }}
//             transition={{ duration: 0.5 }}
//             onClick={() => setShowToast(false)}
//           >
//             Login and take a free trial now!
//           </Toast>
//         )}
//       </AnimatePresence>
//       <Container>
//         <Content
//           initial={{ opacity: 0, y: -50 }}
//           animate={{ opacity: 1, y: 0 }}
//           transition={{ duration: 1 }}
//         >
//           <Title>
//             Welcome to the Future of Accounting with TallyMatic!
//           </Title>
//           <Description
//             initial={{ opacity: 0, y: 50 }}
//             animate={{ opacity: 1, y: 0 }}
//             transition={{ duration: 1, delay: 0.5 }}
//           >
//             Simplify your financial analysis with our powerful tool.
//             Convert your bank statements effortlessly!          </Description>
//           {/* <SignedOut>
//             <Link to="/sign-in">
//               <Button
//                 whileHover={{ scale: 1.1 }}
//                 whileTap={{ scale: 0.95 }}
//                 initial={{ opacity: 0 }}
//                 animate={{ opacity: 1 }}
//                 transition={{ duration: 1, delay: 1 }}
//               >
//                 Get Started
//               </Button>
//             </Link>
//           </SignedOut> */}
//           <SignedOut>
//             <Link to="/sign-in">
//               <Button
//                 whileHover={{ scale: 1.1 }}
//                 whileTap={{ scale: 0.95 }}
//                 initial={{ opacity: 0 }}
//                 animate={{ opacity: 1 }}
//                 transition={{ duration: 1, delay: 1 }}
//               >
//                 Get Started
//               </Button>
//             </Link>
//           </SignedOut>
//           <SignedIn>
//             <Link to="/gst">
//               <Button
//                 whileHover={{ scale: 1.1 }}
//                 whileTap={{ scale: 0.95 }}
//                 initial={{ opacity: 0 }}
//                 animate={{ opacity: 1 }}
//                 transition={{ duration: 1, delay: 1 }}
//               >
//                 Go to Converter
//               </Button>
//             </Link>
//           </SignedIn>

//         </Content>
//         <ImageContainer>
//           <Image src={landingPageGif2} alt="Illustration" />
//         </ImageContainer>
//       </Container>
//       <TextAndGif />
//         <AccountingRevolutionPage />
//         <BeforeAfterImage />
//       <AboutPage />
//       {/* <Testimonials/> */}
//       <StatsCards />
//       <BuyNow />
//       <ContactPage />
//       <Footer>
//         © R. K. Paper Products 2024 |
//         <InlineLink onClick={() => setShowTerms(true)}> Terms and Conditions</InlineLink> |
//         <InlineLink onClick={() => setShowPrivacy(true)}> Privacy Policy</InlineLink> |
//         <InlineLink onClick={() => setShowRefund(true)}> Refund/Cancellation Policy</InlineLink>
//       </Footer>

//       <Dialog isOpen={showTerms} onClose={() => setShowTerms(false)} title="Terms and Conditions">
//         <p>Last updated: 9th June 2024</p>
//         <h2>Introduction</h2>
//         <p>Welcome to TallyMatic! These Terms and Conditions outline the rules and regulations for the use of TallyMatic's website and services.</p>
//         <h2>Acceptance of Terms</h2>
//         <p>By accessing and using TallyMatic, you accept and agree to be bound by these terms. If you do not agree to these terms, please do not use our services.</p>
//         <h2>Use of Service</h2>
//         <p>You agree to use the service only for lawful purposes and in accordance with these Terms and Conditions.</p>
//         <h2>Changes to Terms</h2>
//         <p>We reserve the right to modify or replace these Terms and Conditions at any time. We will notify you of changes by posting the new terms on our website.</p>
//         <p>If you have any questions about these Terms and Conditions, please contact us.</p>
//       </Dialog>

//       <Dialog isOpen={showPrivacy} onClose={() => setShowPrivacy(false)} title="Privacy Policy">
//         <p>Your privacy is important to us. It is TallyMatic's policy to respect your privacy regarding any information we may collect from you across our website, http://tallymatic.com, and other sites we own and operate.</p>
//         <p>We only ask for personal information when we truly need it to provide a service to you. We collect it by fair and lawful means, with your knowledge and consent. We also let you know why we're collecting it and how it will be used.</p>
//         <p>We only retain collected information for as long as necessary to provide you with your requested service. What data we store, we'll protect within commercially acceptable means to prevent loss and theft, as well as unauthorized access, disclosure, copying, use, or modification.</p>
//         <p>We don't share any personally identifying information publicly or with third-parties, except when required to by law.</p>
//         <p>Our website may link to external sites that are not operated by us. Please be aware that we have no control over the content and practices of these sites, and cannot accept responsibility or liability for their respective privacy policies.</p>
//         <p>You are free to refuse our request for your personal information, with the understanding that we may be unable to provide you with some of your desired services.</p>
//         <p>Your continued use of our website will be regarded as acceptance of our practices around privacy and personal information. If you have any questions about how we handle user data and personal information, feel free to contact us.</p>
//         <p>This policy is effective as of 1 January 2024.</p>
//       </Dialog>

//       <Dialog isOpen={showRefund} onClose={() => setShowRefund(false)} title="Refund/Cancellation Policy">
//         <p>We offer a full money-back guarantee for all purchases made on our website. If you are not satisfied with the product that you have purchased from us, you can get your money back no questions asked. You are eligible for a full reimbursement within 14 calendar days of your purchase.</p>
//         <p>After the 14-day period, you will no longer be eligible and won't be able to receive a refund. We encourage our customers to try the product in the first two weeks after their purchase to ensure it fits your needs.</p>
//         <p>If you have any additional questions or would like to request a refund, feel free to contact us.</p>
//         <p>Refund processing time: 5-7 working days.</p>
//       </Dialog>
//     </div>
//   );
// };

// export default WelcomePage;

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import styled from 'styled-components';
import { SignedIn, SignedOut } from '@clerk/clerk-react';
import Navigation from './Navigation';
import AboutPage from './AboutPage';
import ContactPage from './ContactPage';
import BuyNow from './BuyNow';
import StatsCards from './StatsCards';
import Dialog from './Dialog';
import WelcomeImage from './welcome.png';
import landingPageGif2 from './landingPageGif2.gif';
import BeforeAfterImage from './BeforeAfterImage';
import AccountingRevolutionPage from './AccountingRevolutionPage';
import TextAndGif from './TextAndGif';
//import TallyMaticVideo from './TallyMaticVideo.mp4';
import Tlogo from './Tlogo.png'

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 75vh;
  background-color: white;
  font-family: 'Arial, sans-serif';
  padding: 20px;
  position: relative;
  top: 35px;

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 10px;
  }
`;

const Content = styled(motion.div)`
  flex: 1;
  text-align: left;
  color: #000;
  position: relative;
  z-index: 1;
  margin-left: 55px;

  @media (max-width: 768px) {
    text-align: center;
    margin-bottom: 20px;
    margin-left: 0;
  }
`;

const Title = styled(motion.h1)`
  font-size: 48px;
  color: #007bff;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    font-size: 32px;
  }
`;

const Description = styled(motion.p)`
  font-size: 24px;
  margin-bottom: 20px;
  color: #000;

  @media (max-width: 768px) {
    font-size: 18px;
  }
`;

const Button = styled(motion.button)`
  padding: 16px 32px;
  font-size: 20px;
  background-color: #ff0080;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #460059;
  }
`;

const ImageContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Image = styled.img`
  max-width: 100%;
  height: auto;

  @media (max-width: 768px) {
    max-width: 80%;
  }
`;

const Footer = styled.footer`
  width: 100%;
  padding: 20px;
  text-align: center;
  background-color: white;
  color: black;
  position: fixed;
  bottom: 0;

  @media (max-width: 768px) {
    padding: 10px;
    font-size: 14px;
  }
`;

const Toast = styled(motion.div)`
  position: fixed;
  top: 50px;
  left: 20px;
  background-color: #ff0080;
  color: #fff;
  padding: 16px 32px;
  border-radius: 4px;
  z-index: 1000;
  font-size: 18px;
  cursor: pointer;
`;

const InlineLink = styled.span`
  color: #007bff;
  cursor: pointer;
  text-decoration: underline;

  &:hover {
    color: black;
  }
`;

const WelcomePage = () => {
  const [dialogState, setDialogState] = useState({
    showToast: true,
    showTerms: false,
    showPrivacy: false,
    showRefund: false,
    showHowItWorks: false,
    showToast: true,

  });

  const toggleDialog = (dialog) => {
    setDialogState((prevState) => ({
      ...prevState,
      [dialog]: !prevState[dialog],
    }));
  };

  // useEffect(() => {
  //   if (dialogState.showToast) {
  //     const timer = setTimeout(() => {
  //       setDialogState(prevState => ({ ...prevState, showToast: false }));
  //     }, 3000); 

  //     return () => clearTimeout(timer);
  //   }
  // }, [dialogState.showToast]);

  return (
    <div>
      <Navigation />
      <AnimatePresence>
        <SignedOut>
        {dialogState.showToast && (
          // <Toast
          //   initial={{ opacity: 0, x: -50 }}
          //   animate={{ opacity: 1, x: 0 }}
          //   exit={{ opacity: 0, x: -50 }}
          //   transition={{ duration: 0.5 }}
          //   onClick={() => toggleDialog('showToast')}
          // >
          //   Login and take a free trial now!
          // </Toast>
          <Toast
            initial={{ opacity: 0, x: -50 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: -50 }}
            transition={{ duration: 0.5 }}
            onClick={() => setDialogState(prevState => ({ ...prevState, showToast: false }))}
          >
            Login and Take 3 days Trial Now!
          </Toast>
        )}
        </SignedOut>
      </AnimatePresence>
      <Container>
        <Content
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1 }}
        >
          <Title>Welcome to the Future of Accounting with TallyMatic!</Title>
          <Description
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1, delay: 0.5 }}
          >
            Effortlessly Convert Bank Statements: Import, Assign, and Export to Tally!
          </Description>
          <SignedOut>
            <Link to="/sign-in">
              <Button
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.95 }}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 1, delay: 1 }}
              >
                Free Trial
              </Button>
            </Link>
            <Button
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.95 }}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 1, delay: 1 }}
              onClick={() => toggleDialog('showHowItWorks')}
              style={{ marginLeft: '10px' }}
            >
              How It Works?
            </Button>
          </SignedOut>
          <SignedIn>
            <Link to="/gst">
              <Button
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.95 }}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 1, delay: 1 }}
              >
                Go to Converter
              </Button>
            </Link>
            <Button
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.95 }}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 1, delay: 1 }}
              onClick={() => toggleDialog('showHowItWorks')}
              style={{ marginLeft: '10px' }}
            >
              How It Works?
            </Button>
          </SignedIn>
        </Content>
        <ImageContainer>
          <Image src={landingPageGif2} alt="Illustration" />
        </ImageContainer>
      </Container>
      <TextAndGif />
      <AccountingRevolutionPage />
      <BeforeAfterImage />

      <StatsCards />
      <BuyNow />
      <AboutPage />
      <ContactPage />
      <Footer>
        © R. K. Paper Products 2024 |
        <InlineLink onClick={() => toggleDialog('showTerms')}> Terms and Conditions</InlineLink> |
        <InlineLink onClick={() => toggleDialog('showPrivacy')}> Privacy Policy</InlineLink> |
        <InlineLink onClick={() => toggleDialog('showRefund')}> Refund/Cancellation Policy</InlineLink>
      </Footer>

      <Dialog isOpen={dialogState.showTerms} onClose={() => toggleDialog('showTerms')} title="Terms and Conditions">
        <p>Last updated: 9th June 2024</p>
        <h2>Introduction</h2>
        <p>Welcome to TallyMatic! These Terms and Conditions outline the rules and regulations for the use of TallyMatic's website and services.</p>
        <h2>Acceptance of Terms</h2>
        <p>By accessing and using TallyMatic, you accept and agree to be bound by these terms. If you do not agree to these terms, please do not use our services.</p>
        <h2>Use of Service</h2>
        <p>You agree to use the service only for lawful purposes and in accordance with these Terms and Conditions.</p>
        <h2>Changes to Terms</h2>
        <p>We reserve the right to modify or replace these Terms and Conditions at any time. We will notify you of changes by posting the new terms on our website.</p>
        <p>If you have any questions about these Terms and Conditions, please contact us.</p>
      </Dialog>

      <Dialog isOpen={dialogState.showPrivacy} onClose={() => toggleDialog('showPrivacy')} title="Privacy Policy">
        <p>Your privacy is important to us. It is TallyMatic's policy to respect your privacy regarding any information we may collect from you across our website, http://tallymatic.com, and other sites we own and operate.</p>
        <p>We only ask for personal information when we truly need it to provide a service to you. We collect it by fair and lawful means, with your knowledge and consent. We also let you know why we're collecting it and how it will be used.</p>
        <p>We only retain collected information for as long as necessary to provide you with your requested service. What data we store, we'll protect within commercially acceptable means to prevent loss and theft, as well as unauthorized access, disclosure, copying, use, or modification.</p>
        <p>We don't share any personally identifying information publicly or with third-parties, except when required to by law.</p>
        <p>Our website may link to external sites that are not operated by us. Please be aware that we have no control over the content and practices of these sites, and cannot accept responsibility or liability for their respective privacy policies.</p>
        <p>You are free to refuse our request for your personal information, with the understanding that we may be unable to provide you with some of your desired services.</p>
        <p>Your continued use of our website will be regarded as acceptance of our practices around privacy and personal information. If you have any questions about how we handle user data and personal information, feel free to contact us.</p>
        <p>This policy is effective as of 1 January 2024.</p>
      </Dialog>

      <Dialog isOpen={dialogState.showRefund} onClose={() => toggleDialog('showRefund')} title="Refund/Cancellation Policy">
        <p>We offer a full money-back guarantee for all purchases made on our website. If you are not satisfied with the product that you have purchased from us, you can get your money back no questions asked. You are eligible for a full reimbursement within 14 calendar days of your purchase.</p>
        <p>After the 14-day period, you will no longer be eligible and won't be able to receive a refund. We encourage our customers to try the product in the first two weeks after their purchase to ensure it fits your needs.</p>
        <p>If you have any additional questions or would like to request a refund, feel free to contact us.</p>
        <p>Refund processing time: 5-7 working days.</p>
      </Dialog>

      <Dialog
  isOpen={dialogState.showHowItWorks}
  onClose={() => toggleDialog('showHowItWorks')}
  title="How It Works?"
  style={{ width: '80%' }} // Adjust the width as needed
>
  <div style={{ textAlign: 'center' }}>
    {/* <video width="100%" controls>
      <source src='https://www.youtube.com/watch?v=CdmbipAzaBU' type="video/mp4" />
      Your browser does not support the video tag.
    </video> */}
    <iframe width="100%" height="315" src="https://www.youtube.com/embed/CdmbipAzaBU" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
  </div>
</Dialog>

    </div>
  );
};

export default WelcomePage;
