// import React, { useState } from 'react';
// import { motion } from 'framer-motion';
// import styled from 'styled-components';
// import { Link } from 'react-router-dom';
// import toast, { Toaster } from 'react-hot-toast';

// const styles = {
//   container: {
//     display: 'flex',
//     flexWrap: 'wrap',
//     gap: '20px',
//     justifyContent: 'center',
//     alignItems: 'center',
//     height: 'auto',
//     fontFamily: "'Poppins', sans-serif",
//     padding: '20px',
//   },
//   plan: {
//     borderRadius: '15px',
//     textAlign: 'center',
//     width: '100%',
//     maxWidth: '300px',
//     padding: '20px',
//     color: '#fff',
//     position: 'relative',
//     transition: 'transform 0.3s ease',
//     height: '415px',
//     overflow: 'hidden',
//     display:'flex',
//     flexDirection:'column',
//     justifyContent:'space-between'
//   },
//   planHover: {
//     transform: 'translateY(-10px)',
//   },
//   basic: {
//     background: 'linear-gradient(135deg, rgb(0, 128, 255) 0%, rgb(128, 255, 255) 100%)',
//   },
//   premium: {
//     background: 'linear-gradient(135deg, rgb(70, 0, 89) 0%, rgb(255, 0, 128) 100%)',
//   },
//   enterprise: {
//     background: 'linear-gradient(135deg, rgb(0, 6, 24) 0%, rgb(0, 8, 19) 100%)',
//   },
//   title: {
//     fontSize: '24px',
//     margin: '10px 0',
//   },
//   subtitle: {
//     fontSize: '16px',
//     margin: '10px 0',
//   },
//   price: {
//     fontSize: '36px',
//     margin: '20px 0',
//   },
//   button: {
//     backgroundColor: 'rgba(255, 255, 255, 0.3)',
//     color: '#fff',
//     border: 'none',
//     padding: '10px 20px',
//     borderRadius: '25px',
//     cursor: 'pointer',
//     marginTop: '20px',
//     transition: 'background-color 0.3s',
//   },
//   buttonHover: {
//     backgroundColor: 'rgba(255, 255, 255, 0.5)',
//   },
//   features: {
//     textAlign: 'left',
//     marginTop: '20px',
//   },
//   feature: {
//     display: 'flex',
//     alignItems: 'center',
//     margin: '10px 0',
//     fontSize: '14px',
//     top:'20px'
//   },
//   icon: {
//     marginRight: '10px',
//     color: '#fff',
//   },
//   ribbon: {
//     position: 'absolute',
//     top: '15px',
//     right: '15px',
//     background: '#ff007b',
//     color: '#fff',
//     padding: '5px 10px',
//     borderRadius: '15px',
//     fontSize: '12px',
//   },
// };

// const Title = styled(motion.h1)`
//   font-size: 38px;
//   color: rgb(0, 123, 255);
//   margin-bottom: 20px;
//   text-align: center;
// `;

// const PopupOverlay = styled(motion.div)`
//   position: fixed;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 100%;
//   background: rgba(0, 0, 0, 0.5);
//   display: flex;
//   justify-content: center;
//   align-items: center;
// `;

// const PopupContent = styled(motion.div)`
//   background: #fff;
//   padding: 20px;
//   border-radius: 8px;
//   max-width: 400px;
//   width: 80%;
//   box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
//   text-align: center;
// `;

// const Nav = styled.nav`
//   width: 100%;
//   background-color: rgb(0, 6, 24);
//   padding: 10px 0;
//   display: flex;
//   justify-content: flex-end;
// `;

// const NavLink = styled(Link)`
//   color: rgb(255, 255, 255);
//   text-decoration: none;
//   padding: 0 20px;
//   font-size: 18px;
//   position: relative;
//   transition: color 0.3s;

//   &::after {
//     content: '';
//     position: absolute;
//     width: 0;
//     height: 2px;
//     display: block;
//     margin-top: 5px;
//     right: 0;
//     background: rgb(0, 128, 255);
//     transition: width 0.4s ease, right 0.4s ease;
//   }

//   &:hover {
//     color: rgb(0, 128, 255);
//   }

//   &:hover::after {
//     width: 100%;
//     right: 0;
//   }
// `;

// const Footer = styled.footer`
//   width: 100%;
//   padding: 20px;
//   text-align: center;
//   background-color: rgb(0, 6, 24);
//   color: rgb(255, 255, 255);
//   bottom: 0;
//   position: fixed;
// `;

// const Container = styled.div`
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: center;
//   padding: 40px 20px;
//   background-color:#f9fafb;
//   font-family: 'Poppins', sans-serif;
//   color: rgb(255, 255, 255);
// `;

// const Paragraph = styled(motion.p)`
//   font-size: 18px;
//   line-height: 1.8;
//   margin-bottom: 15px;
//   text-align: justify;
//   max-width: 800px;
//   color:'black'
// `;

// const BuyNow = () => {
//   const [amount, setamount] = useState(5000);

//   const handleSubscribe = (plan) => {
//     alert(`You have selected the ${plan} plan.`);
//     const confetti = document.createElement('div');
//     confetti.style.position = 'fixed';
//     confetti.style.width = '100vw';
//     confetti.style.height = '100vh';
//     confetti.style.top = '0';
//     confetti.style.left = '0';
//     confetti.style.pointerEvents = 'none';
//     confetti.style.background = "url('https://i.imgur.com/dZ9oXps.gif') center/cover no-repeat";
//     document.body.appendChild(confetti);
//     setTimeout(() => {
//       confetti.remove();
//     }, 3000);
//   };

//   const handlePayment = async () => {
//     // try {
//     //   const res = await fetch(`http://localhost:4000/api/payment/order`, {
//     //     method: 'POST',
//     //     headers: {
//     //       'content-type': 'application/json',
//     //     },
//     //     body: JSON.stringify({
//     //       amount,
//     //     }),
//     //   });

//     //   const data = await res.json();
//     //   console.log('data', data);
//     //   handlePaymentVerify(data.data);

//     //   const getRes = await fetch(`http://localhost:4000/api/payment/data`, {
//     //     method: 'GET',
//     //     headers: {
//     //       'content-type': 'application/json',
//     //     },
//     //   });

//     //   const getData = await getRes.json();
//     //   console.log('getData', getData);
//     // } catch (error) {
//     //   console.log(error);
//     // }
//     alert('Login first please')
//   };

//   const handlePaymentVerify = async (data) => {
//     const options = {
//       key: 'rzp_test_cTpmNyTPwiKygP',
//       amount: data.amount,
//       currency: data.currency,
//       name: 'Devknus',
//       description: 'Test Mode',
//       order_id: data.id,
//       handler: async (response) => {
//         console.log('response', response);
//         try {
//           const res = await fetch(`http://localhost:4000/api/payment/verify`, {
//             method: 'POST',
//             headers: {
//               'content-type': 'application/json',
//             },
//             body: JSON.stringify({
//               razorpay_order_id: response.razorpay_order_id,
//               razorpay_payment_id: response.razorpay_payment_id,
//               razorpay_signature: response.razorpay_signature,
//             }),
//           });

//           const verifyData = await res.json();

//           if (verifyData.message) {
//             toast.success(verifyData.message);
//           }
//         } catch (error) {
//           console.log(error);
//         }
//       },
//       theme: {
//         color: '#5f63b8',
//       },
//     };
//     const rzp1 = new window.Razorpay(options);
//     rzp1.open();
//   };

//   return (
//     <div style={{backgroundColor:'#f9fafb'}}>
//       <Container id="buyNow">
//         <div>
//           <Title
//             initial={{ opacity: 0, y: -50 }}
//             animate={{ opacity: 1, y: 0 }}
//             transition={{ duration: 1 }}
//           >
//             Buy Now
//           </Title>
//         </div>
//         <div style={styles.container}>
//           <div style={{ ...styles.plan, ...styles.basic }}>
//             <h2 style={styles.title}>Pro</h2>
//             <p style={styles.subtitle}>Best for personal use</p>
//             <p style={styles.price}>
//               Rs 5,000 <span>/ Per Year</span>
//             </p>
//             <button
//               style={styles.button}
//               onMouseOver={(e) => (e.target.style.backgroundColor = 'rgba(255, 255, 255, 0.5)')}
//               onMouseOut={(e) => (e.target.style.backgroundColor = 'rgba(255, 255, 255, 0.3)')}
//               onClick={handlePayment}
//             >
//               Get started
//             </button>
//             <Toaster />
//             <div style={styles.features}>
//               <div style={styles.feature}>
//                 <i className="fas fa-check-circle" style={styles.icon}></i> 50 Bank Statements per month
//               </div>
//             </div>
//           </div>
//           <div style={{ ...styles.plan, ...styles.premium }}>
//             <div style={styles.ribbon}>Popular</div>
//             <h2 style={styles.title}>Premium</h2>
//             <p style={styles.subtitle}>Perfect for professionals and small businesses</p>
//             <p style={styles.price}>
//               Rs 8,000 <span>/ Per Year</span>
//             </p>
//             <button
//               style={styles.button}
//               onMouseOver={(e) => (e.target.style.backgroundColor = 'rgba(255, 255, 255, 0.5)')}
//               onMouseOut={(e) => (e.target.style.backgroundColor = 'rgba(255, 255, 255, 0.3)')}
//               onClick={handlePayment}
//             >
//               Get started
//             </button>
//             <div style={styles.features}>
//               <div style={styles.feature}>
//                 <i className="fas fa-check-circle" style={styles.icon}></i> 100 Bank Statements per month
//               </div>
//             </div>
//           </div>
//           <div style={{ ...styles.plan, ...styles.enterprise }}>
//             <h2 style={styles.title}>Top up</h2>
//             <p style={styles.subtitle}>Perfect for large businesses or organizations</p>
//             <p style={styles.price}>
//               Rs 500 <span>/ Per Year</span>
//             </p>
//             <button
//               style={styles.button}
//               onMouseOver={(e) => (e.target.style.backgroundColor = 'rgba(255, 255, 255, 0.5)')}
//               onMouseOut={(e) => (e.target.style.backgroundColor = 'rgba(255, 255, 255, 0.3)')}
//               onClick={handlePayment}
//             >
//               Contact sales
//             </button>
//             <div style={styles.features}>
//               <div style={styles.feature}>
//                 <i className="fas fa-check-circle" style={styles.icon}></i> 10 Bank Statements available of current month, ONLY ACTIVE IF BASIC OR PRO PLAN IS ACTIVE
//               </div>
//             </div>
//           </div>
//         </div>
//       </Container>
//       <Footer>---------R. K. Paper Products 2024 ----</Footer>
//     </div>
//   );
// };

// export default BuyNow;

import React, { useState } from 'react';
import { motion } from 'framer-motion';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';

// const styles = {
//   container: {
//     display: 'flex',
//     justifyContent:'center',
//     flexWrap: 'wrap',
//     gap: '20px',
//     justifyContent: 'center',
//     alignItems: 'center',
//     height: 'auto',
//     fontFamily: "'Poppins', sans-serif",
//     padding: '20px',
//   },
//   plan: {
//     borderRadius: '15px',
//     textAlign: 'center',
//     width: '100%',
//     maxWidth: '300px',
//     padding: '20px',
//     color: '#fff',
//     position: 'relative',
//     transition: 'transform 0.3s ease',
//     height: '415px',
//     overflow: 'hidden',
//     display:'flex',
//     flexDirection:'column',
//     justifyContent:'space-between'
//   },
//   planHover: {
//     transform: 'translateY(-10px)',
//   },
//   basic: {
//     background: 'linear-gradient(135deg, rgb(0, 128, 255) 0%, rgb(128, 255, 255) 100%)',
//   },
//   premium: {
//     background: 'linear-gradient(135deg, rgb(70, 0, 89) 0%, rgb(255, 0, 128) 100%)',
//   },
//   enterprise: {
//     background: 'linear-gradient(135deg, rgb(0, 6, 24) 0%, rgb(0, 8, 19) 100%)',
//   },
//   newBasic: {
//     background: 'linear-gradient(135deg, rgb(30, 60, 90) 0%, rgb(60, 120, 180) 100%)', // Customized colors for the new card
//   },
//   title: {
//     fontSize: '24px',
//     margin: '10px 0',
//   },
//   subtitle: {
//     fontSize: '16px',
//     margin: '10px 0',
//   },
//   price: {
//     fontSize: '36px',
//     margin: '20px 0',
//   },
//   button: {
//     backgroundColor: 'rgba(255, 255, 255, 0.3)',
//     color: '#fff',
//     border: 'none',
//     padding: '10px 20px',
//     borderRadius: '25px',
//     cursor: 'pointer',
//     marginTop: '20px',
//     transition: 'background-color 0.3s',
//   },
//   buttonHover: {
//     backgroundColor: 'rgba(255, 255, 255, 0.5)',
//   },
//   features: {
//     textAlign: 'left',
//     marginTop: '20px',
//   },
//   feature: {
//     display: 'flex',
//     alignItems: 'center',
//     margin: '10px 0',
//     fontSize: '14px',
//   },
//   icon: {
//     marginRight: '10px',
//     color: '#fff',
//   },
//   ribbon: {
//     position: 'absolute',
//     top: '15px',
//     right: '15px',
//     background: '#ff007b',
//     color: '#fff',
//     padding: '5px 10px',
//     borderRadius: '15px',
//     fontSize: '12px',
//   },
// };

// const Title = styled(motion.h1)`
//   font-size: 38px;
//   color: rgb(0, 123, 255);
//   margin-bottom: 20px;
//   text-align: center;
// `;

// const PopupOverlay = styled(motion.div)`
//   position: fixed;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 100%;
//   background: rgba(0, 0, 0, 0.5);
//   display: flex;
//   justify-content: center;
//   align-items: center;
// `;

// const PopupContent = styled(motion.div)`
//   background: #fff;
//   padding: 20px;
//   border-radius: 8px;
//   max-width: 400px;
//   width: 80%;
//   box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
//   text-align: center;
// `;

// const Nav = styled.nav`
//   width: 100%;
//   background-color: rgb(0, 6, 24);
//   padding: 10px 0;
//   display: flex;
//   justifyContent: flex-end;
// `;

// const NavLink = styled(Link)`
//   color: rgb(255, 255, 255);
//   text-decoration: none;
//   padding: 0 20px;
//   font-size: 18px;
//   position: relative;
//   transition: color 0.3s;

//   &::after {
//     content: '';
//     position: absolute;
//     width: 0;
//     height: 2px;
//     display: block;
//     margin-top: 5px;
//     right: 0;
//     background: rgb(0, 128, 255);
//     transition: width 0.4s ease, right 0.4s ease;
//   }

//   &:hover {
//     color: rgb(0, 128, 255);
//   }

//   &:hover::after {
//     width: 100%;
//     right: 0;
//   }
// `;

// const Footer = styled.footer`
//   width: 100%;
//   padding: 20px;
//   text-align: center;
//   background-color: rgb(0, 6, 24);
//   color: rgb(255, 255, 255);
//   bottom: 0;
//   position: fixed;
// `;

// const Container = styled.div`
//   display: flex;
//   flexDirection: column;
//   alignItems: center;
//   justifyContent: center !important;
//   padding: 40px 20px;
//   backgroundColor: '#f9fafb';
//   fontFamily: 'Poppins', sans-serif;
//   color: rgb(255, 255, 255);
// `;

// const Paragraph = styled(motion.p)`
//   fontSize: 18px;
//   lineHeight: 1.8;
//   marginBottom: 15px;
//   textAlign: justify;
//   maxWidth: 800px;
//   color: 'black';
// `;
const styles = {
  container: {
    // display: 'flex',
    // gap: '20px',
    // justifyContent: 'center',
    // alignItems: 'center',
    // height: '55vh',
    // fontFamily: "'Poppins', sans-serif",
    // padding: '20px',
    display: 'flex',
    flexWrap: 'wrap',
    gap: '20px',
    justifyContent: 'center',
    alignItems: 'center',
    height: 'auto',
    fontFamily: "'Poppins', sans-serif",
    padding: '20px',
  },
  plan: {
    // borderRadius: '15px',
    // textAlign: 'center',
    // width: '300px',
    // padding: '20px',
    // color: '#fff',
    // position: 'relative',
    // transition: 'transform 0.3s ease',
    // height: '415px',
    // overflow: 'hidden',
    // display:'flex',
    // flexDirection:'column',
    // justifyContent:'space-between'
    borderRadius: '15px',
    textAlign: 'center',
    width: '100%',
    maxWidth: '300px',
    padding: '20px',
    color: '#fff',
    position: 'relative',
    transition: 'transform 0.3s ease',
    height: '415px',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  planHover: {
    transform: 'translateY(-10px)',
  },
  basic: {
    background: 'linear-gradient(135deg, rgb(0, 128, 255) 0%, rgb(128, 255, 255) 100%)',
  },
  premium: {
    background: 'linear-gradient(135deg, rgb(70, 0, 89) 0%, rgb(255, 0, 128) 100%)',
  },
  enterprise: {
    background: 'linear-gradient(135deg, rgb(0, 6, 24) 0%, rgb(0, 8, 19) 100%)',
  },
  newBasic: {
    background: 'linear-gradient(135deg, rgb(30, 60, 90) 0%, rgb(60, 120, 180) 100%)', // Customized colors for the new card
  },
  title: {
    fontSize: '24px',
    margin: '10px 0',
  },
  subtitle: {
    fontSize: '16px',
    margin: '10px 0',
  },
  price: {
    fontSize: '36px',
    margin: '20px 0',
  },
  button: {
    backgroundColor: 'rgba(255, 255, 255, 0.3)',
    color: '#fff',
    border: 'none',
    padding: '10px 20px',
    borderRadius: '25px',
    cursor: 'pointer',
    marginTop: '20px',
    transition: 'background-color 0.3s',
  },
  buttonHover: {
    backgroundColor: 'rgba(255, 255, 255, 0.5)',
  },
  features: {
    textAlign: 'left',
    marginTop: '120px',
  },
  feature: {
    // display: 'flex',
    // alignItems: 'center',
    // margin: '10px 0',
    // fontSize: '14px',
    display: 'flex',
    alignItems: 'center',
    margin: '10px 0',
    fontSize: '14px',
    position: 'relative',
    bottom: '25px'
    //top:'20px'
  },
  icon: {
    marginRight: '10px',
    color: '#fff',
  },
  ribbon: {
    position: 'absolute',
    top: '15px',
    right: '15px',
    background: '#ff007b',
    color: '#fff',
    padding: '5px 10px',
    borderRadius: '15px',
    fontSize: '12px',
  },
};

const Title = styled(motion.h1)`
  font-size: 42px;
  color: rgb(0, 123, 255);
  margin-bottom: 20px;
  text-align: center;
`;

const PopupOverlay = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PopupContent = styled(motion.div)`
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  max-width: 400px;
  width: 80%;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  text-align: center;
`;

const Nav = styled.nav`
  width: 100%;
  background-color: rgb(0, 6, 24);
  padding: 10px 0;
  display: flex;
  justify-content: flex-end;
`;

const NavLink = styled(Link)`
  color: rgb(255, 255, 255);
  text-decoration: none;
  padding: 0 20px;
  font-size: 18px;
  position: relative;
  transition: color 0.3s;

  &::after {
    content: '';
    position: absolute;
    width: 0;
    height: 2px;
    display: block;
    margin-top: 5px;
    right: 0;
    background: rgb(0, 128, 255);
    transition: width 0.4s ease, right 0.4s ease;
  }

  &:hover {
    color: rgb(0, 128, 255);
  }

  &:hover::after {
    width: 100%;
    right: 0;
  }
`;

const Footer = styled.footer`
  width: 100%;
  padding: 20px;
  text-align: center;
  background-color: white;
  color: black;
  bottom: 0;
  position: fixed;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px 20px;
  background-color: white;
  font-family: 'Poppins', sans-serif;
  color: rgb(255, 255, 255);
`;

const Paragraph = styled(motion.p)`
  font-size: 18px;
  line-height: 1.8;
  margin-bottom: 15px;
  text-align: justify;
  max-width: 800px;
`;

const InlineLink = styled.span`
  color: #007bff;
  cursor: pointer;
  text-decoration: underline;

  &:hover {
    color: black;
  }
`;
const BuyNow = () => {
  const [amount, setAmount] = useState(5000);

  const handleSubscribe = (plan) => {
    alert(`You have selected the ${plan} plan.`);
    const confetti = document.createElement('div');
    confetti.style.position = 'fixed';
    confetti.style.width = '100vw';
    confetti.style.height = '100vh';
    confetti.style.top = '0';
    confetti.style.left = '0';
    confetti.style.pointerEvents = 'none';
    confetti.style.background = "url('https://i.imgur.com/dZ9oXps.gif') center/cover no-repeat";
    document.body.appendChild(confetti);
    setTimeout(() => {
      confetti.remove();
    }, 3000);
  };

  const handlePayment = async () => {
    alert('Login first please')
  };

  return (
    <div style={{backgroundColor:'#f9fafb'}}>
      <div>
          <Title
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1 }}
          >
            Buy Now
          </Title>
        </div>
      <Container id="buyNow">
        
        <div style={styles.container}>
          <div style={{ ...styles.plan, ...styles.newBasic }}>
            <h2 style={styles.title}>Basic</h2>
            <p style={styles.subtitle}>Ideal for personal use</p>
            <p style={styles.price}>
              Rs 1,000 <span>/ Per Month</span>
            </p>
            <button
              style={styles.button}
              onMouseOver={(e) => (e.target.style.backgroundColor = 'rgba(255, 255, 255, 0.5)')}
              onMouseOut={(e) => (e.target.style.backgroundColor = 'rgba(255, 255, 255, 0.3)')}
              onClick={handlePayment}
            >
              Get started
            </button>
            <div style={styles.features}>
              <div style={styles.feature}>
                <i className="fas fa-check-circle" style={styles.icon}></i> 50 Bank Statements per month
              </div>
            </div>
          </div>
          <div style={{ ...styles.plan, ...styles.basic }}>
            <h2 style={styles.title}>Pro</h2>
            <p style={styles.subtitle}>Best for personal use</p>
            <p style={styles.price}>
              Rs 3,000 <span>/ Per Year</span>
            </p>
            <button
              style={styles.button}
              onMouseOver={(e) => (e.target.style.backgroundColor = 'rgba(255, 255, 255, 0.5)')}
              onMouseOut={(e) => (e.target.style.backgroundColor = 'rgba(255, 255, 255, 0.3)')}
              onClick={handlePayment}
            >
              Get started
            </button>
            <Toaster />
            <div style={styles.features}>
              <div style={styles.feature}>
                <i className="fas fa-check-circle" style={styles.icon}></i> 50 Bank Statements per month
              </div>
            </div>
          </div>
          <div style={{ ...styles.plan, ...styles.premium }}>
            <div style={styles.ribbon}>Popular</div>
            <h2 style={styles.title}>Premium</h2>
            <p style={styles.subtitle}>Perfect for professionals and small businesses</p>
            <p style={styles.price}>
              Rs 5,000 <span>/ Per Year</span>
            </p>
            <button
              style={styles.button}
              onMouseOver={(e) => (e.target.style.backgroundColor = 'rgba(255, 255, 255, 0.5)')}
              onMouseOut={(e) => (e.target.style.backgroundColor = 'rgba(255, 255, 255, 0.3)')}
              onClick={handlePayment}
            >
              Get started
            </button>
            <div style={styles.features}>
              <div style={styles.feature}>
                <i className="fas fa-check-circle" style={styles.icon}></i> 100 Bank Statements per month
              </div>
            </div>
          </div>
          
        </div>
      </Container>
      <Footer>---------R. K. Paper Products 2024 ----</Footer>
    </div>
  );
};

export default BuyNow;
