// import React from 'react';
// import { motion } from 'framer-motion';
// import styled from 'styled-components';
// import { Link } from 'react-router-dom';

// const Container = styled.div`
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: center;
//   padding: 40px 20px;
//   background-color: #f9f9f9;
//   font-family: 'Poppins', sans-serif;
//   color: #333;
// `;

// const Title = styled(motion.h1)`
//   font-size: 42px;
//   color: #007bff;
//   margin-bottom: 20px;
//   text-align: center;
// `;

// const SectionTitle = styled(motion.h2)`
//   font-size: 32px;
//   color: #333;
//   margin-top: 30px;
//   margin-bottom: 15px;
//   text-align: center;
// `;

// const Paragraph = styled(motion.p)`
//   font-size: 18px;
//   line-height: 1.8;
//   margin-bottom: 15px;
//   text-align: justify;
//   max-width: 800px;
// `;

// const Footer = styled.footer`
//   width: 100%;
//   padding: 20px;
//   text-align: center;
//   background-color: #333;
//   color: #fff;
//   position: fixed;
//   bottom: 0;
// `;

// const Nav = styled.nav`
//   width: 100%;
//   background-color: #333;
//   padding: 10px 0;
//   display: flex;
//   justify-content: flex-end;
// `;

// const NavLink = styled(Link)`
//   color: #fff;
//   text-decoration: none;
//   padding: 0 20px;
//   font-size: 18px;
//   position: relative;
//   transition: color 0.3s;

//   &::after {
//     content: '';
//     position: absolute;
//     width: 0;
//     height: 2px;
//     display: block;
//     margin-top: 5px;
//     right: 0;
//     background: #007bff;
//     transition: width 0.4s ease, right 0.4s ease;
//   }

//   &:hover {
//     color: #007bff;
//   }

//   &:hover::after {
//     width: 100%;
//     right: 0;
//   }
// `;

// const TermsAndConditions = () => {
//   return (
//     <div>

// <Container id="TermsAndConditions">
//             <Title
//           initial={{ opacity: 0, y: -50 }}
//           animate={{ opacity: 1, y: 0 }}
//           transition={{ duration: 1 }}
//         >
//           Terms and Conditions
//         </Title>
//         <Paragraph
//           initial={{ opacity: 0, y: 50 }}
//           animate={{ opacity: 1, y: 0 }}
//           transition={{ duration: 1, delay: 0.5 }}
//         >
//           Last updated: 9th June 2024
//         </Paragraph>
//         <SectionTitle
//           initial={{ opacity: 0, y: 50 }}
//           animate={{ opacity: 1, y: 0 }}
//           transition={{ duration: 1, delay: 1 }}
//         >
//           Introduction
//         </SectionTitle>
//         <Paragraph
//           initial={{ opacity: 0, y: 50 }}
//           animate={{ opacity: 1, y: 0 }}
//           transition={{ duration: 1, delay: 1 }}
//         >
//           Welcome to TallyMatic! These Terms and Conditions outline the rules and regulations for the use of TallyMatic's website and services.
//         </Paragraph>
//         <SectionTitle
//           initial={{ opacity: 0, y: 50 }}
//           animate={{ opacity: 1, y: 0 }}
//           transition={{ duration: 1, delay: 1.5 }}
//         >
//           Acceptance of Terms
//         </SectionTitle>
//         <Paragraph
//           initial={{ opacity: 0, y: 50 }}
//           animate={{ opacity: 1, y: 0 }}
//           transition={{ duration: 1, delay: 1.5 }}
//         >
//           By accessing and using TallyMatic, you accept and agree to be bound by these terms. If you do not agree to these terms, please do not use our services.
//         </Paragraph>
//         <SectionTitle
//           initial={{ opacity: 0, y: 50 }}
//           animate={{ opacity: 1, y: 0 }}
//           transition={{ duration: 1, delay: 2 }}
//         >
//           Use of Service
//         </SectionTitle>
//         <Paragraph
//           initial={{ opacity: 0, y: 50 }}
//           animate={{ opacity: 1, y: 0 }}
//           transition={{ duration: 1, delay: 2 }}
//         >
//           You agree to use the service only for lawful purposes and in accordance with these Terms and Conditions.
//         </Paragraph>
//         <SectionTitle
//           initial={{ opacity: 0, y: 50 }}
//           animate={{ opacity: 1, y: 0 }}
//           transition={{ duration: 1, delay: 2.5 }}
//         >
//           Changes to Terms
//         </SectionTitle>
//         <Paragraph
//           initial={{ opacity: 0, y: 50 }}
//           animate={{ opacity: 1, y: 0 }}
//           transition={{ duration: 1, delay: 2.5 }}
//         >
//           We reserve the right to modify or replace these Terms and Conditions at any time. We will notify you of changes by posting the new terms on our website.
//         </Paragraph>
//         <Paragraph
//           initial={{ opacity: 0, y: 50 }}
//           animate={{ opacity: 1, y: 0 }}
//           transition={{ duration: 1, delay: 3 }}
//         >
//           If you have any questions about these Terms and Conditions, please <a href="mailto:support@yourwebsite.com">contact us</a>.
//         </Paragraph>
//       </Container>
//       <Footer>© R. K. Paper Products 2024</Footer>
//     </div>
//   );
// };

// export default TermsAndConditions;

import React from 'react';
import { motion } from 'framer-motion';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px 20px;
  background-color: rgb(0, 4, 29);
  font-family: 'Poppins', sans-serif;
  color: rgb(255, 255, 255);
`;

const Title = styled(motion.h1)`
  font-size: 42px;
  color: rgb(128, 255, 255);
  margin-bottom: 20px;
  text-align: center;
`;

const SectionTitle = styled(motion.h2)`
  font-size: 32px;
  color: rgb(255, 255, 255);
  margin-top: 30px;
  margin-bottom: 15px;
  text-align: center;
`;

const Paragraph = styled(motion.p)`
  font-size: 18px;
  line-height: 1.8;
  margin-bottom: 15px;
  text-align: justify;
  max-width: 800px;
  color: rgb(128, 255, 255);
`;

const Footer = styled.footer`
  width: 100%;
  padding: 20px;
  text-align: center;
  background-color: rgb(0, 6, 24);
  color: rgb(255, 255, 255);
  position: fixed;
  bottom: 0;
`;

const Nav = styled.nav`
  width: 100%;
  background-color: rgb(0, 6, 24);
  padding: 10px 0;
  display: flex;
  justify-content: flex-end;
`;

const NavLink = styled(Link)`
  color: rgb(255, 255, 255);
  text-decoration: none;
  padding: 0 20px;
  font-size: 18px;
  position: relative;
  transition: color 0.3s;

  &::after {
    content: '';
    position: absolute;
    width: 0;
    height: 2px;
    display: block;
    margin-top: 5px;
    right: 0;
    background: rgb(0, 128, 255);
    transition: width 0.4s ease, right 0.4s ease;
  }

  &:hover {
    color: rgb(0, 128, 255);
  }

  &:hover::after {
    width: 100%;
    right: 0;
  }
`;

const TermsAndConditions = () => {
  return (
    <div>
      <Container id="TermsAndConditions">
        <Title
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1 }}
        >
          Terms and Conditions
        </Title>
        <Paragraph
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1, delay: 0.5 }}
        >
          Last updated: 9th June 2024
        </Paragraph>
        <SectionTitle
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1, delay: 1 }}
        >
          Introduction
        </SectionTitle>
        <Paragraph
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1, delay: 1 }}
        >
          Welcome to TallyMatic! These Terms and Conditions outline the rules and regulations for the use of TallyMatic's website and services.
        </Paragraph>
        <SectionTitle
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1, delay: 1.5 }}
        >
          Acceptance of Terms
        </SectionTitle>
        <Paragraph
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1, delay: 1.5 }}
        >
          By accessing and using TallyMatic, you accept and agree to be bound by these terms. If you do not agree to these terms, please do not use our services.
        </Paragraph>
        <SectionTitle
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1, delay: 2 }}
        >
          Use of Service
        </SectionTitle>
        <Paragraph
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1, delay: 2 }}
        >
          You agree to use the service only for lawful purposes and in accordance with these Terms and Conditions.
        </Paragraph>
        <SectionTitle
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1, delay: 2.5 }}
        >
          Changes to Terms
        </SectionTitle>
        <Paragraph
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1, delay: 2.5 }}
        >
          We reserve the right to modify or replace these Terms and Conditions at any time. We will notify you of changes by posting the new terms on our website.
        </Paragraph>
        <Paragraph
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1, delay: 3 }}
        >
          If you have any questions about these Terms and Conditions, please <a href="mailto:support@yourwebsite.com" style={{ color: 'rgb(128, 255, 255)' }}>contact us</a>.
        </Paragraph>
      </Container>
      <Footer>© R. K. Paper Products 2024</Footer>
    </div>
  );
};

export default TermsAndConditions;
