// import React, { useState, useEffect, useRef } from 'react';
// import { Table, Select, Input, Upload, message, Modal, Card, InputNumber, Statistic, Divider, Typography } from 'antd';
// import { UploadOutlined } from '@ant-design/icons';
// import moment from 'moment';
// import Papa from 'papaparse';
// import './App.css';
// import * as XLSX from 'xlsx';
// import { SwapOutlined } from '@ant-design/icons';
// import Button from '@mui/material/Button';
// import CloudUploadIcon from '@mui/icons-material/CloudUpload';
// import { Tabs } from 'antd';
// import { SignedIn, SignedOut, SignIn, SignUp  } from '@clerk/clerk-react';
// import { UserButton } from '@clerk/clerk-react';
// import { SignOutButton, useUser } from '@clerk/clerk-react';
// import BankStatement from './BankStatement'
// import { v4 as uuidv4 } from 'uuid';
// import { supabase } from './supabaseClient';
// import AdditionalInfoForm from './AdditionalInfoForm';
// import { Spin } from 'antd';
// import { useSpring, animated } from 'react-spring';
// import PaymentModal from './PaymentModal';
// import axios from 'axios';
// import RazorpayForm from './RazorpayForm';
// import toast, { Toaster } from "react-hot-toast";
// import { Link } from 'react-router-dom';
// import { useNavigate } from 'react-router-dom';
// import { useClerk } from '@clerk/clerk-react';

// const { TabPane } = Tabs;

// const { Option } = Select;

// const discrepancyColors = {
//   'File 1 - File 2 Mismatch': '#89CFF0',
//   'File 2 - File 1 Mismatch': '#FDB9C8',
//   'Only Date Mismatch': '#F3E5AB',
//   'Only Invoice Amount Mismatch': '#A7FBB9',
//   'Only Invoice Number Mismatch': '#CDB4DB',
//   'All Fields Match': '#ACE1AF',
// };

// const Gst = () => {
//   const navigate = useNavigate();
//   const { signOut } = useClerk();

//   const [filters, setFilters] = useState({ name: '', startDate: '', endDate: '', discrepancyType: 'all' });
//   const [table1Data, setTable1Data] = useState([]);
//   const [table2Data, setTable2Data] = useState([]);
//   const [discrepancyStats, setDiscrepancyStats] = useState(null);
//   const [originalTable1Data, setOriginalTable1Data] = useState([]);
//   const [originalTable2Data, setOriginalTable2Data] = useState([]);
//   const [isModalVisible, setIsModalVisible] = useState(false);
//   const [discrepancyResults, setDiscrepancyResults] = useState([]);
//   const [uploadedFileName1, setUploadedFileName1] = useState('');
//   const [uploadedFileName2, setUploadedFileName2] = useState('');
//   const [filteredTable1DataCount, setFilteredTable1DataCount] = useState(0);
//   const [filteredTable2DataCount, setFilteredTable2DataCount] = useState(0);
//   const [totalTable1DataCount, setTotalTable1DataCount] = useState(0);
//   const [totalTable2DataCount, setTotalTable2DataCount] = useState(0);
//   const [showAdditionalInfoForm, setShowAdditionalInfoForm] = useState(false);
//   const [tokenCount, setTokenCount] = useState(0);
//   const [showPaymentModal, setShowPaymentModal] = useState(false);
//   const [paymentLink, setPaymentLink] = useState('https://nyxsolution09.lemonsqueezy.com/buy/e4614b4e-333c-400d-b7f9-a4eb13ec2ae8');
//   const razorpayContainerRef = useRef(null);

//   const [userEmail, setUserEmail] = useState('')
//   const [amount, setAmount] = useState(5000)
//   const handleAdditionalInfoSubmit = () => {
//     setShowAdditionalInfoForm(false);
//   };

//   const handleFilterChange = (e) => {
//     const { name, value } = e.target;
//     setFilters({ ...filters, [name]: value });
//   };

//   const handleDiscrepancyFilterChange = (value) => {
//     console.log("Selected Discrepancy Type:", value);
//     setFilters({ ...filters, discrepancyType: value });

//     let currentTable1Data = [...originalTable1Data];
//     let currentTable2Data = [...originalTable2Data];

//     if (value === 'Full Match') {
//       // Function to add one day to a date
//       const addOneDay = (date) => moment(date).add(1, 'days');
//       // Function to check if two dates match or are one day apart
//       const isDateMatch = (date1, date2) => {
//         const momentDate1 = moment(date1);
//         const momentDate2 = moment(date2);
//         return momentDate1.isSame(momentDate2) ||
//           momentDate1.isSame(addOneDay(momentDate2)) ||
//           addOneDay(momentDate1).isSame(momentDate2);
//       };

//       let table2Map = new Map();
//       table2Data.forEach(item => {
//         let key = `${item.invoiceNumber?.toLowerCase()}-${item.gstNumber?.toLowerCase()}-${item.particulars?.toLowerCase()}`;
//         table2Map.set(key, item);
//       });

//       let fullMatchesTable1 = [];
//       let fullMatchesTable2 = [];

//       table1Data.forEach(item1 => {
//         let key = `${item1.invoiceNumber?.toLowerCase()}-${item1.gstNumber?.toLowerCase()}-${item1.name?.toLowerCase()}`;
//         if (table2Map.has(key)) {
//           let item2 = table2Map.get(key);
//           if (parseFloat(item1.taxableValue) === parseFloat(item2.taxableValue) && isDateMatch(item1.date, item2.date)) {
//             fullMatchesTable1.push(item1);
//             fullMatchesTable2.push(item2);
//           }
//         }
//       });

//       const formattedData = fullMatchesTable2.map(item => ({
//         gstNumber: item.gstNumber,
//         name: item.particulars, // Assuming 'particulars' as 'name'
//         invoiceNumber: item.invoiceNumber,
//         date: item.date,
//         invoiceValue: item.invoiceValue,
//         taxableValue: item.taxableValue
//       }));

//       console.log('Full Matches in Table1Data:', fullMatchesTable1);
//       console.log('Full Matches in Table2Data:', formattedData);

//       setTable1Data(fullMatchesTable1);
//       setTable2Data(formattedData);
//     }

//     else if (value === 'GST Match') {
//       let gstMatchesTable1 = [];
//       let uniqueGstMatchesTable2 = new Map();

//       table1Data.forEach(item1 => {
//         let gstNumberLower = item1.gstNumber?.toLowerCase();
//         if (table2Data.some(item2 => item2.gstNumber?.toLowerCase() === gstNumberLower)) {
//           gstMatchesTable1.push(item1);
//           table2Data.forEach(item2 => {
//             if (item2.gstNumber?.toLowerCase() === gstNumberLower) {
//               let key = `${item2.gstNumber}-${item2.invoiceNumber}`; // Use a combination of GST and Invoice Number as key
//               if (!uniqueGstMatchesTable2.has(key)) {
//                 uniqueGstMatchesTable2.set(key, item2);
//               }
//             }
//           });
//         }
//       });

//       let gstMatchesTable2 = Array.from(uniqueGstMatchesTable2.values());

//       const formattedData = gstMatchesTable2.map(item => ({
//         gstNumber: item.gstNumber,
//         name: item.particulars, // Adjust property names as per your data structure
//         invoiceNumber: item.invoiceNumber,
//         date: item.date, // Ensure date formatting is consistent if displayed
//         invoiceValue: item.invoiceValue,
//         taxableValue: item.taxableValue
//       }));

//       console.log('GST Matches in Table1Data:', gstMatchesTable1);
//       console.log('GST Matches in Table2Data:', formattedData);
//       setTable1Data(gstMatchesTable1); // Update state or data structure as needed
//       setTable2Data(formattedData); // Update state or data structure as needed
//     }
//     else if (value === 'GST & Date Match') {
//       const addOneDay = (date) => moment(date).add(1, 'days');
//       const isDateMatch = (date1, date2) => {
//         const momentDate1 = moment(date1);
//         const momentDate2 = moment(date2);
//         return momentDate1.isSame(momentDate2) ||
//           momentDate1.isSame(addOneDay(momentDate2)) ||
//           addOneDay(momentDate1).isSame(momentDate2);
//       };

//       let table2Map = new Map();
//       table2Data.forEach(item => {
//         let key = item.gstNumber?.toLowerCase();
//         if (!table2Map.has(key)) {
//           table2Map.set(key, []);
//         }
//         table2Map.get(key).push(item);
//       });

//       let gstDateMatchesTable1 = [];
//       let gstDateMatchesTable2 = [];

//       table1Data.forEach(item1 => {
//         let key = item1.gstNumber?.toLowerCase();
//         if (table2Map.has(key)) {
//           table2Map.get(key).forEach(item2 => {
//             if (isDateMatch(item1.date, item2.date)) {
//               gstDateMatchesTable1.push(item1);
//               gstDateMatchesTable2.push(item2);
//             }
//           });
//         }
//       });

//       const formattedData = gstDateMatchesTable2.map(item => ({
//         gstNumber: item.gstNumber,
//         name: item.particulars, // Assuming 'particulars' as 'name'
//         invoiceNumber: item.invoiceNumber,
//         date: item.date,
//         invoiceValue: item.invoiceValue,
//         taxableValue: item.taxableValue
//       }));

//       console.log('GST & Date Matches in Table1Data:', gstDateMatchesTable1);
//       console.log('GST & Date Matches in Table2Data:', formattedData);
//       setTable1Data(gstDateMatchesTable1);
//       setTable2Data(formattedData);
//     }
//     else if (value === 'GST Match + Name + Date') {
//       let tempMatchesTable1 = [];
//       let matchedIndicesTable2 = new Set();

//       table1Data.forEach(item1 => {
//         table2Data.forEach((item2, index) => {

//           if (item2.gstNumber === item1.gstNumber &&
//             item2.name.toLowerCase() === item1.name.toLowerCase() &&
//             formatDate(item2.date) === formatDate(item1.date)) {
//             console.log(`Matching Dates: Table1 - ${formatDate(item1.date)}, Table2 - ${formatDate(item2.date)}`);
//             tempMatchesTable1.push(item1); // Temporarily add item from table1Data to the matches
//             matchedIndicesTable2.add(index); // Add index of the matched item from table2Data
//           }

//         });
//       });

//       // Filter tempMatchesTable1 for uniqueness
//       let uniqueKeySet = new Set();
//       let gstNameDateMatchesTable1 = tempMatchesTable1.filter(item => {
//         let key = `${item.gstNumber}-${item.name.toLowerCase()}-${formatDate(item.date)}`;
//         if (uniqueKeySet.has(key)) {
//           return false; // Skip this item, it's a duplicate
//         } else {
//           uniqueKeySet.add(key);
//           return true; // Keep this item, it's unique
//         }
//       });

//       // Create a matched array for table2Data using the indices stored in matchedIndicesTable2
//       let gstNameDateMatchesTable2 = [...matchedIndicesTable2].map(index => table2Data[index]);

//       const formattedData = gstNameDateMatchesTable2.map(item => ({
//         key: parseInt(item.key),
//         gstNumber: item.gstNumber,
//         name: item.name.toUpperCase(),
//         invoiceNumber: item.invoiceNumber,
//         date: moment(item.date, ["MM/DD/YYYY", "DD-MM-YYYY"]).format("YYYY-MM-DD"),
//         invoiceValue: item.amount, // Assuming that `amount` is your `invoiceValue`
//         taxableValue: item.taxableValue
//       }));

//       console.log('GST + Name + Date Matches in Table1Data:', gstNameDateMatchesTable1);
//       console.log('GST + Name + Date Matches in Table2Data:', formattedData);
//       setTable1Data(gstNameDateMatchesTable1);
//       setTable2Data(formattedData);
//     }

//     else if (value === 'GST, Date & Name Match') {
//       // Function to add one day to a date
//       const addOneDay = (date) => moment(date).add(1, 'days');
//       // Function to check if two dates match or are one day apart
//       const isDateMatch = (date1, date2) => {
//         const momentDate1 = moment(date1);
//         const momentDate2 = moment(date2);
//         return momentDate1.isSame(momentDate2) ||
//           momentDate1.isSame(addOneDay(momentDate2)) ||
//           addOneDay(momentDate1).isSame(momentDate2);
//       };

//       let table2Map = new Map();
//       table2Data.forEach(item => {
//         // Use GST Number and Name as the key for mapping, ensuring case-insensitive comparison
//         let key = `${item.gstNumber?.toLowerCase()}-${item.particulars?.toLowerCase()}`;
//         // If multiple entries for the same GST Number and Name exist, consider storing them in an array
//         if (!table2Map.has(key)) {
//           table2Map.set(key, []);
//         }
//         table2Map.get(key).push(item);
//       });

//       let gstDateNameMatchesTable1 = [];
//       let gstDateNameMatchesTable2 = [];

//       table1Data.forEach(item1 => {
//         let key = `${item1.gstNumber?.toLowerCase()}-${item1.name?.toLowerCase()}`;
//         if (table2Map.has(key)) {
//           table2Map.get(key).forEach(item2 => {
//             // Check if dates match using the isDateMatch function
//             if (isDateMatch(item1.date, item2.date)) {
//               gstDateNameMatchesTable1.push(item1);
//               gstDateNameMatchesTable2.push(item2);
//             }
//           });
//         }
//       });

//       // Optionally format the matched data for display or further processing
//       const formattedData = gstDateNameMatchesTable2.map(item => ({
//         gstNumber: item.gstNumber,
//         name: item.particulars, // Use 'particulars' as 'name'
//         invoiceNumber: item.invoiceNumber,
//         date: item.date,
//         invoiceValue: item.invoiceValue,
//         taxableValue: item.taxableValue
//       }));

//       console.log('GST, Date & Name Matches in Table1Data:', gstDateNameMatchesTable1);
//       console.log('GST, Date & Name Matches in Table2Data:', formattedData);
//       setTable1Data(gstDateNameMatchesTable1); // Update your state or UI accordingly
//       setTable2Data(formattedData); // Update your state or UI accordingly
//     }

//     else if (value === 'GST Match + Name + Date + InvoiceValue') {
//       // Step 1: Create a map for quick lookup in table2Data
//       let table2Map = new Map();
//       table2Data.forEach(item => {
//         let key = `${item.invoiceNumber}-${item.gstNumber}-${item.particulars}`;
//         table2Map.set(key, item);
//       });

//       // Results holder for matches in table1Data and table2Data
//       let fullMatchesTable1 = [];
//       let fullMatchesTable2 = [];

//       // Step 2: Iterate through table1Data and compare
//       table1Data.forEach(item1 => {
//         let key = `${item1.invoiceNumber}-${item1.gstNumber}-${item1.name}`;
//         if (table2Map.has(key)) {
//           let item2 = table2Map.get(key);
//           // Since it's a full match, add to both tables' match lists
//           fullMatchesTable1.push(item1);
//           fullMatchesTable2.push(item2);
//         }
//       });

//       const formattedData = fullMatchesTable2.map(item => ({
//         gstNumber: item.gstNumber,
//         name: item.particulars, // Use 'particulars' as 'name'
//         invoiceNumber: item.invoiceNumber,
//         date: item.date,
//         invoiceValue: item.invoiceValue,
//         taxableValue: item.taxableValue
//       }));

//       console.log(formattedData);
//       // Log the fully matched data separately for table1Data and table2Data
//       console.log('Full Matches in Table1Data:', fullMatchesTable1);
//       console.log('Full Matches in Table2Data:', formattedData);
//       setTable1Data(fullMatchesTable1);
//       setTable2Data(formattedData);
//     }
//     else if (value === 'GST, Date, Name & Invoice Match') {
//       // Function to add one day to a date
//       const addOneDay = (date) => moment(date).add(1, 'days');
//       // Function to check if two dates match or are one day apart
//       const isDateMatch = (date1, date2) => {
//         const momentDate1 = moment(date1);
//         const momentDate2 = moment(date2);
//         return momentDate1.isSame(momentDate2) ||
//           momentDate1.isSame(addOneDay(momentDate2)) ||
//           addOneDay(momentDate1).isSame(momentDate2);
//       };

//       let table2Map = new Map();
//       table2Data.forEach(item => {
//         // Use GST Number, Name, and Invoice Number as the key for mapping, ensuring case-insensitive comparison
//         let key = `${item.gstNumber?.toLowerCase()}-${item.particulars?.toLowerCase()}-${item.invoiceNumber?.toLowerCase()}`;
//         // Normalize date format if necessary
//         item.normalizedDate = moment(item.date).format('YYYY-MM-DD');
//         if (!table2Map.has(key)) {
//           table2Map.set(key, []);
//         }
//         table2Map.get(key).push(item);
//       });

//       let matchesTable1 = [];
//       let matchesTable2 = [];

//       table1Data.forEach(item1 => {
//         // Normalize date format for comparison
//         item1.normalizedDate = moment(item1.date).format('YYYY-MM-DD');
//         let key = `${item1.gstNumber?.toLowerCase()}-${item1.name?.toLowerCase()}-${item1.invoiceNumber?.toLowerCase()}`;
//         if (table2Map.has(key)) {
//           table2Map.get(key).forEach(item2 => {
//             // Check if dates match using the isDateMatch function
//             if (isDateMatch(item1.normalizedDate, item2.normalizedDate)) {
//               matchesTable1.push(item1);
//               matchesTable2.push(item2);
//             }
//           });
//         }
//       });

//       const formattedData = matchesTable2.map(item => ({
//         gstNumber: item.gstNumber,
//         name: item.particulars, // Use 'particulars' as 'name'
//         invoiceNumber: item.invoiceNumber,
//         date: item.date, // Assuming date is already normalized for display
//         invoiceValue: item.invoiceValue,
//         taxableValue: item.taxableValue
//       }));

//       console.log('GST, Date, Name & Invoice Matches in Table1Data:', matchesTable1);
//       console.log('GST, Date, Name & Invoice Matches in Table2Data:', formattedData);
//       setTable1Data(matchesTable1);
//       setTable2Data(formattedData);
//     }

//     else if (value === 'File 1 - File 2 Mismatch') {
//       let mismatchTable1 = [];
//       let mismatchTable2 = [];

//       const formatDate = (dateStr) => moment(dateStr, ["DD MMM YYYY", "DD-MMM-YYYY"]).format("YYYY-MM-DD");

//       // Check for GST Number Mismatches in Table 1
//       originalTable1Data.forEach(item1 => {
//         if (!isDateWithinRange(item1.date, filters.startDate, filters.endDate)) {
//           return;
//         }
//         const gstMatchExists = originalTable2Data.some(item2 => item1.gstNumber === item2.gstNumber);
//         if (!gstMatchExists) {
//           mismatchTable1.push(item1);
//         } else {
//           // If GST Numbers match, check for mismatches in other fields
//           const fullMismatchExists = !originalTable2Data.some(item2 =>
//             item1.gstNumber === item2.gstNumber &&
//             (item1.invoiceNumber.toLowerCase() === item2.invoiceNumber.toLowerCase() &&
//               formatDate(item1.date) === formatDate(item2.date) &&
//               parseFloat(item1.taxableValue) === parseFloat(item2.taxableValue))
//           );
//           if (fullMismatchExists) {
//             mismatchTable1.push(item1);
//           }
//         }
//       });

//       // Check for GST Number Mismatches in Table 2
//       originalTable2Data.forEach(item2 => {
//         if (!isDateWithinRange(item2.date, filters.startDate, filters.endDate)) {
//           return;
//         }
//         const gstMatchExists = originalTable1Data.some(item1 => item2.gstNumber === item1.gstNumber);
//         if (!gstMatchExists) {
//           mismatchTable2.push(item2);
//         } else {
//           // If GST Numbers match, check for mismatches in other fields
//           const fullMismatchExists = !originalTable1Data.some(item1 =>
//             item2.gstNumber === item1.gstNumber &&
//             (item2.invoiceNumber.toLowerCase() === item1.invoiceNumber.toLowerCase() &&
//               formatDate(item2.date) === formatDate(item1.date) &&
//               parseFloat(item2.taxableValue) === parseFloat(item1.taxableValue))
//           );
//           if (fullMismatchExists) {
//             mismatchTable2.push(item2);
//           }
//         }
//       });

//       console.log(mismatchTable2)
//       setTable1Data([]);
//       setTable2Data(mismatchTable2);
//     }
//     else if (value === 'File 2 - File 1 Mismatch') {
//       let mismatchTable1 = [];
//       let mismatchTable2 = [];

//       const formatDate = (dateStr) => moment(dateStr, ["DD MMM YYYY", "DD-MMM-YYYY"]).format("YYYY-MM-DD");

//       originalTable1Data.forEach(item1 => {
//         if (!isDateWithinRange(item1.date, filters.startDate, filters.endDate)) {
//           return;
//         }
//         const gstMatchExists = originalTable2Data.some(item2 => item1.gstNumber === item2.gstNumber);
//         if (!gstMatchExists) {
//           mismatchTable1.push(item1);
//         } else {
//           const fullMismatchExists = !originalTable2Data.some(item2 =>
//             item1.gstNumber === item2.gstNumber &&
//             (item1.invoiceNumber.toLowerCase() === item2.invoiceNumber.toLowerCase() &&
//               formatDate(item1.date) === formatDate(item2.date) &&
//               parseFloat(item1.taxableValue) === parseFloat(item2.taxableValue))
//           );
//           if (fullMismatchExists) {
//             mismatchTable1.push(item1);
//           }
//         }
//       });

//       originalTable2Data.forEach(item2 => {
//         if (!isDateWithinRange(item2.date, filters.startDate, filters.endDate)) {
//           return;
//         }
//         const gstMatchExists = originalTable1Data.some(item1 => item2.gstNumber === item1.gstNumber);
//         if (!gstMatchExists) {
//           mismatchTable2.push(item2);
//         } else {
//           const fullMismatchExists = !originalTable1Data.some(item1 =>
//             item2.gstNumber === item1.gstNumber &&
//             (item2.invoiceNumber.toLowerCase() === item1.invoiceNumber.toLowerCase() &&
//               formatDate(item2.date) === formatDate(item1.date) &&
//               parseFloat(item2.taxableValue) === parseFloat(item1.taxableValue))
//           );
//           if (fullMismatchExists) {
//             mismatchTable2.push(item2);
//           }
//         }
//       });

//       setTable1Data(mismatchTable1);
//       setTable2Data([]);
//     }


//     else if (value === 'Only Date Mismatch') {
//       let dateMismatchItemsTable1 = [];
//       let dateMismatchItemsTable2 = [];

//       originalTable1Data.forEach(item1 => {
//         if (!isDateWithinRange(item1.date, filters.startDate, filters.endDate)) {
//           return;
//         }
//         const match = originalTable2Data.find(item2 =>
//           item1.gstNumber?.toLowerCase() === item2.gstNumber?.toLowerCase() &&
//           parseFloat(item1.taxableValue) === parseFloat(item2.taxableValue) &&
//           item1.invoiceNumber?.toLowerCase() === item2.invoiceNumber?.toLowerCase() &&
//           isDateWithinRange(item2.date, filters.startDate, filters.endDate)
//         );

//         if (match && moment(item1.date).format('YYYY-MM-DD') !== moment(match.date).format('YYYY-MM-DD')) {
//           dateMismatchItemsTable1.push(item1);
//         }
//       });

//       originalTable2Data.forEach(item2 => {
//         if (!isDateWithinRange(item2.date, filters.startDate, filters.endDate)) {
//           return;
//         }
//         const match = originalTable1Data.find(item1 =>
//           item2.gstNumber?.toLowerCase() === item1.gstNumber?.toLowerCase() &&
//           parseFloat(item2.taxableValue) === parseFloat(item1.taxableValue) &&
//           item2.invoiceNumber?.toLowerCase() === item1.invoiceNumber?.toLowerCase() &&
//           isDateWithinRange(item1.date, filters.startDate, filters.endDate)
//         );

//         if (match && moment(item2.date).format('YYYY-MM-DD') !== moment(match.date).format('YYYY-MM-DD')) {
//           dateMismatchItemsTable2.push(item2);
//         }
//       });
//       setTable1Data(dateMismatchItemsTable1);
//       setTable2Data(dateMismatchItemsTable2);
//     }
//     else if (value === 'Only Invoice Amount Mismatch') {
//       let dateMismatchItemsTable1 = [];
//       let dateMismatchItemsTable2 = [];
//       originalTable1Data.forEach(item1 => {
//         if (!isDateWithinRange(item1.date, filters.startDate, filters.endDate)) {
//           return;
//         }
//         const match = originalTable2Data.find(item2 =>
//           item1.gstNumber?.toLowerCase() === item2.gstNumber?.toLowerCase() &&
//           moment(item1.date).format('YYYY-MM-DD') === moment(item2.date).format('YYYY-MM-DD') &&
//           item1.invoiceNumber?.toLowerCase() === item2.invoiceNumber?.toLowerCase() &&
//           isDateWithinRange(item2.date, filters.startDate, filters.endDate)
//         );

//         if (match && parseFloat(item1.taxableValue) !== parseFloat(match.taxableValue)) {
//           dateMismatchItemsTable1.push(item1);
//         }
//       });

//       originalTable2Data.forEach(item2 => {
//         if (!isDateWithinRange(item2.date, filters.startDate, filters.endDate)) {
//           return;
//         }
//         const match = originalTable1Data.find(item1 =>
//           item2.gstNumber?.toLowerCase() === item1.gstNumber?.toLowerCase() &&
//           moment(item2.date).format('YYYY-MM-DD') === moment(item1.date).format('YYYY-MM-DD') &&
//           item2.invoiceNumber?.toLowerCase() === item1.invoiceNumber?.toLowerCase() &&
//           isDateWithinRange(item1.date, filters.startDate, filters.endDate)
//         );

//         if (match && item2.taxableValue !== match.taxableValue) {
//           dateMismatchItemsTable2.push(item2);
//         }
//       });
//       setTable1Data(dateMismatchItemsTable1);
//       setTable2Data(dateMismatchItemsTable2);
//     }

//     else if (value === 'Only Invoice Number Mismatch') {
//       let invoiceNumberMismatchItemsTable1 = [];
//       let invoiceNumberMismatchItemsTable2 = [];

//       originalTable1Data.forEach(item1 => {
//         if (!isDateWithinRange(item1.date, filters.startDate, filters.endDate)) {
//           return;
//         }
//         const match = originalTable2Data.find(item2 =>
//           item1.gstNumber?.toLowerCase() === item2.gstNumber?.toLowerCase() &&
//           moment(item1.date).format('YYYY-MM-DD') === moment(item2.date).format('YYYY-MM-DD') &&
//           parseFloat(item1.taxableValue) === parseFloat(item2.taxableValue) &&
//           isDateWithinRange(item2.date, filters.startDate, filters.endDate)
//         );

//         if (match && item1.invoiceNumber?.toLowerCase() !== match.invoiceNumber?.toLowerCase()) {
//           invoiceNumberMismatchItemsTable1.push(item1);
//         }
//       });

//       originalTable2Data.forEach(item2 => {
//         if (!isDateWithinRange(item2.date, filters.startDate, filters.endDate)) {
//           return;
//         }
//         const match = originalTable1Data.find(item1 =>
//           item2.gstNumber?.toLowerCase() === item1.gstNumber?.toLowerCase() &&
//           moment(item2.date).format('YYYY-MM-DD') === moment(item1.date).format('YYYY-MM-DD') &&
//           parseFloat(item2.taxableValue) === parseFloat(item1.taxableValue) &&
//           isDateWithinRange(item1.date, filters.startDate, filters.endDate)
//         );

//         if (match && item2.invoiceNumber?.toLowerCase() !== match.invoiceNumber?.toLowerCase()) {
//           invoiceNumberMismatchItemsTable2.push(item2);
//         }
//       });
//       setTable1Data(invoiceNumberMismatchItemsTable1);
//       setTable2Data(invoiceNumberMismatchItemsTable2);
//     }

//     else if (value === 'Only GST Number Mismatch') {

//       let table2Map = new Map();
//       originalTable2Data.forEach(item => {
//         if (!isDateWithinRange(item.date, filters.startDate, filters.endDate)) {
//           return;
//         }
//         let key = `${item.invoiceNumber?.toLowerCase()}-${item.date}-${parseFloat(item.taxableValue)}`;
//         if (!table2Map.has(key)) {
//           table2Map.set(key, []);
//         }
//         table2Map.get(key).push(item);
//       });

//       let gstNumberMismatchTable1 = [];
//       let gstNumberMismatchTable2 = [];

//       originalTable1Data.forEach(item1 => {
//         if (!isDateWithinRange(item1.date, filters.startDate, filters.endDate)) {
//           return;
//         }
//         let key = `${item1.invoiceNumber?.toLowerCase()}-${item1.date}-${parseFloat(item1.taxableValue)}`;
//         if (table2Map.has(key)) {
//           const potentialMatches = table2Map.get(key);
//           const actualMatches = potentialMatches.filter(item2 => item1.gstNumber?.toLowerCase() !== item2.gstNumber?.toLowerCase());

//           if (actualMatches.length > 0) {
//             gstNumberMismatchTable1.push(item1);
//             gstNumberMismatchTable2.push(...actualMatches);
//           }
//         }
//       });

//       const formattedTable2Data = gstNumberMismatchTable2.map(item => ({
//         gstNumber: item.gstNumber,
//         name: item.particulars,
//         invoiceNumber: item.invoiceNumber,
//         date: item.date,
//         invoiceValue: item.invoiceValue,
//         taxableValue: item.taxableValue,
//       }));

//       console.log('Only GST Number Mismatch in Table1Data:', gstNumberMismatchTable1);
//       console.log('Only GST Number Mismatch in Table2Data:', formattedTable2Data);

//       setTable1Data(gstNumberMismatchTable1);
//       setTable2Data(formattedTable2Data);
//     }

//     else if (value === 'All Fields Match') {
//       const formatDate = (dateStr) => {
//         const dateStrNormalized = dateStr.replace(/-/g, " ");
//         return moment(dateStrNormalized, "DD MMM YYYY").format("YYYY-MM-DD");
//       };
//       let allMatchesTable1 = originalTable1Data.filter(item1 => {

//         return originalTable2Data.some(item2 => {
//           if (!isDateWithinRange(item2.date, filters.startDate, filters.endDate)) {
//             return false;
//           }
//           return item1.gstNumber === item2.gstNumber &&
//             parseFloat(item1.taxableValue) === parseFloat(item2.taxableValue) &&
//             formatDate(item1.date) === formatDate(item2.date) &&
//             item1.invoiceNumber === item2.invoiceNumber
//         })
//       })
//       let allMatchesTable2 = originalTable2Data.filter(item2 => {
//         return originalTable1Data.some(item1 => {
//           if (!isDateWithinRange(item1.date, filters.startDate, filters.endDate)) {
//             return false;
//           }

//           return item2.gstNumber === item1.gstNumber &&
//             parseFloat(item2.taxableValue) === parseFloat(item1.taxableValue) &&
//             formatDate(item2.date) === formatDate(item1.date) &&
//             item2.invoiceNumber === item1.invoiceNumber
//         })
//       });

//       console.log('full field match 1', allMatchesTable1);
//       console.log('full field match 2', allMatchesTable2);

//       setTable1Data(allMatchesTable1);
//       setTable2Data(allMatchesTable2);
//     }
//     else if (value === 'No Fields Match') {
//       let mismatchesTable1 = [];
//       let mismatchesTable2 = [];

//       const normalizeString = (str) => str?.toLowerCase().trim();
//       const normalizeNumber = (value) => parseFloat(value).toFixed(2);

//       const formatDate = (date) => {
//         const cleanedDate = date.replace(/-/g, ' '); // Replacing hyphens with spaces
//         return moment(cleanedDate, "DD MMM YYYY").format('YYYY-MM-DD');
//       };

//       originalTable1Data.forEach(item1 => {
//         if (!isDateWithinRange(item1.date, filters.startDate, filters.endDate)) {
//           return;
//         }

//         const matches = originalTable2Data.filter(item2 =>
//           normalizeString(item1.gstNumber) === normalizeString(item2.gstNumber) &&
//           normalizeString(item1.gstNumber) === normalizeString(item2.gstNumber)
//         );

//         const isMismatch = matches.every(match => {
//           const date1 = formatDate(item1.date);
//           const date2 = formatDate(match.date);
//           const taxableValue1 = normalizeNumber(item1.taxableValue);
//           const taxableValue2 = normalizeNumber(match.taxableValue);

//           return date1 !== date2 || taxableValue1 !== taxableValue2;
//         });

//         if (isMismatch) {
//           mismatchesTable1.push(item1);
//         }
//       });

//       originalTable2Data.forEach(item2 => {
//         if (!isDateWithinRange(item2.date, filters.startDate, filters.endDate)) {
//           return;
//         }

//         const matches = originalTable1Data.filter(item1 =>
//           normalizeString(item2.gstNumber) === normalizeString(item1.gstNumber) &&
//           normalizeString(item2.gstNumber) === normalizeString(item1.gstNumber)
//         );

//         const isMismatch = matches.every(match => {
//           const date1 = formatDate(match.date);
//           const date2 = formatDate(item2.date);
//           const taxableValue1 = normalizeNumber(match.taxableValue);
//           const taxableValue2 = normalizeNumber(item2.taxableValue);

//           return date1 !== date2 || taxableValue1 !== taxableValue2;
//         });

//         if (isMismatch) {
//           mismatchesTable2.push(item2);
//         }
//       });

//       setTable1Data(mismatchesTable1);
//       setTable2Data(mismatchesTable2);
//     }


//   };

//   useEffect(() => {
//     console.log(table1Data)
//     console.log(table2Data)
//   }, [table1Data, table2Data, filters.discrepancyType]);

//   useEffect(() => {
//     console.log("StartDate or EndDate changed");
//     console.log("Table 1 Data:", table1Data);
//     console.log("Table 2 Data:", table2Data);
//   }, [filters.startDate, filters.endDate]);
//   //
//   const summarizeDataByFilter = () => {
//     const discrepancyTypes = [
//       'Full Match', 'GST Match', 'GST & Date Match', 'GST Match + Name + Date',
//       'GST, Date & Name Match', 'GST Match + Name + Date + InvoiceValue', 'GST, Date, Name & Invoice Match',
//       'File 1 - File 2 Missmatch', 'File 2 - File 1 Missmatch', 'Only Date Mismatch',
//       'Only Invoice Amount Mismatch', 'Only Invoice Number Mismatch', 'Only GST Number Mismatch',
//       'All Fields Match', 'No Fields Match'
//     ];

//     let summaryData = discrepancyTypes.map(type => {
//       const filteredTable1Data = filterData(table1Data, type); // You need to implement this function based on your filtering criteria
//       const filteredTable2Data = filterData(table2Data, type); // Same as above, implement this function

//       return {
//         filter: type,
//         table1Data: filteredTable1Data,
//         table2Data: filteredTable2Data
//       };
//     });

//     console.log("Summary Data by Filter:", summaryData);
//   };

//   function filterData(data, filterType) {

//     return data.filter(item => {

//       return item.discrepancyType === filterType;
//     });
//   }

//   const formatDate = (dateStr) => {
//     let parsedDate = moment(dateStr, "DD/MM/YYYY", true);

//     if (!parsedDate.isValid()) {
//       parsedDate = moment(dateStr, "MM/DD/YYYY", true);
//     }

//     if (!parsedDate.isValid()) {
//       parsedDate = moment(dateStr, ["YYYY-MM-DD", "D/M/YYYY", "M/D/YYYY", "DD-MM-YYYY", "MM-DD-YYYY"], true);
//     }

//     return parsedDate.isValid() ? parsedDate.format("YYYY-MM-DD") : "Invalid Date";
//   };


//   const excelDateToJSDate = (excelDate) => {
//     const JS_DATE_START = new Date(Date.UTC(1899, 11, 30));
//     return new Date(JS_DATE_START.getTime() + (excelDate * 86400000) + (2 * 86400000));
//   };


//   const sortByDate = (a, b) => {
//     const dateA = moment(a.date, "DD MMM YYYY");
//     const dateB = moment(b.date, "DD MMM YYYY");

//     if (dateA.isBefore(dateB)) return -1;
//     if (dateA.isAfter(dateB)) return 1;
//     return 0;
//   };


//   const handleFileUpload1 = (file) => {
//     const fileReader = new FileReader();
//     fileReader.onload = (e) => {
//       const bufferArray = e.target.result;
//       const wb = XLSX.read(bufferArray, { type: 'buffer' });

//       // Directly target the "B2B" sheet
//       const wsname = wb.SheetNames.includes("B2B") ? "B2B" : wb.SheetNames[0];
//       const ws = wb.Sheets[wsname];

//       const data = XLSX.utils.sheet_to_json(ws, {
//         header: 1,
//         range: 0,
//         raw: false,
//       });

//       const possibleHeaders = {
//         gstNumber: ['GSTIN', 'GSTIN of supplier'],
//         name: ['Supplier Name', 'Trade/Legal name of the supplier'],
//         invoiceNumber: ['Invoice No', 'Invoice Number'],
//         date: ['Invoice Date'],
//         taxableValue: ['Taxable Value', 'Taxable Value (â‚¹)'],
//         period: ['Period', 'GSTR-1/IFF/GSTR-5 Period'],
//         invoiceDetails: ['Invoice Details']
//       };

//       let headerRowIndex = data.findIndex(row =>
//         row.some(cell => Object.values(possibleHeaders).flat().includes(cell))
//       );

//       const headers = data[headerRowIndex];
//       let headerIndexMap = {};

//       headers.forEach((header, index) => {
//         Object.entries(possibleHeaders).forEach(([key, values]) => {
//           if (values.includes(header)) {
//             headerIndexMap[key] = index;
//           }
//         });
//       });
//       let formattedData = data.slice(headerRowIndex + 1)
//         .filter(row => row.length)
//         .map((row, i) => {
//           let rowData = { key: i + 1 };
//           Object.keys(headerIndexMap).forEach(key => {
//             let cellValue = row[headerIndexMap[key]];

//             if (key === 'invoiceDetails' && cellValue) {
//               const detailsParts = cellValue.split(', ');
//               rowData['invoiceNumber'] = detailsParts[0]; // Extracting invoice number

//               const dateColumnIndex = headerIndexMap[key] + 2;
//               let dateCellValue = row[dateColumnIndex];

//               if (dateCellValue) {
//                 const [day, month, year] = dateCellValue.split('/');
//                 const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
//                 dateCellValue = `${day} ${monthNames[parseInt(month) - 1]} ${year}`;
//               }
//               rowData['date'] = dateCellValue;
//             } else if (key === 'date' && cellValue) {
//               console.log(`Condition met for key 'date' with cellValue:`, cellValue);

//               if (rowData['period']) {
//                 const periodParts = rowData['period'].split(', ');
//                 const monthName = periodParts[0].trim(); // "April"
//                 const year = periodParts[1].trim(); // "2023"

//                 // Split the date from the cellValue ("4/1/23" -> Month: 4, Day: 1)
//                 const [month, day, yearShort] = cellValue.split('/');

//                 // Zero-pad the day and convert year to four digits
//                 const dayPadded = day.padStart(2, '0'); // "01"

//                 // Convert month number to month name, ensure only the first three letters are used, and capitalize
//                 const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
//                 const monthAbbreviation = monthNames[parseInt(month) - 1]; // Since month is "4", this will be "Apr"

//                 // Assuming the year format in `cellValue` always needs to be prefixed with "20" for simplicity
//                 const yearCorrected = `20${yearShort}`; // "2023"

//                 // Construct the formatted date string
//                 cellValue = `${dayPadded} ${monthAbbreviation} ${year}`; // "01 Apr 2023"

//               } else {
//                 // Convert "13/11/2023" to "13 Nov 2023" if period is not used
//                 const [day, month, year] = cellValue.split('/');
//                 const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
//                 cellValue = `${day} ${monthNames[parseInt(month) - 1]} ${year}`;
//               }
//               rowData['date'] = cellValue;
//             } else if (key !== 'invoiceDetails') {
//               rowData[key] = cellValue;
//             }
//           });
//           return rowData;
//         });

//       const finalData = formattedData.filter(rowData => {
//         return !(rowData.invoiceNumber === "Invoice number" ||
//           rowData.date && rowData.date.includes("undefined"));
//       });


//       const sortedData = finalData.sort(sortByDate);
//       console.log("Formatted data from 'B2B' sheet:", sortedData);
//       setOriginalTable1Data(sortedData);
//       setTable1Data(sortedData);
//       message.success(`${file.name} file uploaded successfully.`);
//       setUploadedFileName1(file.name);
//     };

//     fileReader.readAsArrayBuffer(file);
//     return false;
//   };

//   const handleFileUpload2 = (file) => {
//     const fileReader = new FileReader();
//     fileReader.onload = (e) => {
//       const bufferArray = e.target.result;
//       const wb = XLSX.read(bufferArray, { type: 'buffer' });
//       const wsname = wb.SheetNames[0];
//       const ws = wb.Sheets[wsname];

//       const data = XLSX.utils.sheet_to_json(ws, {
//         header: 1,
//         range: 0,
//         raw: false,
//       });

//       const possibleHeaders = {
//         gstNumber: ['GSTIN/UIN', 'SupplierGSTIN/UIN', 'Supplier GSTIN/UIN', 'Supplier'],
//         name: ['Particulars', 'Supplier Name'],
//         invoiceNumber: ['Invoice No.', 'InvoiceNo', 'Vch No.'],
//         date: ['Invoice Date', 'Date', 'Invoice Date'],
//         taxableValue: ['Taxable Value', 'Total Taxable Amount', 'Taxable', 'Taxable Amount', 'Total Taxable'],
//       };

//       let headerRowIndex = data.findIndex(row =>
//         row.some(cell => Object.values(possibleHeaders).flat().includes(cell?.toString()))
//       );

//       if (headerRowIndex === -1) {
//         console.error("No header row found, please check the file format.");
//         return;
//       }

//       let headers = data[headerRowIndex];
//       let subHeaders = data[headerRowIndex + 1] || [];

//       headers = headers.map((header, i) => subHeaders[i] ? `${header} ${subHeaders[i]}`.trim() : header);

//       let headerIndexMap = {};
//       headers.forEach((header, index) => {
//         Object.entries(possibleHeaders).forEach(([key, values]) => {
//           if (values.some(val => header.includes(val))) {
//             headerIndexMap[key] = index;
//           }
//         });
//       });

//       let formattedData = data.slice(headerRowIndex + 2)
//         .filter(row => row.some(cell => cell !== null && cell !== undefined))
//         .map((row, i) => {
//           let rowData = { key: i + 1 };
//           Object.keys(headerIndexMap).forEach(key => {
//             rowData[key] = row[headerIndexMap[key]];
//           });
//           return rowData;
//         })
//         .filter(row => row.name !== 'Grand Total');

//       const sortedData = formattedData.sort(sortByDate);
//       console.log("Formatted data:", sortedData);
//       setOriginalTable2Data(sortedData);
//       setTable2Data(sortedData);
//       message.success(`${file.name} file uploaded successfully.`);
//       setUploadedFileName2(file.name);
//     };

//     fileReader.readAsArrayBuffer(file);
//     return false;
//   };


//   useEffect(() => {
//     if (filters.discrepancyType === 'all') {
//       setTable1Data(originalTable1Data);
//       setTable2Data(originalTable2Data);
//     }
//   }, [filters.discrepancyType, originalTable1Data, originalTable2Data]);

//   const compareObjects = (obj1, obj2) => JSON.stringify(obj1) === JSON.stringify(obj2);

//   const checkDiscrepancy = (item1, item2) => {

//   };

//   const getFilteredData = (tableData, otherTableData, filters) => {
//     return tableData
//       .map(item => {
//         const match = otherTableData.find(otherItem =>
//           item.gstNumber === otherItem.gstNumber && item.invoiceNumber === otherItem.invoiceNumber
//         );
//         return {
//           ...item,
//           discrepancy: checkDiscrepancy(item, match),
//         };
//       })
//       .filter(item => {
//         const nameMatch = !filters.name || item.name.includes(filters.name);
//         const startDateMatch = !filters.startDate || moment(item.date).isSameOrAfter(filters.startDate, 'day');
//         const endDateMatch = !filters.endDate || moment(item.date).isSameOrBefore(filters.endDate, 'day');
//         const discrepancyMatch = filters.discrepancyType === 'all' || item.discrepancy === filters.discrepancyType;

//         return nameMatch && startDateMatch && endDateMatch && discrepancyMatch;
//       });
//   };


//   const handleSummarize = () => {
//     const allDiscrepancies = table1Data.concat(table2Data).map(item => checkDiscrepancy(item, table2Data.find(table1Item => item.gstNumber === table1Item.gstNumber && item.invoiceNumber === table1Item.invoiceNumber))).filter((value, index, self) => self.indexOf(value) === index);
//     const discrepancyCounts = {};
//     allDiscrepancies.forEach(discrepancy => {
//       discrepancyCounts[discrepancy] = (discrepancyCounts[discrepancy] || 0) + 1;
//     });
//     setDiscrepancyStats(discrepancyCounts);
//   };

//   //
//   const columns = [
//     {
//       title: 'GST Number',
//       dataIndex: 'gstNumber',
//       key: 'gstNumber',
//       render: (text, record) => {
//         //console.log("Current Discrepancy Type:", filters.discrepancyType); // Logging the discrepancyType
//         const backgroundColor = filters.discrepancyType === 'Only GST Number Mismatch' ? '#FFDFD3' :
//           filters.discrepancyType === 'All Fields Match' ? '#ACE1AF' :
//             filters.discrepancyType === 'File 2 - File 1 Mismatch' ? '#FDB9C8' :
//               filters.discrepancyType === 'No Fields Match' ? '#FFC0CB' :
//                 discrepancyColors[record.discrepancy] || 'transparent';
//         //const backgroundColor = filters.discrepancyType === 'Only GST Number Mismatch' ? '#FFDFD3' : discrepancyColors[record.discrepancy] || 'transparent';
//         return {
//           children: text,
//           props: {
//             style: { background: backgroundColor },
//           },
//         };
//       },
//     },
//     {
//       title: 'Ledger',
//       dataIndex: 'name',
//       key: 'name',
//       render: (text, record) => {
//         const backgroundColor =
//           filters.discrepancyType === 'All Fields Match' ? '#ACE1AF' :
//             filters.discrepancyType === 'No Fields Match' ? '#FFC0CB' :
//               filters.discrepancyType === 'File 2 - File 1 Mismatch' ? '#FDB9C8' :
//                 discrepancyColors[record.discrepancy] || 'transparent';
//         return {
//           children: text,
//           props: {
//             style: { background: backgroundColor },
//           },
//         };
//       },
//     },
//     {
//       title: 'Date',
//       dataIndex: 'date',
//       key: 'date',
//       render: (text, record) => {

//         const options = { year: 'numeric', month: 'short', day: 'numeric' };
//         const backgroundColor = filters.discrepancyType === 'Only Date Mismatch' ? '#F3E5AB' :
//           filters.discrepancyType === 'All Fields Match' ? '#ACE1AF' :
//             filters.discrepancyType === 'No Fields Match' ? '#FFC0CB' :
//               filters.discrepancyType === 'File 2 - File 1 Mismatch' ? '#FDB9C8' :
//                 discrepancyColors[record.discrepancy] || 'transparent';
//         return {
//           children: moment(text).format('DD MMM YYYY'),
//           props: {
//             style: { background: backgroundColor },
//           },
//         };
//       },
//     },
//     {
//       title: 'Invoice Number',
//       dataIndex: 'invoiceNumber',
//       key: 'invoiceNumber',
//       render: (text, record) => {
//         const backgroundColor = filters.discrepancyType === 'Only Invoice Number Mismatch' ? '#CDB4DB' :
//           filters.discrepancyType === 'All Fields Match' ? '#ACE1AF' :
//             filters.discrepancyType === 'No Fields Match' ? '#FFC0CB' :
//               filters.discrepancyType === 'File 2 - File 1 Mismatch' ? '#FDB9C8' :
//                 discrepancyColors[record.discrepancy] || 'transparent';
//         return {
//           children: text,
//           props: {
//             style: { background: backgroundColor },
//           },
//         };
//       },
//     },
//     {
//       title: 'Taxable Value',
//       dataIndex: 'taxableValue',
//       key: 'taxableValue',
//       render: (text, record) => {
//         const backgroundColor = filters.discrepancyType === 'Only Invoice Amount Mismatch' ? '#A7FBB9' :
//           filters.discrepancyType === 'All Fields Match' ? '#ACE1AF' :
//             filters.discrepancyType === 'No Fields Match' ? '#FFC0CB' :
//               filters.discrepancyType === 'File 2 - File 1 Mismatch' ? '#FDB9C8' :
//                 discrepancyColors[record.discrepancy] || 'transparent';
//         return {
//           children: text,
//           props: {
//             style: { background: backgroundColor },
//           },
//         };
//       },
//     },
//   ];

//   const columns2 = [
//     {
//       title: 'GST Number',
//       dataIndex: 'gstNumber',
//       key: 'gstNumber',
//       render: (text, record) => {
//         const backgroundColor = filters.discrepancyType === 'Only GST Number Mismatch' ? '#FFDFD3' :
//           filters.discrepancyType === 'All Fields Match' ? '#ACE1AF' :
//             filters.discrepancyType === 'No Fields Match' ? '#FFC0CB' :
//               filters.discrepancyType === 'File 1 - File 2 Mismatch' ? '#89CFF0' :
//                 discrepancyColors[record.discrepancy] || 'transparent';
//         return {
//           children: text,
//           props: {
//             style: { background: backgroundColor },
//           },
//         };
//       },
//     },
//     {
//       title: 'Ledger',
//       dataIndex: 'name',
//       key: 'name',

//       render: (text, record) => {
//         const backgroundColor =
//           filters.discrepancyType === 'All Fields Match' ? '#ACE1AF' :
//             filters.discrepancyType === 'No Fields Match' ? '#FFC0CB' :
//               filters.discrepancyType === 'File 1 - File 2 Mismatch' ? '#89CFF0' :
//                 discrepancyColors[record.discrepancy] || 'transparent';

//         return {
//           children: text,
//           props: {
//             style: { background: backgroundColor },
//           },
//         };
//       },
//     },
//     {
//       title: 'Date',
//       dataIndex: 'date',
//       key: 'date',
//       render: (text, record) => {
//         const backgroundColor = filters.discrepancyType === 'Only Date Mismatch' ? '#F3E5AB' :
//           filters.discrepancyType === 'All Fields Match' ? '#ACE1AF' :
//             filters.discrepancyType === 'No Fields Match' ? '#FFC0CB' :
//               filters.discrepancyType === 'File 1 - File 2 Mismatch' ? '#89CFF0' :
//                 discrepancyColors[record.discrepancy] || 'transparent';
//         return {
//           children: moment(text).format('DD MMM YYYY'),
//           props: {
//             style: { background: backgroundColor },
//           },
//         };
//       },
//     },
//     {
//       title: 'Invoice Number',
//       dataIndex: 'invoiceNumber',
//       key: 'invoiceNumber',
//       render: (text, record) => {
//         const backgroundColor = filters.discrepancyType === 'Only Invoice Number Mismatch' ? '#CDB4DB' :
//           filters.discrepancyType === 'All Fields Match' ? '#ACE1AF' :
//             filters.discrepancyType === 'No Fields Match' ? '#FFC0CB' :
//               filters.discrepancyType === 'File 1 - File 2 Mismatch' ? '#89CFF0' :
//                 discrepancyColors[record.discrepancy] || 'transparent';
//         return {
//           children: text,
//           props: {
//             style: { background: backgroundColor },
//           },
//         };
//       },
//     },
//     {
//       title: 'Taxable Value',
//       dataIndex: 'taxableValue',
//       key: 'taxableValue',
//       render: (text, record) => {
//         const backgroundColor = filters.discrepancyType === 'Only Invoice Amount Mismatch' ? '#A7FBB9' :
//           filters.discrepancyType === 'No Fields Match' ? '#FFC0CB' :
//             filters.discrepancyType === 'All Fields Match' ? '#ACE1AF' :
//               filters.discrepancyType === 'File 1 - File 2 Mismatch' ? '#89CFF0' :
//                 discrepancyColors[record.discrepancy] || 'transparent';
//         return {
//           children: text,
//           props: {
//             style: { background: backgroundColor },
//           },
//         };
//       },
//     },
//   ];

//   //
//   const handleAllDiscrepancyChecks = () => {

//     let results = [];

//     let file1File2Mismatch = {
//       filterName: "File 1 - File 2 Mismatch",
//       table1Data: [],
//       table2Data: []
//     };

//     const formatDate = (dateStr) => moment(dateStr, ["DD MMM YYYY", "DD-MMM-YYYY"]).format("YYYY-MM-DD");

//     const data2Match = originalTable2Data.forEach(item2 => {
//       if (!isDateWithinRange(item2.date, filters.startDate, filters.endDate)) {
//         return;
//       }
//       const gstMatchExists = originalTable1Data.some(item1 => item2.gstNumber === item1.gstNumber);
//       if (!gstMatchExists) {
//         file1File2Mismatch.table2Data.push(item2);
//       } else {
//         const fullMismatchExists = !originalTable1Data.some(item1 =>
//           item2.gstNumber === item1.gstNumber &&
//           (item2.invoiceNumber.toLowerCase() === item1.invoiceNumber.toLowerCase() &&
//             formatDate(item2.date) === formatDate(item1.date) &&
//             parseFloat(item2.taxableValue) === parseFloat(item1.taxableValue))
//         );
//         if (fullMismatchExists) {
//           file1File2Mismatch.table2Data.push(item2);
//         }
//       }
//     });

//     console.log('File 1 - File 2 Mismatch', file1File2Mismatch);

//     results.push(file1File2Mismatch);

//     let file2File1Mismatch = {
//       filterName: "File 2 - File 1 Mismatch",
//       table1Data: [],
//       table2Data: []
//     };

//     const data1Match = originalTable1Data.forEach(item1 => {
//       if (!isDateWithinRange(item1.date, filters.startDate, filters.endDate)) {
//         return;
//       }
//       const gstMatchExists = originalTable2Data.some(item2 => item1.gstNumber === item2.gstNumber);
//       if (!gstMatchExists) {
//         file2File1Mismatch.table1Data.push(item1);
//       } else {
//         // If GST Numbers match, check for mismatches in other fields
//         const fullMismatchExists = !originalTable2Data.some(item2 =>
//           item1.gstNumber === item2.gstNumber &&
//           (item1.invoiceNumber.toLowerCase() === item2.invoiceNumber.toLowerCase() &&
//             formatDate(item1.date) === formatDate(item2.date) &&
//             parseFloat(item1.taxableValue) === parseFloat(item2.taxableValue))
//         );
//         if (fullMismatchExists) {
//           file2File1Mismatch.table1Data.push(item1);
//         }
//       }
//     });

//     results.push(file2File1Mismatch);

//     let onlyDateMismatch = {
//       filterName: "Only Date Mismatch",
//       table1Data: [],
//       table2Data: []
//     };

//     originalTable1Data.forEach(item1 => {
//       if (!isDateWithinRange(item1.date, filters.startDate, filters.endDate)) {
//         return; // Skip this item if its date is outside the selected range
//       }
//       const match = originalTable2Data.find(item2 =>
//         item1.gstNumber?.toLowerCase() === item2.gstNumber?.toLowerCase() &&
//         //item1.name?.toLowerCase() === item2.name?.toLowerCase() &&
//         parseFloat(item1.taxableValue) === parseFloat(item2.taxableValue) &&
//         item1.invoiceNumber?.toLowerCase() === item2.invoiceNumber?.toLowerCase() &&
//         isDateWithinRange(item2.date, filters.startDate, filters.endDate)
//       );

//       if (match && moment(item1.date).format('YYYY-MM-DD') !== moment(match.date).format('YYYY-MM-DD')) {
//         onlyDateMismatch.table1Data.push(item1);
//       }
//     });

//     originalTable2Data.forEach(item2 => {
//       if (!isDateWithinRange(item2.date, filters.startDate, filters.endDate)) {
//         return; // Skip this item as well
//       }
//       const match = originalTable1Data.find(item1 =>
//         item2.gstNumber?.toLowerCase() === item1.gstNumber?.toLowerCase() &&
//         //item2.name?.toLowerCase() === item1.name?.toLowerCase() &&
//         parseFloat(item2.taxableValue) === parseFloat(item1.taxableValue) &&
//         item2.invoiceNumber?.toLowerCase() === item1.invoiceNumber?.toLowerCase() &&
//         isDateWithinRange(item1.date, filters.startDate, filters.endDate)
//       );

//       if (match && moment(item2.date).format('YYYY-MM-DD') !== moment(match.date).format('YYYY-MM-DD')) {
//         onlyDateMismatch.table2Data.push(item2);
//       }
//     });

//     results.push(onlyDateMismatch)

//     let onlyInvoiceAmountMismatch = {
//       filterName: "Only Invoice Amount Mismatch",
//       table1Data: [],
//       table2Data: []
//     };

//     const normalizeDate = (dateStr) => {
//       const parsedDate = moment(dateStr, ["YYYY-MM-DD", "D/M/YYYY", "M/D/YYYY", "DD-MM-YYYY", "MM-DD-YYYY", "D-M-YYYY"]);
//       return parsedDate.isValid() ? parsedDate.format("YYYY-MM-DD") : dateStr;
//     };

//     const compareTaxableValue = (value1, value2) => parseFloat(value1) !== parseFloat(value2);

//     originalTable1Data.forEach(item1 => {
//       if (!isDateWithinRange(item1.date, filters.startDate, filters.endDate)) {
//         return; // Skip this item if its date is outside the selected range
//       }
//       const match = originalTable2Data.find(item2 =>
//         item1.gstNumber?.toLowerCase() === item2.gstNumber?.toLowerCase() &&
//         //item1.name?.toLowerCase() === item2.name?.toLowerCase() &&
//         moment(item1.date).format('YYYY-MM-DD') === moment(item2.date).format('YYYY-MM-DD') &&
//         //parseFloat(item1.taxableValue) === parseFloat(item2.taxableValue) &&
//         item1.invoiceNumber?.toLowerCase() === item2.invoiceNumber?.toLowerCase() &&
//         isDateWithinRange(item2.date, filters.startDate, filters.endDate)
//       );

//       if (match && parseFloat(item1.taxableValue) !== parseFloat(match.taxableValue)) {
//         onlyInvoiceAmountMismatch.table1Data.push(item1);
//       }
//     });

//     originalTable2Data.forEach(item2 => {
//       if (!isDateWithinRange(item2.date, filters.startDate, filters.endDate)) {
//         return; // Skip this item as well
//       }
//       const match = originalTable1Data.find(item1 =>
//         item2.gstNumber?.toLowerCase() === item1.gstNumber?.toLowerCase() &&
//         //item2.name?.toLowerCase() === item1.name?.toLowerCase() &&
//         moment(item2.date).format('YYYY-MM-DD') === moment(item1.date).format('YYYY-MM-DD') &&
//         //parseFloat(item2.taxableValue) === parseFloat(item1.taxableValue) &&
//         item2.invoiceNumber?.toLowerCase() === item1.invoiceNumber?.toLowerCase() &&
//         isDateWithinRange(item1.date, filters.startDate, filters.endDate)
//       );

//       if (match && item2.taxableValue !== match.taxableValue) {
//         onlyInvoiceAmountMismatch.table2Data.push(item2);
//       }
//     });

//     results.push(onlyInvoiceAmountMismatch);

//     let onlyInvoiceNumberMismatch = {
//       filterName: "Only Invoice Number Mismatch",
//       table1Data: [],
//       table2Data: []
//     };

//     const normalizeTaxableValue = (value) => parseFloat(value).toFixed(2);

//     originalTable1Data.forEach(item1 => {
//       if (!isDateWithinRange(item1.date, filters.startDate, filters.endDate)) {
//         return; // Skip this item if its date is outside the selected range
//       }
//       const match = originalTable2Data.find(item2 =>
//         item1.gstNumber?.toLowerCase() === item2.gstNumber?.toLowerCase() &&
//         //item1.name?.toLowerCase() === item2.name?.toLowerCase() &&
//         moment(item1.date).format('YYYY-MM-DD') === moment(item2.date).format('YYYY-MM-DD') &&
//         parseFloat(item1.taxableValue) === parseFloat(item2.taxableValue) &&
//         //item1.invoiceNumber?.toLowerCase() === item2.invoiceNumber?.toLowerCase() &&
//         isDateWithinRange(item2.date, filters.startDate, filters.endDate)
//       );

//       if (match && item1.invoiceNumber?.toLowerCase() !== match.invoiceNumber?.toLowerCase()) {
//         onlyInvoiceNumberMismatch.table1Data.push(item1);
//       }
//     });

//     originalTable2Data.forEach(item2 => {
//       if (!isDateWithinRange(item2.date, filters.startDate, filters.endDate)) {
//         return; // Skip this item as well
//       }
//       const match = originalTable1Data.find(item1 =>
//         item2.gstNumber?.toLowerCase() === item1.gstNumber?.toLowerCase() &&
//         //item2.name?.toLowerCase() === item1.name?.toLowerCase() &&
//         moment(item2.date).format('YYYY-MM-DD') === moment(item1.date).format('YYYY-MM-DD') &&
//         parseFloat(item2.taxableValue) === parseFloat(item1.taxableValue) &&
//         //item2.invoiceNumber?.toLowerCase() === item1.invoiceNumber?.toLowerCase() &&
//         isDateWithinRange(item1.date, filters.startDate, filters.endDate)
//       );

//       if (match && item2.invoiceNumber?.toLowerCase() !== match.invoiceNumber?.toLowerCase()) {
//         onlyInvoiceNumberMismatch.table2Data.push(item2);
//       }
//     });

//     results.push(onlyInvoiceNumberMismatch);

//     let allFieldsMatch = {
//       filterName: "All Fields Match",
//       table1Data: [],
//       table2Data: []
//     };

//     const formatDateItem = (dateStr) => {
//       const dateStrNormalized = dateStr.replace(/-/g, " ");
//       return moment(dateStrNormalized, "DD MMM YYYY").format("YYYY-MM-DD");
//     };
//     let allMatchesTable1 = originalTable1Data.filter(item1 => {

//       return originalTable2Data.some(item2 => {
//         if (!isDateWithinRange(item2.date, filters.startDate, filters.endDate)) {
//           return false;
//         }
//         return item1.gstNumber === item2.gstNumber &&
//           parseFloat(item1.taxableValue) === parseFloat(item2.taxableValue) &&
//           formatDateItem(item1.date) === formatDateItem(item2.date) &&
//           item1.invoiceNumber === item2.invoiceNumber
//       })
//     })
//     let allMatchesTable2 = originalTable2Data.filter(item2 => {
//       // if (!isDateWithinRange(item2.date, filters.startDate, filters.endDate)) {
//       //   return;
//       // }
//       return originalTable1Data.some(item1 => {
//         if (!isDateWithinRange(item1.date, filters.startDate, filters.endDate)) {
//           return false;
//         }

//         return item2.gstNumber === item1.gstNumber &&
//           //item2.particulars.toLowerCase() === item1.name.toLowerCase() &&

//           parseFloat(item2.taxableValue) === parseFloat(item1.taxableValue) &&
//           formatDateItem(item2.date) === formatDateItem(item1.date) &&
//           item2.invoiceNumber === item1.invoiceNumber
//       })
//     });

//     console.log('full field match 1', allMatchesTable1);
//     console.log('full field match 2', allMatchesTable2);

//     allFieldsMatch.table1Data.push(...allMatchesTable1);
//     allFieldsMatch.table2Data.push(...allMatchesTable2);

//     results.push(allFieldsMatch);

//     setDiscrepancyResults(results);
//     setIsModalVisible(true);

//     console.log("Discrepancy Check Results:", results);

//   };

//   const isDateWithinRange = (date, startDate, endDate) => {
//     const momentDate = moment(date);
//     const start = startDate ? moment(startDate) : null;
//     const end = endDate ? moment(endDate) : null;

//     if (start && end) {
//       return momentDate.isSameOrAfter(start) && momentDate.isSameOrBefore(end);
//     } else if (start) {
//       return momentDate.isSameOrAfter(start);
//     } else if (end) {
//       return momentDate.isSameOrBefore(end);
//     }
//     return true; // If no start or end date is provided, consider it within range
//   };

//   const handleFileChange = (event) => {
//     const file = event.target.files[0];
//     if (file) {
//       handleFileUpload2(file); // Adjust based on your actual file handling logic
//     }
//   };

//   const handleFileChange1 = (event) => {
//     const file = event.target.files[0];
//     if (file) {
//       handleFileUpload1(file);
//     }
//     event.target.value = null;
//   };

//   useEffect(() => {
//     const filteredTable1Data = table1Data.filter(item =>
//       (!filters.startDate || moment(item.date).isSameOrAfter(filters.startDate, 'day')) &&
//       (!filters.endDate || moment(item.date).isSameOrBefore(filters.endDate, 'day'))
//     );

//     const filteredTable2Data = table2Data.filter(item =>
//       (!filters.startDate || moment(item.date).isSameOrAfter(filters.startDate, 'day')) &&
//       (!filters.endDate || moment(item.date).isSameOrBefore(filters.endDate, 'day'))
//     );

//     console.log("Filtered Table 1 Data based on date range:", filteredTable1Data);
//     console.log("Filtered Table 2 Data based on date range:", filteredTable2Data);

//   }, [filters.startDate, filters.endDate, table1Data, table2Data]);

//   useEffect(() => {
//     const filteredTable1Data = originalTable1Data.filter(item =>
//       (!filters.startDate || moment(item.date).isSameOrAfter(filters.startDate, 'day')) &&
//       (!filters.endDate || moment(item.date).isSameOrBefore(filters.endDate, 'day'))
//     );

//     const filteredTable2Data = originalTable2Data.filter(item =>
//       (!filters.startDate || moment(item.date).isSameOrAfter(filters.startDate, 'day')) &&
//       (!filters.endDate || moment(item.date).isSameOrBefore(filters.endDate, 'day'))
//     );

//     setTotalTable1DataCount(filteredTable1Data.length);
//     setTotalTable2DataCount(filteredTable2Data.length);

//   }, [filters.startDate, filters.endDate, originalTable1Data, originalTable2Data]);

//   const dataSourceWithTotal = discrepancyResults.map((item, index) => ({
//     key: index,
//     filterName: item.filterName,
//     table1DataCount: item.table1Data.length,
//     table2DataCount: item.table2Data.length,
//     table1DataExample: item.table1Data[0] ? item.table1Data[0].invoiceValue : 'N/A',
//     table2DataExample: item.table2Data[0] ? item.table2Data[0].invoiceValue : 'N/A',
//     table1DataParticulars: item.table1Data[0] ? item.table1Data[0].particulars : 'N/A',
//     table2DataParticulars: item.table2Data[0] ? item.table2Data[0].particulars : 'N/A',
//   })).concat([{
//     key: 'total',
//     filterName: 'Total',
//     table1DataCount: totalTable1DataCount, // use the state values that reflect only date filtering
//     table2DataCount: totalTable2DataCount,
//     // These fields might be irrelevant for the total row, so you could leave them out or set to 'N/A'
//     table1DataExample: 'N/A',
//     table2DataExample: 'N/A',
//     table1DataParticulars: 'N/A',
//     table2DataParticulars: 'N/A',
//   }]);

//   const { user, isLoaded } = useUser();
//   const [userDataFromFirstCall, setUserDataFromFirstCall] = useState(null);
//   const [apiCallCounter, setApiCallCounter] = useState(0);
//   const [shouldStopCalls, setShouldStopCalls] = useState(false);
//   const [timeoutId, setTimeoutId] = useState(null);

//   useEffect(() => {
//     if (isLoaded && user) {
//       console.log('Logging in user with:', {
//         username: user.username,
//         email: user.primaryEmailAddress.emailAddress,
//       });
//       handleUserLogin(user);
//       setUserEmail(user.primaryEmailAddress.emailAddress)
//     }

//     if (userDataFromFirstCall && !shouldStopCalls) {
//       const newTimeoutId = setTimeout(() => {
//         handleUserLogin(user);
//       }, 5);
//       setTimeoutId(newTimeoutId);
//     }

//     return () => {
//       if (timeoutId) {
//         clearTimeout(timeoutId);
//       }
//     };
//   }, [isLoaded, user, userDataFromFirstCall, shouldStopCalls]);

//   const handleUserLogin = async (user) => {
//     if (!user || shouldStopCalls) return;

//     const userId = uuidv4();
//     const username = user.username || user.primaryEmailAddress.emailAddress.split('@')[0];
//     const email = user.primaryEmailAddress.emailAddress;

//     console.log('userEmail set in Gst component:', email);
//     if (!email) {
//       console.error('Email is missing from the user object');
//       return;
//     }

//     try {
//       const { data: existingUsers, error: getError } = await supabase
//         .from('users_talllymatic')
//         .select('*')
//         .eq('email', email);

//       if (getError) {
//         console.error('Error fetching user data:', getError);
//         return;
//       }

//       if (existingUsers.length > 0) {
//         console.log('User already exists:', existingUsers[0]);
//         setUserDataFromFirstCall(existingUsers[0]);
//       } else {
//         const { error: insertError } = await supabase
//           .from('users_talllymatic')
//           .insert([{ id: userId, username, email, token: 10 }]);

//         if (insertError) {
//           console.error('Error creating new user:', insertError);
//           return;
//         }

//         await new Promise(resolve => setTimeout(resolve, 5));

//         const { data: newUser, error: getNewUserError } = await supabase
//           .from('users_talllymatic')
//           .select('*')
//           .eq('id', userId);

//         if (getNewUserError) {
//           console.error('Error fetching new user data:', getNewUserError);
//         } else if (newUser.length > 0) {
//           console.log('New user created:', newUser[0]);
//           setUserDataFromFirstCall(newUser[0]);
//           setShowAdditionalInfoForm(true);
//         } else {
//           const { data: existingUser, error: getExistingUserError } = await supabase
//             .from('users_talllymatic')
//             .select('*')
//             .eq('email', email);

//           if (getExistingUserError) {
//             console.error('Error fetching existing user data:', getExistingUserError);
//           } else {
//             console.log('User already exists:', existingUser[0]);
//             setUserDataFromFirstCall(existingUser[0]);
//           }
//         }
//       }

//       setApiCallCounter(prevCounter => prevCounter + 1);

//       if (apiCallCounter >= 2) {
//         setShouldStopCalls(true);
//       }
//     } catch (err) {
//       console.error('Error in user login:', err);
//     }
//   };

//   const handlePaymentSuccess = async () => {
//     const userId = userDataFromFirstCall.id;
//     const email = userDataFromFirstCall.email;

//     // Store the email in the database along with the user ID
//     const { error } = await supabase
//       .from('users_talllymatic')
//       .update({ email })
//       .eq('id', userId);

//     if (error) {
//       console.error('Error updating email in the database:', error);
//       return;
//     }

//     const paymentLink = `https://nyxsolution09.lemonsqueezy.com/buy/e4614b4e-333c-400d-b7f9-a4eb13ec2ae8?userId=${userId}&email=${encodeURIComponent(email)}`;

//     window.open(paymentLink, '_blank');
//   };

//   const tokenSpring = useSpring({
//     number: userDataFromFirstCall ? userDataFromFirstCall.token : 0,
//     from: { number: 0 }
//   });


//   const handlePayment = async () => {
//     try {
//       const res = await fetch(`${process.env.VITE_BACKEND_HOST_URL}/api/payment/order`, {
//         method: "POST",
//         headers: {
//           "content-type": "application/json"
//         },
//         body: JSON.stringify({
//           amount
//         })
//       });

//       const data = await res.json();
//       console.log(data);
//       handlePaymentVerify(data.data);
//     } catch (error) {
//       // handle error here, for example logging it
//       console.error(error);

//       // Assuming you still want to open the Razorpay widget in case of an error
//       const options = {
//         key: process.env.RAZORPAY_KEY_ID,
//         amount: 1000, // placeholder value since data is not defined here
//         currency: "INR", // placeholder value since data is not defined here
//         name: "Devknus",
//         description: "Test Mode",
//         order_id: "order_id_placeholder", // placeholder value since data is not defined here
//         handler: async (response) => {
//           console.log("response", response)
//           try {
//             const res = await fetch(`${process.env.VITE_BACKEND_HOST_URL}/api/payment/verify`, {
//               method: 'POST',
//               headers: {
//                 'content-type': 'application/json'
//               },
//               body: JSON.stringify({
//                 razorpay_order_id: response.razorpay_order_id,
//                 razorpay_payment_id: response.razorpay_payment_id,
//                 razorpay_signature: response.razorpay_signature,
//               })
//             });

//             const verifyData = await res.json();

//             if (verifyData.message) {
//               toast.success(verifyData.message);
//             }
//           } catch (verifyError) {
//             console.error(verifyError);
//           }
//         },
//         theme: {
//           color: "#5f63b8"
//         }
//       };
//       const rzp1 = new window.Razorpay(options);
//       rzp1.open();
//     }
//   }


//   // handlePaymentVerify Function
//   const handlePaymentVerify = async (data) => {
//     const options = {
//       key: ({}).RAZORPAY_KEY_ID,
//       amount: data.amount,
//       currency: data.currency,
//       name: "Devknus",
//       description: "Test Mode",
//       order_id: data.id,
//       handler: async (response) => {
//         console.log("response", response)
//         try {
//           const res = await fetch(`${({}).VITE_BACKEND_HOST_URL}/api/payment/verify`, {
//             method: 'POST',
//             headers: {
//               'content-type': 'application/json'
//             },
//             body: JSON.stringify({
//               razorpay_order_id: response.razorpay_order_id,
//               razorpay_payment_id: response.razorpay_payment_id,
//               razorpay_signature: response.razorpay_signature,
//             })
//           })

//           const verifyData = await res.json();

//           if (verifyData.message) {
//             toast.success(verifyData.message)
//           }
//         } catch (error) {
//           console.log(error);
//         }
//       },
//       theme: {
//         color: "#5f63b8"
//       }
//     };
//     const rzp1 = new window.Razorpay(options);
//     rzp1.open();
//   }

//   useEffect(() => {
//     console.log('email passing', userEmail)
//   }, [userEmail]);

//   const handleSignOut = async () => {
//     await signOut();
//     console.log('User has signed out.');
//     window.location.href = '/';
//   };

//   return (
//     <div>
//       <SignedIn>
//         {userDataFromFirstCall ? (
//           showAdditionalInfoForm ? (
//             <AdditionalInfoForm
//               user={user}
//               onSubmit={handleAdditionalInfoSubmit}
//             />
//           ) : (
//             <>
//               <div style={{ padding: '20px' }}>
//                 <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
//               <Toaster />

//                   <Link to={{ pathname: "/purchase", search: `?email=${encodeURIComponent(user?.primaryEmailAddress?.emailAddress || '')}` }}>

//                     <Button className="w-full bg-[#1B9CFC]">Purchase</Button>
//                   </Link>
//                   <Button onClick={handleSignOut}>
//                     Sign Out
//                   </Button>
//                 </div>
//                 <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
//                   <Tabs defaultActiveKey="1" type="card" style={{ flexGrow: 1 }}>
//                     <TabPane tab="Bank Statement" key="2">
//                       <Card style={{ marginBottom: '20px' }}>
//                         <BankStatement userData={userDataFromFirstCall} />
//                       </Card>
//                     </TabPane>
//                   </Tabs>
//                 </div>
//               </div>
//             </>
//           )
//         ) : (
//           <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
//             <Spin size="large" />
//           </div>
//         )}
//       </SignedIn>
//     </div>
//   );
// };

// export default Gst;

import React, { useState, useEffect, useRef } from 'react';
import { Table, Select, Input, Upload, message, Modal, Card, InputNumber, Statistic, Divider, Typography, Tabs, Spin } from 'antd';
import { UploadOutlined, SwapOutlined } from '@ant-design/icons';
import moment from 'moment';
import Papa from 'papaparse';
import * as XLSX from 'xlsx';
import { SignedIn, SignedOut, SignIn, SignUp, UserButton, SignOutButton, useUser } from '@clerk/clerk-react';
import BankStatement from './BankStatement';
import { v4 as uuidv4 } from 'uuid';
import { supabase } from './supabaseClient';
import AdditionalInfoForm from './AdditionalInfoForm';
import { useSpring, animated } from 'react-spring';
import PaymentModal from './PaymentModal';
import axios from 'axios';
import RazorpayForm from './RazorpayForm';
import toast, { Toaster } from "react-hot-toast";
import { Link, useNavigate } from 'react-router-dom';
import { useClerk } from '@clerk/clerk-react';
import styled from 'styled-components';
import Button from '@mui/material/Button';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { SearchOutlined } from '@ant-design/icons';
//import TallyMaticVideo from './TallyMaticVideo.mp4';
import Dialog from './Dialog';

import { position } from '@chakra-ui/react';

const { TabPane } = Tabs;
const { Option } = Select;

const discrepancyColors = {
  'File 1 - File 2 Mismatch': '#89CFF0',
  'File 2 - File 1 Mismatch': '#FDB9C8',
  'Only Date Mismatch': '#F3E5AB',
  'Only Invoice Amount Mismatch': '#A7FBB9',
  'Only Invoice Number Mismatch': '#CDB4DB',
  'All Fields Match': '#ACE1AF',
};


const Container = styled.div`
  background-color: white;
  color: #ffffff;
  font-family: 'Poppins', sans-serif;
  padding: 20px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;
`;

const Title = styled.h1`
  font-size: 32px;
  color: #007bff;
  background-color: rgb(0, 4, 29);
`;

const TableContainer = styled.div`
  margin-top: 20px;
  background-color: white;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  z-index: 2;
`;

const columns = [
  {
    title: 'GST Number',
    dataIndex: 'gstNumber',
    key: 'gstNumber',
    render: (text, record) => {
      const backgroundColor = discrepancyColors[record.discrepancy] || 'transparent';
      return {
        children: text,
        props: {
          style: { background: backgroundColor },
        },
      };
    },
  },
  {
    title: 'Ledger',
    dataIndex: 'name',
    key: 'name',
    render: (text, record) => {
      const backgroundColor = discrepancyColors[record.discrepancy] || 'transparent';
      return {
        children: text,
        props: {
          style: { background: backgroundColor },
        },
      };
    },
  },
  {
    title: 'Date',
    dataIndex: 'date',
    key: 'date',
    render: (text, record) => {
      const backgroundColor = discrepancyColors[record.discrepancy] || 'transparent';
      return {
        children: moment(text).format('DD MMM YYYY'),
        props: {
          style: { background: backgroundColor },
        },
      };
    },
  },
  {
    title: 'Invoice Number',
    dataIndex: 'invoiceNumber',
    key: 'invoiceNumber',
    render: (text, record) => {
      const backgroundColor = discrepancyColors[record.discrepancy] || 'transparent';
      return {
        children: text,
        props: {
          style: { background: backgroundColor },
        },
      };
    },
  },
  {
    title: 'Taxable Value',
    dataIndex: 'taxableValue',
    key: 'taxableValue',
    render: (text, record) => {
      const backgroundColor = discrepancyColors[record.discrepancy] || 'transparent';
      return {
        children: text,
        props: {
          style: { background: backgroundColor },
        },
      };
    },
  },
];

const Gst = () => {
  const navigate = useNavigate();
  const { signOut } = useClerk();
  const [filters, setFilters] = useState({ name: '', startDate: '', endDate: '', discrepancyType: 'all' });
  const [table1Data, setTable1Data] = useState([]);
  const [table2Data, setTable2Data] = useState([]);
  const [discrepancyStats, setDiscrepancyStats] = useState(null);
  const [originalTable1Data, setOriginalTable1Data] = useState([]);
  const [originalTable2Data, setOriginalTable2Data] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [discrepancyResults, setDiscrepancyResults] = useState([]);
  const [uploadedFileName1, setUploadedFileName1] = useState('');
  const [uploadedFileName2, setUploadedFileName2] = useState('');
  const [filteredTable1DataCount, setFilteredTable1DataCount] = useState(0);
  const [filteredTable2DataCount, setFilteredTable2DataCount] = useState(0);
  const [totalTable1DataCount, setTotalTable1DataCount] = useState(0);
  const [totalTable2DataCount, setTotalTable2DataCount] = useState(0);
  const [showAdditionalInfoForm, setShowAdditionalInfoForm] = useState(false);
  const [tokenCount, setTokenCount] = useState(0);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [paymentLink, setPaymentLink] = useState('https://nyxsolution09.lemonsqueezy.com/buy/e4614b4e-333c-400d-b7f9-a4eb13ec2ae8');
  const razorpayContainerRef = useRef(null);
  const [userEmail, setUserEmail] = useState('')
  const [amount, setAmount] = useState(5000);
  const [userData, setUserData] = useState([]);
  const [showAdminPanel, setShowAdminPanel] = useState(false);

  const [dialogState, setDialogState] = useState({
    showToast: true,
    showTerms: false,
    showPrivacy: false,
    showRefund: false,
    showHowItWorks: false,
  });

  const toggleDialog = (dialog) => {
    setDialogState((prevState) => ({
      ...prevState,
      [dialog]: !prevState[dialog],
    }));
  };

  const handleAdditionalInfoSubmit = () => {
    setShowAdditionalInfoForm(false);
  };
  // const handleAdminPanel = async () => {
  //   try {
  //     const { data, error } = await supabase
  //       .from('users_talllymatic')
  //       .select('*');

  //     if (error) {
  //       console.error('Error fetching user data:', error);
  //       return;
  //     }

  //     setUserData(data);
  //     setShowAdminPanel(true);
  //   } catch (err) {
  //     console.error('Error in admin panel:', err);
  //   }
  // };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters({ ...filters, [name]: value });
  };

  const handleDiscrepancyFilterChange = (value) => {
    setFilters({ ...filters, discrepancyType: value });
  };

  const sortByDate = (a, b) => {
    const dateA = moment(a.date, "DD MMM YYYY");
    const dateB = moment(b.date, "DD MMM YYYY");

    if (dateA.isBefore(dateB)) return -1;
    if (dateA.isAfter(dateB)) return 1;
    return 0;
  };

  const handleFileUpload1 = (file) => {
    const fileReader = new FileReader();
    fileReader.onload = (e) => {
      const bufferArray = e.target.result;
      const wb = XLSX.read(bufferArray, { type: 'buffer' });

      const wsname = wb.SheetNames.includes("B2B") ? "B2B" : wb.SheetNames[0];
      const ws = wb.Sheets[wsname];

      const data = XLSX.utils.sheet_to_json(ws, {
        header: 1,
        range: 0,
        raw: false,
      });

      const possibleHeaders = {
        gstNumber: ['GSTIN', 'GSTIN of supplier'],
        name: ['Supplier Name', 'Trade/Legal name of the supplier'],
        invoiceNumber: ['Invoice No', 'Invoice Number'],
        date: ['Invoice Date'],
        taxableValue: ['Taxable Value', 'Taxable Value (â‚¹)'],
        period: ['Period', 'GSTR-1/IFF/GSTR-5 Period'],
        invoiceDetails: ['Invoice Details']
      };

      let headerRowIndex = data.findIndex(row =>
        row.some(cell => Object.values(possibleHeaders).flat().includes(cell))
      );

      const headers = data[headerRowIndex];
      let headerIndexMap = {};

      headers.forEach((header, index) => {
        Object.entries(possibleHeaders).forEach(([key, values]) => {
          if (values.includes(header)) {
            headerIndexMap[key] = index;
          }
        });
      });

      let formattedData = data.slice(headerRowIndex + 1)
        .filter(row => row.length)
        .map((row, i) => {
          let rowData = { key: i + 1 };
          Object.keys(headerIndexMap).forEach(key => {
            let cellValue = row[headerIndexMap[key]];

            if (key === 'invoiceDetails' && cellValue) {
              const detailsParts = cellValue.split(', ');
              rowData['invoiceNumber'] = detailsParts[0];
              const dateColumnIndex = headerIndexMap[key] + 2;
              let dateCellValue = row[dateColumnIndex];
              if (dateCellValue) {
                const [day, month, year] = dateCellValue.split('/');
                const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
                dateCellValue = `${day} ${monthNames[parseInt(month) - 1]} ${year}`;
              }
              rowData['date'] = dateCellValue;
            } else if (key === 'date' && cellValue) {
              if (rowData['period']) {
                const periodParts = rowData['period'].split(', ');
                const monthName = periodParts[0].trim();
                const year = periodParts[1].trim();
                const [month, day, yearShort] = cellValue.split('/');
                const dayPadded = day.padStart(2, '0');
                const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
                const monthAbbreviation = monthNames[parseInt(month) - 1];
                const yearCorrected = `20${yearShort}`;
                cellValue = `${dayPadded} ${monthAbbreviation} ${year}`;
              } else {
                const [day, month, year] = cellValue.split('/');
                const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
                cellValue = `${day} ${monthNames[parseInt(month) - 1]} ${year}`;
              }
              rowData['date'] = cellValue;
            } else if (key !== 'invoiceDetails') {
              rowData[key] = cellValue;
            }
          });
          return rowData;
        });

      const finalData = formattedData.filter(rowData => {
        return !(rowData.invoiceNumber === "Invoice number" || rowData.date && rowData.date.includes("undefined"));
      });

      const sortedData = finalData.sort(sortByDate);
      setOriginalTable1Data(sortedData);
      setTable1Data(sortedData);
      message.success(`${file.name} file uploaded successfully.`);
      setUploadedFileName1(file.name);
    };

    fileReader.readAsArrayBuffer(file);
    return false;
  };

  const handleFileUpload2 = (file) => {
    const fileReader = new FileReader();
    fileReader.onload = (e) => {
      const bufferArray = e.target.result;
      const wb = XLSX.read(bufferArray, { type: 'buffer' });
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];

      const data = XLSX.utils.sheet_to_json(ws, {
        header: 1,
        range: 0,
        raw: false,
      });

      const possibleHeaders = {
        gstNumber: ['GSTIN/UIN', 'SupplierGSTIN/UIN', 'Supplier GSTIN/UIN', 'Supplier'],
        name: ['Particulars', 'Supplier Name'],
        invoiceNumber: ['Invoice No.', 'InvoiceNo', 'Vch No.'],
        date: ['Invoice Date', 'Date', 'Invoice Date'],
        taxableValue: ['Taxable Value', 'Total Taxable Amount', 'Taxable', 'Taxable Amount', 'Total Taxable'],
      };

      let headerRowIndex = data.findIndex(row =>
        row.some(cell => Object.values(possibleHeaders).flat().includes(cell?.toString()))
      );

      if (headerRowIndex === -1) {
        console.error("No header row found, please check the file format.");
        return;
      }

      let headers = data[headerRowIndex];
      let subHeaders = data[headerRowIndex + 1] || [];

      headers = headers.map((header, i) => subHeaders[i] ? `${header} ${subHeaders[i]}`.trim() : header);

      let headerIndexMap = {};
      headers.forEach((header, index) => {
        Object.entries(possibleHeaders).forEach(([key, values]) => {
          if (values.some(val => header.includes(val))) {
            headerIndexMap[key] = index;
          }
        });
      });

      let formattedData = data.slice(headerRowIndex + 2)
        .filter(row => row.some(cell => cell !== null && cell !== undefined))
        .map((row, i) => {
          let rowData = { key: i + 1 };
          Object.keys(headerIndexMap).forEach(key => {
            rowData[key] = row[headerIndexMap[key]];
          });
          return rowData;
        })
        .filter(row => row.name !== 'Grand Total');

      const sortedData = formattedData.sort(sortByDate);
      setOriginalTable2Data(sortedData);
      setTable2Data(sortedData);
      message.success(`${file.name} file uploaded successfully.`);
      setUploadedFileName2(file.name);
    };

    fileReader.readAsArrayBuffer(file);
    return false;
  };

  useEffect(() => {
    if (filters.discrepancyType === 'all') {
      setTable1Data(originalTable1Data);
      setTable2Data(originalTable2Data);
    }
  }, [filters.discrepancyType, originalTable1Data, originalTable2Data]);

  const handleFileChange1 = (event) => {
    const file = event.target.files[0];
    if (file) {
      handleFileUpload1(file);
    }
    event.target.value = null;
  };

  const handleFileChange2 = (event) => {
    const file = event.target.files[0];
    if (file) {
      handleFileUpload2(file);
    }
    event.target.value = null;
  };

  useEffect(() => {
    const filteredTable1Data = table1Data.filter(item =>
      (!filters.startDate || moment(item.date).isSameOrAfter(filters.startDate, 'day')) &&
      (!filters.endDate || moment(item.date).isSameOrBefore(filters.endDate, 'day'))
    );

    const filteredTable2Data = table2Data.filter(item =>
      (!filters.startDate || moment(item.date).isSameOrAfter(filters.startDate, 'day')) &&
      (!filters.endDate || moment(item.date).isSameOrBefore(filters.endDate, 'day'))
    );

    console.log("Filtered Table 1 Data based on date range:", filteredTable1Data);
    console.log("Filtered Table 2 Data based on date range:", filteredTable2Data);

  }, [filters.startDate, filters.endDate, table1Data, table2Data]);

  useEffect(() => {
    const filteredTable1Data = originalTable1Data.filter(item =>
      (!filters.startDate || moment(item.date).isSameOrAfter(filters.startDate, 'day')) &&
      (!filters.endDate || moment(item.date).isSameOrBefore(filters.endDate, 'day'))
    );

    const filteredTable2Data = originalTable2Data.filter(item =>
      (!filters.startDate || moment(item.date).isSameOrAfter(filters.startDate, 'day')) &&
      (!filters.endDate || moment(item.date).isSameOrBefore(filters.endDate, 'day'))
    );

    setTotalTable1DataCount(filteredTable1Data.length);
    setTotalTable2DataCount(filteredTable2Data.length);

  }, [filters.startDate, filters.endDate, originalTable1Data, originalTable2Data]);

  const { user, isLoaded } = useUser();
  const [userDataFromFirstCall, setUserDataFromFirstCall] = useState(null);
  const [apiCallCounter, setApiCallCounter] = useState(0);
  const [shouldStopCalls, setShouldStopCalls] = useState(false);
  const [timeoutId, setTimeoutId] = useState(null);

  useEffect(() => {
    if (isLoaded && user) {
      handleUserLogin(user);
      setUserEmail(user.primaryEmailAddress.emailAddress)
    }

    if (userDataFromFirstCall && !shouldStopCalls) {
      const newTimeoutId = setTimeout(() => {
        handleUserLogin(user);
      }, 5);
      setTimeoutId(newTimeoutId);
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [isLoaded, user, userDataFromFirstCall, shouldStopCalls]);


  // const handleUserLogin = async (user) => {
  //   if (!user || shouldStopCalls) return;

  //   const userId = uuidv4();
  //   const username = user.username || user.primaryEmailAddress.emailAddress.split('@')[0];
  //   const email = user.primaryEmailAddress.emailAddress;

  //   console.log('userEmail set in Gst component:', email);
  //   if (!email) {
  //     console.error('Email is missing from the user object');
  //     return;
  //   }

  //   try {
  //     const { data: existingUsers, error: getError } = await supabase
  //       .from('users_talllymatic')
  //       .select('*')
  //       .eq('email', email);

  //     if (getError) {
  //       console.error('Error fetching user data:', getError);
  //       return;
  //     }

  //     if (existingUsers.length > 0) {
  //       console.log('User already exists:', existingUsers[0]);
  //       setUserDataFromFirstCall(existingUsers[0]);

  //       // Update the latest_login_date for the existing user
  //       const { error: updateError } = await supabase
  //         .from('users_talllymatic')
  //         .update({ latest_login_date: new Date().toISOString() })
  //         .eq('email', email);

  //       if (updateError) {
  //         console.error('Error updating latest_login_date:', updateError);
  //       }
  //     } else {
  //       const { error: insertError } = await supabase
  //         .from('users_talllymatic')
  //         .insert([{
  //           id: userId,
  //           username,
  //           email,
  //           token: 10,
  //           latest_login_date: new Date().toISOString(),
  //           created_at: new Date().toISOString() // Set the created_at field
  //         }]);

  //       if (insertError) {
  //         console.error('Error creating new user:', insertError);
  //         return;
  //       }

  //       await new Promise(resolve => setTimeout(resolve, 5));

  //       const { data: newUser, error: getNewUserError } = await supabase
  //         .from('users_talllymatic')
  //         .select('*')
  //         .eq('id', userId);

  //       if (getNewUserError) {
  //         console.error('Error fetching new user data:', getNewUserError);
  //       } else if (newUser.length > 0) {
  //         console.log('New user created:', newUser[0]);
  //         setUserDataFromFirstCall(newUser[0]);
  //         setShowAdditionalInfoForm(true);
  //       } else {
  //         const { data: existingUser, error: getExistingUserError } = await supabase
  //           .from('users_talllymatic')
  //           .select('*')
  //           .eq('email', email);

  //         if (getExistingUserError) {
  //           console.error('Error fetching existing user data:', getExistingUserError);
  //         } else {
  //           console.log('User already exists:', existingUser[0]);
  //           setUserDataFromFirstCall(existingUser[0]);
  //         }
  //       }
  //     }

  //     setApiCallCounter(prevCounter => prevCounter + 1);

  //     if (apiCallCounter >= 2) {
  //       setShouldStopCalls(true);
  //     }
  //     return <Link to="/gst" />;

  //   } catch (err) {
  //     console.error('Error in user login:', err);
  //   }
  // };
  const handleUserLogin = async (user) => {
    if (!user || shouldStopCalls) return;

    const userId = uuidv4();
    const username = user.username || user.primaryEmailAddress.emailAddress.split('@')[0];
    const email = user.primaryEmailAddress.emailAddress;

    console.log('userEmail set in Gst component:', email);
    if (!email) {
      console.error('Email is missing from the user object');
      return;
    }

    try {
      const { data: existingUsers, error: getError } = await supabase
        .from('users_talllymatic')
        .select('*')
        .eq('email', email);

      if (getError) {
        console.error('Error fetching user data:', getError);
        return;
      }

      if (existingUsers.length > 0) {
        console.log('User already exists:', existingUsers[0]);
        setUserDataFromFirstCall(existingUsers[0]);

        // Update the latest_login_date for the existing user
        const { error: updateError } = await supabase
          .from('users_talllymatic')
          .update({ latest_login_date: new Date().toISOString() })
          .eq('email', email);

        if (updateError) {
          console.error('Error updating latest_login_date:', updateError);
        }
      } else {
        const { error: insertError } = await supabase
          .from('users_talllymatic')
          .insert([{
            id: userId,
            username,
            email,
            token: 10,
            is_paid: false,
            purchase_date: null,
            latest_login_date: new Date().toISOString(),
            created_at: new Date().toISOString()
          }]);

        if (insertError) {
          console.error('Error creating new user:', insertError);
          return;
        }

        await new Promise(resolve => setTimeout(resolve, 5));

        const { data: newUser, error: getNewUserError } = await supabase
          .from('users_talllymatic')
          .select('*')
          .eq('id', userId);

        if (getNewUserError) {
          console.error('Error fetching new user data:', getNewUserError);
        } else if (newUser.length > 0) {
          console.log('New user created:', newUser[0]);
          setUserDataFromFirstCall(newUser[0]);
          setShowAdditionalInfoForm(true);
        } else {
          const { data: existingUser, error: getExistingUserError } = await supabase
            .from('users_talllymatic')
            .select('*')
            .eq('email', email);

          if (getExistingUserError) {
            console.error('Error fetching existing user data:', getExistingUserError);
          } else {
            console.log('User already exists:', existingUser[0]);
            setUserDataFromFirstCall(existingUser[0]);
          }
        }
      }

      setApiCallCounter(prevCounter => prevCounter + 1);

      if (apiCallCounter >= 2) {
        setShouldStopCalls(true);
      }
      return <Link to="/gst" />;

    } catch (err) {
      console.error('Error in user login:', err);
    }
  };

  // const handleUserLogin = async (user) => {
  //   if (!user || shouldStopCalls) return;

  //   const userId = uuidv4();
  //   const username = user.username || user.primaryEmailAddress.emailAddress.split('@')[0];
  //   const email = user.primaryEmailAddress.emailAddress;

  //   console.log('userEmail set in Gst component:', email);
  //   if (!email) {
  //     console.error('Email is missing from the user object');
  //     return;
  //   }

  //   try {
  //     const { data: existingUsers, error: getError } = await supabase
  //       .from('users_talllymatic')
  //       .select('*')
  //       .eq('email', email);

  //     if (getError) {
  //       console.error('Error fetching user data:', getError);
  //       return;
  //     }

  //     if (existingUsers.length > 0) {
  //       console.log('User already exists:', existingUsers[0]);
  //       setUserDataFromFirstCall(existingUsers[0]);

  //       // Update the latest_login_date for the existing user
  //       const { error: updateError } = await supabase
  //         .from('users_talllymatic')
  //         .update({ latest_login_date: new Date().toISOString() })
  //         .eq('email', email);

  //       if (updateError) {
  //         console.error('Error updating latest_login_date:', updateError);
  //       }
  //     } else {
  //       const { error: insertError } = await supabase
  //         .from('users_talllymatic')
  //         .insert([{ id: userId, username, email, token: 10, latest_login_date: new Date().toISOString() }]);

  //       if (insertError) {
  //         console.error('Error creating new user:', insertError);
  //         return;
  //       }

  //       await new Promise(resolve => setTimeout(resolve, 5));

  //       const { data: newUser, error: getNewUserError } = await supabase
  //         .from('users_talllymatic')
  //         .select('*')
  //         .eq('id', userId);

  //       if (getNewUserError) {
  //         console.error('Error fetching new user data:', getNewUserError);
  //       } else if (newUser.length > 0) {
  //         console.log('New user created:', newUser[0]);
  //         setUserDataFromFirstCall(newUser[0]);
  //         setShowAdditionalInfoForm(true);
  //       } else {
  //         const { data: existingUser, error: getExistingUserError } = await supabase
  //           .from('users_talllymatic')
  //           .select('*')
  //           .eq('email', email);

  //         if (getExistingUserError) {
  //           console.error('Error fetching existing user data:', getExistingUserError);
  //         } else {
  //           console.log('User already exists:', existingUser[0]);
  //           setUserDataFromFirstCall(existingUser[0]);
  //         }
  //       }
  //     }

  //     setApiCallCounter(prevCounter => prevCounter + 1);

  //     if (apiCallCounter >= 2) {
  //       setShouldStopCalls(true);
  //     }
  //     return <Link to="/gst" />;

  //   } catch (err) {
  //     console.error('Error in user login:', err);
  //   }
  // };

  const handlePaymentSuccess = async () => {
    const userId = userDataFromFirstCall.id;
    const email = userDataFromFirstCall.email;

    const { error } = await supabase
      .from('users_talllymatic')
      .update({ email })
      .eq('id', userId);

    if (error) {
      console.error('Error updating email in the database:', error);
      return;
    }

    const paymentLink = `https://nyxsolution09.lemonsqueezy.com/buy/e4614b4e-333c-400d-b7f9-a4eb13ec2ae8?userId=${userId}&email=${encodeURIComponent(email)}`;

    window.open(paymentLink, '_blank');
  };

  useEffect(() => {
    if (isLoaded && user) {
      handleUserLogin(user);
      setUserEmail(user.primaryEmailAddress.emailAddress);
    }

  }, [isLoaded, user, userDataFromFirstCall, shouldStopCalls]);

  const tokenSpring = useSpring({
    number: userDataFromFirstCall ? userDataFromFirstCall.token : 0,
    from: { number: 0 }
  });

  const handlePayment = async () => {
    try {
      const res = await fetch(`${process.env.VITE_BACKEND_HOST_URL}/api/payment/order`, {
        method: "POST",
        headers: {
          "content-type": "application/json"
        },
        body: JSON.stringify({
          amount
        })
      });

      const data = await res.json();
      handlePaymentVerify(data.data);
    } catch (error) {
      console.error(error);
      const options = {
        key: process.env.RAZORPAY_KEY_ID,
        amount: 1000,
        currency: "INR",
        name: "Devknus",
        description: "Test Mode",
        order_id: "order_id_placeholder",
        handler: async (response) => {
          try {
            const res = await fetch(`${process.env.VITE_BACKEND_HOST_URL}/api/payment/verify`, {
              method: 'POST',
              headers: {
                'content-type': 'application/json'
              },
              body: JSON.stringify({
                razorpay_order_id: response.razorpay_order_id,
                razorpay_payment_id: response.razorpay_payment_id,
                razorpay_signature: response.razorpay_signature,
              })
            });

            const verifyData = await res.json();

            if (verifyData.message) {
              toast.success(verifyData.message);
            }
          } catch (verifyError) {
            console.error(verifyError);
          }
        },
        theme: {
          color: "#5f63b8"
        }
      };
      const rzp1 = new window.Razorpay(options);
      rzp1.open();
    }
  }

  const handlePaymentVerify = async (data) => {
    const options = {
      key: process.env.RAZORPAY_KEY_ID,
      amount: data.amount,
      currency: data.currency,
      name: "Devknus",
      description: "Test Mode",
      order_id: data.id,
      handler: async (response) => {
        try {
          const res = await fetch(`${process.env.VITE_BACKEND_HOST_URL}/api/payment/verify`, {
            method: 'POST',
            headers: {
              'content-type': 'application/json'
            },
            body: JSON.stringify({
              razorpay_order_id: response.razorpay_order_id,
              razorpay_payment_id: response.razorpay_payment_id,
              razorpay_signature: response.razorpay_signature,
            })
          });

          const verifyData = await res.json();

          if (verifyData.message) {
            toast.success(verifyData.message);
          }
        } catch (error) {
          console.log(error);
        }
      },
      theme: {
        color: "#5f63b8"
      }
    };
    const rzp1 = new window.Razorpay(options);
    rzp1.open();
  }

  const handleSignOut = async () => {
    await signOut();
    window.location.href = '/';
  };

  const AdminPanel = ({ userData, onTokenChange }) => {
    const columns = [
      {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
          <div style={{ padding: 8 }}>
            <Input
              placeholder="Search email"
              value={selectedKeys[0]}
              onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={() => confirm()}
              style={{ width: 188, marginBottom: 8, display: 'block' }}
            />
            <Button
              type="primary"
              onClick={() => confirm()}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90, marginRight: 8 }}
            >
              Search
            </Button>
            <Button onClick={() => {
              clearFilters();
              confirm();
            }} size="small" style={{ width: 90 }}>
              Reset
            </Button>
          </div>
        ),
        filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) => record.email.toLowerCase().includes(value.toLowerCase()),
      },
      {
        title: 'First Name',
        dataIndex: 'first_name',
        key: 'first_name',
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
          <div style={{ padding: 8 }}>
            <Input
              placeholder="Search first name"
              value={selectedKeys[0]}
              onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={() => confirm()}
              style={{ width: 188, marginBottom: 8, display: 'block' }}
            />
            <Button
              type="primary"
              onClick={() => confirm()}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90, marginRight: 8 }}
            >
              Search
            </Button>
            <Button onClick={() => {
              clearFilters();
              confirm();
            }} size="small" style={{ width: 90 }}>
              Reset
            </Button>
          </div>
        ),
        filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) => record.first_name && record.first_name.toLowerCase().includes(value.toLowerCase()),
      },
      {
        title: 'Last Name',
        dataIndex: 'last_name',
        key: 'last_name',
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
          <div style={{ padding: 8 }}>
            <Input
              placeholder="Search last name"
              value={selectedKeys[0]}
              onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={() => confirm()}
              style={{ width: 188, marginBottom: 8, display: 'block' }}
            />
            <Button
              type="primary"
              onClick={() => confirm()}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90, marginRight: 8 }}
            >
              Search
            </Button>
            <Button onClick={() => {
              clearFilters();
              confirm();
            }} size="small" style={{ width: 90 }}>
              Reset
            </Button>
          </div>
        ),
        filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) => record.last_name && record.last_name.toLowerCase().includes(value.toLowerCase()),
      },
      {
        title: 'Phone Number',
        dataIndex: 'phone_number',
        key: 'phone_number',
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
          <div style={{ padding: 8 }}>
            <Input
              placeholder="Search phone number"
              value={selectedKeys[0]}
              onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={() => confirm()}
              style={{ width: 188, marginBottom: 8, display: 'block' }}
            />
            <Button
              type="primary"
              onClick={() => confirm()}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90, marginRight: 8 }}
            >
              Search
            </Button>
            <Button onClick={() => {
              clearFilters();
              confirm();
            }} size="small" style={{ width: 90 }}>
              Reset
            </Button>
          </div>
        ),
        filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
          record.phone_number &&
          record.phone_number.toString().toLowerCase().includes(value.toLowerCase()),
      },
      {
        title: 'Token',
        dataIndex: 'token',
        key: 'token',
        render: (text, record) => (
          <InputNumber
            value={text}
            onChange={(value) => onTokenChange(record.id, value)}
          />
        ),
      },
    ];

    return <Table dataSource={userData} columns={columns} />;
  };
  const handleCloseAdminPanel = () => {
    setShowAdminPanel(false);
  };

  const fetchUserData = async () => {
    try {
      const { data, error } = await supabase.from('users_talllymatic').select('*');

      if (error) {
        console.error('Error fetching user data:', error);
        return;
      }

      setUserData(data);
    } catch (err) {
      console.error('Error fetching user data:', err);
    }
  };

  const handleTokenChange = async (userId, value) => {
    try {
      const { error } = await supabase
        .from('users_talllymatic')
        .update({ token: value })
        .eq('id', userId);

      if (error) {
        console.error('Error updating token:', error);
        return;
      }

      await fetchUserData();
    } catch (err) {
      console.error('Error updating token:', err);
    }
  };

  return (
    <Container>
      <SignedIn>
        {userDataFromFirstCall ? (
          showAdditionalInfoForm ? (
            <AdditionalInfoForm
              user={user}
              onSubmit={handleAdditionalInfoSubmit}
            />
          ) : (
            <>
              <Header>
                <Title></Title>
                <ButtonContainer>
                  <Link to={{ pathname: "/purchase", search: `?email=${encodeURIComponent(user?.primaryEmailAddress?.emailAddress || '')}` }}>
                    <Button style={{ border: '1px solid #f0f0f0', backgroundColor:'#f0f0f0', right:'35px' }} className="w-full bg-[#1B9CFC]">Purchase</Button>
                  </Link>
                  {userEmail === 'solutionnyx@gmail.com' && (
                    <Link to="/Adm1n_P@n3l_53cUr3_9x7Q!z">
                      <Button style={{ border: '1px solid #f0f0f0', backgroundColor:'#f0f0f0', right:'25px' }} className="w-full bg-[#1B9CFC]">Admin Panel</Button>
                    </Link>
                  )}
                  <Button style={{ border: '1px solid #f0f0f0', backgroundColor:'#f0f0f0', right:'15px' }} onClick={() => toggleDialog('showHowItWorks')}>
                    How It Works?
                  </Button>
                  <Button style={{ border: '1px solid #f0f0f0', backgroundColor:'#f0f0f0',right:'5px' }} onClick={handleSignOut}>
                    Sign Out
                  </Button>
                </ButtonContainer>
              </Header>
              <TableContainer>
                <Tabs style={{ backgroundColor: 'white', position: 'relative', top: '-70px' }} defaultActiveKey="1" type="card">
                  <TabPane tab="Bank Statement" key="2">
                    <Card style={{ marginBottom: '20px', backgroundColor: '#f9fafb' }}>
                      <BankStatement userData={userDataFromFirstCall} />
                    </Card>
                  </TabPane>
                </Tabs>
              </TableContainer>
            </>
          )
        ) : (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
            <Spin size="large" />
          </div>
        )}
      </SignedIn>
      <Toaster />
      <Dialog isOpen={dialogState.showHowItWorks} onClose={() => toggleDialog('showHowItWorks')} title="How It Works?">
        <div style={{ textAlign: 'center' }}>
        <iframe width="100%" height="315" src="https://www.youtube.com/embed/CdmbipAzaBU" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
      </Dialog>
    </Container>
  );
};

export default Gst;
