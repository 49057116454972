// import React from 'react';
// import WAI1 from './users/WAI1.jpeg';
// import WAI2 from './users/WAI2.jpeg';
// import WAI3 from './users/WAI3.jpeg';
// import WAI4 from './users/WAI4.jpeg';
// import WAI5 from './users/WAI5.jpeg';
// import WAI6 from './users/WAI6.jpeg';
// import WAI7 from './users/WAI7.jpeg';
// import WAI8 from './users/WAI8.jpeg';
// import WAI9 from './users/WAI9.jpeg';

// const testimonialsData = [
//   {
//     name: 'Sarthak',
//     review: `TallyMatic is a game-changer for my accounting needs! The automatic conversion of UPI sales and bank entries into Tally, along with detailed narrations, has made my work so much simpler and faster. It’s a must-have tool for anyone looking to streamline their accounting process. 😊`,
//     color: 'purple',
//     image: WAI1,
//   },
//   {
//     name: 'Yash',
//     review: 'Using TallyMatic has revolutionized how I handle my finances. The comprehensive conversion feature is fantastic—it effortlessly manages UPI sales, debits, credits, and contra entries. The automatic narrations provide context that makes reviewing transactions a breeze.',
//     color: 'green',
//     image: WAI2,
//   },
//   {
//     name: 'Shubham',
//     review: 'TallyMatic has made managing my accounts so much easier. The instant conversion of transactions into Tally saves me hours of manual work, and the detailed descriptions ensure that everything is accurately documented. Highly recommend it for any business!',
//     color: 'orange',
//     image: WAI3,
//   },
//   {
//     name: 'Shilpa',
//     review: 'I love how TallyMatic simplifies my accounting tasks. The user-friendly interface and the ability to convert months of transactions into Tally in seconds are impressive. The automatic narration adds valuable details that help me keep my records organized and clear.',
//     color: 'blue',
//     image: WAI4,
//   },
//   {
//     name: 'Yash G',
//     review: 'TallyMatic is an incredible tool that has transformed my accounting practices. I no longer worry about manual entry errors or spending countless hours on bookkeeping. The automatic narration and conversion features are just what I needed to boost my productivity.',
//     color: 'red',
//     image: WAI5,
//   },
//   {
//     name: 'Mark',
//     review: 'TallyMatic has become an essential part of my accounting toolkit. The time I save with its automatic transaction conversion and error-free entries is invaluable. The clarity provided by the automatic narration makes it my favorite accounting solution by far!',
//     color: 'teal',
//     image: WAI6,
//   },
//   {
//     name: 'Aditi',
//     review: `Managing my accounts used to be a daunting task until I discovered TallyMatic. Its ability to convert years of transactions in seconds and add detailed narrations has made my accounting work so much more efficient. I can't imagine going back to manual entries!`,
//     color: 'brown',
//     image: WAI7,
//   },
//   {
//     name: 'Ria',
//     review: 'As a financial consultant, TallyMatic has been a revelation. It simplifies my workflow by handling complex transactions with ease and adding comprehensive narrations. This tool is a must-have for anyone serious about accurate and efficient accounting.',
//     color: 'cyan',
//     image: WAI8,
//   },
//   {
//     name: 'Shreya',
//     review: 'TallyMatic is an amazing tool that has taken the hassle out of my accounting tasks. The automatic entry conversions and detailed narrations have not only saved me time but also enhanced the accuracy of my financial records. Don’t hesitate to give it a try!',
//     color: 'pink',
//     image: WAI9,
//   },
// ];

// const Testimonials = () => {
//   const containerStyle = {
//     display: 'flex',
//     justifyContent: 'center',
//     alignItems: 'flex-start',
//     //margin: '20px 0',
//   };

//   const columnStyle = {
//     display: 'flex',
//     flexDirection: 'column',
//     alignItems: 'center',
//     margin: '0 10px',
//   };

//   const cardStyle = {
//     backgroundColor: '#fff',
//     borderRadius: '8px',
//     padding: '20px',
//     boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
//     textAlign: 'left',
//     margin: '10px',
//     width: '250px',
//   };

//   const imageStyle = {
//     width: '50px',
//     height: '50px',
//     borderRadius: '50%',
//     marginRight: '10px',
//   };

//   const nameContainerStyle = {
//     display: 'flex',
//     alignItems: 'center',
//     marginBottom: '10px',
//   };

//   const nameStyle = {
//     fontSize: '16px',
//     fontWeight: 'bold',
//   };

//   const reviewStyle = {
//     fontSize: '14px',
//     color: '#666',
//   };

//   const renderColumn = (startIndex, endIndex) => (
//     <div style={columnStyle}>
//       {testimonialsData.slice(startIndex, endIndex).map((testimonial, index) => (
//         <div key={index} style={cardStyle}>
//           <div style={nameContainerStyle}>
//             <img
//               src={testimonial.image}
//               alt={testimonial.name}
//               style={imageStyle}
//             />
//             <div style={{ ...nameStyle, color: testimonial.color }}>{testimonial.name}</div>
//           </div>
//           <div style={reviewStyle}>{testimonial.review}</div>
//         </div>
//       ))}
//     </div>
//   );

//   return (
//     <div style={containerStyle}>
//       {renderColumn(0, 3)}
//       {renderColumn(3, 6)}
//       {renderColumn(6, 9)}
//     </div>
//   );
// };

// export default Testimonials;

// import React from 'react';
// import WAI1 from './users/WAI1.jpeg';
// import WAI2 from './users/WAI2.jpeg';
// import WAI3 from './users/WAI3.jpeg';
// import WAI4 from './users/WAI4.jpeg';
// import WAI5 from './users/WAI5.jpeg';
// import WAI6 from './users/WAI6.jpeg';
// import WAI7 from './users/WAI7.jpeg';
// import WAI8 from './users/WAI8.jpeg';
// import WAI9 from './users/WAI9.jpeg';

// const testimonialsData = [
//   {
//     name: 'Sarthak',
//     review: `TallyMatic is a game-changer for my accounting needs! The automatic conversion of UPI sales and bank entries into Tally, along with detailed narrations, has made my work so much simpler and faster. It’s a must-have tool for anyone looking to streamline their accounting process. 😊`,
//     color: 'purple',
//     image: WAI1,
//   },
//   {
//     name: 'Yash',
//     review: 'Using TallyMatic has revolutionized how I handle my finances. The comprehensive conversion feature is fantastic—it effortlessly manages UPI sales, debits, credits, and contra entries. The automatic narrations provide context that makes reviewing transactions a breeze.',
//     color: 'green',
//     image: WAI2,
//   },
//   {
//     name: 'Shubham',
//     review: 'TallyMatic has made managing my accounts so much easier. The instant conversion of transactions into Tally saves me hours of manual work, and the detailed descriptions ensure that everything is accurately documented. Highly recommend it for any business!',
//     color: 'orange',
//     image: WAI3,
//   },
// //   {
// //     name: 'Shilpa',
// //     review: 'I love how TallyMatic simplifies my accounting tasks. The user-friendly interface and the ability to convert months of transactions into Tally in seconds are impressive. The automatic narration adds valuable details that help me keep my records organized and clear.',
// //     color: 'blue',
// //     image: WAI4,
// //   },
// //   {
// //     name: 'Yash G',
// //     review: 'TallyMatic is an incredible tool that has transformed my accounting practices. I no longer worry about manual entry errors or spending countless hours on bookkeeping. The automatic narration and conversion features are just what I needed to boost my productivity.',
// //     color: 'red',
// //     image: WAI5,
// //   },
// //   {
// //     name: 'Mark',
// //     review: 'TallyMatic has become an essential part of my accounting toolkit. The time I save with its automatic transaction conversion and error-free entries is invaluable. The clarity provided by the automatic narration makes it my favorite accounting solution by far!',
// //     color: 'teal',
// //     image: WAI6,
// //   },
// //   {
// //     name: 'Aditi',
// //     review: `Managing my accounts used to be a daunting task until I discovered TallyMatic. Its ability to convert years of transactions in seconds and add detailed narrations has made my accounting work so much more efficient. I can't imagine going back to manual entries!`,
// //     color: 'brown',
// //     image: WAI7,
// //   },
// //   {
// //     name: 'Ria',
// //     review: 'As a financial consultant, TallyMatic has been a revelation. It simplifies my workflow by handling complex transactions with ease and adding comprehensive narrations. This tool is a must-have for anyone serious about accurate and efficient accounting.',
// //     color: 'cyan',
// //     image: WAI8,
// //   },
// //   {
// //     name: 'Shreya',
// //     review: 'TallyMatic is an amazing tool that has taken the hassle out of my accounting tasks. The automatic entry conversions and detailed narrations have not only saved me time but also enhanced the accuracy of my financial records. Don’t hesitate to give it a try!',
// //     color: 'pink',
// //     image: WAI9,
// //   },
// ];

// const Testimonials = () => {
//   const containerStyle = {
//     display: 'flex',
//     justifyContent: 'center',
//     alignItems: 'flex-start',
//     flexWrap: 'wrap',
//     margin: '20px 0',
//   };

//   const columnStyle = {
//     display: 'flex',
//     flexDirection: 'column',
//     alignItems: 'center',
//     margin: '0 10px',

//     '@media (max-width: 768px)': {
//       width: '100%',
//       margin: '10px 0',
//     },
//   };

//   const cardStyle = {
//     backgroundColor: '#fff',
//     borderRadius: '8px',
//     padding: '20px',
//     boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
//     textAlign: 'left',
//     margin: '10px',
//     width: '250px',

//     '@media (max-width: 768px)': {
//       width: '90%',
//     },
//   };

//   const imageStyle = {
//     width: '50px',
//     height: '50px',
//     borderRadius: '50%',
//     marginRight: '10px',
//   };

//   const nameContainerStyle = {
//     display: 'flex',
//     alignItems: 'center',
//     marginBottom: '10px',
//   };

//   const nameStyle = {
//     fontSize: '16px',
//     fontWeight: 'bold',
//   };

//   const reviewStyle = {
//     fontSize: '14px',
//     color: '#666',
//   };

//   const renderColumn = (startIndex, endIndex) => (
//     <div style={columnStyle}>
//       {testimonialsData.slice(startIndex, endIndex).map((testimonial, index) => (
//         <div key={index} style={cardStyle}>
//           <div style={nameContainerStyle}>
//             <img
//               src={testimonial.image}
//               alt={testimonial.name}
//               style={imageStyle}
//             />
//             <div style={{ ...nameStyle, color: testimonial.color }}>{testimonial.name}</div>
//           </div>
//           <div style={reviewStyle}>{testimonial.review}</div>
//         </div>
//       ))}
//     </div>
//   );

//   return (
//     <div style={containerStyle}>
//       {renderColumn(0, 3)}
//       {renderColumn(3, 6)}
//       {renderColumn(6, 9)}
//     </div>
//   );
// };

// export default Testimonials;

import React from 'react';
import WAI1 from './users/WAI1.jpeg';
import WAI2 from './users/WAI2.jpeg';
import WAI3 from './users/WAI3.jpeg';

const testimonialsData = [
  {
    name: 'Sarthak',
    review: `TallyMatic is a game-changer for my accounting needs! The automatic conversion of UPI sales and bank entries into Tally, along with detailed narrations, has made my work so much simpler and faster. It’s a must-have tool for anyone looking to streamline their accounting process. 😊`,
    color: 'purple',
    image: WAI1,
  },
  {
    name: 'Yash',
    review: 'Using TallyMatic has revolutionized how I handle my finances. The comprehensive conversion feature is fantastic—it effortlessly manages UPI sales, debits, credits, and contra entries. The automatic narrations provide context that makes reviewing transactions a breeze.',
    color: 'green',
    image: WAI2,
  },
  {
    name: 'Shubham',
    review: 'TallyMatic has made managing my accounts so much easier. The instant conversion of transactions into Tally saves me hours of manual work, and the detailed descriptions ensure that everything is accurately documented. Highly recommend it for any business!',
    color: 'orange',
    image: WAI3,
  },
];

const Testimonials = () => {
  const containerStyle = {
    textAlign: 'center',
    margin: '40px 0',
  };

  const headerStyle = {
    fontSize: '32px', // Increased font size for the header
    fontWeight: 'bold',
    marginBottom: '40px',
    color:'rgb(0, 123, 255)'
  };

  const testimonialsContainerStyle = {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    gap: '20px',
    padding: '0 20px',
  };

  const cardStyle = {
    backgroundColor: '#fff',
    borderRadius: '16px',
    padding: '30px', // Increased padding for larger content area
    boxShadow: '0 4px 16px rgba(0, 0, 0, 0.1)',
    width: '350px', // Increased width for larger cards
    maxWidth: '100%',
    textAlign: 'left',
    marginBottom: '20px',
  };

  const reviewStyle = {
    fontSize: '16px', // Increased font size for the review text
    color: '#666',
    marginBottom: '20px',
    lineHeight: '1.6',
  };

  const nameContainerStyle = (index) => ({
    display: 'flex',
    alignItems: 'center',
    marginTop: '20px',
    ...(index > 0 && { position: 'relative', top: '20px' }), // Add position relative and top 20px for second and third cards
  });

  const imageStyle = {
    width: '50px',
    height: '50px',
    borderRadius: '50%',
    marginRight: '10px',
  };

  const nameStyle = {
    fontSize: '18px', // Increased font size for the name
    fontWeight: 'bold',
    color: '#333',
  };

  const roleStyle = {
    fontSize: '16px', // Increased font size for the role
    color: '#999',
  };

  return (
    <div style={containerStyle}>
      <div style={headerStyle}>Loved by 2000+ professionals</div>
      <div style={testimonialsContainerStyle}>
        {testimonialsData.map((testimonial, index) => (
          <div key={index} style={cardStyle}>
            <div style={reviewStyle}>{testimonial.review}</div>
            <div style={nameContainerStyle(index)}>
              <img
                src={testimonial.image}
                alt={testimonial.name}
                style={imageStyle}
              />
              <div>
                <div style={nameStyle}>{testimonial.name}</div>
                <div style={roleStyle}>User</div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Testimonials;
