// import React from 'react';
// import { motion } from 'framer-motion';
// import styled from 'styled-components';
// import { FiMail, FiPhone, FiMapPin } from 'react-icons/fi';
// import { Link } from 'react-router-dom';

// const Container = styled.div`
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: center;
//   height: 100vh;
//   background-color: #f5f5f5;
//   font-family: 'Poppins', sans-serif;
//   color: #333;
//   padding: 20px;
// `;

// const Title = styled(motion.h1)`
//   font-size: 42px;
//   color: #007bff;
//   margin-bottom: 20px;
//   text-align: center;
// `;

// const ContactInfo = styled(motion.div)`
//   display: flex;
//   align-items: center;
//   margin-bottom: 15px;
//   font-size: 20px;
//   color: #333;
// `;

// const Icon = styled.span`
//   margin-right: 10px;
//   color: #007bff;
//   font-size: 24px;
// `;

// const Text = styled.span`
//   font-size: 18px;
//   color: #666;
// `;

// const Nav = styled.nav`
//   width: 100%;
//   background-color: #333;
//   padding: 10px 0;
//   display: flex;
//   justify-content: flex-end;
// `;

// const NavLink = styled(Link)`
//   color: #fff;
//   text-decoration: none;
//   padding: 0 20px;
//   font-size: 18px;
//   position: relative;
//   transition: color 0.3s;

//   &::after {
//     content: '';
//     position: absolute;
//     width: 0;
//     height: 2px;
//     display: block;
//     margin-top: 5px;
//     right: 0;
//     background: #007bff;
//     transition: width 0.4s ease, right 0.4s ease;
//   }

//   &:hover {
//     color: #007bff;
//   }

//   &:hover::after {
//     width: 100%;
//     right: 0;
//   }
// `;

// const PopupOverlay = styled(motion.div)`
//   position: fixed;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 100%;
//   background: rgba(0, 0, 0, 0.5);
//   display: flex;
//   justify-content: center;
//   align-items: center;
// `;

// const PopupContent = styled(motion.div)`
//   background: #fff;
//   padding: 20px;
//   border-radius: 8px;
//   max-width: 400px;
//   width: 80%;
//   box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
//   text-align: center;
// `;

// const Footer = styled.footer`
//   width: 100%;
//   padding: 20px;
//   text-align: center;
//   background-color: #333;
//   color: #fff;
//   bottom: 0;
//   position: fixed
// `;

// const ContactPage = () => {
//   return (
//     <div>
      
//       <Container id="contact">
//       <Title
//         initial={{ opacity: 0, y: -50 }}
//         animate={{ opacity: 1, y: 0 }}
//         transition={{ duration: 1 }}
//       >
//         Contact
//       </Title>
//       <ContactInfo
//         initial={{ opacity: 0, y: 50 }}
//         animate={{ opacity: 1, y: 0 }}
//         transition={{ duration: 1, delay: 0.5 }}
//       >
//         <Icon>
//           <FiMail />
//         </Icon>
//         <Text>solutionnyx@gmail.com</Text>
//       </ContactInfo>
//       <ContactInfo
//         initial={{ opacity: 0, y: 50 }}
//         animate={{ opacity: 1, y: 0 }}
//         transition={{ duration: 1, delay: 1 }}
//       >
//         <Icon>
//           <FiPhone />
//         </Icon>
//         <Text>+91 9765723830</Text>
//       </ContactInfo>
//       <ContactInfo
//         initial={{ opacity: 0, y: 50 }}
//         animate={{ opacity: 1, y: 0 }}
//         transition={{ duration: 1, delay: 1.5 }}
//       >
//         <Icon>
//           <FiMapPin />
//         </Icon>
//         <Text>A/ 23, New Midc Area, Jalna, Maharashtra</Text>
//       </ContactInfo>
//     </Container>
//     <Footer>---------R. K. Paper Products 2024 ----</Footer>
//     </div>
//   );
// };

// export default ContactPage;

import React from 'react';
import { motion } from 'framer-motion';
import styled from 'styled-components';
import { FiMail, FiPhone, FiMapPin } from 'react-icons/fi';
import { Link } from 'react-router-dom';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 55vh;
  background-color: white;
  font-family: 'Poppins', sans-serif;
  color: black;
`;

const Title = styled(motion.h1)`
  font-size: 42px;
  color: rgb(0, 123, 255);
  margin-bottom: 20px;
  text-align: center;
`;

const ContactInfo = styled(motion.div)`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  font-size: 20px;
  color: black;
`;

const Icon = styled.span`
  margin-right: 10px;
  color: rgb(0, 128, 255);
  font-size: 24px;
`;

const Text = styled.span`
  font-size: 18px;
  color: black;
`;

const Footer = styled.footer`
  width: 100%;
  padding: 20px;
  text-align: center;
  background-color: rgb(0, 6, 24);
  color: black;
  bottom: 0;
  position: fixed;
`;

const ContactPage = () => {
  return (
    <div>
      <Container id="contact">
        <Title
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1 }}
        >
          Contact
        </Title>
        <ContactInfo
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1, delay: 0.5 }}
        >
          <Icon>
            <FiMail />
          </Icon>
          <Text>solutionnyx@gmail.com</Text>
        </ContactInfo>
        <ContactInfo
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1, delay: 1 }}
        >
          <Icon>
            <FiPhone />
          </Icon>
          <Text>+91 9765723830</Text>
        </ContactInfo>
        <ContactInfo
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1, delay: 1.5 }}
        >
          <Icon>
            <FiMapPin />
          </Icon>
          <Text>A/ 23, New Midc Area, Jalna, Maharashtra</Text>
        </ContactInfo>
      </Container>
      <Footer>---------R. K. Paper Products 2024 ----</Footer>
    </div>
  );
};

export default ContactPage;
