// // SignIn.js
// import React from 'react';
// import { SignIn } from '@clerk/clerk-react';

// const SignInPage = () => {
//   return (
//     <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
//       <SignIn path="/sign-in" redirectUrl="/gst" />
//     </div>
//   );
// };

// export default SignInPage;

import React from 'react';
import { SignIn } from '@clerk/clerk-react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: white;
`;

const SignInPage = () => {
  return (
    <Container>
      <SignIn path="/sign-in" redirectUrl="/gst" />
    </Container>
  );
};

export default SignInPage;
