
import React, { useState, useEffect } from 'react';
import { Table, Input, Button, InputNumber, Spin, Alert, Popconfirm } from 'antd';
import { SearchOutlined, DeleteOutlined } from '@ant-design/icons';
import { supabase } from './supabaseClient';
import styled from 'styled-components';
import { useDebouncedCallback } from 'use-debounce';
import moment from 'moment';
import { ChakraProvider, Box, Input as ChakraInput } from "@chakra-ui/react";
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

const Container = styled.div`
  background-color: rgb(0, 4, 29);
  color: #ffffff;
  font-family: 'Poppins', sans-serif;
  padding: 20px;
  min-height: 100vh;
`;

const Title = styled.h1`
  font-size: 32px;
  color: #007bff;
  background-color: rgb(0, 4, 29);
  margin-bottom: 20px;
`;

const createFilterDropdown = (placeholder, selectedKeys, setSelectedKeys, confirm, clearFilters) => (
  <div style={{ padding: 8 }}>
    <Input
      placeholder={placeholder}
      value={selectedKeys[0]}
      onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
      onPressEnter={() => confirm()}
      style={{ width: 188, marginBottom: 8, display: 'block' }}
    />
    <Button
      type="primary"
      onClick={() => confirm()}
      icon={<SearchOutlined />}
      size="small"
      style={{ width: 90, marginRight: 8 }}
    >
      Search
    </Button>
    <Button onClick={() => { clearFilters(); confirm(); }} size="small" style={{ width: 90 }}>
      Reset
    </Button>
  </div>
);

const padZero = (value) => value.toString().padStart(2, '0');

const formatDate = (date) => `${padZero(date.getDate())}-${padZero(date.getMonth() + 1)}-${date.getFullYear()}`;

const AdminPanelPage = () => {
  const [userData, setUserData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [inputValues, setInputValues] = useState({});

  useEffect(() => {
    fetchUserData();
  }, []);

  const fetchUserData = async () => {
    try {
      setLoading(true);
      const { data, error } = await supabase.from('users_talllymatic').select('*');

      if (error) {
        console.error('Error fetching user data:', error);
        setError('Failed to fetch user data. Please try again.');
        setLoading(false);
        return;
      }

      // Sort the data by expiry_date
      const sortedData = data.sort((a, b) => new Date(a.expiry_date) - new Date(b.expiry_date));

      setUserData(sortedData);
      setLoading(false);
    } catch (err) {
      console.error('Error fetching user data:', err);
      setError('An error occurred. Please try again.');
      setLoading(false);
    }
  };

  const handleUpdateUser = useDebouncedCallback(async (userId, updatedFields) => {
    try {
      const { error } = await supabase
        .from('users_talllymatic')
        .update(updatedFields)
        .eq('id', userId);

      if (error) {
        console.error('Error updating user:', error);
        return;
      }

      await fetchUserData();
    } catch (err) {
      console.error('Error updating user:', err);
    }
  }, 500);

  const handleDeleteUser = async (userId) => {
    try {
      const { error } = await supabase
        .from('users_talllymatic')
        .delete()
        .eq('id', userId);

      if (error) {
        console.error('Error deleting user:', error);
        return;
      }

      await fetchUserData();
    } catch (err) {
      console.error('Error deleting user:', err);
    }
  };

  const handleInputChange = (userId, field, value) => {
    setInputValues((prev) => ({
      ...prev,
      [userId]: {
        ...prev[userId],
        [field]: value,
      },
    }));
    handleUpdateUser(userId, { [field]: value });
  };

  const columns = [
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) =>
        createFilterDropdown('Search email', selectedKeys, setSelectedKeys, confirm, clearFilters),
      filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
      onFilter: (value, record) => record.email.toLowerCase().includes(value.toLowerCase()),
    },
    {
      title: 'First Name',
      dataIndex: 'first_name',
      key: 'first_name',
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) =>
        createFilterDropdown('Search first name', selectedKeys, setSelectedKeys, confirm, clearFilters),
      filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
      onFilter: (value, record) => record.first_name && record.first_name.toLowerCase().includes(value.toLowerCase()),
    },
    {
      title: 'Last Name',
      dataIndex: 'last_name',
      key: 'last_name',
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) =>
        createFilterDropdown('Search last name', selectedKeys, setSelectedKeys, confirm, clearFilters),
      filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
      onFilter: (value, record) => record.last_name && record.last_name.toLowerCase().includes(value.toLowerCase()),
    },
    {
      title: 'Phone Number',
      dataIndex: 'phone_number',
      key: 'phone_number',
      render: (text, record) => (
        <Input
          value={inputValues[record.id]?.phone_number || text}
          onChange={(e) => handleInputChange(record.id, 'phone_number', e.target.value)}
        />
      ),
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) =>
        createFilterDropdown('Search phone number', selectedKeys, setSelectedKeys, confirm, clearFilters),
      filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
      onFilter: (value, record) =>
        record.phone_number && record.phone_number.toString().toLowerCase().includes(value.toLowerCase()),
    },
    {
      title: 'Purchase Date',
      dataIndex: 'purchase_date',
      key: 'purchase_date',
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) =>
        createFilterDropdown('Search purchase date', selectedKeys, setSelectedKeys, confirm, clearFilters),
      filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
      onFilter: (value, record) => record.purchase_date && record.purchase_date.toLowerCase().includes(value.toLowerCase()),
    },
    {
      title: 'Expiry Date',
      dataIndex: 'expiry_date',
      key: 'expiry_date',
      render: (text, record) => (
        <DatePicker
          selected={text ? new Date(text) : new Date(record.created_at).setDate(new Date(record.created_at).getDate() + 3)}
          onChange={(date) => handleInputChange(record.id, 'expiry_date', moment(date).format('YYYY-MM-DD'))}
          customInput={<ChakraInput />}
        />
      ),
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) =>
        createFilterDropdown('Search expiry date', selectedKeys, setSelectedKeys, confirm, clearFilters),
      filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
      onFilter: (value, record) => {
        const expiryDate = record.expiry_date
          ? formatDate(new Date(record.expiry_date))
          : formatDate(new Date(new Date(record.created_at).getTime() + 3 * 24 * 60 * 60 * 1000));
        return expiryDate.toLowerCase().includes(value.toLowerCase());
      },
      sorter: (a, b) => new Date(a.expiry_date) - new Date(b.expiry_date),
      defaultSortOrder: 'ascend',
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) =>
        createFilterDropdown('Search amount', selectedKeys, setSelectedKeys, confirm, clearFilters),
      filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
      onFilter: (value, record) => record.amount && record.amount.toString().toLowerCase().includes(value.toLowerCase()),
    },
    {
      title: 'Token',
      dataIndex: 'token',
      key: 'token',
      render: (text, record) => (
        <InputNumber
          value={inputValues[record.id]?.token || text}
          onChange={(value) => handleInputChange(record.id, 'token', value)}
        />
      ),
    },
    {
      title: 'Additional Days',
      dataIndex: 'additional_days',
      key: 'additional_days',
      render: (text, record) => (
        <InputNumber
          value={inputValues[record.id]?.additional_days || text}
          onChange={(value) => handleInputChange(record.id, 'additional_days', value)}
        />
      ),
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Popconfirm
          title="Are you sure you want to delete this user?"
          onConfirm={() => handleDeleteUser(record.id)}
          okText="Yes"
          cancelText="No"
        >
          <Button type="primary" danger icon={<DeleteOutlined />}>
            Delete
          </Button>
        </Popconfirm>
      ),
    },
  ];

  if (loading) {
    return (
      <Container>
        <Spin size="large" />
      </Container>
    );
  }

  if (error) {
    return (
      <Container>
        <Alert message="Error" description={error} type="error" showIcon />
      </Container>
    );
  }

  return (
    <ChakraProvider>
      <Container>
        <Title>Admin Panel</Title>
        <Table dataSource={userData} columns={columns} rowKey="id" />
      </Container>
    </ChakraProvider>
  );
};

export default AdminPanelPage;
