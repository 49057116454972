// import React, { useState } from 'react';
// import { supabase } from './supabaseClient';
// import { useToast } from '@chakra-ui/react';
// import { Box, FormControl, FormLabel, Input, Button, Heading, Flex } from '@chakra-ui/react';

// const AdditionalInfoForm = ({ user, onSubmit }) => {
//   const [additionalInfo, setAdditionalInfo] = useState({
//     firstName: '',
//     lastName: '',
//     phoneNumber: '',
//   });
//   const toast = useToast();

//   const handleAdditionalInfoChange = (field, value) => {
//     setAdditionalInfo((prevInfo) => ({
//       ...prevInfo,
//       [field]: value,
//     }));
//   };

//   const handleAdditionalInfoSubmit = async () => {
//     console.log('Updating user with info:', additionalInfo);
//     console.log('Email:', user.primaryEmailAddress.emailAddress);
    
//     try {
//       const { error: updateError } = await supabase
//         .from('users_talllymatic')
//         .update({
//           first_name: additionalInfo.firstName,
//           last_name: additionalInfo.lastName,
//           phone_number: additionalInfo.phoneNumber,
//         })
//         .eq('email', user.primaryEmailAddress.emailAddress);
  
//       if (updateError) {
//         toast({
//           title: 'Error',
//           description: 'Error updating user info.',
//           status: 'error',
//           duration: 5000,
//           isClosable: true,
//         });
//         return;
//       }
  
//       toast({
//         title: 'Success',
//         description: 'User info updated successfully.',
//         status: 'success',
//         duration: 5000,
//         isClosable: true,
//       });
//       onSubmit();
//     } catch (err) {
//       toast({
//         title: 'Error',
//         description: 'Error updating user info.',
//         status: 'error',
//         duration: 5000,
//         isClosable: true,
//       });
//       console.error('Error updating user info:', err);
//     }
//   };
  
//   const formContainerStyle = {
//     display: 'flex',
//     alignItems: 'center',
//     justifyContent: 'center',
//     minHeight: '100vh',
//     background: 'linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%)',
//   };

//   const formCardStyle = {
//     background: 'rgba(255, 255, 255, 0.8)',
//     backdropFilter: 'blur(10px)',
//     borderRadius: '15px',
//     boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)',
//     padding: '40px',
//     maxWidth: '400px',
//     width: '100%',
//     textAlign: 'center',
//   };

//   const formTitleStyle = {
//     marginBottom: '24px',
//     fontSize: '24px',
//     fontWeight: '600',
//     color: '#333',
//   };

//   const formGroupStyle = {
//     marginBottom: '16px',
//   };

//   const formLabelStyle = {
//     display: 'block',
//     marginBottom: '8px',
//     fontWeight: '500',
//     color: '#555',
//   };

//   const formInputStyle = {
//     width: '90%',
//     padding: '12px',
//     borderRadius: '8px',
//     border: '1px solid #ddd',
//     boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
//     transition: 'box-shadow 0.2s',
//   };

//   const formButtonStyle = {
//     width: '100%',
//     padding: '12px',
//     border: 'none',
//     borderRadius: '8px',
//     background: '#38a169',
//     color: 'white',
//     fontSize: '16px',
//     fontWeight: '600',
//     cursor: 'pointer',
//     transition: 'background 0.2s',
//   };

//   return (
//     <Flex style={formContainerStyle}>
//       <Box style={formCardStyle}>
//         <Heading as="h3" style={formTitleStyle}>
//           Additional Information
//         </Heading>
//         <FormControl style={formGroupStyle}>
//           <FormLabel style={formLabelStyle}>First Name</FormLabel>
//           <Input
//             style={formInputStyle}
//             placeholder="First Name"
//             value={additionalInfo.firstName}
//             onChange={(e) => handleAdditionalInfoChange('firstName', e.target.value)}
//             _hover={{ boxShadow: 'md' }}
//             _focus={{ boxShadow: 'outline' }}
//           />
//         </FormControl>
//         <FormControl style={formGroupStyle}>
//           <FormLabel style={formLabelStyle}>Last Name</FormLabel>
//           <Input
//             style={formInputStyle}
//             placeholder="Last Name"
//             value={additionalInfo.lastName}
//             onChange={(e) => handleAdditionalInfoChange('lastName', e.target.value)}
//             _hover={{ boxShadow: 'md' }}
//             _focus={{ boxShadow: 'outline' }}
//           />
//         </FormControl>
//         <FormControl style={formGroupStyle}>
//           <FormLabel style={formLabelStyle}>Phone Number</FormLabel>
//           <Input
//             style={formInputStyle}
//             placeholder="Phone Number"
//             value={additionalInfo.phoneNumber}
//             onChange={(e) => handleAdditionalInfoChange('phoneNumber', e.target.value)}
//             _hover={{ boxShadow: 'md' }}
//             _focus={{ boxShadow: 'outline' }}
//           />
//         </FormControl>
//         <Button style={formButtonStyle} onClick={handleAdditionalInfoSubmit}>
//           Submit
//         </Button>
//       </Box>
//     </Flex>
//   );
// };

// export default AdditionalInfoForm;

import React, { useState } from 'react';
import { supabase } from './supabaseClient';
import { useToast } from '@chakra-ui/react';
import { Box, FormControl, FormLabel, Input, Button, Heading, Flex, Text } from '@chakra-ui/react';

const AdditionalInfoForm = ({ user, onSubmit }) => {
  const [additionalInfo, setAdditionalInfo] = useState({
    firstName: '',
    lastName: '',
    phoneNumber: '',
  });
  const [showError, setShowError] = useState(false);
  const toast = useToast();

  const handleAdditionalInfoChange = (field, value) => {
    setAdditionalInfo((prevInfo) => ({
      ...prevInfo,
      [field]: value,
    }));
    setShowError(false);
  };

  const handleAdditionalInfoSubmit = async () => {
    if (!additionalInfo.firstName || !additionalInfo.lastName || !additionalInfo.phoneNumber) {
      setShowError(true);
      return;
    }

    console.log('Updating user with info:', additionalInfo);
    console.log('Email:', user.primaryEmailAddress.emailAddress);
    
    try {
      const { error: updateError } = await supabase
        .from('users_talllymatic')
        .update({
          first_name: additionalInfo.firstName,
          last_name: additionalInfo.lastName,
          phone_number: additionalInfo.phoneNumber,
        })
        .eq('email', user.primaryEmailAddress.emailAddress);
  
      if (updateError) {
        toast({
          title: 'Error',
          description: 'Error updating user info.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
        return;
      }
  
      toast({
        title: 'Success',
        description: 'User info updated successfully.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
      onSubmit();
    } catch (err) {
      toast({
        title: 'Error',
        description: 'Error updating user info.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      console.error('Error updating user info:', err);
    }
  };
  
  const formContainerStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '100vh',
    background: 'linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%)',
  };

  const formCardStyle = {
    background: 'rgba(255, 255, 255, 0.8)',
    backdropFilter: 'blur(10px)',
    borderRadius: '15px',
    boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)',
    padding: '40px',
    maxWidth: '400px',
    width: '100%',
    textAlign: 'center',
  };

  const formTitleStyle = {
    marginBottom: '24px',
    fontSize: '24px',
    fontWeight: '600',
    color: '#333',
  };

  const formGroupStyle = {
    marginBottom: '16px',
  };

  const formLabelStyle = {
    display: 'block',
    marginBottom: '8px',
    fontWeight: '500',
    color: '#555',
  };

  const formInputStyle = {
    width: '90%',
    padding: '12px',
    borderRadius: '8px',
    border: '1px solid #ddd',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    transition: 'box-shadow 0.2s',
  };

  const formButtonStyle = {
    width: '100%',
    padding: '12px',
    border: 'none',
    borderRadius: '8px',
    background: '#38a169',
    color: 'white',
    fontSize: '16px',
    fontWeight: '600',
    cursor: 'pointer',
    transition: 'background 0.2s',
  };

  return (
    <Flex style={formContainerStyle}>
      <Box style={formCardStyle}>
        <Heading as="h3" style={formTitleStyle}>
          Additional Information
        </Heading>
        {showError && (
          <Text color="red" mb="4">
            All fields are compulsory.
          </Text>
        )}
        <FormControl style={formGroupStyle}>
          <FormLabel style={formLabelStyle}>First Name</FormLabel>
          <Input
            style={formInputStyle}
            placeholder="First Name"
            value={additionalInfo.firstName}
            onChange={(e) => handleAdditionalInfoChange('firstName', e.target.value)}
            _hover={{ boxShadow: 'md' }}
            _focus={{ boxShadow: 'outline' }}
          />
        </FormControl>
        <FormControl style={formGroupStyle}>
          <FormLabel style={formLabelStyle}>Last Name</FormLabel>
          <Input
            style={formInputStyle}
            placeholder="Last Name"
            value={additionalInfo.lastName}
            onChange={(e) => handleAdditionalInfoChange('lastName', e.target.value)}
            _hover={{ boxShadow: 'md' }}
            _focus={{ boxShadow: 'outline' }}
          />
        </FormControl>
        <FormControl style={formGroupStyle}>
          <FormLabel style={formLabelStyle}>Phone Number</FormLabel>
          <Input
            style={formInputStyle}
            placeholder="Phone Number"
            value={additionalInfo.phoneNumber}
            onChange={(e) => handleAdditionalInfoChange('phoneNumber', e.target.value)}
            _hover={{ boxShadow: 'md' }}
            _focus={{ boxShadow: 'outline' }}
          />
        </FormControl>
        <Button style={formButtonStyle} onClick={handleAdditionalInfoSubmit}>
          Submit
        </Button>
      </Box>
    </Flex>
  );
};

export default AdditionalInfoForm;
