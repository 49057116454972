// import React from 'react';
// import { motion } from 'framer-motion';
// import Join from './Join.png';

// const AccountingRevolutionPage = () => {
//   // Container style for the component
//   const containerStyle = {
//     display: 'flex',
//     flexDirection: 'row-reverse',
//     alignItems: 'center',
//     justifyContent: 'space-between',
//     padding: '40px',
//     backgroundColor: '#f5f5f5',
//     borderRadius: '12px',
//     boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
//     margin: '20px',
//     height: '100vh',
//     backgroundColor:'black'

//   };

//   // Style for the image container
//   const imageContainerStyle = {
//     flex: '1',
//     marginLeft: '20px',
//     width: '40%', // Reduced width by 20%
//   };

//   // Style for the image
//   const imageStyle = {
//     width: '100%',
//     height: 'auto',
//     borderRadius: '12px',
//   };

//   // Style for the text content
//   const contentStyle = {
//     flex: '1',
//     width: '60%', // Increased width to occupy the remaining space
//   };
//   const headingStyle = {
//     marginBottom: '20px',
//     fontSize: '2rem',
//     color: '#333',
//     color:"rgb(128, 255, 255)"
//   };

//   const paragraphStyle = {
//     marginBottom: '10px',
//     fontSize: '1.1rem',
//   };
// //   return (
// //     <div style={containerStyle}>
// //         <div style={imageContainerStyle}>
// //         <img 
// //           style={imageStyle} 
// //           src={Join} 
// //           alt="Before and After Tallymatic" 
// //         />
// //       </div>
// //       <div style={contentStyle}>
// //         <motion.h2
// //           initial={{ opacity: 0, y: 50 }}
// //           animate={{ opacity: 1, y: 0 }}
// //           transition={{ duration: 1, delay: 3 }}
// //         >
// //           Join the Accounting Revolution
// //         </motion.h2>
// //         <motion.p
// //           initial={{ opacity: 0, y: 50 }}
// //           animate={{ opacity: 1, y: 0 }}
// //           transition={{ duration: 1, delay: 3 }}
// //         >
// //           Experience the future of accounting with TallyMatic. Join our growing community of satisfied users who have already discovered the benefits of automated accounting.
// //         </motion.p>
// //         <motion.p
// //           initial={{ opacity: 0, y: 50 }}
// //           animate={{ opacity: 1, y: 0 }}
// //           transition={{ duration: 1, delay: 3.5 }}
// //         >
// //           Don’t just take our word for it – try TallyMatic today and see the difference for yourself!
// //         </motion.p>
// //       </div>
      
// //     </div>
// //   );
// return (
//     <div style={containerStyle}>
//       <div style={imageContainerStyle}>
//         <img 
//           style={imageStyle} 
//           src={Join} 
//           alt="Join the Accounting Revolution" 
//         />
//       </div>
//       <div style={contentStyle}>
//         <motion.h2
//           style={headingStyle}
//           initial={{ opacity: 0, y: 50 }}
//           animate={{ opacity: 1, y: 0 }}
//           transition={{ duration: 1, delay: 0.5 }}
//         >
//           Join the Accounting Revolution
//         </motion.h2>
//         <motion.p
//           style={paragraphStyle}
//           initial={{ opacity: 0, y: 50 }}
//           animate={{ opacity: 1, y: 0 }}
//           transition={{ duration: 1, delay: 1 }}
//         >
//           Experience the future of accounting with TallyMatic. Join our growing community of satisfied users who have already discovered the benefits of automated accounting.
//         </motion.p>
//         <motion.p
//           style={paragraphStyle}
//           initial={{ opacity: 0, y: 50 }}
//           animate={{ opacity: 1, y: 0 }}
//           transition={{ duration: 1, delay: 1.5 }}
//         >
//           <strong>Boost Productivity:</strong> Spend less time on data entry and more on strategic tasks that drive your business forward.
//         </motion.p>
//         <motion.p
//           style={paragraphStyle}
//           initial={{ opacity: 0, y: 50 }}
//           animate={{ opacity: 1, y: 0 }}
//           transition={{ duration: 1, delay: 2 }}
//         >
//           Don't just take our word for it – try TallyMatic today and see the difference for yourself!
//         </motion.p>
//       </div>
//     </div>
// )
// };

// export default AccountingRevolutionPage;

// import React from 'react';
// import { motion } from 'framer-motion';
// import Join from './Join.png';

// const containerStyle = {
//   display: 'flex',
//   flexDirection: 'row-reverse',
//   alignItems: 'center',
//   justifyContent: 'space-between',
//   padding: '40px',
//   backgroundColor: 'black',
//   borderRadius: '12px',
//   boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
//   margin: '20px',
//   flexWrap: 'wrap',
// };

// const imageContainerStyle = {
//   flex: '1',
//   marginLeft: '20px',
//   width: '40%',
//   '@media (max-width: 768px)': {
//     width: '100%',
//     marginBottom: '20px',
//   },
// };

// const imageStyle = {
//   width: '100%',
//   height: 'auto',
//   borderRadius: '12px',
// };

// const contentStyle = {
//   flex: '1',
//   width: '60%',
//   '@media (max-width: 768px)': {
//     width: '100%',
//   },
// };

// const headingStyle = {
//   marginBottom: '20px',
//   fontSize: '2rem',
//   color: 'rgb(128, 255, 255)',
// };

// const paragraphStyle = {
//   marginBottom: '10px',
//   fontSize: '1.1rem',
// };

// const AccountingRevolutionPage = () => (
//   <div style={containerStyle}>
//     <div style={imageContainerStyle}>
//       <img
//         style={imageStyle}
//         src={Join}
//         alt="Join the Accounting Revolution"
//       />
//     </div>
//     <div style={contentStyle}>
//       <motion.h2
//         style={headingStyle}
//         initial={{ opacity: 0, y: 50 }}
//         animate={{ opacity: 1, y: 0 }}
//         transition={{ duration: 1, delay: 0.5 }}
//       >
//         Join the Accounting Revolution
//       </motion.h2>
//       <motion.p
//         style={paragraphStyle}
//         initial={{ opacity: 0, y: 50 }}
//         animate={{ opacity: 1, y: 0 }}
//         transition={{ duration: 1, delay: 1 }}
//       >
//         Experience the future of accounting with TallyMatic. Join our growing community of satisfied users who have already discovered the benefits of automated accounting.
//       </motion.p>
//       <motion.p
//         style={paragraphStyle}
//         initial={{ opacity: 0, y: 50 }}
//         animate={{ opacity: 1, y: 0 }}
//         transition={{ duration: 1, delay: 1.5 }}
//       >
//         <strong>Boost Productivity:</strong> Spend less time on data entry and more on strategic tasks that drive your business forward.
//       </motion.p>
//       <motion.p
//         style={paragraphStyle}
//         initial={{ opacity: 0, y: 50 }}
//         animate={{ opacity: 1, y: 0 }}
//         transition={{ duration: 1, delay: 2 }}
//       >
//         Don't just take our word for it – try TallyMatic today and see the difference for yourself!
//       </motion.p>
//     </div>
//   </div>
// );

// export default AccountingRevolutionPage;

// import React from 'react';
// import { motion } from 'framer-motion';
// import Join from './Join.png';
// import { position } from '@chakra-ui/react';
// import { PinLeftIcon } from '@radix-ui/react-icons';

// const containerStyle = {
//   display: 'flex',
//   flexDirection: 'row-reverse',
//   alignItems: 'center',
//   justifyContent: 'space-between',
//   padding: '40px',
//   margin: '20px 0',
//   flexWrap: 'wrap',
// };

// const imageContainerStyle = {
//   flex: '1',
//   marginLeft: '20px',
//   width: '40%',

//   '@media (max-width: 768px)': {
//     width: '100%',
//     marginBottom: '20px',
//   },
// };

// const imageStyle = {
//   width: '80%',
//   height: 'auto',
//   borderRadius: '12px',
//   position:'relative',
//   left:'15%'
// };

// const contentStyle = {
//   flex: '1',
//   width: '60%',

//   '@media (max-width: 768px)': {
//     width: '100%',
//   },
// };

// const swipeFilesStyle = {
//   fontSize: '18px',
//   color: 'rgb(0, 123, 255)', // Adjusted font color
//   marginBottom: '10px',
//   fontStyle: 'italic', // Italic style

//   '@media (max-width: 768px)': {
//     fontSize: '16px',
//     marginBottom: '5px',
//   },
// };

// const headingStyle = {
//   marginBottom: '20px',
//   fontSize: '36px', // Adjusted font size
//   color: 'black', // Adjusted font color
//   fontWeight: 'bold',

//   '@media (max-width: 768px)': {
//     fontSize: '28px',
//     marginBottom: '10px',
//   },
// };

// const paragraphStyle = {
//   marginBottom: '10px',
//   fontSize: '18px', // Adjusted font size
//   color: 'black', // Adjusted font color

//   '@media (max-width: 768px)': {
//     fontSize: '16px',
//   },
// };

// const featureStyle = {
//   display: 'flex',
//   alignItems: 'center',
//   fontSize: '18px',
//   color: 'black', // Adjusted font color
//   marginBottom: '15px',

//   '@media (max-width: 768px)': {
//     fontSize: '16px',
//     marginBottom: '10px',
//   },
// };

// const iconStyle = {
//   marginRight: '10px',
//   color: 'rgb(0, 123, 255)', // Adjusted icon color
// };

// const AccountingRevolutionPage = () => (
//   <div style={containerStyle}>
//     <div style={imageContainerStyle}>
//       <img
//         style={imageStyle}
//         src={Join}
//         alt="Join the Accounting Revolution"
//       />
//     </div>
//     <div style={contentStyle}>
//       <motion.div
//         initial={{ opacity: 0, y: 50 }}
//         animate={{ opacity: 1, y: 0 }}
//         transition={{ duration: 1, delay: 0.5 }}
//       >
//         {/* <div style={swipeFilesStyle}>Swipe files</div> */}
//         <h2 style={headingStyle}>Join the Accounting Revolution</h2>
//         <div style={featureStyle}>
//           <span style={iconStyle}>✔</span> Experience the future of accounting with TallyMatic. Join our growing community of satisfied users who have already discovered the benefits of automated accounting.
//         </div>
//         <div style={featureStyle}>
//           <span style={iconStyle}>✔</span> <strong>Boost Productivity:</strong> Spend less time on data entry and more on strategic tasks that drive your business forward.
//         </div>
//         <div style={featureStyle}>
//           <span style={iconStyle}>✔</span> Don't just take our word for it – try TallyMatic today and see the difference for yourself!
//         </div>
//       </motion.div>
//     </div>
//   </div>
// );

// export default AccountingRevolutionPage;

import React from 'react';
import { motion } from 'framer-motion';
import Join from './Join.png';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;
  padding: 40px;
  margin: 20px 0;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const ImageContainer = styled.div`
  flex: 1;
  margin-left: 20px;
  width: 40%;

  @media (max-width: 768px) {
    width: 100%;
    margin-bottom: 20px;
  }
`;

const Image = styled.img`
  width: 80%;
  height: auto;
  border-radius: 12px;
  position: relative;
  left: 15%;

  @media (max-width: 768px) {
    width: 100%;
    left: 0;
  }
`;

const Content = styled.div`
  flex: 1;
  width: 60%;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const SwipeFiles = styled.div`
  font-size: 18px;
  color: rgb(0, 123, 255);
  margin-bottom: 10px;
  font-style: italic;

  @media (max-width: 768px) {
    font-size: 16px;
    margin-bottom: 5px;
  }
`;

const Heading = styled.h2`
  margin-bottom: 20px;
  font-size: 36px;
  color: black;
  font-weight: bold;

  @media (max-width: 768px) {
    font-size: 28px;
    margin-bottom: 10px;
  }
`;

const Paragraph = styled.p`
  margin-bottom: 10px;
  font-size: 18px;
  color: black;

  @media (max-width: 768px) {
    font-size: 16px;
  }
`;

const Feature = styled.div`
  display: flex;
  align-items: center;
  font-size: 18px;
  color: black;
  margin-bottom: 15px;

  @media (max-width: 768px) {
    font-size: 16px;
    margin-bottom: 10px;
  }
`;

const Icon = styled.span`
  margin-right: 10px;
  color: rgb(0, 123, 255);
`;

const AccountingRevolutionPage = () => (
  <Container>
    <ImageContainer>
      <Image
        src={Join}
        alt="Join the Accounting Revolution"
      />
    </ImageContainer>
    <Content>
  <motion.div
    initial={{ opacity: 0, y: 50 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ duration: 1, delay: 0.5 }}
  >
    {/* <SwipeFiles>Swipe files</SwipeFiles> */}
    <Heading>Simplify Bank Statement Conversion with TallyMatic</Heading>
    <Paragraph>
    Effortless Conversion: Easily convert your bank statements into Tally-compatible formats with TallyMatic. Say goodbye to manual data entry and hello to efficiency.
    </Paragraph>
    <Feature>
      <Icon>✔</Icon>
      <span>Assign Multiple Entries in One Click: Quickly assign multiple bank statement entries with a single click, drastically reducing the time spent on tedious tasks.</span>
    </Feature>
    <Feature>
      <Icon>✔</Icon>
      <span>Months of UPI Sales Entries in One Click: Seamlessly import and convert months of UPI sales entries into Tally in just one click. Streamline your financial processes like never before.</span>
    </Feature>
    <Feature>
      <Icon>✔</Icon>
      <span>Automated Data Entry: Save time and reduce errors with our automated bank statement conversion. TallyMatic ensures accurate and reliable data entry, every time.</span>
    </Feature>
    <Feature>
      <Icon>✔</Icon>
      <span>Real-Time Insights: Gain immediate access to real-time financial insights by effortlessly converting your bank statements. Stay on top of your business performance with ease.</span>
    </Feature>
    <Feature>
      <Icon>✔</Icon>
      <span>Compliance and Accuracy: Ensure compliance and minimize errors with our advanced automation features. TallyMatic helps you maintain accurate records without the hassle.</span>
    </Feature>
    <Paragraph>
      Don't just take our word for it – try TallyMatic today and see the difference for yourself!
    </Paragraph>
  </motion.div>
</Content>
  </Container>
);

export default AccountingRevolutionPage;
