// import React from 'react';
// import { motion } from 'framer-motion';
// import styled from 'styled-components';
// import { Link } from 'react-router-dom';
// import Navigation from './Navigation';
// import BeforeAfterImage from './BeforeAfterImage';
// import AccountingRevolutionPage from './AccountingRevolutionPage';
// import TextAndGif from './TextAndGif'

// const Container = styled.div`
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: center;
//   padding: 40px 20px;
//   background-color: rgb(0, 4, 29);
//   font-family: 'Poppins', sans-serif;
//   color: rgb(255, 255, 255);
// `;

// const Title = styled(motion.h1)`
//   font-size: 42px;
//   color: rgb(128, 255, 255);
//   margin-bottom: 20px;
//   text-align: center;
// `;

// const SectionTitle = styled(motion.h2)`
//   font-size: 32px;
//   color: rgb(255, 255, 255);
//   margin-top: 30px;
//   margin-bottom: 15px;
//   text-align: center;
// `;

// const Paragraph = styled(motion.p)`
//   font-size: 18px;
//   line-height: 1.8;
//   margin-bottom: 15px;
//   text-align: justify;
//   max-width: 800px;
// `;

// const List = styled(motion.ul)`
//   font-size: 18px;
//   line-height: 1.8;
//   margin-bottom: 15px;
//   max-width: 800px;
//   padding-left: 20px;
// `;

// const ListItem = styled(motion.li)`
//   margin-bottom: 10px;
//   color: rgb(128, 255, 255);
// `;

// const Footer = styled.footer`
//   width: 100%;
//   padding: 20px;
//   text-align: center;
//   background-color: rgb(0, 6, 24);
//   color: rgb(255, 255, 255);
//   bottom: 0;
//   position: fixed;
// `;

// // const AboutPage = () => {
// //   return (
// //     <div>

// //       <Container id="about">
// //         <Title
// //           initial={{ opacity: 0, y: -50 }}
// //           animate={{ opacity: 1, y: 0 }}
// //           transition={{ duration: 1 }}
// //         >
// //           About Us
// //         </Title>
// //         <Paragraph
// //           initial={{ opacity: 0, y: 50 }}
// //           animate={{ opacity: 1, y: 0 }}
// //           transition={{ duration: 1, delay: 0.5 }}
// //         >
// //           <strong>All entries can be easily converted into Payment, Receipt, Contra, and Sales automatically</strong>
// //         </Paragraph>
// //         <Paragraph
// //           initial={{ opacity: 0, y: 50 }}
// //           animate={{ opacity: 1, y: 0 }}
// //           transition={{ duration: 1, delay: 0.5 }}
// //         >
// //           Narration will be added to the voucher automatically
// //         </Paragraph>
// //         <BeforeAfterImage />

// //         <AccountingRevolutionPage />

// // <TextAndGif/>

// //       </Container>
// //       <Footer>---------R. K. Paper Products 2024 ----</Footer>
// //     </div>
// //   );
// // };
// const AboutPage = () => {
//   return (
//     <div>
//       <Container id="about">
//         <Title
//           initial={{ opacity: 0, y: -50 }}
//           animate={{ opacity: 1, y: 0 }}
//           transition={{ duration: 1 }}
//         >
//           About Us
//         </Title>
//         <Paragraph
//           initial={{ opacity: 0, y: 50 }}
//           animate={{ opacity: 1, y: 0 }}
//           transition={{ duration: 1, delay: 0.5 }}
//         >
//           <strong>Comprehensive Conversion:</strong> TallyMatic effortlessly handles UPI sales, debits, credits, and contra entries, converting them seamlessly from bank statements into Tally.
//         </Paragraph>
//         <Paragraph
//           initial={{ opacity: 0, y: 50 }}
//           animate={{ opacity: 1, y: 0 }}
//           transition={{ duration: 1, delay: 0.5 }}
//         >
//           <strong>Automatic Narration:</strong> Every entry is enhanced with automatic narration, providing crucial context and improving clarity in your accounting records.
//         </Paragraph>
//         <TextAndGif />
//         <AccountingRevolutionPage />
//         <BeforeAfterImage />
//       </Container>
//       <Footer>---------R. K. Paper Products 2024 ----</Footer>
//     </div>
//   );
// }
// export default AboutPage;

import React from 'react';
import { motion } from 'framer-motion';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Navigation from './Navigation';
import BeforeAfterImage from './BeforeAfterImage';
import AccountingRevolutionPage from './AccountingRevolutionPage';
import TextAndGif from './TextAndGif'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px 20px;
  background-color: #f9fafb;
  font-family: 'Poppins', sans-serif;
  color: rgb(255, 255, 255);

  @media (max-width: 768px) {
    padding: 20px 10px;
  }
`;

const Title = styled(motion.h1)`
  font-size: 42px;
  color: rgb(0, 123, 255);
  margin-bottom: 20px;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 32px;
  }
`;

const SectionTitle = styled(motion.h2)`
  font-size: 32px;
  color: rgb(255, 255, 255);
  margin-top: 30px;
  margin-bottom: 15px;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 24px;
  }
`;

const Paragraph = styled(motion.p)`
  font-size: 18px;
  line-height: 1.8;
  margin-bottom: 15px;
  text-align: justify;
  max-width: 800px;
  color: black;

  @media (max-width: 768px) {
    font-size: 16px;
    text-align: left;
  }
`;

const List = styled(motion.ul)`
  font-size: 18px;
  line-height: 1.8;
  margin-bottom: 15px;
  max-width: 800px;
  padding-left: 20px;

  @media (max-width: 768px) {
    font-size: 16px;
  }
`;

const ListItem = styled(motion.li)`
  margin-bottom: 10px;
  color: rgb(128, 255, 255);
`;

const Footer = styled.footer`
  width: 100%;
  padding: 20px;
  text-align: center;
  background-color: rgb(0, 6, 24);
  color: rgb(255, 255, 255);
  bottom: 0;
  position: fixed;

  @media (max-width: 768px) {
    padding: 10px;
    font-size: 14px;
  }
`;

const AboutPage = () => {
  return (
    <div>
      <Container id="about">
        <Title
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1 }}
        >
          About Us
        </Title>
        <Paragraph
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1, delay: 0.5 }}
        >
          <strong>Comprehensive Conversion:</strong> TallyMatic effortlessly handles UPI sales, debits, credits, and contra entries, converting them seamlessly from bank statements into Tally.
        </Paragraph>
        <Paragraph
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1, delay: 0.5 }}
        >
          <strong>Automatic Narration:</strong> Every entry is enhanced with automatic narration, providing crucial context and improving clarity in your accounting records.
        </Paragraph>
        {/* <TextAndGif />
        <AccountingRevolutionPage />
        <BeforeAfterImage /> */}
      </Container>
      <Footer>---------R. K. Paper Products 2024 ----</Footer>
    </div>
  );
}
export default AboutPage;
