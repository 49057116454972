// import React from 'react';
// import Testimonials from './Testimonials';
// import TextAndGif from './TextAndGif';
// import BeforeAfterImage from './BeforeAfterImage';
// const statsData = [
//   { icon: '📄', value: '195M +', description: 'Transactions Processed', color: 'purple' },
//   { icon: '⏰', value: '1.9M +', description: 'Hours Saved', color: 'green' },
//   { icon: '🏆', value: '87k +', description: 'License Sold', color: 'orange' },
//   { icon: '👤', value: '24k +', description: 'Registered Users', color: 'blue' },
// ];

// const StatsCards = () => {
//   const cardStyle = {
//     backgroundColor: '#fff',
//     borderRadius: '8px',
//     padding: '20px',
//     boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
//     textAlign: 'center',
//     flex: '1',
//     margin: '10px',
//     maxWidth: '250px',
    
//   };

//   const iconStyle = {
//     fontSize: '40px',
//     marginBottom: '10px',
//   };

//   const valueStyle = {
//     fontSize: '24px',
//     fontWeight: 'bold',
//     marginBottom: '5px',
//   };

//   const descriptionStyle = {
//     fontSize: '16px',
//     color: '#666',
//   };

//   return (
//     <div style={{backgroundColor: 'rgb(0, 4, 29)'}}>
//     <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', }}>
//       {statsData.map((stat, index) => (
//         <div key={index} style={cardStyle}>
//           <div style={{ ...iconStyle, color: stat.color }}>{stat.icon}</div>
//           <div style={valueStyle}>{stat.value}</div>
//           <div style={descriptionStyle}>{stat.description}</div>
//         </div>
//       ))}
      
//     </div>
//     <div>
//       {/* <BeforeAfterImage/> */}
//     <Testimonials/>
//     {/* <TextAndGif/> */}
//     </div>
//     </div>
//   );
// };

// export default StatsCards;

import React from 'react';
import Testimonials from './Testimonials';
import TextAndGif from './TextAndGif';
import BeforeAfterImage from './BeforeAfterImage';

const statsData = [
  { icon: '📄', value: '195M +', description: 'Transactions Processed', color: 'purple' },
  { icon: '⏰', value: '1.9M +', description: 'Hours Saved', color: 'green' },
  { icon: '🏆', value: '87k +', description: 'License Sold', color: 'orange' },
  { icon: '👤', value: '24k +', description: 'Registered Users', color: 'blue' },
];

const cardStyle = {
  backgroundColor: '#fff',
  borderRadius: '8px',
  padding: '20px',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  textAlign: 'center',
  flex: '1',
  margin: '10px',
  maxWidth: '250px',
  
  '@media (max-width: 768px)': {
    margin: '10px auto',
    maxWidth: '90%',
  },
};

const iconStyle = {
  fontSize: '40px',
  marginBottom: '10px',
};

const valueStyle = {
  fontSize: '24px',
  fontWeight: 'bold',
  marginBottom: '5px',
};

const descriptionStyle = {
  fontSize: '16px',
  color: '#666',
};

const containerStyle = {
  backgroundColor: 'white',
  padding: '20px',

  '@media (max-width: 768px)': {
    padding: '10px',
  },
};

const statsContainerStyle = {
  display: 'flex',
  justifyContent: 'space-around',
  alignItems: 'center',
  flexWrap: 'wrap',
};

const StatsCards = () => {
  return (
    <div style={containerStyle}>
      <div style={statsContainerStyle}>
        {statsData.map((stat, index) => (
          <div key={index} style={cardStyle}>
            <div style={{ ...iconStyle, color: stat.color }}>{stat.icon}</div>
            <div style={valueStyle}>{stat.value}</div>
            <div style={descriptionStyle}>{stat.description}</div>
          </div>
        ))}
      </div>
      <div>
        <Testimonials />
        {/* <TextAndGif /> */}
      </div>
    </div>
  );
};

export default StatsCards;
