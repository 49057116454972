// import React from 'react';
// import ArrowRightIcon from '@mui/icons-material/ArrowRight';
// import gif from './gif.gif';
// import { motion } from 'framer-motion';

// const TextAndGif = () => {
//   const containerStyle = {
//     display: 'flex',
//     alignItems: 'center',
//     justifyContent: 'space-around',
//     padding: '40px',
//     backgroundColor: 'rgb(0, 4, 29)',
//     borderRadius: '12px',
//     boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
//     margin: '20px',
//     backgroundColor:'rgb(0, 4, 29)'

//   };

//   const textContainerStyle = {
//     maxWidth: '600px',
//     marginRight: '20px',
//     position: 'relative',
//     left: '5%',
//   };

//   const titleStyle = {
//     fontSize: '32px',
//     color: '#fff',
//     marginBottom: '20px',
//   };

//   const featureStyle = {
//     display: 'flex',
//     alignItems: 'center',
//     fontSize: '20px',
//     color: '#fff',
//     marginBottom: '15px',
//   };

//   const iconStyle = {
//     marginRight: '10px',
//     color: '#fff',
//   };

//   const gifContainerStyle = {
//     border: '5px solid #ddd',
//     borderRadius: '12px',
//     padding: '10px',
//     position: 'relative',
//     right: '5%',
//   };

//   const gifStyle = {
//     width: '675px',  // Adjusted width
//     height: '375px',
//     borderRadius: '12px',
//   };

//   return (
//     <div style={containerStyle}>
//       <div style={gifContainerStyle}>
//         <img 
//           style={gifStyle} 
//           src={gif} 
//           alt="Productivity Enhancement" 
//         />
//       </div>
//       <motion.div 
//         style={textContainerStyle}
//         initial={{ opacity: 0, x: -50 }}
//         animate={{ opacity: 1, x: 0 }}
//         transition={{ duration: 0.5 }}
//       >
//         <h2 style={titleStyle}>Key Features</h2>
//         <div style={featureStyle}><ArrowRightIcon style={iconStyle} /> Few clicks to assign ledger</div>
//         <div style={featureStyle}><ArrowRightIcon style={iconStyle} /> Directly import in Tally</div>
//         <div style={featureStyle}><ArrowRightIcon style={iconStyle} /> Saves time</div>
//         <div style={featureStyle}><ArrowRightIcon style={iconStyle} /> Cash entries to be marked as contra</div>
//         <div style={featureStyle}><ArrowRightIcon style={iconStyle} /> Payment and Receipt entries to be assigned automatically</div>
//         <div style={featureStyle}><ArrowRightIcon style={iconStyle} /> Narrations will be assigned automatically</div>
//       </motion.div>
//     </div>
//   );
// };

// export default TextAndGif;

// import React from 'react';
// import ArrowRightIcon from '@mui/icons-material/ArrowRight';
// import gif from './gif.gif';
// import { motion } from 'framer-motion';

// const containerStyle = {
//   display: 'flex',
//   alignItems: 'center',
//   justifyContent: 'space-around',
//   padding: '40px',
//   backgroundColor: 'rgb(0, 4, 29)',
//   borderRadius: '12px',
//   boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
//   margin: '20px',
//   backgroundColor: 'rgb(0, 4, 29)',
//   flexWrap: 'wrap',
// };

// const textContainerStyle = {
//   maxWidth: '600px',
//   marginRight: '20px',
//   position: 'relative',
//   left: '5%',
//   marginBottom: '20px',
// };

// const titleStyle = {
//   fontSize: '32px',
//   color: '#fff',
//   marginBottom: '20px',
// };

// const featureStyle = {
//   display: 'flex',
//   alignItems: 'center',
//   fontSize: '20px',
//   color: '#fff',
//   marginBottom: '15px',
// };

// const iconStyle = {
//   marginRight: '10px',
//   color: '#fff',
// };

// const gifContainerStyle = {
//   border: '5px solid #ddd',
//   borderRadius: '12px',
//   padding: '10px',
//   position: 'relative',
//   right: '5%',
// };

// const gifStyle = {
//   width: '675px',  // Adjusted width
//   height: '375px',
//   borderRadius: '12px',

//   '@media (max-width: 768px)': {
//     width: '100%',  // Make the gif responsive
//     height: 'auto',
//   },
// };

// const TextAndGif = () => (
//   <div style={containerStyle}>
//     <div style={gifContainerStyle}>
//       <img
//         style={gifStyle}
//         src={gif}
//         alt="Productivity Enhancement"
//       />
//     </div>
//     <motion.div
//       style={textContainerStyle}
//       initial={{ opacity: 0, x: -50 }}
//       animate={{ opacity: 1, x: 0 }}
//       transition={{ duration: 0.5 }}
//     >
//       <h2 style={titleStyle}>Key Features</h2>
//       <div style={featureStyle}><ArrowRightIcon style={iconStyle} /> Few clicks to assign ledger</div>
//       <div style={featureStyle}><ArrowRightIcon style={iconStyle} /> Directly import in Tally</div>
//       <div style={featureStyle}><ArrowRightIcon style={iconStyle} /> Saves time</div>
//       <div style={featureStyle}><ArrowRightIcon style={iconStyle} /> Cash entries to be marked as contra</div>
//       <div style={featureStyle}><ArrowRightIcon style={iconStyle} /> Payment and Receipt entries to be assigned automatically</div>
//       <div style={featureStyle}><ArrowRightIcon style={iconStyle} /> Narrations will be assigned automatically</div>
//     </motion.div>
//   </div>
// );

// export default TextAndGif;
// import React from 'react';
// import ArrowRightIcon from '@mui/icons-material/ArrowRight';
// import gif from './gif.gif';
// import { motion } from 'framer-motion';

// const containerStyle = {
//   display: 'flex',
//   alignItems: 'center',
//   justifyContent: 'space-around',
//   padding: '40px',
//   backgroundColor: 'rgb(0, 4, 29)',
//   borderRadius: '12px',
//   boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
//   margin: '20px',
//   backgroundColor: 'rgb(0, 4, 29)',
//   flexWrap: 'wrap',

//   '@media (max-width: 768px)': {
//     flexDirection: 'column',
//     padding: '20px',
//     margin: '10px',
//   },
// };

// const textContainerStyle = {
//   maxWidth: '600px',
//   marginRight: '20px',
//   position: 'relative',
//   left: '5%',
//   marginBottom: '20px',

//   '@media (max-width: 768px)': {
//     maxWidth: '100%',
//     marginRight: '0',
//     left: '0',
//     marginBottom: '10px',
//   },
// };

// const titleStyle = {
//   fontSize: '32px',
//   color: '#fff',
//   marginBottom: '20px',

//   '@media (max-width: 768px)': {
//     fontSize: '24px',
//     marginBottom: '10px',
//   },
// };

// const featureStyle = {
//   display: 'flex',
//   alignItems: 'center',
//   fontSize: '20px',
//   color: '#fff',
//   marginBottom: '15px',

//   '@media (max-width: 768px)': {
//     fontSize: '18px',
//     marginBottom: '10px',
//   },
// };

// const iconStyle = {
//   marginRight: '10px',
//   color: '#fff',
// };

// const gifContainerStyle = {
//   border: '5px solid #ddd',
//   borderRadius: '12px',
//   padding: '10px',
//   position: 'relative',
//   right: '5%',

//   '@media (max-width: 768px)': {
//     width: '100%',
//     right: '0',
//     padding: '5px',
//   },
// };

// const gifStyle = {
//   width: '675px',  // Adjusted width
//   height: '375px',
//   borderRadius: '12px',

//   '@media (max-width: 768px)': {
//     width: '100%',  // Make the gif responsive
//     height: 'auto',
//   },
// };

// const TextAndGif = () => (
//   <div style={containerStyle}>
//     <div style={gifContainerStyle}>
//       <img
//         style={gifStyle}
//         src={gif}
//         alt="Productivity Enhancement"
//       />
//     </div>
//     <motion.div
//       style={textContainerStyle}
//       initial={{ opacity: 0, x: -50 }}
//       animate={{ opacity: 1, x: 0 }}
//       transition={{ duration: 0.5 }}
//     >
//       <h2 style={titleStyle}>Key Features</h2>
//       <div style={featureStyle}><ArrowRightIcon style={iconStyle} /> Few clicks to assign ledger</div>
//       <div style={featureStyle}><ArrowRightIcon style={iconStyle} /> Directly import in Tally</div>
//       <div style={featureStyle}><ArrowRightIcon style={iconStyle} /> Saves time</div>
//       <div style={featureStyle}><ArrowRightIcon style={iconStyle} /> Cash entries to be marked as contra</div>
//       <div style={featureStyle}><ArrowRightIcon style={iconStyle} /> Payment and Receipt entries to be assigned automatically</div>
//       <div style={featureStyle}><ArrowRightIcon style={iconStyle} /> Narrations will be assigned automatically</div>
//     </motion.div>
//   </div>
// );

// export default TextAndGif;

// import React from 'react';
// import gif from './gif.gif';
// import { motion } from 'framer-motion';
// import { color } from '@chakra-ui/react';

// const containerStyle = {
//   display: 'flex',
//   alignItems: 'center',
//   justifyContent: 'space-between',
//   padding: '40px',
//   margin: '20px 0',
//   flexWrap: 'wrap',
// };

// const textContainerStyle = {
//   maxWidth: '600px',
//   marginRight: '20px',
//   marginBottom: '20px',
//       position: 'relative',
//     //left: '5%',

//   '@media (max-width: 768px)': {
//     maxWidth: '100%',
//     marginRight: '0',
//     marginBottom: '10px',
//   },
// };

// const swipeFilesStyle = {
//   fontSize: '18px',
//   color: 'rgb(0, 123, 255)', // Adjusted font color
//   marginBottom: '10px',
//   fontStyle: 'italic', // Italic style

//   '@media (max-width: 768px)': {
//     fontSize: '16px',
//     marginBottom: '5px',
//   },
// };

// const titleStyle = {
//   fontSize: '36px', // Adjusted font size
//   color: 'rgb(0, 4, 29)', // Adjusted font color
//   marginBottom: '20px',
//   fontWeight: 'bold',
//   color:'black',

//   '@media (max-width: 768px)': {
//     fontSize: '28px',
//     marginBottom: '10px',
//   },
// };

// const subtitleStyle = {
//   fontSize: '18px', // Adjusted font size
//   color: 'rgb(0, 4, 29)', // Adjusted font color
//   marginBottom: '30px',
//   color:'black',

//   '@media (max-width: 768px)': {
//     fontSize: '16px',
//     marginBottom: '15px',
//   },
// };

// const featureStyle = {
//   display: 'flex',
//   alignItems: 'center',
//   fontSize: '18px',
//   color:'black',  marginBottom: '15px',

//   '@media (max-width: 768px)': {
//     fontSize: '16px',
//     marginBottom: '10px',
//   },
// };

// const iconStyle = {
//   marginRight: '10px',
//   //color: 'rgb(0, 4, 29)', // Adjusted icon color
//   color:'rgb(0, 123, 255)',
// };

// const gifContainerStyle = {
//   border: '5px solid #ddd',
//   borderRadius: '12px',
//   padding: '10px',
//       position: 'relative',
//     //right: '5%',

//   '@media (max-width: 768px)': {
//     width: '100%',
//     padding: '5px',
//   },
// };

// const gifStyle = {
//   width: '675px', // Adjusted width
//   height: '375px',
//   borderRadius: '12px',

//   '@media (max-width: 768px)': {
//     width: '100%', // Make the gif responsive
//     height: 'auto',
//   },
// };

// const buttonStyle = {
//   padding: '10px 20px',
//   border: '2px solid rgb(0, 123, 255)', // Adjusted button border color
//   borderRadius: '5px',
//   color: 'rgb(0, 123, 255)', // Adjusted button text color
//   backgroundColor: 'transparent',
//   cursor: 'pointer',
//   fontSize: '16px',
//   fontWeight: 'bold',
//   marginTop: '20px',
//   display: 'inline-block',
//   textDecoration: 'none',

//   '@media (max-width: 768px)': {
//     fontSize: '14px',
//     padding: '8px 16px',
//   },
// };

// const TextAndGif = () => (
//   <div style={containerStyle}>
//     <div style={gifContainerStyle}>
//       <img
//         style={gifStyle}
//         src={gif}
//         alt="Productivity Enhancement"
//       />
//     </div>
//     <div style={textContainerStyle}>
//       <motion.div
//         initial={{ opacity: 0, x: -50 }}
//         animate={{ opacity: 1, x: 0 }}
//         transition={{ duration: 0.5 }}
//       >
//         {/* <div style={swipeFilesStyle}>Swipe files</div> */}
//         <h2 style={titleStyle}>Make work easy with TallyMatic</h2>
//         <p style={subtitleStyle}>
//           Key Features of TellyMatic
//         </p>
//         <div style={featureStyle}>
//           <span style={iconStyle}>✔</span> Few clicks to assign ledger
//         </div>
//         <div style={featureStyle}>
//           <span style={iconStyle}>✔</span> Directly import in Tally
//         </div>
//         <div style={featureStyle}>
//           <span style={iconStyle}>✔</span> Saves time
//         </div>
//         <div style={featureStyle}>
//           <span style={iconStyle}>✔</span> Cash entries to be marked as contra
//         </div>
//         <div style={featureStyle}>
//           <span style={iconStyle}>✔</span> Payment and Receipt entries to be assigned automatically
//         </div>
//         <div style={featureStyle}>
//           <span style={iconStyle}>✔</span> Narrations will be assigned automatically
//         </div>
//         <a href="/" style={buttonStyle}>Get Start</a>
//       </motion.div>
//     </div>
//     {/* <div style={gifContainerStyle}>
//       <img
//         style={gifStyle}
//         src={gif}
//         alt="Productivity Enhancement"
//       />
//     </div> */}
//   </div>
// );

// export default TextAndGif;

import React from 'react';
import gif from './gif.gif';
import { motion } from 'framer-motion';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 40px;
  margin: 20px 0;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const TextContainer = styled.div`
  max-width: 600px;
  margin-right: 20px;
  margin-bottom: 20px;
  position: relative;

  @media (max-width: 768px) {
    max-width: 100%;
    margin-right: 0;
    margin-bottom: 10px;
    text-align: center;
  }
`;

const SwipeFiles = styled.div`
  font-size: 18px;
  color: rgb(0, 123, 255); // Adjusted font color
  margin-bottom: 10px;
  font-style: italic;

  @media (max-width: 768px) {
    font-size: 16px;
    margin-bottom: 5px;
  }
`;

const Title = styled.h2`
  font-size: 36px;
  color: black;
  margin-bottom: 20px;
  font-weight: bold;

  @media (max-width: 768px) {
    font-size: 28px;
    margin-bottom: 10px;
  }
`;

const Subtitle = styled.p`
  font-size: 18px;
  color: black;
  font-weight: bold;

  margin-bottom: 30px;

  @media (max-width: 768px) {
    font-size: 16px;
    margin-bottom: 15px;
  }
`;

const Feature = styled.div`
  display: flex;
  align-items: center;
  font-size: 18px;
  color: black;
  margin-bottom: 15px;

  @media (max-width: 768px) {
    font-size: 16px;
    margin-bottom: 10px;
    justify-content: center;
  }
`;

const Icon = styled.span`
  margin-right: 10px;
  color: rgb(0, 123, 255);
`;

const GifContainer = styled.div`
  border: 5px solid #ddd;
  border-radius: 12px;
  padding: 10px;
  position: relative;

  @media (max-width: 768px) {
    width: 100%;
    padding: 5px;
    margin-bottom: 20px;
  }
`;

const Gif = styled.img`
  width: 675px;
  height: 375px;
  border-radius: 12px;

  @media (max-width: 768px) {
    width: 100%;
    height: auto;
  }
`;

const Button = styled.a`
  padding: 10px 20px;
  border: 2px solid rgb(0, 123, 255);
  border-radius: 5px;
  color: rgb(0, 123, 255);
  background-color: transparent;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  margin-top: 20px;
  display: inline-block;
  text-decoration: none;

  @media (max-width: 768px) {
    font-size: 14px;
    padding: 8px 16px;
  }
`;

const TextAndGif = () => (
  <Container>
    <GifContainer>
      <Gif src={gif} alt="Productivity Enhancement" />
    </GifContainer>
    <TextContainer>
      <motion.div
        initial={{ opacity: 0, x: -50 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ duration: 0.5 }}
      >
        {/* <SwipeFiles>Swipe files</SwipeFiles> */}
        <Title>Bank Statement to Tally with TallyMatic</Title>
        <Subtitle>Key Features of TallyMatic</Subtitle>
        <div style={{display:'flex', flexDirection:'column', alignItems:'flex-start'}}>
        <Feature>
          <Icon>✔</Icon> Assign multiple entries in One click
        </Feature>
        <Feature>
          <Icon>✔</Icon> Months of UPI Sales Entries in One Click
        </Feature>
        <Feature>
          <Icon>✔</Icon> Few clicks to assign ledger
        </Feature>
        <Feature>
          <Icon>✔</Icon> Directly import in Tally
        </Feature>
        <Feature>
          <Icon>✔</Icon> Saves time
        </Feature>
        <Feature>
          <Icon>✔</Icon> Cash entries to be marked as contra
        </Feature>
        <Feature>
          <Icon>✔</Icon> Payment and Receipt entries to be assigned automatically
        </Feature>
        <Feature>
          <Icon>✔</Icon> Narrations will be assigned automatically
        </Feature>
        </div>
        <Button href="/">Get Started</Button>
      </motion.div>
    </TextContainer>
  </Container>
);

export default TextAndGif;
